import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { OpportunityUpdateSelectedResponse } from '@/Types/OpportunityAmongCompetition';

const updateSelected = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}) => {
  const url = `/projects/${projectSlug}/opportunity/update-selected/${id}`;

  try {
    const response = await goFetcher.put(url, {});
    return response.data as OpportunityUpdateSelectedResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedOpportunity = () => {
  return useMutation<
    OpportunityUpdateSelectedResponse,
    Error,
    { id: number; projectSlug: string }
  >({
    mutationFn: updateSelected,
  });
};

export { useUpdateSelectedOpportunity };
