import AudienceArchetypeRoutes from './AudienceArchetype';
import AuthRoutes from './Auth';
import ChallengeAndTaskRoutes from './ChallengeAndTask';
import CompetitorAnalysisRoutes from './CompetitorAnalysis';
import CredibilityContentRoutes from './CredibilityContent';
import DiscoverabilityContentRoutes from './DiscoverabilityContent';
import HomeRoutes from './Home';
import IdeaAlignmentRoutes from './IdeaAlignment';
import IdeaExpansionRoutes from './IdeaExpansion';
import IdeaPersonalizationRoutes from './IdeaPersonalization';
import IdeaSubmissionRoutes from './IdeaSubmission';
import KeyInsightRoutes from './KeyInsights';
import KeyTouchPointRoutes from './KeyTouchPoint';
import MarketResearchRoutes from './MarketResearch';
import NotFoundRoutes from './NotFound';
import OnePageStrategyRoutes from './OnePageStrategy';
import OpportunityAmongCompetitionRoutes from './OpportunityAmongCompetition';
import ProductResearchRoutes from './ProductResearch';
import ProjectRoutes from './Projects';
import SearchabilityContentRoutes from './SearchabilityContent';
import SelectedContentIdeasRoutes from './SelectedContentIdeas';
import SubmissionRoutes from './Submission';
import TargetAudienceRoutes from './TargetAudience';

const showCreative = (import.meta.env.VITE_SHOW_CREATIVE ?? 'true') === 'true';
const showContent = (import.meta.env.VITE_SHOW_CONTENT ?? 'true') === 'true';
const showStrategy = (import.meta.env.VITE_SHOW_STRATEGY ?? 'true') === 'true';

const routes = [
  ...HomeRoutes,
  ...AuthRoutes,
  ...ProjectRoutes,
  ...SubmissionRoutes,
  ...(showStrategy
    ? [
        ...ProductResearchRoutes,
        ...MarketResearchRoutes,
        ...TargetAudienceRoutes,
        ...AudienceArchetypeRoutes,
        ...KeyInsightRoutes,
        ...ChallengeAndTaskRoutes,
        ...CompetitorAnalysisRoutes,
        ...OpportunityAmongCompetitionRoutes,
        ...OnePageStrategyRoutes,
        ...KeyTouchPointRoutes,
      ]
    : []),
  ...(showCreative
    ? [
        ...IdeaSubmissionRoutes,
        ...IdeaAlignmentRoutes,
        ...IdeaPersonalizationRoutes,
        ...IdeaExpansionRoutes,
      ]
    : []),

  ...(showContent
    ? [
        ...SearchabilityContentRoutes,
        ...CredibilityContentRoutes,
        ...DiscoverabilityContentRoutes,
        ...SelectedContentIdeasRoutes,
      ]
    : []),
  ...NotFoundRoutes,
];

export default routes;
