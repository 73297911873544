import { TabGroup } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import ActionSection from '@/Components/Projects/ActionSection';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import {
  useCtaAction,
  useOpportunities,
  useSectionDataHandler,
  useUpdateOpportunities,
} from '@/Hooks/Opportunity';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type {
  ArchetypeOpportunity,
  ArchetypesState,
  OpportunitiesSection,
} from '@/Types/OpportunityAmongCompetition';
import type { OpportunitySchemaType } from '@/Types/OpportunityAmongCompetition/schema';
import type { SourcesType } from '@/Types/Projects';
import type { SectionList } from '@/Types/tabs';
import { getArchetypeDetails } from '@/Utils/archetype';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import Skeleton from './Components/Skeleton';
import Tabs from './Components/Tabs';

const Index = () => {
  const sectionList: SectionList = {
    title: 'Opportunity Among Competition',
    value: '',
    section: 'opportunity_among_competition',
  };
  const [message, setMessage] = useState('');
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [shouldTriggerModal, setShouldTriggerModal] = useState(false);
  const { roles } = useRolesBadge(project, user);

  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [archetypes, setArchetypes] = useState<ArchetypesState>({});
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>([]);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, isLoading, refetch, isRefetching } = useOpportunities(
    project.slug,
  );
  const [totals, setTotals] = useState<{
    allSelected: number;
    allData: number;
  }>({
    allSelected: 0,
    allData: 0,
  });
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const { mutate, isPending: isSubmitting } = useUpdateOpportunities();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({}); // State per archetype

  const archetypesArray = useMemo(() => {
    return Object.values(archetypes);
  }, [archetypes]);

  const [sources, setSources] = useState<SourcesType[]>([]);
  const { mutate: generate, isPending: isGenerating } = useGenerate();

  const { data: differentArchetypes, refetch: refetchDiffArchetype } =
    useDifferentArchetypes(project.slug);

  const {
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
    isOpportunityAmongCompetitionComplete,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Generate Challenge & Task',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  });

  const activeArchetype = useMemo(() => {
    return (
      archetypesArray.find(
        (archetype) => archetype.id === archetypesArray[activeTab]?.id,
      ) || null
    );
  }, [archetypesArray, activeTab]);

  useSectionDataHandler({
    diffArch: differentArchetypes,
    archetypes,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionKey: sectionList.section,
    setArchetypes,
    setGeneratingStates,
    setProgressStates,
    setSources,
    setTotalRegenerate,
    setTotals,
    setActiveTab,
    setMessage,
  });

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: sectionList.section,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
    isQuerying: isOpportunityAmongCompetitionComplete.isQuerying,
  });

  const onSelectSuccess = useCallback(async (isEdited: boolean) => {
    setShowAlertGenerateModal(isEdited);
    await refetch();
    setShouldTriggerModal(true);
  }, []);

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const selectedIndexes = useMemo(() => {
    return activeArchetype?.opportunities
      .map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [activeArchetype?.opportunities]);

  // const limit = activeArchetype?.total_regenerate || 0; TODO: will use this if 'generate more' is generated by archetype

  const handleFetchSubmit = async (
    payload: OpportunitySchemaType,
    id: number,
    index: number,
  ) => {
    if (!activeArchetype) return;
    const projectSlug = project.slug;

    mutate(
      { payload, id, archetypeId: activeArchetype.id, projectSlug },
      {
        onSuccess: ({ data }) => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Opportunity Among Competition`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Opportunity Among Competition  Click`,
            eventAction: 'Click',
            eventLabel: 'Opportunity Among Competition',
            userId: user.email,
            data: payload,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setShowAlertGenerateModal(data.is_edited && data.is_selected);
          refetch();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleCancelEdit = () => {
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleCancelAdd = () => {
    if (!activeArchetype) return;

    setArchetypes((prev) => {
      const currentData = prev[activeArchetype.id]?.opportunities || [];
      const filteredData = currentData.filter(
        (opportunity) => opportunity.id !== -1,
      );

      return {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          opportunities: filteredData,
        },
      };
    });

    toggleIsAdding();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleAddNew = () => {
    if (!activeArchetype) return;

    const newData: OpportunitiesSection = {
      id: -1,
      is_edited: false,
      submission_id: -1,
      history_id: -1,
      content_number: -1,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_selected: false,
      is_manual: true,
      opportunities_content: {
        digital_key_touch_points: '',
        brand_persona: '',
        features_and_benefits: '',
        creative_communication_angle: '',
        opportunity_among_competition_number: -1,
      },
    };

    // Hitung panjang array baru
    const currentData = archetypes[activeArchetype.id]?.opportunities || [];
    const newIndex = currentData.length;

    setArchetypes((prev) => {
      const updatedArchetypes = {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          opportunities: [...currentData, newData],
        },
      };

      return updatedArchetypes;
    });

    setGoToIndex(newIndex);
    toggleIsAdding();
  };

  const handleRegenerate = useCallback(() => {
    removeAllArchetypesBasedOnSectionFromLocalStorage(project.slug, 'onepage');
    generate(
      {
        payload: {
          generate_more: '',
          regenerate_prompt: '',
          ...((activeArchetype?.has_regenerate_per_archetype_one_pages ||
            activeArchetype?.is_diff_opportunities) && {
            status: 'regenerate_per_archetype_from_opportunity',
            archetype_id: [activeArchetype?.id],
          }),
        },
        projectSlug: project.slug,
        section: 'one-page-strategy',
      },
      {
        onSuccess: () => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        },
      },
    );
  }, [
    routeOptionValue,
    activeArchetype?.has_regenerate_per_archetype_one_pages,
    activeArchetype?.id,
    activeArchetype?.is_diff_opportunities,
  ]);

  const handleRefetchSelectedData = useCallback(() => {
    queryClient.resetQueries({
      queryKey: ['challenges', project.slug],
      exact: true,
    });

    if (
      !isOnePageStrategy.isRegenerated &&
      (activeArchetype?.has_regenerate_per_archetype_one_pages ||
        activeArchetype?.is_diff_opportunities)
    ) {
      setShowRegenerateModal(true);
      return;
    }
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));

    removeAllArchetypesBasedOnSectionFromLocalStorage(project.slug, 'onepage');

    generate(
      {
        payload: {
          generate_more: '',
          regenerate_prompt: '',
        },
        projectSlug: project.slug,
        section: 'one-page-strategy',
      },
      {
        onSuccess: () => {
          navigate(`/${project.slug}/one-page-strategy`);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        },
      },
    );
  }, [
    isOnePageStrategy.isRegenerated,
    routeOptionValue,
    differentArchetypes?.is_different_archetype.one_pages,
    activeArchetype?.has_regenerate_per_archetype_one_pages,
    activeArchetype?.is_diff_opportunities,
  ]);

  const handleRegenerateButton = async (prompt: string) => {
    if (!activeArchetype) return;

    // TO DO: will use this code when generate more has per archetype

    // removeProgressFromLocalStorage(
    //   project.slug,
    //   'key_insights',
    //   activeArchetype.id,
    // );

    // setArchetypes((prev) => ({
    //   ...prev,
    //   [activeArchetypeId]: {
    //     ...prev[activeArchetype.id],
    //     is_querying: true,
    //   },
    // }));

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      sectionList.section,
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeOpportunity>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Opportunity Among Competition`,
      eventCategory: `Generate More Opportunity Among Competition Click`,
      eventAction: 'Click',
      eventLabel: 'Opportunity Among Competition',
      userId: user.email,
      data: { slug: project.slug, prompt },
    });

    generate(
      {
        payload: {
          generate_more: prompt,
          regenerate_prompt: '',
        },
        projectSlug: project.slug,
        section: 'opportunity-among-competition',
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(setEnableHistoryStatus(true));
        },
      },
    );
  };

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useRoutePrev({
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/competitor-analysis`),
  });

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useCtaAction({
    routeOptionValue,
    activeArchetype,
    totalSelected: totals.allSelected,
    archetypesArray,
    isEditor,
    project,
    handleRefetchSelectedData,
    setErrorTabIndex,
    setShowViewInfoModal,
    setRouteOption: setRouteOptionValue,
  });

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totals.allSelected} from ${totals.allData} opportunity selected`,
      ),
    );
  }, [totals]);

  useEffect(() => {
    const isDiffKeyInsight = activeArchetype?.is_diff_opportunities || false;

    if (
      shouldTriggerModal &&
      isDiffKeyInsight &&
      isChallengeAndTaskOfTheBrand.status &&
      !isOnePageStrategy.isRegenerated &&
      !differentArchetypes?.is_different_archetype.one_pages
    ) {
      setShowAlertGenerateModal(true);
    }
    setShouldTriggerModal(false);
  }, [
    activeArchetype?.is_diff_opportunities,
    shouldTriggerModal,
    isChallengeAndTaskOfTheBrand.status,
    isOnePageStrategy.isRegenerated,
  ]);

  const handleClick = () => {
    navigate(`/${project.slug}/competitor-analysis`);
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [
    isOpportunityAmongCompetitionComplete.isQuerying,
    isOnePageStrategy.isQuerying,
    isOnePageStrategy.isRegenerated,
  ]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex max-w-4xl grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">
              Opportunity Among Competition
            </h1>
            <span className="text-1620 font-normal leading-relaxed text-grey-redx">
              Please <b className="text-black-redx">select one or more</b>{' '}
              opportunity idea{' '}
              <b className="text-black-redx">for each archetype</b>, or add by
              clicking &lsquo;Add Opportunity Idea Manually&rsquo;.
            </span>
          </div>
          {isEditor &&
            !activeArchetype?.is_querying &&
            message !== 'No data found' && (
              <RegenerateButton
                isGenerateMore
                isLoading={isLoading}
                limit={totalRegenerate} // TODO: change with 'limit', if 'generate more' is generated per archetype
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="key-insight"
              />
            )}
        </div>

        <TabGroup
          className="pb-80"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs
            activeTab={activeTab}
            archetypesArray={archetypesArray}
            errorTabIndex={errorTabIndex}
            isEditing={isEditing || isAdding}
            isLoading={isLoading}
            isQuerying={isOpportunityAmongCompetitionComplete.isQuerying}
            onActiveTab={setActiveTab}
            user={user}
          />
          {isLoading ? (
            <Skeleton />
          ) : (
            <div
              key={activeArchetype?.id || activeTab}
              className="h-full py-24"
            >
              {(() => {
                if (message === 'No data found') {
                  return (
                    <NotDataFound
                      buttonLabel="Go to Audience Archetype"
                      description="You don't have selected archetype, click the button below to generate a archetype in Audience Archetype page."
                      onClick={() =>
                        navigate(`/${project.slug}/audience-archetype`)
                      }
                      title="No Data Found."
                    />
                  );
                }

                if (!activeArchetype) {
                  return null; // Jangan tampilkan apa-apa
                }

                if (activeArchetype.is_querying) {
                  return (
                    <GenerateLoading
                      estimateTimeTo={3}
                      progress={progressPortion({
                        progress: Number(
                          (progressStates[activeArchetype.id] || 0).toFixed(0),
                        ),
                        isQuerying: activeArchetype.is_querying,
                        isContentAvailable:
                          activeArchetype.opportunities.length > 0,
                      })}
                      project={project}
                      section={sectionList}
                      showEmailNotification={
                        isOpportunityAmongCompetitionComplete.isSendingEmail
                      }
                    />
                  );
                }

                if (
                  activeArchetype.is_regenerate ||
                  activeArchetype.opportunities.length === 0
                ) {
                  return (
                    <NotDataFound
                      buttonLabel="Generate Opportunity"
                      description=" To show results on Opportunities. Click button below to generate Opportunities.."
                      onClick={handleClick}
                      title="You don’t have Opportunities..."
                    />
                  );
                }

                return (
                  <div className="flex flex-col gap-20 ">
                    <SelectedCard
                      data={getArchetypeDetails(
                        activeArchetype.archetype_content,
                      )}
                      title={`Archetype Detail - ${activeArchetype.archetype_content.archetype_name}`}
                    />
                    <SliderContainer
                      goToIndex={goToIndex}
                      isAdding={isAdding}
                      isEditing={isEditing}
                      length={activeArchetype.opportunities.length}
                      onActiveIndex={(index) => setActiveIndex(index)}
                      selectedIndexes={selectedIndexes}
                    >
                      {activeArchetype.opportunities.map(
                        (opportunity, index) => {
                          return (
                            <Card
                              key={index}
                              ref={(el) => (cardRefs.current[index] = el)}
                              id={opportunity.id}
                              index={index}
                              isCanSelect={isEditor}
                              isDisabledSelectButton={
                                isOnePageStrategy.status &&
                                !isOnePageStrategy.isRegenerated &&
                                !differentArchetypes?.is_different_archetype
                                  .one_pages &&
                                activeArchetype.total_selected_opportunities ===
                                  1
                              }
                              isEdited={
                                opportunity.is_edited || opportunity.is_manual
                              }
                              isEditing={isEditing || isAdding}
                              isFetching={isRefetching}
                              isSelected={opportunity.is_selected}
                              item={opportunity.opportunities_content}
                              onSelectSuccess={onSelectSuccess}
                              onSubmitSuccess={handleFetchSubmit}
                              project={project}
                              refetchDiffArchetype={refetchDiffArchetype}
                              totalData={activeArchetype.opportunities.length}
                              user={user}
                            />
                          );
                        },
                      )}
                    </SliderContainer>

                    <ActionSection
                      isEditing={isAdding || isEditing}
                      isEditor={isEditor}
                      isSubmitting={isSubmitting}
                      label="Add Idea manually"
                      onAdd={handleAddNew}
                      onCancel={isAdding ? handleCancelAdd : handleCancelEdit}
                      onSave={handleSubmit}
                      toggleEditing={toggleIsEditing}
                    />

                    {sources && <SourcesList sources={sources} />}
                  </div>
                );
              })()}
            </div>
          )}
        </TabGroup>
      </div>
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="opportunity-among-competition"
      />

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="opportunity-among-competition"
        visibleSections={[
          'One-Page Strategy',
          'Key Touch Point',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
