import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';

interface UpdateSelectedProps {
  id: number;
  projectSlug: string;
  framework: string;
  archetypeId: number;
  recommendationNumber: number;
  checked: boolean;
  stepName: string;
}

const updateSelected = async ({
  id,
  projectSlug,
  framework,
  archetypeId,
  recommendationNumber,
  checked,
  stepName,
}: UpdateSelectedProps) => {
  const url = `/projects/${projectSlug}/credibility/update-selected`;

  try {
    const response = await goFetcher.put(url, {
      id,
      framework,
      archetype_id: archetypeId,
      recommendation_number: recommendationNumber,
      checked,
      step_name: stepName,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedCredibility = () => {
  return useMutation<any, Error, UpdateSelectedProps>({
    mutationFn: updateSelected,
  });
};

export { useUpdateSelectedCredibility };
