import type { ArchetypeContents } from '.';

export enum StepId {
  GOALS_SUCCESS_METRICS_MAIN_FEATURES = 'goals_success_metrics_main_features',
  PERCEPTION_TO_BUILD = 'perception_to_build',
  CONTENT_TOPICS = 'content_topics',
  CONTENT_IDEAS = 'content_ideas',
}

export const stepPropertyMap: Record<StepId, keyof ArchetypeContents> = {
  [StepId.GOALS_SUCCESS_METRICS_MAIN_FEATURES]:
    'goals_success_metrics_main_features',
  [StepId.PERCEPTION_TO_BUILD]: 'perception_to_build',
  [StepId.CONTENT_TOPICS]: 'content_topics',
  [StepId.CONTENT_IDEAS]: 'content_ideas',
};
