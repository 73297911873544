import { TabGroup } from '@headlessui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditedLabel from '@/Components/EditedLabel';
import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import {
  saveInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import {
  useCtaAction,
  useSectionData,
  useTargetAudience,
} from '@/Hooks/TargetAudience';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type { SectionList } from '@/Types/tabs';
import type {
  AudienceInsightProps,
  BehavioralTrendsProps,
  MediaConsumptionPatternsProps,
  PerceptionAnalysisProps,
  SectionDataAudienceInsight,
  SectionDataBehavioralTrends,
  SectionDataMap,
  SectionDataMediaConsumption,
  SectionDataPerceptionAnalysis,
  SectionResponseMap,
} from '@/Types/TargetAudience';
import { cn } from '@/Utils/cn';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import ContentAudienceInsightSection from './Components/ContentAudienceInsightSection';
import ContentBehavioralTrendSection from './Components/ContentBehavioralTrendSection';
import ContentMediaConsumptionSection from './Components/ContentMediaConsumptionSection';
import ContentPerceptionAnalysisSection from './Components/ContentPerceptionAnalysisSection';
import Tabs from './Components/Tabs';

const Index = () => {
  const sectionList: SectionList[] = [
    {
      title: 'Audience Insights',
      value: 'audience_insight',
      section: 'target_audience',
    },
    {
      title: 'Perception Analysis',
      value: 'perception_analysis',
      section: 'target_audience',
    },
    {
      title: 'Behavioral Trends',
      value: 'behavioral_trends',
      section: 'target_audience',
    },
    {
      title: 'Media Consumption Patterns',
      value: 'media_consumption_patterns',
      section: 'target_audience',
    },
  ];
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const { mutateAsync: generate, isPending: isGenerating } = useGenerate();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const maxGenerateLimit = project.max_generated_data;
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({});
  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );

  const [archetypes, setArchetypes] = useState<
    Record<
      number,
      {
        id: number;
        is_querying: boolean;
      }
    >
  >({});

  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  const activeSection = useMemo(() => {
    return sectionList[activeTab];
  }, [activeTab]);

  const [sectionData, setSectionData] = useState<
    Record<keyof SectionDataMap, SectionDataMap[keyof SectionDataMap]>
  >({
    audience_insight: {
      content: {
        passion_points: [],
        motivations: [],
        pain_points: [],
        expectations: [],
      },
      is_diff: false,
      sources: [],
      total_regenerate: 0,
      history_id: 0,
      is_email_notification: false,
      is_querying: false,
      is_regenerate_audience: false,
      message: '',
      check_content: {
        is_edited: false,
        current: '',
        previous: '',
      },
    },
    perception_analysis: {
      content: {
        brand_perception: [],
        sector_perception: [],
        product_category_perception: [],
      },
      is_diff: false,
      sources: [],
      total_regenerate: 0,
      history_id: 0,
      is_email_notification: false,
      is_querying: false,
      is_regenerate_audience: false,
      message: '',
      check_content: {
        is_edited: false,
        current: '',
        previous: '',
      },
    },
    behavioral_trends: {
      content: {
        current_trends: [],
        consumption_behaviors: [],
      },
      is_diff: false,
      sources: [],
      total_regenerate: 0,
      history_id: 0,
      is_email_notification: false,
      is_querying: false,
      is_regenerate_audience: false,
      message: '',
      check_content: {
        is_edited: false,
        current: '',
        previous: '',
      },
    },
    media_consumption_patterns: {
      content: {
        media_consumption_pattern: [],
      },
      is_diff: false,
      sources: [],
      total_regenerate: 0,
      history_id: 0,
      is_email_notification: false,
      is_querying: false,
      is_regenerate_audience: false,
      message: '',
      check_content: {
        is_edited: false,
        current: '',
        previous: '',
      },
    },
  });

  const { data, isPending, refetch } = useTargetAudience(
    project.slug,
    activeSection.value as keyof SectionResponseMap,
  );

  const {
    isAudienceArchetypeComplete,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
  } = useHistoryStatus(project);

  const routeOptionValue: RouteOption = {
    label: 'Next to Audience Archetype',
    isActive: true,
    onClick: () => navigate(`/${project.slug}/audience-archetype`),
  };

  const handlers: Record<
    string,
    (slug: string, prompt: string) => Promise<void>
  > = {
    audience_insight: async (slug: string, prompt: string) => {
      await generate({
        projectSlug: slug,
        section: 'target-audience/audience-insight',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt,
        },
      });
    },
    behavioral_trends: async (slug: string, prompt: string) => {
      await generate({
        projectSlug: slug,
        section: 'target-audience/behavioral-trends',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt,
        },
      });
    },
    media_consumption_patterns: async (slug: string, prompt: string) => {
      await generate({
        projectSlug: slug,
        section: 'target-audience/media-consumption-patterns',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt,
        },
      });
    },
    perception_analysis: async (slug: string, prompt: string) => {
      await generate({
        projectSlug: slug,
        section: 'target-audience/perception-analysis',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt,
        },
      });
    },
  };

  const getSectionDetails = <T extends keyof SectionDataMap>(
    sectionData: Record<
      keyof SectionDataMap,
      | SectionDataAudienceInsight
      | SectionDataPerceptionAnalysis
      | SectionDataBehavioralTrends
      | SectionDataMediaConsumption
    >,
    activeSection: T,
  ) => {
    const section = sectionData[activeSection] as SectionDataMap[T];

    let isEmailNotification = false;
    if (activeSection === 'audience_insight') {
      isEmailNotification = isTargetAudienceAudienceInsight.isSendingEmail;
    } else if (activeSection === 'perception_analysis') {
      isEmailNotification = isTargetAudiencePerceptionAnalysis.isSendingEmail;
    } else if (activeSection === 'behavioral_trends') {
      isEmailNotification = isTargetAudienceBehavioralTrend.isSendingEmail;
    } else if (activeSection === 'media_consumption_patterns') {
      isEmailNotification =
        isTargetAudienceMediaConsumptionPattern.isSendingEmail;
    }

    return {
      totalRegenerate: section?.total_regenerate || 0,
      content: section?.content || undefined,
      sources: section?.sources || [],
      historyId: section?.history_id || 0,
      isQuerying: section?.is_querying || false,
      isEmailNotification,
      isRegenerateAudience: section?.is_regenerate_audience || false,
      isDiff: section?.is_diff || false,
      message: section?.message || '',
      checkContent: section?.check_content || {
        is_edited: false,
        current: '',
        previous: '',
      },
    };
  };

  useEffect(() => {
    dispatch(saveInformation(''));
  }, []);

  useSectionData({
    isAudienceArchetype: isAudienceArchetypeComplete,
    activeSection: activeSection.value,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionData,
    setGeneratingStates,
    setProgressStates,
    setSectionData,
  });

  const {
    totalRegenerate,
    content,
    sources,
    historyId,
    isQuerying,
    isEmailNotification,
    isRegenerateAudience,
    isDiff,
    message,
    checkContent,
  } = getSectionDetails(
    sectionData,
    activeSection.value as keyof SectionDataMap,
  );

  useEffect(() => {
    if (historyId) {
      setArchetypes((prev) => ({
        ...prev,
        [historyId]: {
          id: historyId,
          is_querying: isQuerying ?? false,
        },
      }));
    }
  }, [historyId, isQuerying]);

  const activeArchetype = useMemo(() => {
    if (!historyId) return null; // Jika tidak ada historyId, kembalikan null
    return archetypes[historyId] || null; // Cari berdasarkan historyId di archetypes
  }, [archetypes, historyId]);

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: activeSection.value,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
  });

  useCtaAction({
    message,
    activeSection: activeSection.value,
    isEditing,
    isEditor,
    project,
    routeOptionValue,
    isRegenerateAudience: isDiff || isRegenerateAudience,
    setShowViewInfoModal,
    setShowRegenerateModal,
  });

  const handleRegenerateButton = async (section: string, prompt: string) => {
    triggerGTMEvent({
      event: `Regenerate ${section}`,
      eventCategory: `Btn Regenerate ${section} Click`,
      eventAction: 'Click',
      eventLabel: section,
      userId: user.email,
      data: prompt,
    });

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      activeSection.value,
    );

    setSectionData((prev) => ({
      ...prev,
      [activeSection.value]: {
        ...prev[activeSection.value as keyof SectionResponseMap],
        is_querying: true,
      },
    }));

    await handlers[section](project.slug, prompt);

    refetch();
  };

  useRoutePrev({
    id: 'btn-back-target-audience',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/market-research`),
  });

  const handleRegenerate = async () => {
    await generate({
      payload: {
        regenerate_prompt: '',
        generate_more: '',
        status: 'edit target audience',
      },
      projectSlug: project.slug,
      section: 'audience-archetype',
    });
    setShowRegenerateModal(false);
    dispatch(setEnableHistoryStatus(true));
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [
    isAudienceArchetypeComplete.isQuerying,
    isAudienceArchetypeComplete.isRegenerated,
  ]);

  console.log(checkContent.is_edited);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 w-full bg-white py-16">
          <h1 className="mb-8 text-25 font-bold leading-none">
            Target Audience
          </h1>
          <span className="text-15 font-normal text-grey-redx">
            Please review and edit this information, considering that the
            provided reference may not always be accurate.
          </span>
        </div>
        <TabGroup
          className="pb-80"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs sections={sectionList} user={user} />
          <div className="h-full py-24">
            <div
              className={cn(
                'mb-8 flex w-full items-center',
                checkContent.is_edited || isRegenerateAudience
                  ? 'justify-between'
                  : 'justify-end',
              )}
            >
              {(checkContent.is_edited || isRegenerateAudience) &&
                !isQuerying && <EditedLabel />}
              {isEditor && !isQuerying && message !== 'No data found' && (
                <RegenerateButton
                  limit={totalRegenerate}
                  maxLimit={maxGenerateLimit}
                  onSubmit={({ prompt }) =>
                    handleRegenerateButton(activeSection.value, prompt)
                  }
                  section={activeSection.value}
                />
              )}
            </div>
            {(() => {
              if (isPending) {
                return (
                  <div className="flex flex-col gap-24">
                    {Array.from({ length: 20 }).map((_, index) => (
                      <div
                        key={index}
                        className={`full h-1 ${index === 19 ? 'w-2/3' : 'w-full'} animate-pulse rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx py-4`}
                      />
                    ))}
                  </div>
                );
              }

              if (isQuerying) {
                return (
                  <GenerateLoading
                    progress={progressPortion({
                      progress: Number(
                        (progressStates[historyId] || 0).toFixed(0),
                      ),
                      isQuerying,
                      isContentAvailable: !!content,
                    })}
                    project={project}
                    section={activeSection}
                    showEmailNotification={isEmailNotification}
                  />
                );
              }

              if (activeSection.value === 'audience_insight') {
                return (
                  <ContentAudienceInsightSection
                    activeSection={activeSection.value}
                    content={content as AudienceInsightProps}
                    id={historyId}
                    isEditor={isEditor}
                    message={message}
                    onEditingChange={setIsEditing}
                    projectSlug={project.slug}
                    refetch={refetch}
                    setShowAlertGenerateModal={setShowAlertGenerateModal}
                    sources={sources}
                    user={user}
                  />
                );
              }

              if (activeSection.value === 'perception_analysis') {
                return (
                  <ContentPerceptionAnalysisSection
                    activeSection={activeSection.value}
                    content={content as PerceptionAnalysisProps}
                    id={historyId}
                    isEditor={isEditor}
                    message={message}
                    onEditingChange={setIsEditing}
                    projectSlug={project.slug}
                    refetch={refetch}
                    setShowAlertGenerateModal={setShowAlertGenerateModal}
                    sources={sources}
                    user={user}
                  />
                );
              }

              if (activeSection.value === 'behavioral_trends') {
                return (
                  <ContentBehavioralTrendSection
                    activeSection={activeSection.value}
                    content={content as BehavioralTrendsProps}
                    id={historyId}
                    isEditor={isEditor}
                    message={message}
                    onEditingChange={setIsEditing}
                    projectSlug={project.slug}
                    refetch={refetch}
                    setShowAlertGenerateModal={setShowAlertGenerateModal}
                    sources={sources}
                    user={user}
                  />
                );
              }

              return (
                <ContentMediaConsumptionSection
                  activeSection={activeSection.value}
                  content={content as MediaConsumptionPatternsProps}
                  id={historyId}
                  isEditor={isEditor}
                  message={message}
                  onEditingChange={setIsEditing}
                  projectSlug={project.slug}
                  refetch={refetch}
                  setShowAlertGenerateModal={setShowAlertGenerateModal}
                  sources={sources}
                  user={user}
                />
              );
            })()}
          </div>
        </TabGroup>
      </div>

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="target-audience"
      />

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="target-audience"
        visibleSections={[
          'Audience Archetype',
          'Key Insight',
          'Challenge & Communication Task',
          'Opportunity Among Competition',
          'One-Page Strategy',
          'Key Touch Point',
          'Idea Alignment',
          'Idea Personalization',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />
    </>
  );
};

export default Index;
