import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ArchetypeKeyInsight } from '@/Types/KeyInsight';
import type { ProjectProps } from '@/Types/Projects';

import { useDifferentArchetypes } from '../AudienceArchetype';
import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  routeOptionValue: RouteOption;
  activeArchetype: ArchetypeKeyInsight | null;
  totalSelected: number;
  isEditor: boolean;
  isEditing: boolean;
  project: ProjectProps;
  message: string;
  archetypesArray: ArchetypeKeyInsight[];
  handleRefetchSelectedData: () => Promise<void>;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorTabIndex: React.Dispatch<React.SetStateAction<number[]>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  isEditor,
  routeOptionValue,
  project,
  totalSelected,
  isEditing,
  archetypesArray,
  activeArchetype,
  message,
  setErrorTabIndex,
  setShowViewInfoModal,
  handleRefetchSelectedData,
  setRouteOption,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;

  const { isChallengeAndTaskOfTheBrand } = useHistoryStatus(project);

  const { data: differentArchetypes } = useDifferentArchetypes(project.slug);

  // Memoize routeOptionValue to avoid unnecessary re-render
  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const hasSelectedKeyInsight = (archetypesArray: ArchetypeKeyInsight[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedKeyInsight = archetypesArray
      .map((section, index) => {
        const hasSelectedKeyInsight = section.key_insights.some(
          (keyInsight) => keyInsight.is_selected,
        );
        return hasSelectedKeyInsight ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyInsight.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyInsight);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedKeyInsight
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select key insight for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleEditorClick = useCallback(async () => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Key insight is still generating.`,
        ),
      );
      return;
    }

    if (isChallengeAndTaskOfTheBrand.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Challenge & Task is still generating`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isChallengeAndTaskOfTheBrand.status &&
      (activeArchetype?.total_challenges ?? 0) > 0 &&
      !activeArchetype?.has_regenerate_challenge &&
      !activeArchetype?.is_diff_key_insight &&
      !activeArchetype?.has_regenerate_per_archetype_challenges &&
      !isChallengeAndTaskOfTheBrand.isRegenerated
    ) {
      if (totalSelected === 0) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please select key insight first`,
          ),
        );

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      navigate(`/${project.slug}/challenge-and-communication-task`);
      return;
    }

    const hasError = hasSelectedKeyInsight(archetypesArray);

    if (!hasError) await handleRefetchSelectedData();
  }, [
    isEditing,
    totalSelected,
    activeArchetype?.has_regenerate_per_archetype_challenges,
    isChallengeAndTaskOfTheBrand.isRegenerated,
    archetypesArray,
    activeArchetype?.is_querying,
    activeArchetype?.has_regenerate_challenge,
    isChallengeAndTaskOfTheBrand.status,
    activeArchetype?.total_challenges,
    isChallengeAndTaskOfTheBrand.isQuerying,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Key insight is still generating.`,
        ),
      );
      return;
    }

    if (
      !isChallengeAndTaskOfTheBrand.status ||
      isChallengeAndTaskOfTheBrand.isRegenerated ||
      (activeArchetype?.total_challenges ?? 0) === 0 ||
      activeArchetype?.has_regenerate_challenge
    ) {
      setShowViewInfoModal(true);
      return;
    }

    navigate(`/${projectSlug}/challenge-and-communication-task`);
  }, [
    activeArchetype?.is_querying,
    activeArchetype?.has_regenerate_challenge,
    activeArchetype?.total_challenges,
    isChallengeAndTaskOfTheBrand.status,
    isChallengeAndTaskOfTheBrand.isRegenerated,
    differentArchetypes?.is_different_archetype.challenges,
  ]);

  useEffect(() => {
    const totalChallenges = activeArchetype?.total_challenges ?? 0;
    memoizedRouteOptionValue.isActive = message !== 'No data found';
    if (isEditor) {
      memoizedRouteOptionValue.isInactive =
        activeArchetype?.is_querying ||
        activeArchetype?.key_insights.length === 0 ||
        isChallengeAndTaskOfTheBrand.isQuerying ||
        totalSelected === 0;

      if (
        isChallengeAndTaskOfTheBrand.status &&
        !isChallengeAndTaskOfTheBrand.isRegenerated &&
        totalChallenges > 0 &&
        (activeArchetype?.has_regenerate_per_archetype_challenges ||
          activeArchetype?.has_regenerate_challenge ||
          activeArchetype?.is_diff_key_insight)
      ) {
        memoizedRouteOptionValue.label =
          activeArchetype?.has_regenerate_challenge
            ? `Generate Challenge & Task (Archetype ${activeArchetype.archetype_number})`
            : 'Update Other Pages';
        memoizedRouteOptionValue.isGenerate = true;
      } else if (
        isChallengeAndTaskOfTheBrand.status &&
        totalChallenges > 0 &&
        !isChallengeAndTaskOfTheBrand.isRegenerated &&
        !activeArchetype?.has_regenerate_challenge &&
        !activeArchetype?.is_diff_key_insight
      ) {
        memoizedRouteOptionValue.label = 'Next to Challenge & Task';
        memoizedRouteOptionValue.isGenerate = false;
      } else {
        memoizedRouteOptionValue.label = 'Generate Challenge & Task';
        memoizedRouteOptionValue.isGenerate = true;
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.label = 'Next to Challenge & Task';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.isInactive =
        !isChallengeAndTaskOfTheBrand.status ||
        isChallengeAndTaskOfTheBrand.isQuerying ||
        activeArchetype?.total_challenges === 0 ||
        activeArchetype?.has_regenerate_challenge;

      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    activeArchetype?.is_querying,
    activeArchetype?.total_selected_key_insights,
    activeArchetype?.key_insights,
    activeArchetype?.has_regenerate_challenge,
    activeArchetype?.has_regenerate_per_archetype_challenges,
    activeArchetype?.total_challenges,
    isEditor,
    totalSelected,
    isChallengeAndTaskOfTheBrand.isRegenerated,
    isChallengeAndTaskOfTheBrand.status,
    isChallengeAndTaskOfTheBrand.isQuerying,
    activeArchetype?.is_diff_key_insight,
    message,
  ]);
};

export { useCtaAction };
