import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { KeyTouchPointListResponse } from '@/Types/KeyTouchPoint';

const getLists = async (
  projectSlug: string,
): Promise<KeyTouchPointListResponse> => {
  try {
    const response = await goFetcher.get(
      `/projects/${projectSlug}/key-touch-point/lists`,
    );

    return response.data as KeyTouchPointListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useKeyTouchPoint = (projectSlug: string) => {
  return useQuery<KeyTouchPointListResponse, Error>({
    queryKey: ['keyTouchPoint', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists(projectSlug),
    refetchOnWindowFocus: false, // Prevent refetch on window focus (e.g., tab switch)
  });
};

export { useKeyTouchPoint };
