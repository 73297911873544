import { zodResolver } from '@hookform/resolvers/zod';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import Content from '@/Components/Content';
import InputLabel from '@/Components/InputLabel';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import TextInput from '@/Components/TextInput';
import type { ProjectAction } from '@/Context/Actions/project-actions';
import {
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useUpdateProductSubmission } from '@/Hooks/Submission';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import type { SectionHandles } from '@/Types/Submission';
import {
  ProductInformation,
  type ProductInformationSchema,
} from '@/Types/Submission/schema';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import ActionButtons from './Action';

const fieldsToDisplay = [
  { name: 'product_name', label: 'Product Name', required: true },
  {
    name: 'brand_name',
    label: 'Brand Name',
    required: true,
  },
  {
    name: 'product_links',
    label: 'Product Related Links',
    required: false,
  },
];

interface ProductInformationProps {
  activeSection: number;
  projectSlug: string;
  user: UserResponse;
  isComplete: boolean;
  data: {
    product_name: string;
    brand_name: string;
    product_links: string;
  };
  isActive: boolean;
  isEditor: boolean;
  isEditingCount: number;
  onRefetchSubmission: () => void;
  onDispatch: React.Dispatch<ProjectAction>;
  onSetIsEditingCount: React.Dispatch<React.SetStateAction<number>>;
  onSetActiveSection: React.Dispatch<React.SetStateAction<number>>;
}

const ProductInformationSection = forwardRef<
  SectionHandles,
  ProductInformationProps
>(
  (
    {
      activeSection,
      projectSlug,
      user,
      data,
      isActive,
      isEditor,
      isEditingCount,
      isComplete,
      onDispatch,
      onRefetchSubmission,
      onSetIsEditingCount,
      onSetActiveSection,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const project = useMemo(() => state.project, [state.project]);
    const isEnabledHistoryStatus = useMemo(
      () => state.isEnabledHistoryStatus,
      [state.isEnabledHistoryStatus],
    );

    const {
      isProductResearchMainFeature,
      isProductResearchMarcommHighlight,
      isProductResearchProductDescription,
    } = useHistoryStatus(project, isEnabledHistoryStatus);

    const { mutate } = useUpdateProductSubmission();
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const { mutate: generate } = useGenerate();

    const defaultValues = useMemo(
      () => ({
        brand_name: data?.brand_name ?? '',
        product_links: data?.product_links ?? '',
        product_name: data?.product_name ?? '',
      }),
      [data],
    );

    const {
      handleSubmit,
      reset,
      control,
      formState: { isLoading, isValid },
    } = useForm<ProductInformationSchema>({
      resolver: zodResolver(ProductInformation),
      defaultValues,
      mode: 'all',
    });

    const toggleIsEditing = useCallback(
      (isEdit: boolean) => {
        setIsEditing(isEdit);
        if (onSetIsEditingCount) {
          const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
          onSetIsEditingCount(newCount);
        }
      },
      [isEditingCount, onSetIsEditingCount],
    );

    const onSubmit = async (data: ProductInformationSchema) => {
      triggerGTMEvent({
        event: 'Edit Submission Production Information',
        eventCategory: 'Button Edit Submission Production Information Click',
        eventAction: 'Click',
        eventLabel: 'Submission Product Information',
        userId: user.email,
        data,
      });

      toggleIsEditing(true);
      mutate(
        {
          payload: data,
          projectSlug,
        },
        {
          onSuccess: () => {
            triggerGTMEvent({
              event: 'Generate Product Research',
              eventCategory: 'Button Generate Product Research Click',
              eventAction: 'Click',
              eventLabel: 'Generate Product Research',
              userId: user.email,
              data: projectSlug,
            });

            triggerGTMEvent({
              event: 'Generate Main Features',
              eventCategory: 'Button Generate Main Features Click',
              eventAction: 'Click',
              eventLabel: 'Generate Main Features',
              userId: user.email,
              data: projectSlug,
            });

            triggerGTMEvent({
              event: 'Generate Marcomm Highlight',
              eventCategory: 'Button Generate Marcomm Highlight Click',
              eventAction: 'Click',
              eventLabel: 'Generate Marcomm Highlight',
              userId: user.email,
              data: projectSlug,
            });

            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                status: 'regenerate from submission',
              },
              projectSlug,
              section: 'product-research/product-description',
            });

            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                status: 'regenerate from submission',
              },
              projectSlug,
              section: 'product-research/main-features',
            });

            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                status: 'regenerate from submission',
              },
              projectSlug,
              section: 'product-research/marcomm-highlights',
            });

            onDispatch(setEnableHistoryStatus(true));
            onRefetchSubmission();
            toggleIsEditing(false);
          },
        },
      );
    };

    useImperativeHandle(ref, () => ({
      save: async () => {
        await handleSubmit(onSubmit)();
        onSetActiveSection(activeSection + 1);
      },
      element: divRef.current, // Ekspos elemen HTMLDivElement
    }));

    useEffect(() => {
      if (isEditing) {
        onDispatch(setEnableHistoryStatus(false));
        reset({
          brand_name: data?.brand_name ?? '',
          product_links: data?.product_links ?? '',
          product_name: data?.product_name ?? '',
        });
      } else {
        onDispatch(setEnableHistoryStatus(true));
      }
    }, [isEditing]);

    const handleCancel = () => {
      setIsEditing(false);
    };

    const isSaveDisabled = useMemo(() => !isValid, [isValid]);

    const handleSave = useCallback(async () => {
      if (
        isProductResearchMainFeature.isQuerying ||
        isProductResearchMarcommHighlight.isQuerying ||
        isProductResearchProductDescription.isQuerying
      ) {
        onDispatch(
          saveSubInformation(
            `{text-error-redx} Please wait product research is still generating`,
          ),
        );
        return;
      }
      if (
        isProductResearchMainFeature.status ||
        isProductResearchMarcommHighlight.status ||
        isProductResearchProductDescription.status
      ) {
        setIsModalOpen(true);
        return;
      }
      await handleSubmit(onSubmit)();
    }, [
      isProductResearchMainFeature.status,
      isProductResearchMarcommHighlight.status,
      isProductResearchProductDescription.status,
      isProductResearchMainFeature.isQuerying,
      isProductResearchMarcommHighlight.isQuerying,
      isProductResearchProductDescription.isQuerying,
    ]);

    return (
      <div
        ref={divRef}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Product Information
          </h1>
          <ActionButtons
            isActive={isActive && isComplete}
            isEditing={isEditing}
            isEditor={isEditor}
            isFetching={false}
            isValid={isSaveDisabled}
            onCancel={handleCancel}
            onEdit={() => setIsEditing(true)}
            onSave={handleSave}
          />
        </div>
        <div className="grid w-full grid-cols-2 gap-24">
          {fieldsToDisplay.map(({ name, label, required }) => {
            return (
              <div key={name} className="flex flex-col gap-10">
                <InputLabel optional={!required} value={label} />
                {isEditing || !isComplete ? (
                  <Controller
                    control={control}
                    name={name as keyof ProductInformationSchema}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextInput
                        autoComplete="off"
                        className="overflow-custom mt-1 block w-full resize-none !text-14"
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        error={error?.message}
                        onChange={onChange}
                        placeholder={`Input the ${label} here`}
                        rows={2}
                        value={value}
                      />
                    )}
                  />
                ) : (
                  <Content
                    isFetching={false}
                    isMarkDown={false}
                    value={data?.[name as keyof ProductInformationSchema] || ''}
                  />
                )}
              </div>
            );
          })}
        </div>
        <ChangingImpactModal
          handleSubmit={async () => {
            await handleSubmit(onSubmit)();
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          isRegenerating={isLoading}
          onClose={() => {
            handleCancel();
            toggleIsEditing(true);
            setIsModalOpen(false);
          }}
          section="product-information"
          visibleSections={[
            'Product Research',
            'Key Insight',
            'Challenge & Communication Task',
            'One-Page Strategy',
            'Key Touch Point',
            'Idea Alignment',
            'Idea Personalization',
            'Idea Expansion',
            'Searchability Content',
            'Discoverability Content',
            'Credibility Content',
            'Selected Content Ideas',
          ]}
        />
      </div>
    );
  },
);

export default memo(ProductInformationSection);
