import { Icon } from '@iconify/react';
import React from 'react';

import DeleteButton from '@/Components/Projects/DeleteButton';
import EditButton from '@/Components/Projects/EditButton';

interface ActionsProps {
  isSubmitting?: boolean;
  isEditing: boolean;
  isCanEdit: boolean;
  onHandleCancelEdit: () => void;
  onHandleDelete: () => void;
  toggleIsEditing: () => void;
}

const Actions = ({
  isSubmitting = false,
  isEditing,
  isCanEdit,
  onHandleCancelEdit,
  onHandleDelete,
  toggleIsEditing,
}: ActionsProps) => {
  if (!isCanEdit) return null;

  return (
    <div
      className={`flex w-full justify-end gap-x-15 py-10 ${
        !isEditing ? 'border-t-1 border-t-stroke-redx' : ''
      }`}
    >
      {isEditing ? (
        <>
          <button
            className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
            onClick={onHandleCancelEdit}
            type="button"
          >
            <span className="text-15 font-semibold">Cancel</span>
            <Icon className="size-20" icon="lucide:x" />
          </button>
          <button
            className={`inline-flex items-center gap-x-4 ${
              isSubmitting ? 'text-gray-600' : 'text-blue-redx'
            } cursor-pointer`}
            disabled={isSubmitting}
            type="submit"
          >
            <span className="text-15 font-semibold">Save</span>
            <Icon className="size-20" icon="lucide:save" />
          </button>
        </>
      ) : (
        <div className="inline-flex w-full justify-between">
          <DeleteButton onHandleDelete={onHandleDelete} />
          <EditButton toggleEditing={toggleIsEditing} />
        </div>
      )}
    </div>
  );
};

export default React.memo(Actions);
