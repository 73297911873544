import { zodResolver } from '@hookform/resolvers/zod';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Content from '@/Components/Content';
import InputLabel from '@/Components/InputLabel';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import TextAreaInput from '@/Components/TextAreaInput';
import TextInput from '@/Components/TextInput';
import type { ProjectAction } from '@/Context/Actions/project-actions';
import { setEnableHistoryStatus } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useUpdateCreative } from '@/Hooks/IdeaSubmission';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import type {
  CreativeIdeaContent,
  SectionHandles,
} from '@/Types/CreativeIdea/creative-idea';
import {
  CreativeIdea,
  type CreativeIdeaSchema,
} from '@/Types/CreativeIdea/schema';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import ActionButtons from './Action';

const fieldsToDisplay = [
  { name: 'title', label: 'Title', required: true },
  {
    name: 'description',
    label: 'Description',
    required: true,
  },
];

interface CreativeIdeaSectionProps {
  projectSlug: string;
  user: UserResponse;
  isGenerate: boolean;
  isComplete: boolean;
  data: CreativeIdeaContent;
  isFetching: boolean;
  isEditor: boolean;
  isEditingCount: number;
  onRefetch: () => void;
  onDispatch: React.Dispatch<ProjectAction>;
  onSetIsEditingCount: React.Dispatch<React.SetStateAction<number>>;
}

const CreativeIdeaSection = forwardRef<
  SectionHandles,
  CreativeIdeaSectionProps
>(
  (
    {
      projectSlug,
      user,
      data,
      isGenerate,
      isEditor,
      isFetching,
      isEditingCount,
      isComplete,
      onDispatch,
      onRefetch,
      onSetIsEditingCount,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const project = useMemo(() => state.project, [state.project]);
    const isEnabledHistoryStatus = useMemo(
      () => state.isEnabledHistoryStatus,
      [state.isEnabledHistoryStatus],
    );

    const { isIdeaAlignment } = useHistoryStatus(
      project,
      isEnabledHistoryStatus,
    );

    const { mutate: generate, isPending: isGenerating } = useGenerate();
    const { mutate } = useUpdateCreative();
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const defaultValues = useMemo(
      () => ({
        title: data?.title ?? '',
        description: data?.description ?? '',
      }),
      [data],
    );

    const {
      handleSubmit,
      reset,
      control,
      formState: { isLoading, isValid },
    } = useForm<CreativeIdeaSchema>({
      resolver: zodResolver(CreativeIdea),
      defaultValues,
      mode: 'all',
    });

    const toggleIsEditing = useCallback(
      (isEdit: boolean) => {
        setIsEditing(isEdit);
        if (onSetIsEditingCount) {
          const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
          onSetIsEditingCount(newCount);
        }
      },
      [isEditingCount, onSetIsEditingCount],
    );

    const onSubmit = useCallback(
      async (data: CreativeIdeaSchema) => {
        triggerGTMEvent({
          event: `Edit Creative Idea`,
          eventCategory: `Button Edit Creative Idea Click`,
          eventAction: 'Click',
          eventLabel: 'Creative Idea',
          userId: user.email,
          data,
        });

        mutate(
          {
            payload: data,
            projectSlug,
          },
          {
            onSuccess: () => {
              triggerGTMEvent({
                event: 'Generate Idea Alignment',
                eventCategory: 'Button Generate Idea Alignment Click',
                eventAction: 'Click',
                eventLabel: 'Generate Idea Alignment',
                userId: user.email,
                data: projectSlug,
              });

              generate(
                {
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                    status: 'update from idea submission',
                  },
                  projectSlug,
                  section: 'idea-alignment',
                },
                {
                  onSuccess: () => {
                    onRefetch();
                    toggleIsEditing(false);
                    onDispatch(setEnableHistoryStatus(true));
                    if (isGenerate || isIdeaAlignment.isRegenerated) {
                      navigate(`/${projectSlug}/idea-alignment`);
                    }
                  },
                },
              );
            },
          },
        );
      },
      [isIdeaAlignment.isRegenerated],
    );

    useImperativeHandle(
      ref,
      () => ({
        save: () => {
          handleSubmit(onSubmit)();
        },
      }),
      [handleSubmit, onSubmit],
    );

    useEffect(() => {
      if (isEditing || !isComplete || isIdeaAlignment.isRegenerated) {
        onDispatch(setEnableHistoryStatus(false));
        reset({
          title: data?.title ?? '',
          description: data?.description ?? '',
        });
      } else {
        onDispatch(setEnableHistoryStatus(true));
      }
    }, [isEditing, isComplete, isIdeaAlignment.isRegenerated]);

    const isSaveDisabled = useMemo(() => !isValid, [isValid]);

    const handleSave = useCallback(async () => {
      if (isIdeaAlignment.status && !isIdeaAlignment.isRegenerated) {
        setIsModalOpen(true);
        return;
      }
      await handleSubmit(onSubmit)();
    }, [isIdeaAlignment.status, isIdeaAlignment.isRegenerated]);

    return (
      <div className="mb-24 flex w-full flex-col gap-24">
        <div className="grid w-full grid-cols-1 gap-24">
          {fieldsToDisplay.map(({ name, label, required }) => {
            return (
              <div key={name} className="flex flex-col gap-10">
                <InputLabel optional={!required} value={label} />
                {isEditing || !isComplete || isIdeaAlignment.isRegenerated ? (
                  <Controller
                    control={control}
                    name={name as keyof CreativeIdeaSchema}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      if (name === 'description') {
                        return (
                          <TextAreaInput
                            autoComplete="off"
                            className="overflow-custom mt-1 block w-full resize-none !text-14"
                            containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                            error={error?.message}
                            onChange={onChange}
                            placeholder={`Input the ${label} here`}
                            rows={5}
                            value={value}
                          />
                        );
                      }

                      return (
                        <TextInput
                          autoComplete="off"
                          className="overflow-custom mt-1 block w-full resize-none !text-14"
                          containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                          error={error?.message}
                          onChange={onChange}
                          placeholder={`Input the ${label} here`}
                          rows={2}
                          value={value}
                        />
                      );
                    }}
                  />
                ) : (
                  <Content
                    isFetching={isFetching}
                    isMarkDown={false}
                    value={data?.[name as keyof CreativeIdeaSchema] || ''}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex w-full items-center justify-end">
          <ActionButtons
            isActive={isComplete && !isIdeaAlignment.isRegenerated}
            isEditing={isEditing}
            isEditor={isEditor}
            isFetching={false}
            isValid={isSaveDisabled || isGenerating}
            onCancel={() => toggleIsEditing(false)}
            onEdit={() => toggleIsEditing(true)}
            onSave={handleSave}
          />
        </div>
        <ChangingImpactModal
          handleSubmit={async () => {
            await handleSubmit(onSubmit)();
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          isRegenerating={isLoading}
          onClose={() => {
            toggleIsEditing(false);
            setIsModalOpen(false);
          }}
          section="creative-idea"
          visibleSections={[
            'Idea Alignment',
            'Idea Expansion',
            'Idea Personalization',
          ]}
        />
      </div>
    );
  },
);

export default memo(CreativeIdeaSection);
