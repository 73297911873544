import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { GetDifferentResponse } from '@/Types/AudienceArchetype';
import type { HistoryStatus } from '@/Types/history';
import type { ArchetypeIdeaPersonalization } from '@/Types/IdeaPersonalization';
import type { ProjectProps } from '@/Types/Projects';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import { useGenerate } from '../useGenerate';

interface UseCtaParams {
  differentArchetypes: GetDifferentResponse | undefined;
  isKeyTouchPoint: HistoryStatus;
  isIdeaExpansion: HistoryStatus;
  routeOptionValue: RouteOption;
  activeArchetype: ArchetypeIdeaPersonalization | null;
  totalSelected: number;
  isEditor: boolean;
  isEditing: boolean;
  message: string;
  project: ProjectProps;
  archetypesArray: ArchetypeIdeaPersonalization[];
  setShowRegenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorTabIndex: React.Dispatch<React.SetStateAction<number[]>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  differentArchetypes,
  isKeyTouchPoint,
  isIdeaExpansion,
  isEditor,
  routeOptionValue,
  project,
  totalSelected,
  isEditing,
  archetypesArray,
  activeArchetype,
  message,
  setErrorTabIndex,
  setShowViewInfoModal,
  setShowRegenerateModal,
  setRouteOption,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;
  const { mutate: generate } = useGenerate();

  // Memoize routeOptionValue to avoid unnecessary re-render
  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const hasSelectedKey = (archetypesArray: ArchetypeIdeaPersonalization[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedData = archetypesArray
      .map((section, index) => {
        const hasSelectedData = section.idea_personalizations.some(
          (datum) => datum.is_selected,
        );
        return hasSelectedData ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedData.length) {
      setErrorTabIndex(indicesWithoutSelectedData);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedData
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select idea personalization for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleEditorClick = useCallback(async () => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea Personalization is still generating.`,
        ),
      );
      return;
    }

    if (isIdeaExpansion.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea expansion is still generating`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isKeyTouchPoint.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Key Touch Point is still generating`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      !isIdeaExpansion.status ||
      isIdeaExpansion.isRegenerated ||
      differentArchetypes?.is_different_archetype.idea_expansions ||
      activeArchetype?.has_regenerate_per_archetype_idea_expansions ||
      activeArchetype?.is_diff_idea_personalization ||
      (activeArchetype?.total_idea_expansions ?? 0) === 0
    ) {
      const hasError = hasSelectedKey(archetypesArray);

      if (hasError) return;

      if (
        !isIdeaExpansion.isRegenerated &&
        (activeArchetype?.has_regenerate_per_archetype_idea_expansions ||
          activeArchetype?.is_diff_idea_personalization)
      ) {
        setShowRegenerateModal(true);
        return;
      }

      dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
      removeAllArchetypesBasedOnSectionFromLocalStorage(
        project.slug,
        'expansion',
      );
      generate(
        {
          projectSlug: project.slug,
          section: 'idea-expansion',
          payload: {
            regenerate_prompt: '',
            generate_more: '',
          },
        },
        {
          onSuccess: () => {
            dispatch(
              saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: false }),
            );
            navigate(`/${project.slug}/idea-expansion`);
          },
        },
      );

      return;
    }

    navigate(`/${project.slug}/idea-expansion`);
  }, [
    memoizedRouteOptionValue,
    isEditing,
    totalSelected,
    archetypesArray,
    activeArchetype?.is_querying,
    isIdeaExpansion.status,
    differentArchetypes?.is_different_archetype.idea_expansions,
    isIdeaExpansion.isQuerying,
    isIdeaExpansion.isRegenerated,
    activeArchetype?.total_idea_expansions,
    activeArchetype?.has_regenerate_per_archetype_idea_expansions,
    activeArchetype?.is_diff_idea_personalization,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Key insight is still generating.`,
        ),
      );
      return;
    }

    if (
      !isIdeaExpansion.status ||
      isIdeaExpansion.isRegenerated ||
      differentArchetypes?.is_different_archetype.idea_expansions ||
      activeArchetype?.has_regenerate_per_archetype_idea_expansions ||
      (activeArchetype?.total_idea_expansions ?? 0) === 0
    ) {
      setShowViewInfoModal(true);
      return;
    }

    navigate(`/${projectSlug}/idea-expansion`);
  }, [
    activeArchetype?.is_querying,
    isIdeaExpansion.status,
    isIdeaExpansion.isRegenerated,
    differentArchetypes?.is_different_archetype.idea_expansions,
    activeArchetype?.total_idea_expansions,
    activeArchetype?.has_regenerate_per_archetype_idea_expansions,
  ]);

  useEffect(() => {
    const totalIdeaExpansion = activeArchetype?.total_idea_expansions ?? 0;
    memoizedRouteOptionValue.isActive = message !== 'No data found';

    if (isEditor) {
      memoizedRouteOptionValue.isInactive =
        activeArchetype?.is_querying ||
        activeArchetype?.idea_personalizations.length === 0 ||
        isIdeaExpansion.isQuerying ||
        isKeyTouchPoint.isQuerying ||
        totalSelected === 0;

      if (
        isIdeaExpansion.status &&
        totalIdeaExpansion > 0 &&
        !isIdeaExpansion.isRegenerated &&
        (activeArchetype?.has_regenerate_per_archetype_idea_expansions ||
          activeArchetype?.is_diff_idea_personalization)
      ) {
        memoizedRouteOptionValue.label = 'Update other pages';
        memoizedRouteOptionValue.isGenerate = true;
      } else if (
        isIdeaExpansion.status &&
        totalIdeaExpansion > 0 &&
        !isIdeaExpansion.isRegenerated &&
        !activeArchetype?.has_regenerate_per_archetype_idea_expansions &&
        !differentArchetypes?.is_different_archetype.idea_expansions
      ) {
        memoizedRouteOptionValue.label = 'Next to Idea Expansion';
        memoizedRouteOptionValue.isGenerate = false;
      } else {
        memoizedRouteOptionValue.label = 'Generate Idea Expansion';
        memoizedRouteOptionValue.isGenerate = true;
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.label = 'Next to Idea Expansion';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.isInactive =
        !isIdeaExpansion.status ||
        isIdeaExpansion.isQuerying ||
        differentArchetypes?.is_different_archetype.idea_expansions ||
        isIdeaExpansion.isRegenerated;

      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    activeArchetype?.is_diff_idea_personalization,
    activeArchetype?.is_querying,
    activeArchetype?.idea_personalizations,
    activeArchetype?.has_regenerate_per_archetype_idea_expansions,
    isEditor,
    totalSelected,
    isKeyTouchPoint.isQuerying,
    isIdeaExpansion.status,
    isIdeaExpansion.isQuerying,
    isIdeaExpansion.isRegenerated,
    activeArchetype?.total_idea_expansions,
    differentArchetypes?.is_different_archetype.idea_expansions,
    message,
  ]);
};

export { useCtaAction };
