import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import NotDataFound from '@/Components/NotDataFound';
import EditButton from '@/Components/Projects/EditButton';
import SourcesList from '@/Components/Projects/SourcesList';
import { useUpdateTargetAudience } from '@/Hooks/TargetAudience';
import type { SourcesType } from '@/Types/Projects';
import type {
  AudienceInsightProps,
  TargetAudienceItem,
} from '@/Types/TargetAudience';
import type { AudienceInsightsSchema } from '@/Types/TargetAudience/schema-audience-insights';
import { AudienceInsights } from '@/Types/TargetAudience/schema-audience-insights';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import { jsonToMarkdown, markdownToJson } from '../Utils/utils';
import SectionData from './SectionData';

interface ContentAudienceInsightSectionProps {
  message: string;
  id?: number;
  activeSection?: string;
  projectSlug?: string;
  user?: UserResponse;
  refetch?: () => void;
  isEditor?: boolean;
  isLoading?: boolean;
  content?: AudienceInsightProps;
  sources?: SourcesType[];
  onEditingChange?: (isEditing: boolean) => void;
  setShowAlertGenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContentAudienceInsightSection = ({
  message,
  id,
  projectSlug,
  activeSection,
  refetch,
  user,
  isEditor = false,
  isLoading = false,
  content = {
    motivations: [],
    passion_points: [],
    pain_points: [],
    expectations: [],
  },
  sources = [],
  onEditingChange,
  setShowAlertGenerateModal,
}: ContentAudienceInsightSectionProps) => {
  const navigate = useNavigate();

  const { mutate: updateTargetAudience } =
    useUpdateTargetAudience<AudienceInsightProps>();

  const [audienceInsights, setAudienceInsights] =
    useState<AudienceInsightProps>({
      motivations: content.motivations,
      passion_points: content.passion_points,
      pain_points: content.pain_points,
      expectations: content.expectations,
    });

  // Handler untuk mengupdate bagian tertentu
  const updateAudienceInsight = (
    key: keyof AudienceInsightProps,
    value: TargetAudienceItem[],
  ) => {
    setAudienceInsights((prev) => ({ ...prev, [key]: value }));
  };

  const [isEditing, setIsEditing] = useState(false);
  const isUpdating = false;

  const { setValue, getValues } = useForm<AudienceInsightsSchema>({
    resolver: zodResolver(AudienceInsights),
    defaultValues: {
      passion_points: '',
      motivations: '',
      pain_points: '',
      expectations: '',
    },
    mode: 'all',
  });

  const setDefaultValue = () => {
    setAudienceInsights({
      motivations: content.motivations,
      passion_points: content.passion_points,
      pain_points: content.pain_points,
      expectations: content.expectations,
    });

    setValue('motivations', jsonToMarkdown(content?.motivations ?? []));
    setValue('passion_points', jsonToMarkdown(content?.passion_points ?? []));
    setValue('expectations', jsonToMarkdown(content?.expectations ?? []));
    setValue('pain_points', jsonToMarkdown(content?.pain_points ?? []));
  };
  useEffect(() => {
    setDefaultValue();
  }, [content]);

  const toggleIsEditing = () => {
    setIsEditing((prev) => {
      const newEditingState = !prev;
      if (onEditingChange) {
        onEditingChange(newEditingState);
      }
      return newEditingState;
    });
  };

  const handleContentChange = (
    section: 'motivations' | 'passion_points' | 'pain_points' | 'expectations',
    updatedContent: string,
  ) => {
    const json = markdownToJson(updatedContent);
    setValue(section, jsonToMarkdown(json));
    updateAudienceInsight(section, json);
  };

  const handleSave = () => {
    if (!user || !id || !projectSlug || !activeSection) return;
    const formattedContent: AudienceInsightProps = {
      passion_points: markdownToJson(getValues('passion_points')),
      motivations: markdownToJson(getValues('motivations')),
      pain_points: markdownToJson(getValues('pain_points')),
      expectations: markdownToJson(getValues('expectations')),
    };

    triggerGTMEvent({
      event: `Edit Audience Insights`,
      eventCategory: `Edit Target Audience Audience Insights Click`,
      eventAction: 'Click',
      eventLabel: 'Audience Insights',
      userId: user.email,
      data: JSON.stringify(formattedContent),
    });

    updateTargetAudience(
      {
        payload: formattedContent,
        historyId: id,
        projectSlug,
        activeSection,
      },
      {
        onSuccess: (data) => {
          data.is_regenerate_audience && setShowAlertGenerateModal(true);
          toggleIsEditing();
          refetch?.();
        },
      },
    );
  };

  const handleCancelEdit = () => {
    setDefaultValue();
    toggleIsEditing();
  };

  if (message === 'No data found') {
    return (
      <NotDataFound
        buttonLabel="Go to Submission"
        description="Click the button below to generate a product research in Submission page."
        onClick={() => navigate(`/${projectSlug}/submission`)}
        title="No Data Found."
      />
    );
  }

  return (
    <>
      <div className="flex flex-col gap-24 text-black-redx">
        <SectionData
          content={audienceInsights?.pain_points ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) => handleContentChange('passion_points', html)}
          title="Passion Points"
        />
        <SectionData
          content={audienceInsights?.motivations ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) => handleContentChange('motivations', html)}
          title="Motivations"
        />
        <SectionData
          content={audienceInsights?.pain_points ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) => handleContentChange('pain_points', html)}
          title="Pain Points"
        />
        <SectionData
          content={audienceInsights?.expectations ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) => handleContentChange('expectations', html)}
          title="Needs/Expectations"
        />
      </div>

      <div className="my-24 flex w-full items-center justify-end gap-x-15">
        {isEditor &&
          (isEditing ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={handleCancelEdit}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className={`inline-flex items-center gap-x-4 ${
                  isUpdating ? 'text-gray-600' : 'text-blue-redx'
                } cursor-pointer`}
                disabled={isUpdating}
                id="btn-edit-audience-insight"
                onClick={handleSave}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>

      <div className="mr-1 flex w-full pb-60">
        {!isLoading && sources && <SourcesList sources={sources} />}
        {isLoading && (
          <div className="inline-flex gap-10">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ContentAudienceInsightSection;
