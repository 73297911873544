import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { UpdateResponse } from '@/Types/TargetAudience';

const updateTargetAudience = async <T>({
  payload,
  historyId,
  projectSlug,
  activeSection,
}: {
  payload: T;
  historyId: number;
  projectSlug: string;
  activeSection: string;
}) => {
  const endpoints: Record<string, string> = {
    audience_insight: `/projects/${projectSlug}/target-audience/audience-insight`,
    perception_analysis: `/projects/${projectSlug}/target-audience/perception-analysis`,
    behavioral_trends: `/projects/${projectSlug}/target-audience/behavioral-trend`,
    media_consumption_patterns: `/projects/${projectSlug}/target-audience/media-consumption-pattern`,
  };

  const url = endpoints[activeSection];

  if (!url) {
    throw new Error('Invalid section provided');
  }

  try {
    const { data } = await goFetcher.put(`${url}/update/${historyId}`, payload);

    return data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useUpdateTargetAudience = <T>() => {
  return useMutation<
    UpdateResponse,
    Error,
    {
      payload: T;
      historyId: number;
      projectSlug: string;
      activeSection: string;
    }
  >({
    mutationFn: updateTargetAudience,
  });
};

export { useUpdateTargetAudience };
