import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { SelectedFramework } from '@/Types/Projects';
import type { UpdateFrameworkResponse } from '@/Types/Submission';

const updateSelectedFramework = async ({
  projectSlug,
  selectedFramework,
}: {
  projectSlug: string;
  selectedFramework: SelectedFramework;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/submission/selected-framework`,
      {
        selected_framework: selectedFramework,
      },
    );

    return data as UpdateFrameworkResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedFramework = () => {
  return useMutation<
    UpdateFrameworkResponse,
    Error,
    { projectSlug: string; selectedFramework: SelectedFramework }
  >({
    mutationFn: updateSelectedFramework,
  });
};

export { useUpdateSelectedFramework };
