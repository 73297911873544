import Card from './Card';

const Skeleton = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <Card key={index} index={index} isFetching />
      ))}
    </>
  );
};

export default Skeleton;
