import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import NotDataFound from '@/Components/NotDataFound';
import EditButton from '@/Components/Projects/EditButton';
import SourcesList from '@/Components/Projects/SourcesList';
import { useUpdateTargetAudience } from '@/Hooks/TargetAudience';
import type { SourcesType } from '@/Types/Projects';
import type {
  MediaConsumptionPatternsProps,
  TargetAudienceItem,
} from '@/Types/TargetAudience';
import {
  AudienceMediaConsumption,
  type AudienceMediaConsumptionSchema,
} from '@/Types/TargetAudience/schema-audience-media-consumption-pattern';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import { jsonToMarkdown, markdownToJson } from '../Utils/utils';
import SectionData from './SectionData';

interface ContentMediaConsumptionSectionProps {
  id?: number;
  message?: string;
  activeSection?: string;
  projectSlug?: string;
  user?: UserResponse;
  refetch?: () => void;
  isEditor?: boolean;
  isLoading?: boolean;
  content?: MediaConsumptionPatternsProps;
  sources?: SourcesType[];
  onEditingChange?: (isEditing: boolean) => void;
  setShowAlertGenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContentMediaConsumptionSection = ({
  id,
  message,
  projectSlug,
  activeSection,
  refetch,
  user,
  isEditor = false,
  isLoading = false,
  content = {
    media_consumption_pattern: [],
  },
  sources = [],
  onEditingChange,
  setShowAlertGenerateModal,
}: ContentMediaConsumptionSectionProps) => {
  const navigate = useNavigate();
  const { mutate: updateTargetAudience } =
    useUpdateTargetAudience<MediaConsumptionPatternsProps>();

  const [mediaConsumption, setMediaConsumption] =
    useState<MediaConsumptionPatternsProps>({
      media_consumption_pattern: content.media_consumption_pattern,
    });

  // Handler untuk mengupdate bagian tertentu
  const updateMediaConsumption = (
    key: keyof MediaConsumptionPatternsProps,
    value: TargetAudienceItem[],
  ) => {
    setMediaConsumption((prev) => ({ ...prev, [key]: value }));
  };

  const [isEditing, setIsEditing] = useState(false);
  const isUpdating = false;

  const { setValue, getValues } = useForm<AudienceMediaConsumptionSchema>({
    resolver: zodResolver(AudienceMediaConsumption),
    defaultValues: {
      media_consumption_pattern: '',
    },
    mode: 'all',
  });

  const setDefaultValue = () => {
    setMediaConsumption({
      media_consumption_pattern: content.media_consumption_pattern,
    });

    setValue(
      'media_consumption_pattern',
      jsonToMarkdown(content?.media_consumption_pattern ?? []),
    );
  };

  useEffect(() => {
    setDefaultValue();
  }, [content]);

  const toggleIsEditing = () => {
    setIsEditing((prev) => {
      const newEditingState = !prev;
      if (onEditingChange) {
        onEditingChange(newEditingState);
      }
      return newEditingState;
    });
  };

  const handleContentChange = (
    section: 'media_consumption_pattern',
    updatedContent: string,
  ) => {
    const json = markdownToJson(updatedContent);
    setValue(section, jsonToMarkdown(json));
    updateMediaConsumption(section, json);
  };

  const handleSave = () => {
    if (!user || !id || !projectSlug || !activeSection) return;
    const formattedContent: MediaConsumptionPatternsProps = {
      media_consumption_pattern: markdownToJson(
        getValues('media_consumption_pattern'),
      ),
    };

    triggerGTMEvent({
      event: `Edit Media Consumption`,
      eventCategory: `Edit Target Audience Media Consumption Click`,
      eventAction: 'Click',
      eventLabel: 'Media Consumption',
      userId: user.email,
      data: JSON.stringify(formattedContent),
    });

    updateTargetAudience(
      {
        payload: formattedContent,
        historyId: id,
        projectSlug,
        activeSection,
      },
      {
        onSuccess: (data) => {
          data.is_regenerate_audience && setShowAlertGenerateModal(true);
          toggleIsEditing();
          refetch?.();
        },
      },
    );
  };

  const handleCancelEdit = () => {
    setDefaultValue();
    toggleIsEditing();
  };

  if (message === 'No data found') {
    return (
      <NotDataFound
        buttonLabel="Go to Submission"
        description="Click the button below to generate a product research in Submission page."
        onClick={() => navigate(`/${projectSlug}/submission`)}
        title="No Data Found."
      />
    );
  }

  return (
    <>
      <div className="flex flex-col gap-24 text-black-redx">
        <SectionData
          content={mediaConsumption?.media_consumption_pattern ?? []}
          isEditing={isEditing}
          isLoading={isLoading}
          onChange={(html) =>
            handleContentChange('media_consumption_pattern', html)
          }
          title="Media Consumption Patterns"
        />
      </div>

      <div className="my-24 flex w-full items-center justify-end gap-x-15">
        {isEditor &&
          (isEditing ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={handleCancelEdit}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className={`inline-flex items-center gap-x-4 ${
                  isUpdating ? 'text-gray-600' : 'text-blue-redx'
                } cursor-pointer`}
                disabled={isUpdating}
                id="btn-edit-audience-insight"
                onClick={handleSave}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>

      <div className="mr-1 flex w-full pb-60">
        {!isLoading && sources && <SourcesList sources={sources} />}
        {isLoading && (
          <div className="inline-flex gap-10">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ContentMediaConsumptionSection;
