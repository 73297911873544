import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';

import { useModalRegenerateContext } from '@/Context/ModalRegenerate';

import Checkbox from '../Checkbox';

interface RegenerateInfoModalProps {
  isOpen: boolean;
  section: string;
  onClose: () => void;
}

const RegenerateInfoModal: React.FC<RegenerateInfoModalProps> = ({
  isOpen,
  onClose,
  section,
}) => {
  const {
    show,
    section: savedSection,
    setModalState,
  } = useModalRegenerateContext();

  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    if (dontShowAgain && !isOpen) {
      setModalState({ section, show: false });
    }
  }, [dontShowAgain, section, setModalState, isOpen]);

  const handleClose = () => {
    if (dontShowAgain) {
      setModalState({ section, show: false });
    }
    onClose();
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen) {
      timer = setTimeout(() => {
        handleClose();
      }, 10000);
    }
    return () => clearTimeout(timer); // Cleanup timer on component unmount or on modal close
  }, [isOpen, onClose, show]);

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  if (!show && savedSection === section) {
    return null;
  }

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed bottom-80 right-4 z-60 mx-12"
        onClick={handleStopPropagation}
        onClose={handleClose}
      >
        <div className="">
          <span
            aria-hidden="true"
            className="inline-block h-screen align-middle"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="my-8 flex max-w-md flex-col justify-between gap-16 overflow-hidden rounded-2xl bg-white p-16 text-left align-middle transition-all"
              style={{
                boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.15)', // Correct format
              }}
            >
              <div className="flex flex-col gap-8">
                <p
                  className="text-14 font-normal leading-relaxed text-black-redx
              "
                >
                  Remember to click the update button below after you finish
                  making changes on this page.
                </p>
              </div>
              <div className="inline-flex items-center justify-between">
                <Checkbox
                  checked={dontShowAgain}
                  className=""
                  label="Don’t show this again"
                  onChange={setDontShowAgain}
                />
                <button
                  className="mt-16 rounded-10 bg-blue-redx px-14 py-10 font-semibold text-white"
                  onClick={handleClose}
                >
                  Understand
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RegenerateInfoModal;
