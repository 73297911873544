import { z } from 'zod';

export const ArchetypeSchema = z.object({
  archetype_name: z.string().min(4, 'Archetype name is required'),
  demographics: z.string().min(4, 'Demographics is required'),
  occupation: z.string().min(4, 'Occupation is required'),
  lifestyle: z.string().min(4, 'Lifestyle is required'),
  behavior: z.string().min(4, 'Behavior is required'),
  needs_and_challenges: z.string().min(4, 'Needs and challenges is required'),
  potential_buying_motives: z
    .string()
    .min(4, 'Potential buying motives is required'),
  current_trends: z.string().min(4, 'Current trends is required'),
  source_of_information: z.string().min(4, 'Source of information is required'),
  online_search_behavior: z
    .string()
    .min(4, 'Online search behavior is required'),
  purchase_frequency: z.string().min(1, 'Purchase frequency is required'),
  preferred_sales_channels: z
    .string()
    .min(4, 'Preferred sales channels is required'),
});

export type Schema = z.infer<typeof ArchetypeSchema>;
