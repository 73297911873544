import { TabGroup } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import ActionSection from '@/Components/Projects/ActionSection';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import {
  useChallengeAndTasks,
  useCtaAction,
  useSectionDataHandler,
  useUpdateChallengeAndTask,
} from '@/Hooks/ChallengeAndTask';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type {
  ArchetypeChallenge,
  ArchetypesState,
  SectionChallenge,
} from '@/Types/ChallengeAndTask';
import type { ChallengeSchema } from '@/Types/ChallengeAndTask/schema';
import type { SourcesType } from '@/Types/Projects';
import type { SectionList } from '@/Types/tabs';
import { getArchetypeDetails } from '@/Utils/archetype';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import Skeleton from './Components/Skeleton';
import Tabs from './Components/Tabs';

const Index = () => {
  const sectionList: SectionList = {
    title: 'Challenge & Communication Task',
    value: 'challenges',
    section: 'brand',
  };
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [message, setMessage] = useState('');
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const isEnabledHistoryStatus = useMemo(
    () => state.isEnabledHistoryStatus,
    [state.isEnabledHistoryStatus],
  );

  const { roles } = useRolesBadge(project, user);
  const { mutate: generate, isPending: isGenerating } = useGenerate();
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [archetypes, setArchetypes] = useState<ArchetypesState>({});
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>([]);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, isLoading, refetch, isRefetching } =
    useChallengeAndTasks(project);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [totals, setTotals] = useState<{
    allSelected: number;
    allData: number;
  }>({
    allSelected: 0,
    allData: 0,
  });

  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const { mutate, isPending: isSubmitting } = useUpdateChallengeAndTask();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({}); // State per archetype
  const archetypesArray = useMemo(
    () => Object.values(archetypes),
    [archetypes],
  );
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [shouldTriggerModal, setShouldTriggerModal] = useState(false);
  const { data: differentArchetypes, refetch: refetchDiffArchetype } =
    useDifferentArchetypes(project.slug);

  const {
    isCompetitorAnalysisComplete,
    isOnePageStrategy,
    isChallengeAndTaskOfTheBrand,
  } = useHistoryStatus(project, isEnabledHistoryStatus);

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Generate Competitor Analysis',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  });

  const activeArchetype = useMemo(() => {
    return (
      archetypesArray.find(
        (archetype) => archetype.id === archetypesArray[activeTab]?.id,
      ) || null
    );
  }, [archetypesArray, activeTab]);

  useSectionDataHandler({
    diffArch: differentArchetypes,
    archetypes,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionKey: sectionList.value,
    setArchetypes,
    setGeneratingStates,
    setProgressStates,
    setSources,
    setTotalRegenerate,
    setTotals,
    setActiveTab,
    setMessage,
  });

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: sectionList.value,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
    isQuerying: isChallengeAndTaskOfTheBrand.isQuerying,
  });

  const onSelectSuccess = async (isEdited: boolean) => {
    setShowAlertGenerateModal(isEdited);
    await refetch();
    setShouldTriggerModal(true);
  };

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const selectedKeyIndexes = useMemo(() => {
    return activeArchetype?.challenges
      .map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [activeArchetype?.challenges]);

  // const limit = activeArchetype?.total_regenerate || 0; TODO: will use this if 'generate more' is generated by archetype

  const handleFetchSubmit = async (
    payload: ChallengeSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, id, archetypeId: activeArchetype?.id!, projectSlug },
      {
        onSuccess: ({ data }) => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Challenge And Task`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Challenge And Task  Click`,
            eventAction: 'Click',
            eventLabel: 'Challenge And Task',
            userId: user.email,
            data: payload,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setShowAlertGenerateModal(data.is_edited && data.is_selected);
          refetch();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleCancelEdit = () => {
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleCancelAdd = () => {
    const activeArchetypeId = archetypesArray[activeTab]?.id;
    if (!activeArchetypeId) return;

    setArchetypes((prev) => {
      const current = prev[activeArchetypeId]?.challenges || [];
      const filtered = current.filter((datum) => datum.id !== -1);

      return {
        ...prev,
        [activeArchetypeId]: {
          ...prev[activeArchetypeId],
          challenges: filtered,
        },
      };
    });

    toggleIsAdding();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleAddNew = () => {
    if (!activeArchetype) return;

    const newData: SectionChallenge = {
      id: -1, // Gunakan ID sementara
      archetype_id: activeArchetype.id,
      is_manual: true,
      is_edited: false,
      submission_id: -1, // Sesuaikan dengan tipe Anda
      history_id: -1, // Sesuaikan dengan tipe Anda
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_selected: false,
      challenges_content: {
        challenge: '',
        communication_task: '',
      },
    };

    // Hitung panjang array baru
    const current = archetypes[activeArchetype.id]?.challenges || [];
    const newIndex = current.length;

    // Update state `archetypes` dengan data baru
    setArchetypes((prev) => {
      const updatedArchetypes = {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          challenges: [...current, newData],
        },
      };
      return updatedArchetypes;
    });

    // Setelah state diperbarui, pastikan slider berpindah ke item baru
    setGoToIndex(newIndex);
    toggleIsAdding(); // Aktifkan mode "Adding"
  };

  const handleRegenerate = useCallback(async () => {
    if (
      activeArchetype &&
      isOnePageStrategy.status &&
      (activeArchetype?.is_diff_challenges ||
        activeArchetype?.regenerate_per_archetype_onepage)
    ) {
      generate(
        {
          projectSlug: project.slug,
          section: 'one-page-strategy',
          payload: {
            generate_more: null,
            regenerate_prompt: null,
            status: 'regenerate_per_archetype_from_challenge',
            archetype_id: [activeArchetype.id],
          },
        },
        {
          onSuccess: () => {
            refetch();
            setShowRegenerateModal(false);
          },
        },
      );
    }
  }, [
    activeArchetype?.id,
    activeArchetype?.regenerate_per_archetype_onepage,
    isOnePageStrategy.status,
    activeArchetype?.is_diff_challenges,
  ]);

  const handleRefetchSelectedData = useCallback(() => {
    queryClient.resetQueries({
      queryKey: ['competitors', project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));

    const totalEditedAndSelected =
      activeArchetype?.challenges.filter(
        (data) => data.is_edited && data.is_selected,
      ).length ?? 0;

    if (
      isOnePageStrategy.status &&
      !differentArchetypes?.is_different_archetype.one_pages &&
      !isOnePageStrategy.isRegenerated &&
      (totalEditedAndSelected > 0 ||
        activeArchetype?.is_diff_challenges ||
        activeArchetype?.regenerate_per_archetype_onepage)
    ) {
      setShowRegenerateModal(true);
      return;
    }

    generate(
      {
        projectSlug: project.slug,
        section: 'competitor-analysis',
        payload: {
          generate_more: null,
          regenerate_prompt: null,
        },
      },
      {
        onSuccess: () => {
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
          navigate(`/${project.slug}/competitor-analysis`);
        },
      },
    );
  }, [
    routeOptionValue,
    isCompetitorAnalysisComplete.isRegenerated,
    activeArchetype?.is_diff_challenges,
    activeArchetype?.regenerate_per_archetype_onepage,
    project.slug,
    differentArchetypes?.is_different_archetype.one_pages,
  ]);

  const handleRegenerateButton = (prompt: string) => {
    if (!activeArchetype) return;

    // TO DO: will use this code when generate more has per archetype

    // removeProgressFromLocalStorage(
    //   project.slug,
    //   'key_insights',
    //   activeArchetype.id,
    // );

    // setArchetypes((prev) => ({
    //   ...prev,
    //   [activeArchetypeId]: {
    //     ...prev[activeArchetype.id],
    //     is_querying: true,
    //   },
    // }));

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      sectionList.value,
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeChallenge>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Challenge And Task`,
      eventCategory: `Generate More Challenge And Task Click`,
      eventAction: 'Click',
      eventLabel: 'Challenge And Task',
      userId: user.email,
      data: prompt,
    });

    generate(
      {
        projectSlug: project.slug,
        section: 'challenge-and-task',
        payload: {
          generate_more: prompt,
          regenerate_prompt: null,
        },
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(setEnableHistoryStatus(true));
        },
      },
    );
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totals.allSelected} from ${totals.allData} challenge & communication task selected`,
      ),
    );
  }, [totals]);

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useRoutePrev({
    id: 'btn-back-challenge',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/key-insight`),
  });

  useEffect(() => {
    setEnableHistoryStatus(!(isAdding || isEditing));
  }, [isAdding, isEditing]);

  useCtaAction({
    totalSelected: totals.allSelected,
    activeArchetype,
    archetypesArray,
    isEditing,
    isEditor,
    project,
    routeOptionValue,
    message,
    handleRefetchSelectedData,
    setErrorTabIndex,
    setRouteOption: setRouteOptionValue,
    setShowViewInfoModal,
  });

  useEffect(() => {
    const isDiffChallenge = activeArchetype?.is_diff_challenges || false;

    if (
      shouldTriggerModal &&
      isDiffChallenge &&
      !differentArchetypes?.is_different_archetype.opportunities &&
      !differentArchetypes?.is_different_archetype.one_pages &&
      !isOnePageStrategy.isRegenerated
    ) {
      setShowAlertGenerateModal(true);
    }
    setShouldTriggerModal(false);
  }, [
    activeArchetype?.is_diff_challenges,
    shouldTriggerModal,
    differentArchetypes?.is_different_archetype.opportunities,
    isOnePageStrategy.isRegenerated,
  ]);

  const handleClick = () => {
    navigate(`/${project.slug}/key-insight`);
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [
    isOnePageStrategy.isQuerying,
    isOnePageStrategy.isRegenerated,
    isChallengeAndTaskOfTheBrand.isQuerying,
  ]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex max-w-4xl grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">
              Challenge & Communication Task of the Brand
            </h1>
            <span className="text-1620 font-normal leading-relaxed text-grey-redx">
              Please{' '}
              <span className="font-bold text-black-redx">
                select one or more
              </span>{' '}
              Challenge & Communication Task{' '}
              <span className="font-bold text-black-redx">
                for each archetype
              </span>
              , or add a new by clicking &apos;Add New Challenge & Communication
              Task&apos;.
            </span>
          </div>
          {activeArchetype &&
            !activeArchetype.is_querying &&
            isEditor &&
            message !== 'No data found' &&
            !activeArchetype.is_regenerate && (
              <RegenerateButton
                isGenerateMore
                isLoading={isLoading}
                limit={totalRegenerate} // TODO: change with 'limit', if 'generate more' is generated per archetype
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="challenge-and-task"
              />
            )}
        </div>

        {message === 'No data found' ? (
          <NotDataFound
            buttonLabel="Go to Audience Archetype"
            description="You don't have selected archetype, click the button below to generate a archetype in Audience Archetype page."
            onClick={handleClick}
            title="No Data Found."
          />
        ) : (
          <TabGroup
            className="pb-80"
            onChange={setActiveTab}
            selectedIndex={activeTab}
          >
            <Tabs
              activeTab={activeTab}
              archetypesArray={archetypesArray}
              errorTabIndex={errorTabIndex}
              isEditing={isEditing || isAdding}
              isLoading={isLoading}
              isQuerying={isChallengeAndTaskOfTheBrand.isQuerying}
              onActiveTab={setActiveTab}
              user={user}
            />
            {isLoading ? (
              <Skeleton project={project} user={user} />
            ) : (
              <div
                key={activeArchetype?.id || activeTab}
                className="h-full py-24"
              >
                {(() => {
                  if (!activeArchetype) {
                    return null; // Jangan tampilkan apa-apa
                  }

                  if (activeArchetype.is_querying) {
                    return (
                      <GenerateLoading
                        estimateTimeTo={3}
                        progress={progressPortion({
                          progress: Number(
                            (progressStates[activeArchetype.id] || 0).toFixed(
                              0,
                            ),
                          ),
                          isQuerying: activeArchetype.is_querying,
                          isContentAvailable:
                            activeArchetype.challenges.length > 0,
                        })}
                        project={project}
                        section={sectionList}
                        showEmailNotification={
                          isChallengeAndTaskOfTheBrand.isSendingEmail
                        }
                      />
                    );
                  }

                  if (
                    activeArchetype.is_regenerate ||
                    activeArchetype.total_challenges === 0
                  ) {
                    return (
                      <NotDataFound
                        buttonLabel="Choose Key insight"
                        description=" To show results on Challenges, we need Key insight. Click button below to choose Key insight.."
                        onClick={handleClick}
                        title="You don’t have Challenges..."
                      />
                    );
                  }

                  return (
                    <div className="flex flex-col gap-20 ">
                      <SelectedCard
                        data={getArchetypeDetails(
                          activeArchetype.archetype_content,
                        )}
                        title={`Archetype Detail - ${activeArchetype.archetype_content.archetype_name}`}
                      />
                      <SliderContainer
                        goToIndex={goToIndex}
                        isAdding={isAdding}
                        isEditing={isEditing}
                        length={activeArchetype.challenges.length}
                        onActiveIndex={(index) => setActiveIndex(index)}
                        selectedIndexes={selectedKeyIndexes}
                      >
                        {activeArchetype.challenges.map((datum, index) => (
                          <Card
                            key={index}
                            ref={(el) => (cardRefs.current[index] = el)}
                            id={datum.id}
                            index={index}
                            isCanSelect={isEditor}
                            isDisabledSelectButton={
                              isOnePageStrategy.status &&
                              activeArchetype.total_selected_challenges === 1 &&
                              !activeArchetype.regenerate_per_archetype_onepage &&
                              !isOnePageStrategy.isRegenerated &&
                              !differentArchetypes?.is_different_archetype
                                .one_pages
                            }
                            isEdited={datum.is_edited || datum.is_manual}
                            isEditing={isEditing || isAdding}
                            isFetching={isRefetching}
                            isSelected={!!datum.is_selected}
                            item={datum.challenges_content}
                            onSelectSuccess={onSelectSuccess}
                            onSubmitSuccess={handleFetchSubmit}
                            project={project}
                            refetchDiffArchetype={refetchDiffArchetype}
                            totalData={activeArchetype.challenges.length}
                            user={user}
                          />
                        ))}
                      </SliderContainer>

                      <ActionSection
                        isEditing={isAdding || isEditing}
                        isEditor={isEditor}
                        isSubmitting={isSubmitting}
                        label="Add Challenge & Communication Task Manually"
                        onAdd={handleAddNew}
                        onCancel={isAdding ? handleCancelAdd : handleCancelEdit}
                        onSave={handleSubmit}
                        toggleEditing={toggleIsEditing}
                      />

                      {sources && <SourcesList sources={sources} />}
                    </div>
                  );
                })()}
              </div>
            )}
          </TabGroup>
        )}
      </div>
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="challenge-and-task"
      />
      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="challenge-and-task"
        visibleSections={[
          'One-Page Strategy',
          'Key Touch Point',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
