import { Helmet } from 'react-helmet';

import ProjectLayout from '@/Layouts/ProjectLayout';

import Index from './Index';

const Wrapper = () => {
  return (
    <>
      <Helmet>
        <title>Key Touch Point</title>
      </Helmet>
      <ProjectLayout>
        <Index />
      </ProjectLayout>
    </>
  );
};

export default Wrapper;
