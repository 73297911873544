import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';

interface UpdateSelectedProps {
  id: number;
  projectSlug: string;
  framework: string;
  title: string;
  archetypeId: number;
  recommendationNumber: number;
  checked: boolean;
}

const updateSelected = async ({
  id,
  projectSlug,
  framework,
  archetypeId,
  recommendationNumber,
  checked,
  title,
}: UpdateSelectedProps) => {
  const url = `/projects/${projectSlug}/${framework}/update-selected`;

  try {
    const response = await goFetcher.put(url, {
      id,
      framework: title,
      archetype_id: archetypeId,
      recommendation_number: recommendationNumber,
      checked,
      step_name: 'content_ideas',
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedSearchability = () => {
  return useMutation<any, Error, UpdateSelectedProps>({
    mutationFn: updateSelected,
  });
};

export { useUpdateSelectedSearchability };
