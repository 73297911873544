import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { DiscoverabilityListResponse } from '@/Types/DiscoverabilityContent';
import type { ProjectProps } from '@/Types/Projects';

interface UseDiscoverabilityProps {
  project: ProjectProps;
}

const getLists = async ({ project }: UseDiscoverabilityProps) => {
  const projectSlug = project.slug;
  const url = `/projects/${projectSlug}/discoverability/lists`;

  try {
    const response = await goFetcher.get(url);

    return response.data as DiscoverabilityListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useDiscoverability = ({
  project,
}: UseDiscoverabilityProps): UseQueryResult<
  DiscoverabilityListResponse,
  Error
> => {
  return useQuery<DiscoverabilityListResponse, Error>({
    queryKey: [
      'discoverability',
      project.slug,
      project.submissions[0].selected_framework,
    ], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists({ project }),
    refetchOnWindowFocus: false,
    enabled: project.submissions[0].selected_framework === 'sdrcp',
  });
};

export { useDiscoverability };
