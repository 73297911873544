import Wrapper from '@/Pages/OpportunityAmongCompetition/Wrapper';

const OpportunityAmongCompetitionRoutes = [
  {
    path: '/:project_slug/opportunity-among-competition',
    element: <Wrapper />,
  },
];

export default OpportunityAmongCompetitionRoutes;
