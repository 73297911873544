import { Icon } from '@iconify/react/dist/iconify.js';
import React from 'react';

interface ActionsButtonsProps {
  isValid?: boolean;
  isFetching: boolean;
  isEditing: boolean;
  isActive: boolean;
  isEditor: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
}

const ActionButtons: React.FC<ActionsButtonsProps> = ({
  isValid = false,
  isFetching,
  isEditing,
  isActive,
  isEditor,
  onEdit,
  onSave,
  onCancel,
}) => {
  if (isFetching) return null;

  return (
    <>
      {!isEditing && isActive && isEditor && (
        <button
          className="inline-flex cursor-pointer items-center justify-center gap-8"
          id="btn-edit"
          onClick={onEdit}
        >
          <span className="text-14 font-semibold text-blue-redx">Edit</span>
          <Icon className="size-16 text-blue-redx" icon="lucide:pencil-line" />
        </button>
      )}

      {isEditing && isActive && isEditor && (
        <div className="inline-flex gap-8">
          <button
            className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
            id="btn-cancel-edit"
            onClick={onCancel}
          >
            <span className="text-15 font-semibold">Cancel</span>
            <Icon className="size-16" icon="lucide:x" />
          </button>

          <button
            className={`inline-flex items-center gap-x-4 ${
              isValid
                ? 'cursor-not-allowed text-gray-600'
                : 'cursor-pointer text-blue-redx'
            } `}
            disabled={isValid}
            onClick={onSave}
          >
            <span className="text-15 font-semibold">Save</span>
            <Icon className="size-20" icon="lucide:save" />
          </button>
        </div>
      )}
    </>
  );
};

export default ActionButtons;
