import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { MarketResearchResponse } from '@/Types/MarketResearch';

const update = async ({
  content,
  id,
  projectSlug,
  activeSection,
}: {
  content: string;
  id: number;
  projectSlug: string;
  activeSection: string;
}) => {
  try {
    const endpoints: Record<string, string> = {
      trends: `/projects/${projectSlug}/market-research/trend/update`,
      demand: `/projects/${projectSlug}/market-research/demand/update`,
    };

    const url = endpoints[activeSection];

    if (!url) {
      throw new Error('Invalid section provided');
    }

    const { data } = await goFetcher.put(`${url}/${id}`, {
      content,
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateMarketResearch = () => {
  return useMutation<
    MarketResearchResponse,
    Error,
    { content: string; id: number; projectSlug: string; activeSection: string }
  >({
    mutationFn: update,
  });
};

export { useUpdateMarketResearch };
