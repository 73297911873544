import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { UserResponse } from '@/Types/user';

const fetchUser = async () => {
  try {
    const response = await goFetcher.get('/projects/user');
    return response.data as UserResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message || 'Failed to fetch user data');
  }
};

const useUser = () => {
  return useQuery<UserResponse, Error>({
    queryKey: ['user'],
    queryFn: fetchUser,
    refetchOnMount: false,
  });
};

export { useUser };
