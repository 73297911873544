import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ArchetypeResponse } from '@/Types/AudienceArchetype';

const updateSelectedArchetype = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}): Promise<ArchetypeResponse> => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/archetype/update-selected/${id}`,
      {},
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedArchetype = () => {
  return useMutation<
    ArchetypeResponse,
    Error,
    { id: number; projectSlug: string }
  >({
    mutationFn: updateSelectedArchetype,
  });
};

export { useUpdateSelectedArchetype };
