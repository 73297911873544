/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unused-imports/no-unused-vars */
import { useContext, useEffect, useRef } from 'react';

import { saveRoutePrev } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';

interface UseSaveRoutePrevProps {
  id?: string;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const useRoutePrev = ({
  id = 'back-btn',
  label,
  isActive,
  onClick,
}: UseSaveRoutePrevProps) => {
  const [_, dispatch] = useContext(ProjectContext);

  const onClickRef = useRef(onClick); // Simpan referensi fungsi

  useEffect(() => {
    onClickRef.current = onClick; // Perbarui referensi fungsi
  }, [onClick]);

  useEffect(() => {
    dispatch(
      saveRoutePrev({
        id,
        label,
        isActive,
        onClick: onClickRef.current,
      }),
    );
  }, [label, isActive]);
};

export { useRoutePrev };
