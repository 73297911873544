import { Helmet } from 'react-helmet';

import ProjectLayout from '@/Layouts/ProjectLayout';

import Index from './Index';

const Wrapper = () => {
  return (
    <ProjectLayout>
      <Helmet>
        <title>Searchability Content</title>
      </Helmet>
      <Index />
    </ProjectLayout>
  );
};

export default Wrapper;
