import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { UpdateFrameworkResponse } from '@/Types/Submission';
import type { MarketInformationSchema } from '@/Types/Submission/schema';

const updateMarketSubmission = async ({
  projectSlug,
  payload,
}: {
  projectSlug: string;
  payload: MarketInformationSchema;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/submission/market-information`,
      {
        product_subcategory: payload.product_subcategory,
      },
    );

    return data as UpdateFrameworkResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateMarketSubmission = () => {
  return useMutation<
    UpdateFrameworkResponse,
    Error,
    { projectSlug: string; payload: MarketInformationSchema }
  >({
    mutationFn: updateMarketSubmission,
  });
};

export { useUpdateMarketSubmission };
