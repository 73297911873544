import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CardTitle from '@/Components/CardTitle';
import Content from '@/Components/Content';
import Label from '@/Components/Label';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import TextAreaInput from '@/Components/TextAreaInput';
import { useUpdateSelectedChallengeAndTask } from '@/Hooks/ChallengeAndTask';
import type { ChallengeProps } from '@/Types/ChallengeAndTask';
import {
  Challenge,
  type ChallengeSchema,
} from '@/Types/ChallengeAndTask/schema';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

export interface CardHandles {
  submitForm: () => void;
}

const fieldsToDisplay = [
  { name: 'challenge', label: 'Challenge' },
  {
    name: 'communication_task',
    label: 'Communication Task',
  },
];

const Card = forwardRef<
  CardHandles,
  {
    user?: UserResponse;
    id?: number;
    isDisabledSelectButton?: boolean;
    project?: ProjectProps;
    totalData?: number;
    isEdited?: boolean;
    isFetching?: boolean;
    item?: ChallengeProps;
    index?: number;
    isEditing?: boolean;
    isSelected?: boolean;
    isCanSelect?: boolean;
    refetchDiffArchetype?: () => void;
    onSubmitSuccess?: (
      data: ChallengeSchema,
      id: number,
      index: number,
    ) => void;
    onSelectSuccess?: (isEdited: boolean) => void;
  }
>(
  (
    {
      user,
      id = 0,
      project,
      totalData,
      item,
      isDisabledSelectButton = false,
      isEdited = false,
      isFetching = true,
      index = 0,
      isSelected = false,
      isEditing = false,
      isCanSelect = false,
      refetchDiffArchetype,
      onSubmitSuccess,
      onSelectSuccess,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedChallengeAndTask();

    const { handleSubmit, reset, control } = useForm<ChallengeSchema>({
      resolver: zodResolver(Challenge),
      defaultValues: {
        challenge: item?.challenge ?? '',
        communication_task: item?.communication_task ?? '',
      },
      mode: 'all',
    });

    const onSubmit = (data: ChallengeSchema) => {
      if (onSubmitSuccess === undefined) return;

      onSubmitSuccess(data, id, index);
    };

    useEffect(() => {
      if (isEditing) {
        reset({
          challenge: item?.challenge ?? '',
          communication_task: item?.communication_task ?? '',
        });
      }
    }, [isEditing, reset, item]);

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    const handleSelected = (id: number) => {
      if (
        project === undefined ||
        user === undefined ||
        onSelectSuccess === undefined ||
        refetchDiffArchetype === undefined
      )
        return;

      const projectSlug = project.slug;

      triggerGTMEvent({
        event: `Select Challenge And Task`,
        eventCategory: `Button Select Challenge And Task Click`,
        eventAction: 'Click',
        eventLabel: 'Challenge And Task',
        userId: user.email,
        data: { type: isSelected ? 'Unselect' : 'Select', ...item },
      });

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: ({ data }) => {
            onSelectSuccess(data.is_selected && data.is_edited);
            refetchDiffArchetype();
          },
        },
      );
    };

    return (
      <div className="flex w-full flex-col pb-35 transition duration-500">
        <div className="px-35 py-15 text-center">
          {isFetching ? (
            <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
          ) : (
            <CardTitle
              id={id ?? 1}
              index={index}
              isEdited={isEdited}
              label="Challenge & Communication Task"
              totalData={totalData ?? 1}
            />
          )}
        </div>
        <div
          className={`flex h-full min-h-350 flex-col gap-24 p-24 text-black-redx ${
            isEditing && isSelected ? '!bg-transparent' : ''
          } ${
            isSelected
              ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
              : 'border-t-1 border-stroke-redx'
          }`}
        >
          {fieldsToDisplay.map(({ name, label }) => {
            return (
              <div key={name} className="flex flex-col gap-10">
                <Label value={label} />
                {isEditing ? (
                  <Controller
                    control={control}
                    name={name as keyof ChallengeSchema}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextAreaInput
                        autoComplete="off"
                        className="overflow-custom mt-1 block w-full resize-none !text-14"
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        error={error?.message}
                        onChange={onChange}
                        placeholder="Input the challenge here"
                        rows={2}
                        value={value}
                      />
                    )}
                  />
                ) : (
                  <Content
                    isFetching={isFetching}
                    value={item?.[name as keyof ChallengeSchema] || ''}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex w-full justify-end px-1 pt-15">
          {id !== -1 && isCanSelect && (
            <SelectButton
              hasOne={isDisabledSelectButton && isSelected}
              index={index}
              isEditing={isEditing}
              isLoading={isUpdatingSelected}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
              section="challenge-and-task"
            />
          )}
        </div>
      </div>
    );
  },
);

export default memo(Card);
