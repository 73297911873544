/* eslint-disable react/prop-types */
import { Icon } from '@iconify/react';
import toast from 'react-hot-toast';

const RequestAccessSuccess: React.FC<{ t: any }> = ({ t }) => {
  return (
    <div
      className="mb-4 inline-flex items-center rounded-lg bg-success-redx px-20 py-10 text-10 text-white"
      style={{
        opacity: t.visible ? 1 : 0,
        transition: 'opacity 100ms ease-in-out',
      }}
    >
      <p className="mr-10">
        Your request successfully sent. Please check your email or notification
        browser regularly.
      </p>
      <Icon
        className="size-15 cursor-pointer"
        icon="mdi:close"
        onClick={() => toast.remove()}
      >
        X
      </Icon>
    </div>
  );
};

export default RequestAccessSuccess;
