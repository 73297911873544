import Wrapper from '@/Pages/AudienceArchetype/Wrapper';

const AudienceArchetypeRoutes = [
  {
    path: '/:project_slug/audience-archetype',
    element: <Wrapper />,
  },
];

export default AudienceArchetypeRoutes;
