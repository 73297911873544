/* eslint-disable consistent-return */
import { useEffect, useMemo } from 'react';

import {
  getProgressFromLocalStorage,
  removeProgressFromLocalStorage,
  saveProgressToLocalStorage,
} from '@/Utils/persist-progress';

interface UseProgressParams<T> {
  activeArchetype: { id: number; is_querying: boolean } | null;
  generatingStates: Record<number, boolean>;
  progressStates: Record<number, number>;
  activeTab: number;
  projectSlug: string;
  section: string;
  refetch: () => void;
  isQuerying?: boolean;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setArchetypes: React.Dispatch<React.SetStateAction<Record<number, T>>>;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
}

export const useProgress = <T extends { id: number; is_querying: boolean }>({
  activeArchetype,
  generatingStates,
  progressStates,
  activeTab,
  projectSlug,
  section,
  refetch,
  setGeneratingStates,
  setArchetypes,
  setProgressStates,
  isQuerying = true,
}: UseProgressParams<T>) => {
  const memoizedActiveArchetype = useMemo(
    () => activeArchetype,
    [activeArchetype?.id, activeArchetype?.is_querying],
  );

  const memoizedGeneratingStates = useMemo(
    () => generatingStates,
    [Object.keys(generatingStates), Object.values(generatingStates)],
  );

  // Memoize progressStates
  const memoizedProgressStates = useMemo(
    () => progressStates,
    [Object.keys(progressStates), Object.values(progressStates)],
  );

  // Update progress periodically if generating
  useEffect(() => {
    if (!memoizedActiveArchetype || !isQuerying) return;

    const activeArchetypeId = memoizedActiveArchetype.id;

    let progressInterval: NodeJS.Timeout | undefined;
    if (
      memoizedGeneratingStates[activeArchetypeId] &&
      memoizedActiveArchetype.is_querying
    ) {
      progressInterval = setInterval(() => {
        setProgressStates((prev) => {
          const currentProgress = prev[activeArchetypeId] || 0;
          if (currentProgress >= 90) {
            clearInterval(progressInterval!);
            return prev;
          }
          return {
            ...prev,
            [activeArchetypeId]: Math.min(currentProgress + 10, 90),
          };
        });
      }, 5000);
    }

    const refetchInterval = setInterval(() => {
      if (!memoizedActiveArchetype.is_querying) {
        clearInterval(refetchInterval);
      } else if (memoizedActiveArchetype.is_querying) {
        refetch();
      }
    }, 1000);

    return () => {
      if (progressInterval) clearInterval(progressInterval);
      clearInterval(refetchInterval);
    };
  }, [memoizedActiveArchetype, memoizedGeneratingStates, isQuerying]);

  // Update generating states and archetypes when fetching completes
  useEffect(() => {
    if (!memoizedActiveArchetype || !isQuerying) return;

    const activeArchetypeId = memoizedActiveArchetype.id;

    if (!memoizedActiveArchetype.is_querying) {
      if (progressStates[activeArchetypeId] === 100) return;

      setGeneratingStates((prev) => ({
        ...prev,
        [activeArchetypeId]: false,
      }));

      setArchetypes((prev) => ({
        ...prev,
        [activeArchetypeId]: {
          ...(prev[activeArchetypeId] || {}),
          ...memoizedActiveArchetype,
        },
      }));

      setProgressStates((prev) => ({
        ...prev,
        [activeArchetypeId]: 100,
      }));
    }
  }, [memoizedActiveArchetype, activeTab, isQuerying]);

  // Save progress to localStorage
  useEffect(() => {
    if (!memoizedActiveArchetype || !isQuerying) return;

    const activeArchetypeId = memoizedActiveArchetype.id;

    if (
      activeArchetypeId &&
      memoizedProgressStates[activeArchetypeId] !== undefined
    ) {
      saveProgressToLocalStorage(
        projectSlug,
        section,
        activeArchetypeId,
        memoizedProgressStates[activeArchetypeId],
      );
    }
  }, [
    memoizedActiveArchetype,
    memoizedProgressStates,
    projectSlug,
    section,
    isQuerying,
  ]);

  // Sync progress from localStorage
  useEffect(() => {
    if (!memoizedActiveArchetype || !isQuerying) return;

    const activeArchetypeId = memoizedActiveArchetype.id;

    if (activeArchetypeId) {
      const savedProgress = getProgressFromLocalStorage(
        projectSlug,
        section,
        activeArchetypeId,
      );

      if (
        savedProgress !== null &&
        memoizedProgressStates[activeArchetypeId] !== savedProgress
      ) {
        setProgressStates((prev) => ({
          ...prev,
          [activeArchetypeId]: savedProgress,
        }));
      }
    }
  }, [
    memoizedActiveArchetype,
    projectSlug,
    section,
    memoizedProgressStates,
    isQuerying,
  ]);

  // Additional sync when activeTab changes
  useEffect(() => {
    if (!memoizedActiveArchetype || !isQuerying) return;

    const activeArchetypeId = memoizedActiveArchetype.id;

    if (activeArchetypeId) {
      const savedProgress = getProgressFromLocalStorage(
        projectSlug,
        section,
        activeArchetypeId,
      );

      if (
        savedProgress !== null &&
        memoizedProgressStates[activeArchetypeId] !== savedProgress
      ) {
        setProgressStates((prev) => ({
          ...prev,
          [activeArchetypeId]: savedProgress,
        }));
      }
    }
  }, [
    isQuerying,
    activeTab,
    memoizedActiveArchetype,
    projectSlug,
    section,
    memoizedProgressStates,
  ]);

  useEffect(() => {
    if (!memoizedActiveArchetype) return;

    const activeArchetypeId = memoizedActiveArchetype.id;

    if (!isQuerying && !memoizedActiveArchetype.is_querying) {
      if (progressStates[activeArchetypeId] === 100) return;

      setGeneratingStates((prev) => ({
        ...prev,
        [activeArchetypeId]: false,
      }));

      setArchetypes((prev) => ({
        ...prev,
        [activeArchetypeId]: {
          ...(prev[activeArchetypeId] || {}),
          ...memoizedActiveArchetype,
        },
      }));

      // Reset progress to 0
      setProgressStates((prev) => ({
        ...prev,
        [activeArchetypeId]: 0,
      }));
    }
  }, [memoizedActiveArchetype, activeTab, isQuerying]);

  useEffect(() => {
    if (activeArchetype && !activeArchetype.is_querying) {
      removeProgressFromLocalStorage(projectSlug, section, activeArchetype.id);
      setProgressStates((prev) => ({
        ...prev,
        [activeArchetype.id]: 0,
      }));
    }
  }, [activeArchetype, section]);
};
