import type { Archetype } from '@/Types/AudienceArchetype';

const getArchetypeDetails = (archetypeContent: Archetype) => {
  return [
    { title: 'Archetype Name', content: archetypeContent.archetype_name },
    { title: 'Demographic', content: archetypeContent.demographics },
    { title: 'Occupation', content: archetypeContent.occupation },
    { title: 'Lifestyle', content: archetypeContent.lifestyle },
    { title: 'Behavior', content: archetypeContent.behavior },
    {
      title: 'Need & Challenges',
      content: archetypeContent.needs_and_challenges,
    },
    {
      title: 'Potential Buying Motives',
      content: archetypeContent.potential_buying_motives,
    },
    { title: 'Current Trends', content: archetypeContent.current_trends },
    {
      title: 'Source of Information',
      content: archetypeContent.source_of_information,
    },
    {
      title: 'Online Search Behavior',
      content: archetypeContent.online_search_behavior,
    },
    {
      title: 'Purchase Frequency',
      content: archetypeContent.purchase_frequency,
    },
    {
      title: 'Preferred Sales Channels',
      content: archetypeContent.preferred_sales_channels,
    },
  ];
};

export { getArchetypeDetails };
