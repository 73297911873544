import { useMutation } from '@tanstack/react-query';

import { fetcher } from '@/Services/axios';
import { goFetcher } from '@/Services/axios-go';
import type { ExternalUser } from '@/Types/user';

interface PostPayload {
  is_released: boolean;
  members: {
    id: number;
    email: string;
    roles: string[] | undefined;
  }[];
  external_members: ExternalUser[];
  project_name: string;
  product_name: string;
  slug: string;
  roles?: string[] | undefined;
  emails?: string[] | undefined;
}

const postProject = async ({
  payload,
  id,
}: {
  payload: PostPayload;
  id: number;
}) => {
  try {
    if (id === -1) {
      await goFetcher.post('/projects/submit', {
        ...payload,
      });
      return;
    }

    // TODO: change into GO
    await fetcher.put(`/projects/${id}`, {
      ...payload,
    });
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useSubmitProject = () => {
  return useMutation<
    any,
    Error,
    {
      payload: PostPayload;
      id: number;
    }
  >({
    mutationFn: postProject,
  });
};

export { useSubmitProject };
