import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ChallengeResponse } from '@/Types/ChallengeAndTask';
import type { ProjectProps } from '@/Types/Projects';

const getLists = async (project: ProjectProps | string) => {
  const projectSlug = typeof project === 'string' ? project : project.slug;
  const url = `/projects/${projectSlug}/challenge-and-task/lists`;
  try {
    const response = await goFetcher.get(url);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useChallengeAndTasks = (
  project: ProjectProps | string,
): UseQueryResult<ChallengeResponse, Error> => {
  const projectSlug = typeof project === 'string' ? project : project.slug;
  return useQuery<ChallengeResponse, Error>({
    queryKey: ['challenges', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists(project),
    refetchOnWindowFocus: false,
  });
};

export { useChallengeAndTasks };
