import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ProductResearchResponse } from '@/Types/ProductResearch';

const getLists = async (projectSlug: string, activeSection: string) => {
  // Mapping section to API endpoints
  const endpoints: Record<string, string> = {
    product_description: `/projects/${projectSlug}/product-research/product-description`,
    main_features: `/projects/${projectSlug}/product-research/main-feature`,
    marcomm_highlights: `/projects/${projectSlug}/product-research/marcomm-highlight`,
  };

  try {
    const url = endpoints[activeSection];

    if (!url) {
      throw new Error('Invalid section provided');
    }

    const response = await goFetcher.get(url);
    return response.data as ProductResearchResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useProductResearch = (
  projectSlug: string,
  activeSection: string,
): UseQueryResult<ProductResearchResponse, Error> => {
  return useQuery<ProductResearchResponse, Error>({
    queryKey: ['productResearch', activeSection, projectSlug],
    queryFn: () => getLists(projectSlug, activeSection),
    refetchOnWindowFocus: false,
  });
};

export { useProductResearch };
