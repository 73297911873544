import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { Idea } from '@/Types/SearchabilityContent';

interface UpdateSearchabilityProps {
  formStatus: string;
  projectSlug: string;
  stepName: string;
  framework: string;
  value: string[] | Omit<Idea, 'value'>;
  archetypeId: number;
  recommendationNumber: number;
}

const updateSearchability = async ({
  formStatus,
  projectSlug,
  stepName,
  framework,
  value,
  archetypeId,
  recommendationNumber,
}: UpdateSearchabilityProps) => {
  try {
    const endpoint = formStatus === 'add' ? 'submit' : 'update';
    const method = formStatus === 'add' ? 'post' : 'put';

    const payload =
      stepName === 'content_ideas' ? { content_idea: value } : { value };

    const { data } = await goFetcher[method](
      `/projects/${projectSlug}/searchability/${endpoint}`,
      {
        ...payload,
        framework,
        archetype_id: archetypeId,
        recommendation_number: recommendationNumber,
        step_name: stepName,
      },
    );
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSearchability = () => {
  return useMutation<any, Error, UpdateSearchabilityProps>({
    mutationFn: updateSearchability,
  });
};

export { useUpdateSearchability };
