import ConfirmPassword from '@/Pages/Auth/ConfirmPassword';
import ForgotPassword from '@/Pages/Auth/ForgotPassword';
import Login from '@/Pages/Auth/Login';
import Logout from '@/Pages/Auth/Logout';
import Register from '@/Pages/Auth/Register';
import ResetPassword from '@/Pages/Auth/ResetPassword';
import VerifyEmail from '@/Pages/Auth/VerifyEmail';

const AuthRoutes = [
  { path: '/login', element: <Login canResetPassword /> },
  { path: '/confirm-password', element: <ConfirmPassword /> },
  { path: '/reset-password/:token', element: <ResetPassword /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/register', element: <Register /> },
  { path: '/verify-email', element: <VerifyEmail /> },
  { path: '/logout', element: <Logout /> },
];

export default AuthRoutes;
