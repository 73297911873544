import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import PrimaryButton from '@/Components/PrimaryButton';
import GuestLayout from '@/Layouts/GuestLayout';
import { fetcher } from '@/Services/axios';
import { useUrlQuery } from '@/Utils/router';

const VerifyEmail = () => {
  const status = useUrlQuery().get('status');

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = () => {
    // Use Inertia's post method for form submission
    try {
      fetcher.post('/email/verification-notification', {});
    } catch (error) {
      toast.error('Error sending verification email!');
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Email Verification</title>
      </Helmet>

      <div className="text-sm mb-4 text-gray-600">
        Thanks for signing up! Before getting started, could you verify your
        email address by clicking on the link we just emailed to you? If you
        didn&apos;t receive the email, we will gladly send you another.
      </div>

      {status === 'verification-link-sent' && (
        <div className="text-sm mb-4 font-medium text-green-600">
          A new verification link has been sent to the email address you
          provided during registration.
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4 flex items-center justify-between">
          <PrimaryButton disabled={isSubmitting}>
            Resend Verification Email
          </PrimaryButton>

          <Link
            className="text-sm rounded-md text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            to="/logout"
          >
            Log Out
          </Link>
        </div>
      </form>
    </GuestLayout>
  );
};

export default VerifyEmail;
