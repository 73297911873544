// Import CSS files and dependencies
import './bootstrap';
import './Css/app.css';
import './Css/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ProjectProvider } from './Context/ProjectContext';
import routes from './Routes';

// Initialize QueryClient
const queryClient = new QueryClient();

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

// Create the root element and render the App component
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ProjectProvider>
        <Router>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                element={route.element as React.ReactNode}
                path={route.path}
              />
            ))}
          </Routes>
        </Router>
      </ProjectProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
