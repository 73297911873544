import { z } from 'zod';

export const OpportunitySchema = z.object({
  brand_persona: z.string().min(4, 'Brand persona is required'),
  digital_key_touch_points: z
    .string()
    .min(4, 'Digital key touch points is required'),
  features_and_benefits: z.string().min(4, 'Features and benefits is required'),
  creative_communication_angle: z
    .string()
    .min(4, 'Creative communication angle is required'),
});

export type OpportunitySchemaType = z.infer<typeof OpportunitySchema>;
