import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import GuestLayout from '@/Layouts/GuestLayout';
import { fetcher } from '@/Services/axios';
import { useUrlQuery } from '@/Utils/router';

interface FormData {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const ResetPassword = () => {
  const query = useUrlQuery();
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      token,
      email: query.get('email') || '',
      password: '',
      password_confirmation: '',
    },
  });

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await fetcher.post('/reset-password', data);
      toast.success('Password reset successful!');
    } catch (error) {
      toast.error('Error resetting password!');
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputLabel htmlFor="email" value="Email" />

          <TextInput
            autoComplete="username"
            className="mt-1 block w-full"
            id="email"
            type="email"
            {...register('email', { required: 'Email is required' })}
          />

          <InputError className="mt-2" message={errors.email?.message} />
        </div>

        <div className="mt-4">
          <InputLabel htmlFor="password" value="Password" />

          <TextInput
            autoComplete="new-password"
            className="mt-1 block w-full"
            id="password"
            isFocused
            type="password"
            {...register('password', { required: 'Password is required' })}
          />

          <InputError className="mt-2" message={errors.password?.message} />
        </div>

        <div className="mt-4">
          <InputLabel
            htmlFor="password_confirmation"
            value="Confirm Password"
          />

          <TextInput
            autoComplete="new-password"
            className="mt-1 block w-full"
            type="password"
            {...register('password_confirmation', {
              required: 'Password confirmation is required',
            })}
          />

          <InputError
            className="mt-2"
            message={errors.password_confirmation?.message}
          />
        </div>

        <div className="mt-4 flex items-center justify-end">
          <PrimaryButton className="ms-4" disabled={isSubmitting}>
            Reset Password
          </PrimaryButton>
        </div>
      </form>
    </GuestLayout>
  );
};

export default ResetPassword;
