import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { IdeaAlignmentResponse } from '@/Types/IdeaAlignment';

const fetch = async (projectSlug: string): Promise<IdeaAlignmentResponse> => {
  try {
    const response = await goFetcher.get(
      `/projects/${projectSlug}/idea-alignment/lists`, // todo: change with correct url
    );
    return response.data as IdeaAlignmentResponse;
  } catch (error: any) {
    throw new Error(
      error.response.data.message ||
        'Failed to fetch idea personalization data',
    );
  }
};

const useIdeaAlignments = (projectSlug: string) => {
  return useQuery<IdeaAlignmentResponse, Error>({
    queryKey: ['ideaAlignment', projectSlug],
    queryFn: () => fetch(projectSlug),
    refetchOnWindowFocus: false, // Prevent refetch on window focus (e.g., tab switch)
  });
};

export { useIdeaAlignments };
