import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { CheckObjectiveResponse } from '@/Types/AudienceArchetype';

const fetchCheckObjective = async (
  projectSlug: string,
): Promise<CheckObjectiveResponse> => {
  const { data } = await goFetcher.get(
    `/projects/${projectSlug}/archetype/check-objective`,
  );
  return data as CheckObjectiveResponse;
};

const useCheckObjective = (
  projectSlug: string,
): UseQueryResult<CheckObjectiveResponse, Error> => {
  return useQuery<CheckObjectiveResponse, Error>({
    queryKey: ['checkObjective', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => fetchCheckObjective(projectSlug),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
  });
};
export { useCheckObjective };
