import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';

const updateSelectedKeyTouchPoint = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/key-touch-point/update-selected/${id}`,
      {},
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelectedKeyTouchPoint = () => {
  return useMutation<any, Error, { id: number; projectSlug: string }>({
    mutationFn: updateSelectedKeyTouchPoint,
  });
};

export { useUpdateSelectedKeyTouchPoint };
