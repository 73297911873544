import _ from 'lodash';

import RoleBadge from '@/Components/RoleBadge';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

const useRolesBadge = (
  project: ProjectProps,
  user: UserResponse | undefined,
) => {
  const roles: string[] = [];

  if (!_.isEmpty(project) && !_.isEmpty(user)) {
    if (project.owner.id === user.id) {
      roles.push('Owner');
    } else {
      project.roles?.forEach((role) => {
        if (role?.pivot?.user_id === user.id) {
          roles.push(role.name);
        }
      });
    }
  }

  const Badge: React.FC = () => <RoleBadge role={roles.join(', ')} />;

  return {
    roles,
    Badge,
  };
};

export default useRolesBadge;
