import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import GuestLayout from '@/Layouts/GuestLayout';
import { fetcher } from '@/Services/axios';

interface FormData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const Register = () => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    // Use Inertia's post method for form submission
    try {
      fetcher.post('/register', data);
    } catch (error) {
      toast.error('Error register!');
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Register</title>
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputLabel htmlFor="name" value="Name" />

          <TextInput
            autoComplete="name"
            className="mt-1 block w-full"
            id="name"
            isFocused
            {...register('name', { required: 'Name is required' })}
          />

          <InputError className="mt-2" message={errors.name?.message} />
        </div>

        <div className="mt-4">
          <InputLabel htmlFor="email" value="Email" />

          <TextInput
            autoComplete="username"
            className="mt-1 block w-full"
            id="email"
            type="email"
            {...register('email', { required: 'Email is required' })}
          />

          <InputError className="mt-2" message={errors.email?.message} />
        </div>

        <div className="mt-4">
          <InputLabel htmlFor="password" value="Password" />

          <TextInput
            autoComplete="new-password"
            className="mt-1 block w-full"
            id="password"
            type="password"
            {...register('password', { required: 'Password is required' })}
          />

          <InputError className="mt-2" message={errors.password?.message} />
        </div>

        <div className="mt-4">
          <InputLabel
            htmlFor="password_confirmation"
            value="Confirm Password"
          />

          <TextInput
            autoComplete="new-password"
            className="mt-1 block w-full"
            id="password_confirmation"
            type="password"
            {...register('password_confirmation', {
              required: 'Password confirmation is required',
            })}
          />

          <InputError
            className="mt-2"
            message={errors.password_confirmation?.message}
          />
        </div>

        <div className="mt-4 flex items-center justify-end">
          <Link
            className="text-sm rounded-md text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            to="/login"
          >
            Already registered?
          </Link>

          <PrimaryButton className="ms-4" disabled={isSubmitting}>
            Register
          </PrimaryButton>
        </div>
      </form>
    </GuestLayout>
  );
};

export default Register;
