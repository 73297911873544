import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ProjectProps } from '@/Types/Projects';
import type { SelectedContentListResponse } from '@/Types/SelectedContentIdeas';

interface UseSelectedContentProps {
  project: ProjectProps;
}

const getLists = async ({ project }: UseSelectedContentProps) => {
  const projectSlug = project.slug;
  const url = `/projects/${projectSlug}/selected-content/lists`;

  try {
    const response = await goFetcher.get(url);

    return response.data as SelectedContentListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useSelectedContent = ({
  project,
}: UseSelectedContentProps): UseQueryResult<
  SelectedContentListResponse,
  Error
> => {
  return useQuery<SelectedContentListResponse, Error>({
    queryKey: [
      'selected-content-lists',
      project.slug,
      project.submissions[0].selected_framework,
    ], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists({ project }),
    refetchOnWindowFocus: false,
  });
};

export { useSelectedContent };
