import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { Schema } from '@/Types/OnePage/schema';

const updateKeyInsight = async ({
  payload,
  projectSlug,
  historyId,
}: {
  payload: Schema;
  projectSlug: string;
  historyId: string;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/one-page-strategy/update/${historyId}`,
      {
        content: {
          selected_audience_archetypes: payload.selected_audience_archetypes,
          campaign_strategic_statement: payload.campaign_strategic_statement,
          challenge: payload.challenge,
          communication_task: payload.communication_task,
        },
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateOnePage = () => {
  return useMutation<
    any,
    Error,
    {
      payload: Schema;
      projectSlug: string;
      historyId: string;
    }
  >({
    mutationFn: updateKeyInsight,
  });
};

export { useUpdateOnePage };
