import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ApprovedUserList } from '@/Types/user';

const fetchApprovedUsers = async (): Promise<ApprovedUserList> => {
  try {
    const response = await goFetcher.get('/projects/approved-user/lists');
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || 'Failed to fetch approved users',
    );
  }
};

const useApprovedUsers = () => {
  return useQuery<ApprovedUserList, Error>({
    queryKey: ['approvedUsers'],
    queryFn: fetchApprovedUsers,
    refetchOnMount: false,
  });
};

export { useApprovedUsers };
