import type { ReactNode } from 'react';
import { memo } from 'react';

interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const Container = ({ children, className = '' }: ContainerProps) => {
  return <div className={`flex flex-col gap-10 ${className}`}>{children}</div>;
};

export default memo(Container);
