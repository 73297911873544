import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { KeyInsightResponse } from '@/Types/KeyInsight';
import type { ProjectProps } from '@/Types/Projects';

const getLists = async (project: ProjectProps | string) => {
  const projectSlug = typeof project === 'string' ? project : project.slug;
  const url = `/projects/${projectSlug}/key-insight/lists`;

  try {
    const response = await goFetcher.get(url);

    return response.data as KeyInsightResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useKeyInsights = (
  project: ProjectProps | string,
): UseQueryResult<KeyInsightResponse, Error> => {
  const projectSlug = typeof project === 'string' ? project : project.slug;
  return useQuery<KeyInsightResponse, Error>({
    queryKey: ['keyInsights', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists(project),
    refetchOnWindowFocus: false,
  });
};

export { useKeyInsights };
