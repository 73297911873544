import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { UpdateFrameworkResponse } from '@/Types/Submission';
import type { TargetAudienceSchema } from '@/Types/Submission/schema';

const updateTargetAudienceSubmission = async ({
  projectSlug,
  payload,
}: {
  projectSlug: string;
  payload: TargetAudienceSchema;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/submission/target-audience`,
      {
        age_market_start: payload.age_market_start.toString(),
        age_market_end: payload.age_market_end.toString(),
        gender: payload.gender,
      },
    );

    return data as UpdateFrameworkResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateTargetAudienceSubmission = () => {
  return useMutation<
    UpdateFrameworkResponse,
    Error,
    { projectSlug: string; payload: TargetAudienceSchema }
  >({
    mutationFn: updateTargetAudienceSubmission,
  });
};

export { useUpdateTargetAudienceSubmission };
