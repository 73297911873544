import Wrapper from '@/Pages/KeyTouchPoint/Wrapper';

const KeyTouchPointRoutes = [
  {
    path: '/:project_slug/key-touch-point',
    element: <Wrapper />,
  },
];

export default KeyTouchPointRoutes;
