import { Icon } from '@iconify/react';
import React, { useContext, useMemo, useState } from 'react';

import { ProjectContext } from '@/Context/ProjectContext';
import FormModal from '@/Pages/Projects/Components/FormModal';
import { fetcher } from '@/Services/axios';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

interface ProjectMembersProps {
  initialUsers: UserResponse[];
  project: ProjectProps;
  onRefetchLists: () => void;
  setIsOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectMembers = ({
  initialUsers,
  project,
  onRefetchLists,
  setIsOpenModal,
}: ProjectMembersProps) => {
  const [state] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectProps>();
  const closeModal = () => {
    setIsOpen(false);
    setIsOpenModal?.(false);
  };
  const appUrl = new URL(window.location.href);
  const baseUrl = appUrl.origin;

  const handleClickAddMember = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const { data } = await fetcher.get(`/${project!.slug}`);
    setSelectedProject(data);
    setIsOpen(true);
    setIsOpenModal?.(true);
  };

  return (
    <>
      <div className="flex -space-x-8 rtl:space-x-reverse">
        {project?.owner && (
          <img
            alt=""
            className="size-32 rounded-full border-2 border-white"
            src={project.owner.avatar_url}
            title={`${project.owner.name} (${project?.owner.email})`}
          />
        )}
        {project?.users?.map((u, i) => (
          <img
            key={i}
            alt=""
            className="size-32 rounded-full border-2 border-white"
            src={u.avatar_url}
            title={`${u.name ?? 'Noname'} (${u.email})`}
          />
        ))}
        {project?.owner.id === user.id && (
          <button
            className="flex size-32 items-center justify-center rounded-full border-2 border-white bg-soft-purple-redx text-blue-redx"
            onClick={handleClickAddMember}
          >
            <Icon className="size-24" icon="uil:plus" />
          </button>
        )}
      </div>
      <FormModal
        appUrl={baseUrl}
        id={project.id}
        initialUsers={initialUsers}
        isOpen={isOpen}
        onClose={closeModal}
        onRefetchLists={onRefetchLists}
        project={selectedProject}
      />
    </>
  );
};

export default ProjectMembers;
