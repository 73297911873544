import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { OpportunityUpdateSelectedResponse } from '@/Types/OpportunityAmongCompetition';
import type { OpportunitySchemaType } from '@/Types/OpportunityAmongCompetition/schema';

const updateOpportunities = async ({
  payload,
  projectSlug,
  id,
  archetypeId,
}: {
  payload: OpportunitySchemaType;
  projectSlug: string;
  id: number;
  archetypeId: number;
}) => {
  try {
    if (id === -1) {
      const { data } = await goFetcher.post(
        `/projects/${projectSlug}/opportunity/submit`,
        {
          content: payload,
          archetypeId,
        },
      );
      return data as OpportunityUpdateSelectedResponse;
    }

    const url = `/projects/${projectSlug}/opportunity/update/${id}`;
    const { data } = await goFetcher.put(url, {
      content: payload,
      archetypeId,
    });
    return data as OpportunityUpdateSelectedResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateOpportunities = () => {
  return useMutation<
    OpportunityUpdateSelectedResponse,
    Error,
    {
      payload: OpportunitySchemaType;
      projectSlug: string;
      id: number;
      archetypeId: number;
    }
  >({
    mutationFn: updateOpportunities,
  });
};

export { useUpdateOpportunities };
