import Wrapper from '@/Pages/Projects/Wrapper';

const ProjectRoutes = [
  {
    path: '/projects',
    element: <Wrapper />,
  },
];

export default ProjectRoutes;
