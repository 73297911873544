import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { GetDifferentResponse } from '@/Types/AudienceArchetype';

const getDifferent = async (
  projectSlug: string,
): Promise<GetDifferentResponse> => {
  const url = `/projects/${projectSlug}/archetype/get-different`;
  try {
    const response = await goFetcher.get(url);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useDifferentArchetypes = (
  projectSlug: string,
): UseQueryResult<GetDifferentResponse, Error> => {
  return useQuery<GetDifferentResponse, Error>({
    queryKey: ['different_archetypes', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getDifferent(projectSlug),
    refetchInterval: 5000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
};

export { useDifferentArchetypes };
