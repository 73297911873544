import { useEffect } from 'react';

import type {
  ArchetypesState,
  IdeaExpansionListResponse,
} from '@/Types/IdeaExpansion';
import type { SourcesType } from '@/Types/Projects';
import { calculateTotals } from '@/Utils/calculate-totals';
import {
  initializeGeneratingStates,
  initializeProgressStates,
  mapArchetypes,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataHandlerProps {
  data: IdeaExpansionListResponse | undefined;
  funnel: string;
  sectionKey: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  archetypes: ArchetypesState;
  selectedFramework: string;
  setArchetypes: React.Dispatch<React.SetStateAction<ArchetypesState>>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setSources: React.Dispatch<React.SetStateAction<SourcesType[]>>;
  setSelectedFunnel: React.Dispatch<React.SetStateAction<string>>;
  setSelectedRecommendationNumber: React.Dispatch<React.SetStateAction<number>>;
  setTotalRegenerate: React.Dispatch<React.SetStateAction<number>>;
  setTotals: React.Dispatch<
    React.SetStateAction<{
      totalArchetype: number;
      totalArchetypeSelected: number;
      totalSelectedIdeaExpansion: number;
    }>
  >;
}

export const useSectionDataHandler = ({
  data,
  archetypes,
  sectionKey,
  projectSlug,
  progressStates,
  setTotalRegenerate,
  setGeneratingStates,
  setProgressStates,
  setSources,
  setArchetypes,
  setTotals,
  setActiveTab,
  setMessage,
}: UseSectionDataHandlerProps) => {
  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      const mappedArchetypes = mapArchetypes(
        data.data, // Inferred type
        archetypes,
      );

      const newGeneratingStates = initializeGeneratingStates(data.data);
      setGeneratingStates(newGeneratingStates);

      const savedProgress = getSectionProgressFromLocalStorage(
        projectSlug,
        sectionKey,
      );

      const updatedProgressStates = initializeProgressStates(
        data.data,
        savedProgress,
        progressStates,
      );

      setProgressStates((prev) => ({
        ...prev,
        ...updatedProgressStates,
      }));
      setSources(data.sources);
      setTotalRegenerate(data.total_regenerate);
      const result = calculateTotals(
        mappedArchetypes,
        'total_idea_expansions',
        'total_selected_idea_expansions',
      );

      const firstNonRegenerateTabIndex = data.data.findIndex(
        (item) => item.is_regenerate === false,
      );

      if (
        firstNonRegenerateTabIndex !== -1 &&
        data.data.some((item) => item.is_regenerate)
      ) {
        setActiveTab(firstNonRegenerateTabIndex);
      }

      setTotals({
        totalArchetype: data.total_archetype,
        totalArchetypeSelected: data.total_selected_per_archetype,
        totalSelectedIdeaExpansion: result.allSelected,
      });

      const updatedArchetypes = { ...mappedArchetypes }; // Salin mappedArchetypes

      Object.keys(updatedArchetypes).forEach((archetypeId) => {
        updatedArchetypes[Number(archetypeId)].framework = data.framework;
      });

      setMessage(data.message);
      setArchetypes(updatedArchetypes);
    }
  }, [data]);
};
