import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { clearAllProgressFromLocalStorage } from '@/Utils/persist-progress';

const Logout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    // Remove the access_token cookie
    Cookies.remove('access_token');
    // Invalidate all queries
    queryClient.removeQueries();
    // Redirect the user to the login page or another appropriate page
    navigate('/login');
  };

  useEffect(() => {
    clearAllProgressFromLocalStorage();
    handleLogout();
  }, []);

  return null;
};

export default Logout;
