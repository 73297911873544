import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { OnePageListResponse } from '@/Types/OnePage/one-page-strategy';

const getLists = async (projectSlug: string) => {
  const url = `/projects/${projectSlug}/one-page-strategy/lists`;
  try {
    const response = await goFetcher.get(url);

    return response.data as OnePageListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useOnePageStrategy = (
  projectSlug: string,
): UseQueryResult<OnePageListResponse, Error> => {
  return useQuery<OnePageListResponse, Error>({
    queryKey: ['OnePageStrategy', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists(projectSlug),
    refetchOnWindowFocus: false,
  });
};

export { useOnePageStrategy };
