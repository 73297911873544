import Wrapper from '@/Pages/IdeaAlignment/Wrapper';

const IdeaAlignmentRoutes = [
  {
    path: '/:project_slug/idea-alignment',
    element: <Wrapper />,
  },
];

export default IdeaAlignmentRoutes;
