import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { SubmissionResponse } from '@/Types/Submission';

const fetchSubmission = async (
  projectSlug: string,
): Promise<SubmissionResponse> => {
  try {
    const response = await goFetcher.get(
      `/projects/${projectSlug}/submission/data`,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || 'Failed to fetch submisison data',
    );
  }
};

const useSubmission = (projectSlug: string) => {
  return useQuery<SubmissionResponse, Error>({
    queryKey: ['submission', projectSlug],
    queryFn: () => fetchSubmission(projectSlug),
    refetchOnMount: true,
  });
};

export { useSubmission };
