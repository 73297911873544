import Redirect from '@/Pages/Redirect';
import Wrapper from '@/Pages/Submission/Wrapper';

const SubmissionRoutes = [
  {
    path: '/:project_slug',
    element: <Redirect />,
  },
  {
    path: '/:project_slug/submission',
    element: <Wrapper />,
  },
];

export default SubmissionRoutes;
