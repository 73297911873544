import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { UpdateFrameworkResponse } from '@/Types/Submission';
import type { ProductInformationSchema } from '@/Types/Submission/schema';

const updateProductSubmission = async ({
  projectSlug,
  payload,
}: {
  projectSlug: string;
  payload: ProductInformationSchema;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/submission/product-information`,
      {
        brand_name: payload.brand_name,
        product_links: payload.product_links,
        product_name: payload.product_name,
      },
    );

    return data as UpdateFrameworkResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateProductSubmission = () => {
  return useMutation<
    UpdateFrameworkResponse,
    Error,
    { projectSlug: string; payload: ProductInformationSchema }
  >({
    mutationFn: updateProductSubmission,
  });
};

export { useUpdateProductSubmission };
