import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ArchetypeListResponse } from '@/Types/AudienceArchetype';

const getLists = async (projectSlug: string) => {
  const url = `/projects/${projectSlug}/archetype/lists`;
  try {
    const { data } = await goFetcher.get(url);

    return data as ArchetypeListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useArchetypes = (
  projectSlug: string,
): UseQueryResult<ArchetypeListResponse, Error> => {
  return useQuery<ArchetypeListResponse, Error>({
    queryKey: ['archetypes', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists(projectSlug),
    refetchOnWindowFocus: false, // Keep data fresh indefinitely (no refetching)
  });
};

export { useArchetypes };
