import { useInfiniteQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ProjectListsResponse } from '@/Types/Projects';

const fetchProjects = async ({
  pageParam = 0,
  search = '',
  sort = '',
}: {
  pageParam?: number;
  search?: string;
  sort?: string;
}): Promise<ProjectListsResponse> => {
  try {
    const params = new URLSearchParams({
      next: String(pageParam),
    });
    if (search) params.append('search', search);
    if (sort) params.append('sort', sort);

    const response = await goFetcher.get(
      `/projects/lists?limit=5&${params.toString()}`,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || 'Failed to fetch projects data',
    );
  }
};

const useProjects = ({
  search = '',
  sort = '',
  date,
}: {
  search?: string;
  sort?: string;
  date: number;
}) => {
  return useInfiniteQuery({
    queryKey: ['projects', search, sort, date],
    queryFn: ({ pageParam = 0 }) => fetchProjects({ pageParam, search, sort }),
    getNextPageParam: (lastPage) => lastPage.next ?? false,
    initialPageParam: 0,
  });
};

export { useProjects };
