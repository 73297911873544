import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ValidateTeamResponse } from '@/Types/Projects';

const validateTeam = async ({
  payload,
}: {
  payload: string[];
}): Promise<ValidateTeamResponse> => {
  try {
    // TODO: change into GO
    const { data } = await goFetcher.post('/projects/validate-teammates', {
      emails: payload,
    });
    return data as ValidateTeamResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useValidateTeam = () => {
  return useMutation<
    ValidateTeamResponse,
    Error,
    {
      payload: string[];
    }
  >({
    mutationFn: validateTeam,
  });
};

export { useValidateTeam };
