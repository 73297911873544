import { useState } from 'react';

const useCopyLink = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copyLink = (link: string) => {
    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return { isCopied, copyLink };
};

export { useCopyLink };
