import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ProjectProps } from '@/Types/Projects';

const fetchProjects = async (projectSlug: string): Promise<ProjectProps> => {
  try {
    const { data } = await goFetcher.get(`/projects/${projectSlug}/detail`);
    return data as ProjectProps;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || 'Failed to fetch projects data',
    );
  }
};

const useProjectDetail = (projectSlug: string) => {
  return useQuery<ProjectProps, Error>({
    queryKey: ['projectDetail', projectSlug],
    queryFn: () => fetchProjects(projectSlug),
    refetchOnMount: false,
  });
};

export { useProjectDetail };
