import ChecklistSection from '@/Components/ChecklistSectionProps';
import ProgressLine from '@/Components/ProgressLine';
import type { Step } from '@/Types/step';

interface SkeletonProps {
  steps: Step[];
}

const Skeleton = ({ steps }: SkeletonProps) => {
  return (
    <div className="pt-5">
      <ProgressLine isFetching steps={steps} />
      <div className="mt-37 flex w-full  animate-pulse rounded-full border-1 border-soft-purple-redx bg-soft-purple-redx px-24 py-4" />
      <div className="flex flex-col gap-37">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="mb-12 flex flex-col">
            <div className="inline-flex gap-12 border-b-1 border-stroke-redx pb-12">
              <div className="mt-37 flex w-full  animate-pulse rounded-full border-1 border-soft-purple-redx bg-soft-purple-redx px-24 py-4" />
            </div>
            <div className="mt-12 flex flex-col gap-37">
              {Array.from({ length: 3 }).map((_, index2) => (
                <ChecklistSection
                  key={index2}
                  isEditor={false}
                  isFetching
                  title="dummy"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Skeleton };
