import React from 'react';

interface SuccessSelectedNotificationProps {
  t: {
    id: string;
    visible: boolean;
  };
}

const SuccessSelectedNotification = React.memo(
  ({ t }: SuccessSelectedNotificationProps) => {
    return (
      <div
        className="inline-flex items-center justify-between rounded-lg bg-success-light-redx px-24 py-12 text-16 leading-32 text-white"
        style={{
          opacity: t.visible ? 1 : 0,
          transition: 'opacity 100ms ease-in-out',
        }}
      >
        <div className="inline-flex items-center gap-8">
          Idea successfully unselected
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.t.id === nextProps.t.id,
);

export default SuccessSelectedNotification;
