export const adjustOrderedList = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const ols = doc.querySelectorAll('ol');

  let globalIndex = 0; // Initialize a global index counter for top-level lists

  ols.forEach((ol) => {
    const parentOl = ol.closest('li')?.parentElement?.closest('ol');

    if (parentOl) {
      // If this <ol> is nested inside another <ol>, reset numbering
      const startAttr = ol.getAttribute('start');
      ol.setAttribute('start', startAttr || '1');
    } else {
      // This is a top-level <ol>
      const startAttr = ol.getAttribute('start');
      const startValue = startAttr ? parseInt(startAttr, 10) : globalIndex + 1;
      ol.setAttribute('start', startValue.toString());

      // Update the global index for top-level lists
      globalIndex = startValue + ol.querySelectorAll('li').length - 1;
    }
  });

  return doc.body.innerHTML;
};
