import { useCallback, useEffect } from 'react';

import type { GetDifferentResponse } from '@/Types/AudienceArchetype';
import type { HistoryStatus } from '@/Types/history';
import type {
  ArchetypeKeyTouchPoint,
  ArchetypesState,
  KeyTouchPointListResponse,
  PreviousData,
} from '@/Types/KeyTouchPoint';
import type { SourcesType } from '@/Types/Projects';
import { calculateTotals } from '@/Utils/calculate-totals';
import {
  initializeGeneratingStates,
  initializeProgressStates,
  mapArchetypes,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataHandlerProps {
  diffArch: GetDifferentResponse | undefined;
  isIdeaExpansion: HistoryStatus;
  data: KeyTouchPointListResponse | undefined;
  sectionKey: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  archetypes: ArchetypesState;
  setArchetypes: React.Dispatch<React.SetStateAction<ArchetypesState>>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setSources: React.Dispatch<React.SetStateAction<SourcesType[]>>;
  setTotalRegenerate: React.Dispatch<React.SetStateAction<number>>;
  setTotals: React.Dispatch<
    React.SetStateAction<{
      totalArchetype: number;
      totalArchetypeSelected: number;
      totalSelectedTouchPoint: number;
    }>
  >;
}

export const useSectionDataHandler = ({
  diffArch,
  data,
  isIdeaExpansion,
  archetypes,
  sectionKey,
  projectSlug,
  progressStates,
  setTotalRegenerate,
  setGeneratingStates,
  setProgressStates,
  setSources,
  setArchetypes,
  setTotals,
  setActiveTab,
  setMessage,
}: UseSectionDataHandlerProps) => {
  const checkChanges = useCallback(
    ({
      data,
      previousResult,
    }: {
      data: ArchetypeKeyTouchPoint[];
      previousResult: PreviousData[] | null;
    }) => {
      if (!Array.isArray(data) || !Array.isArray(previousResult)) {
        return [];
      }

      return data.map((current) => {
        const previous = previousResult.find(
          (prev) => prev.archetype_id === current.id,
        );

        if (
          !previous ||
          isIdeaExpansion.isRegenerated ||
          diffArch?.is_different_archetype.idea_expansions
        ) {
          // Jika tidak ada data sebelumnya, anggap tidak ada perubahan
          return { archetype_id: current.id, isDifferent: false };
        }

        // Extract selected recommendation numbers by funnel from current data
        const currentSelectedByFunnel = current.key_touch_points.reduce<
          Record<string, number[]>
        >((acc, funnel) => {
          acc[funnel.funnel] = funnel.content
            .filter((item) => item.is_selected)
            .map((item) => item.recommendation_number);
          return acc;
        }, {});

        // Extract selected recommendation numbers by funnel from previous data
        const previousSelectedByFunnel = Object.entries(
          previous.selected_key_touchpoint || {},
        ).reduce<Record<string, number[]>>((acc, [funnelKey, funnelItems]) => {
          if (Array.isArray(funnelItems)) {
            acc[funnelKey] = funnelItems.map(
              (item: any) => item.recommendation_number,
            );
          }
          return acc;
        }, {});

        // Compare each funnel to determine if there are differences
        const isDifferent = Object.keys(currentSelectedByFunnel).some(
          (funnelKey) => {
            const currentNumbers = currentSelectedByFunnel[funnelKey] || [];
            const previousNumbers = previousSelectedByFunnel[funnelKey] || [];
            return (
              currentNumbers.length !== previousNumbers.length ||
              !currentNumbers.every((num) => previousNumbers.includes(num))
            );
          },
        );

        return {
          archetype_id: current.id,
          isDifferent,
        };
      });
    },
    [
      isIdeaExpansion.isRegenerated,
      diffArch?.is_different_archetype.idea_expansions,
    ],
  );

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      const mappedArchetypes = mapArchetypes(
        data.data, // Inferred type
        archetypes,
      );

      const newGeneratingStates = initializeGeneratingStates(data.data);
      setGeneratingStates(newGeneratingStates);

      const savedProgress = getSectionProgressFromLocalStorage(
        projectSlug,
        sectionKey,
      );

      const updatedProgressStates = initializeProgressStates(
        data.data,
        savedProgress,
        progressStates,
      );

      setProgressStates((prev) => ({
        ...prev,
        ...updatedProgressStates,
      }));
      setSources(data.sources);
      setTotalRegenerate(data.total_regenerate);
      const result = calculateTotals(
        mappedArchetypes,
        'total_key_touch_points',
        'total_selected_key_touch_points',
      );

      const firstNonRegenerateTabIndex = data.data.findIndex(
        (item) => item.is_regenerate === false,
      );

      if (
        firstNonRegenerateTabIndex !== -1 &&
        data.data.some((item) => item.is_regenerate)
      ) {
        setActiveTab(firstNonRegenerateTabIndex);
      }

      const changes = checkChanges({
        data: data.data,
        previousResult: data.previous_selected_archetype,
      });

      const updatedArchetypes = { ...mappedArchetypes }; // Salin mappedArchetypes

      Object.keys(updatedArchetypes).forEach((archetypeId) => {
        const change = changes.find(
          (c) => c.archetype_id === Number(archetypeId),
        );

        updatedArchetypes[Number(archetypeId)].is_diff_key_touch_point =
          change?.isDifferent || false;

        updatedArchetypes[Number(archetypeId)].framework = data.framework;
      });

      setMessage(data.message);
      setArchetypes(updatedArchetypes);
      setTotals({
        totalArchetype: data.total_archetype,
        totalArchetypeSelected: data.total_selected_per_archetype,
        totalSelectedTouchPoint: result.allSelected,
      });
    }
  }, [data]);
};
