import Wrapper from '@/Pages/IdeaPersonalization/Wrapper';

const IdeaPersonalizationRoutes = [
  {
    path: '/:project_slug/idea-personalization',
    element: <Wrapper />,
  },
];

export default IdeaPersonalizationRoutes;
