import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useContext, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ProjectContext } from '@/Context/ProjectContext';

interface SuccessSelectedNotificationProps {
  t: {
    id: string;
    visible: boolean;
  };
}

const SuccessSelectedNotification = React.memo(
  ({ t }: SuccessSelectedNotificationProps) => {
    const [state] = useContext(ProjectContext);
    const project = useMemo(() => state.project, [state.project]);
    const navigate = useNavigate();

    return (
      <div
        className="inline-flex items-center justify-between rounded-lg bg-success-light-redx px-24 py-12 text-16 leading-32 text-white"
        style={{
          opacity: t.visible ? 1 : 0,
          transition: 'opacity 100ms ease-in-out',
        }}
      >
        <div className="mr-16 inline-flex items-center gap-8">
          Idea successfully selected
          <button
            className="rounded-8 border-1 border-white px-16 py-8"
            onClick={() => {
              toast.dismiss(t.id);
              navigate(`/${project.slug}/selected-content-ideas`);
            }}
          >
            See Selected Content Ideas
          </button>
        </div>
        <Icon
          className="size-20 cursor-pointer"
          icon="lucide:x"
          onClick={() => toast.dismiss(t.id)}
        />
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.t.id === nextProps.t.id,
);

export default SuccessSelectedNotification;
