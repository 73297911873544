import Cookies from 'js-cookie';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useProjectDetail } from '@/Hooks/Project';
import { useUser } from '@/Hooks/useUser';

const Redirect = () => {
  const navigate = useNavigate();
  const { project_slug: projectSlug } = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useProjectDetail(projectSlug ?? '');
  const { data: user, isLoading: userLoading } = useUser();

  useEffect(() => {
    if (!isLoading && !userLoading) {
      if (_.isEmpty(project)) {
        navigate(`/404`);
      } else if (
        project?.owner.id !== user?.id &&
        !project?.users.find(({ id }) => id === user?.id)
      ) {
        Cookies.set('project_found', 'false', { expires: 1 / 2880 });
        Cookies.set('project_owner_name', project?.owner.name || '', {
          expires: 1 / 2880,
        });
        Cookies.set('project_owner_email', project?.owner.email || '', {
          expires: 1 / 2880,
        });
        navigate(`/projects`);
      } else {
        window.location.href = `/${projectSlug}/submission`;
      }
    }
  }, [project, user, isLoading, userLoading]);

  return null;
};

export default Redirect;
