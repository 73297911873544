import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type {
  CompetitorAnalysisContentData,
  EditResponse,
} from '@/Types/CompetitorAnalysis';

const update = async ({
  payload,
  id,
  projectSlug,
}: {
  payload: CompetitorAnalysisContentData[];
  id: number;
  projectSlug: string;
}) => {
  try {
    const url = `/projects/${projectSlug}/competitor-analysis/update/${id}`;
    const { data } = await goFetcher.put(url, {
      content: payload,
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateCompetitor = () => {
  return useMutation<
    EditResponse,
    Error,
    {
      payload: CompetitorAnalysisContentData[];
      id: number;
      projectSlug: string;
    }
  >({
    mutationFn: update,
  });
};

export { useUpdateCompetitor };
