import type { LabelHTMLAttributes } from 'react';

const InputLabel = ({
  value,
  optional = false,
  className = '',
  ...props
}: LabelHTMLAttributes<HTMLLabelElement> & {
  value?: string;
  optional?: boolean;
}) => {
  return (
    <label
      {...props}
      className={`block text-12 font-semibold leading-14 text-black-redx ${className}`}
      htmlFor={value}
    >
      {value} {optional && <span>(Optional)</span>}
    </label>
  );
};

export default InputLabel;
