import { useCallback, useEffect } from 'react';

import type { GetDifferentResponse } from '@/Types/AudienceArchetype';
import type {
  ArchetypeChallenge,
  ArchetypesState,
  ChallengeResponse,
  PreviousData,
} from '@/Types/ChallengeAndTask';
import type { SourcesType } from '@/Types/Projects';
import { calculateTotals } from '@/Utils/calculate-totals';
import {
  initializeGeneratingStates,
  initializeProgressStates,
  mapArchetypes,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataHandlerProps {
  diffArch: GetDifferentResponse | undefined;
  data: ChallengeResponse | undefined;
  sectionKey: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  archetypes: ArchetypesState;
  setArchetypes: React.Dispatch<React.SetStateAction<ArchetypesState>>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;

  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setSources: React.Dispatch<React.SetStateAction<SourcesType[]>>;
  setTotalRegenerate: React.Dispatch<React.SetStateAction<number>>;
  setTotals: React.Dispatch<
    React.SetStateAction<{
      allSelected: number;
      allData: number;
    }>
  >;
}

export const useSectionDataHandler = ({
  diffArch,
  data,
  archetypes,
  sectionKey,
  projectSlug,
  progressStates,
  setTotalRegenerate,
  setGeneratingStates,
  setProgressStates,
  setSources,
  setArchetypes,
  setTotals,
  setActiveTab,
  setMessage,
}: UseSectionDataHandlerProps) => {
  const checkChanges = useCallback(
    ({
      data,
      previousResult,
    }: {
      data: ArchetypeChallenge[];
      previousResult: PreviousData[] | null;
    }) => {
      if (!Array.isArray(data) || !Array.isArray(previousResult)) {
        return [];
      }

      return data.map((current) => {
        const previous = previousResult.find(
          (prev) => prev.archetype_id === current.id,
        );

        if (!previous || diffArch?.is_different_archetype.one_pages) {
          // Jika tidak ada data sebelumnya, anggap tidak ada perubahan
          return { archetype_id: current.id, isDifferent: false };
        }

        // Ambil semua key_insights yang dipilih di data saat ini
        const currentSelectedInsights = current.challenges
          .filter((c) => c.is_selected)
          .map((c) => c.id);

        // Ambil semua key_insights yang dipilih di data sebelumnya
        const previousSelectedInsights =
          previous.selected_challenge_and_communication_task?.map(
            (c) => c.challenge_list_id,
          ) || [];

        // Periksa apakah ada perbedaan dalam jumlah atau isi ID yang dipilih
        const isDifferent =
          currentSelectedInsights.length !== previousSelectedInsights.length ||
          !currentSelectedInsights.every((id) =>
            previousSelectedInsights.includes(id),
          );

        return {
          archetype_id: current.id,
          isDifferent,
        };
      });
    },
    [diffArch?.is_different_archetype.one_pages],
  );

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      const mappedArchetypes = mapArchetypes(
        data.data, // Inferred type
        archetypes,
      );

      const newGeneratingStates = initializeGeneratingStates(data.data);
      setGeneratingStates(newGeneratingStates);

      const savedProgress = getSectionProgressFromLocalStorage(
        projectSlug,
        sectionKey,
      );

      const updatedProgressStates = initializeProgressStates(
        data.data,
        savedProgress,
        progressStates,
      );

      setProgressStates((prev) => ({
        ...prev,
        ...updatedProgressStates,
      }));
      setSources(data.sources);
      setTotalRegenerate(data.total_regenerate);
      const result = calculateTotals(
        mappedArchetypes,
        'total_challenges',
        'total_selected_challenges',
      );

      const firstNonRegenerateTabIndex = data.data.findIndex(
        (item) => item.is_regenerate === false,
      );

      if (
        firstNonRegenerateTabIndex !== -1 &&
        data.data.some((item) => item.is_regenerate)
      ) {
        setActiveTab(firstNonRegenerateTabIndex);
      }

      const changes = checkChanges({
        data: data.data,
        previousResult: data.previous_selected_challenges,
      });

      // Perbarui hanya properti `is_diff_key_insight` pada mappedArchetypes
      const updatedArchetypes = { ...mappedArchetypes }; // Salin mappedArchetypes

      Object.keys(updatedArchetypes).forEach((archetypeId) => {
        const change = changes.find(
          (c) => c.archetype_id === Number(archetypeId),
        );
        updatedArchetypes[Number(archetypeId)].is_diff_challenges =
          change?.isDifferent || false;
      });

      setMessage(data.message);
      setArchetypes(updatedArchetypes);
      setTotals(result);
    }
  }, [data]);
};
