import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { GetDifferentResponse } from '@/Types/AudienceArchetype';
import type { HistoryStatus } from '@/Types/history';
import type { ProjectProps } from '@/Types/Projects';

import { useCheckObjective } from './useCheckObjective';

interface UseCtaParams {
  isIdeaAlignmentAndOnePageNeedRegenerate: boolean;
  isReadyToRegenerate: boolean;
  isEditor: boolean;
  isEditing: boolean;
  project: ProjectProps;
  routeOptionValue: RouteOption;
  message: string;
  activeSection: string;
  totalSelected: number;
  isKeyInsightsComplete: HistoryStatus;
  isTargetAudienceAudienceInsight: HistoryStatus;
  isTargetAudienceBehavioralTrend: HistoryStatus;
  isTargetAudienceMediaConsumptionPattern: HistoryStatus;
  isTargetAudiencePerceptionAnalysis: HistoryStatus;
  isAudienceArchetypeComplete: HistoryStatus;
  differentArchetypes: GetDifferentResponse | undefined;
  handleRefetchSelectedData: () => void;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCtaAction = ({
  message,
  isIdeaAlignmentAndOnePageNeedRegenerate,
  isEditor,
  isEditing,
  project,
  routeOptionValue,
  activeSection,
  totalSelected,
  isReadyToRegenerate,
  isKeyInsightsComplete,
  isTargetAudienceAudienceInsight,
  isTargetAudienceBehavioralTrend,
  isTargetAudienceMediaConsumptionPattern,
  isTargetAudiencePerceptionAnalysis,
  isAudienceArchetypeComplete,
  differentArchetypes,
  handleRefetchSelectedData,
  setShowViewInfoModal,
  setRouteOption,
  setShowConfirmationModal,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;
  const { refetch } = useCheckObjective(project.slug);

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(async () => {
    if (isAudienceArchetypeComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait audience archetype is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isKeyInsightsComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait key insight is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isTargetAudienceAudienceInsight.isQuerying ||
      isTargetAudienceBehavioralTrend.isQuerying ||
      isTargetAudienceMediaConsumptionPattern.isQuerying ||
      isTargetAudiencePerceptionAnalysis.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait target audience is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation('{text-error-redx} Please do save first.'));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (totalSelected === 0) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select audience archetype first`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    const { data } = await refetch();

    if (data?.has_objective === false) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please fill objective in submission first`,
        ),
      );
      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isKeyInsightsComplete.status &&
      !isKeyInsightsComplete.isRegenerated &&
      !differentArchetypes?.is_different_archetype.key_insight &&
      !isReadyToRegenerate &&
      !isIdeaAlignmentAndOnePageNeedRegenerate
    ) {
      navigate(`/${project.slug}/key-insight`);
      return;
    }

    if (
      totalSelected === 1 &&
      (!isKeyInsightsComplete.status || isKeyInsightsComplete.isRegenerated)
    )
      setShowConfirmationModal(true);
    else {
      handleRefetchSelectedData();
    }
  }, [
    isEditing,
    totalSelected,
    isReadyToRegenerate,
    isKeyInsightsComplete.isRegenerated,
    isIdeaAlignmentAndOnePageNeedRegenerate,
    differentArchetypes?.is_different_archetype.key_insight,
    isKeyInsightsComplete.status,
    isAudienceArchetypeComplete.status,
    isAudienceArchetypeComplete.isQuerying,
    isTargetAudienceAudienceInsight.isQuerying,
    isTargetAudienceBehavioralTrend.isQuerying,
    isTargetAudienceMediaConsumptionPattern.isQuerying,
    isTargetAudiencePerceptionAnalysis.isQuerying,
    isKeyInsightsComplete.isQuerying,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (
      !isKeyInsightsComplete.status ||
      isKeyInsightsComplete.isRegenerated ||
      differentArchetypes?.is_different_archetype.key_insight ||
      isReadyToRegenerate
    ) {
      setShowViewInfoModal(true);
      return;
    }

    if (isAudienceArchetypeComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait audience archetype is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    navigate(`/${projectSlug}/key-insight`);
  }, [
    isEditing,
    isReadyToRegenerate,
    isKeyInsightsComplete.status,
    isKeyInsightsComplete.isRegenerated,
    isAudienceArchetypeComplete.isQuerying,
    differentArchetypes?.is_different_archetype.key_insight,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';
    if (isEditor) {
      memoizedRouteOptionValue.isInactive =
        !isAudienceArchetypeComplete.status ||
        isAudienceArchetypeComplete.isQuerying ||
        isKeyInsightsComplete.isQuerying ||
        totalSelected === 0;

      if (
        !isKeyInsightsComplete.status ||
        isKeyInsightsComplete.isRegenerated ||
        differentArchetypes?.is_different_archetype.key_insight
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Generate Key Insight';
      } else if (
        isKeyInsightsComplete.status &&
        !isKeyInsightsComplete.isRegenerated &&
        !differentArchetypes?.is_different_archetype.key_insight &&
        (isReadyToRegenerate || isIdeaAlignmentAndOnePageNeedRegenerate)
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Update Other Pages';
      } else {
        memoizedRouteOptionValue.isGenerate = false;
        memoizedRouteOptionValue.label = 'Next to Key Insight';
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.isInactive =
        !isKeyInsightsComplete.status ||
        isKeyInsightsComplete.isQuerying ||
        isReadyToRegenerate ||
        isKeyInsightsComplete.isRegenerated ||
        differentArchetypes?.is_different_archetype.key_insight;

      memoizedRouteOptionValue.label = 'Next to Key Insight';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    totalSelected,
    activeSection,
    isEditor,
    isEditing,
    isReadyToRegenerate,
    differentArchetypes?.is_different_archetype.key_insight,
    isKeyInsightsComplete.isQuerying,
    isKeyInsightsComplete.status,
    isKeyInsightsComplete.isRegenerated,
    isIdeaAlignmentAndOnePageNeedRegenerate,
    message,
  ]);
};

export { useCtaAction };
