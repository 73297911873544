import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

import {
  type ProjectAction,
  ProjectActionsTypes,
} from '../Actions/project-actions';

export interface RouteOption {
  id?: string;
  label: string;
  isDisabled?: boolean;
  isGenerate?: boolean;
  isActive: boolean;
  isInactive?: boolean;
  onClick?: () => void;
}

export interface State {
  project: ProjectProps;
  user: UserResponse;
  routeNext: RouteOption;
  routePrev: RouteOption;
  information: string;
  subInformation: string;
  isEnabledHistoryStatus: boolean;
}

export const initialState: State = {
  project: {} as ProjectProps,
  user: {} as UserResponse,
  routeNext: {
    id: 'btn-next',
    label: 'Next',
    isActive: true,
    isGenerate: false,
    isDisabled: false,
    isInactive: false,
    onClick: () => {},
  },
  routePrev: {
    id: 'btn-back',
    label: 'Back',
    isActive: false,
    isGenerate: false,
    isDisabled: false,
    isInactive: false,
    onClick: () => {},
  },
  isEnabledHistoryStatus: true,
  information: '',
  subInformation: '',
};

const projectReducer = (state: State, action: ProjectAction) => {
  switch (action.type) {
    case ProjectActionsTypes.SET_PROJECT_DATA:
      return {
        ...state,
        project: action.payload,
      };
    case ProjectActionsTypes.SET_ENABLED_HISTORY_STATUS:
      return {
        ...state,
        isEnabledHistoryStatus: action.payload,
      };
    case ProjectActionsTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ProjectActionsTypes.SET_ROUTE_NEXT:
      return {
        ...state,
        routeNext: action.payload,
      };
    case ProjectActionsTypes.SET_ROUTE_PREV:
      return {
        ...state,
        routePrev: action.payload,
      };

    case ProjectActionsTypes.SET_INFORMATION:
      return {
        ...state,
        information: action.payload,
      };
    case ProjectActionsTypes.SET_SUB_INFORMATION:
      return {
        ...state,
        subInformation: action.payload,
      };

    default:
      return state;
  }
};

export default projectReducer;
