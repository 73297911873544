import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { KeyInsightUpdateSelectedResponse } from '@/Types/KeyInsight';
import type { KeyInsightSchema } from '@/Types/KeyInsight/schema';

const updateKeyInsight = async ({
  payload,
  id,
  archetypeId,
  projectSlug,
}: {
  payload: KeyInsightSchema;
  id: number;
  archetypeId: number;
  projectSlug: string;
}) => {
  try {
    if (id === -1) {
      const url = `/projects/${projectSlug}/key-insight/submit`;
      const { data } = await goFetcher.post(url, {
        content: payload,
        archetypeId,
      });
      return data as KeyInsightUpdateSelectedResponse;
    }

    const url = `/projects/${projectSlug}/key-insight/update/${id}`;
    const { data } = await goFetcher.put(url, {
      content: payload,
      archetypeId,
    });
    return data as KeyInsightUpdateSelectedResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateKeyInsight = () => {
  return useMutation<
    KeyInsightUpdateSelectedResponse,
    Error,
    {
      payload: KeyInsightSchema;
      id: number;
      archetypeId: number;
      projectSlug: string;
    }
  >({
    mutationFn: updateKeyInsight,
  });
};

export { useUpdateKeyInsight };
