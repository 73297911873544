import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ProjectProps } from '@/Types/Projects';

import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  message: string;
  isEditor: boolean;
  isEditing: boolean;
  isRegenerateKeyInsight: boolean | null;
  project: ProjectProps;
  routeOptionValue: RouteOption;
  activeSection: string;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRegenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCtaAction = ({
  message,
  isEditor,
  isEditing,
  isRegenerateKeyInsight,
  project,
  routeOptionValue,
  activeSection,
  setShowViewInfoModal,
  setShowRegenerateModal,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;

  const {
    isMarketResearchDemand,
    isMarketResearchTrend,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isKeyInsightsComplete,
  } = useHistoryStatus(project);

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(() => {
    if (!isMarketResearchDemand.status || !isMarketResearchTrend.status) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please input Market Information in the Submission first.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isMarketResearchDemand.isQuerying || isMarketResearchTrend.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait market research is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isKeyInsightsComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait key insight is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isProductResearchMainFeature.isQuerying ||
      isProductResearchMarcommHighlight.isQuerying ||
      isProductResearchProductDescription.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait product research is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation('{text-error-redx} Please do save first.'));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isRegenerateKeyInsight) {
      setShowRegenerateModal(true);
      return;
    }

    navigate(`/${projectSlug}/market-research`);
  }, [
    isRegenerateKeyInsight,
    isMarketResearchDemand.status,
    isMarketResearchTrend.status,
    isMarketResearchDemand.isQuerying,
    isMarketResearchTrend.isQuerying,
    isEditing,
    isKeyInsightsComplete.isQuerying,
    isProductResearchMainFeature.isQuerying,
    isProductResearchMarcommHighlight.isQuerying,
    isProductResearchProductDescription.isQuerying,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (!isMarketResearchDemand.status || !isMarketResearchTrend.status) {
      setShowViewInfoModal(true);
      return;
    }

    if (isMarketResearchDemand.isQuerying || isMarketResearchTrend.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait market research is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isProductResearchMainFeature.isQuerying ||
      isProductResearchMarcommHighlight.isQuerying ||
      isProductResearchProductDescription.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait product research is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    navigate(`/${projectSlug}/market-research`);
  }, [
    isMarketResearchDemand.status,
    isMarketResearchTrend.status,
    isMarketResearchDemand.isQuerying,
    isMarketResearchTrend.isQuerying,
    isEditing,
    isProductResearchMainFeature.isQuerying,
    isProductResearchMarcommHighlight.isQuerying,
    isProductResearchProductDescription.isQuerying,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';
    memoizedRouteOptionValue.isInactive =
      !isMarketResearchDemand.status ||
      !isMarketResearchTrend.status ||
      isProductResearchMainFeature.isQuerying ||
      isProductResearchMarcommHighlight.isQuerying ||
      isProductResearchProductDescription.isQuerying ||
      isMarketResearchDemand.isQuerying ||
      isMarketResearchTrend.isQuerying ||
      isKeyInsightsComplete.isQuerying ||
      isEditing;

    if (isEditor) {
      if (isRegenerateKeyInsight) {
        memoizedRouteOptionValue.label = 'Update Other Pages';
        memoizedRouteOptionValue.isGenerate = true;
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    isMarketResearchDemand.status,
    isMarketResearchTrend.status,
    isMarketResearchTrend.isQuerying,
    isMarketResearchDemand.isQuerying,
    isKeyInsightsComplete.isQuerying,
    activeSection,
    isRegenerateKeyInsight,
    isEditing,
    isProductResearchMainFeature.isQuerying,
    isProductResearchMarcommHighlight.isQuerying,
    isProductResearchProductDescription.isQuerying,
    message,
  ]);
};

export { useCtaAction };
