import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react/dist/iconify.js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AvatarLabel from '@/Components/AvatarLabel';
import ButtonCopyLink from '@/Components/ButtonCopyLink';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import ModalCenter from '@/Components/Modals/Center';
import PopoverRolesInput from '@/Components/PopoverRolesInput';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import TextInputTags from '@/Components/TextInputTags';
import { ProjectContext } from '@/Context/ProjectContext';
import { useSubmitProject, useValidateTeam } from '@/Hooks/Project';
import type { ProjectProps } from '@/Types/Projects';
import { Project, type ProjectSchema } from '@/Types/Projects/schema';
import type { ExternalUser, UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';
import { getRandomString } from '@/Utils/helper';

import RequestAccessModal from './RequestAccessModal';
import UnsavedChangesPopup from './UnsavedChangesPopup';

interface MainFormProps {
  id: number;
  isOpen: boolean;
  onClose: () => void;
  onRefetchLists?: () => void;
  appUrl: string;
  initialUsers: UserResponse[];
  project?: ProjectProps;
}

const FormModal = ({
  id,
  isOpen,
  onClose,
  appUrl,
  initialUsers,
  onRefetchLists,
  project,
}: MainFormProps) => {
  const [state] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues: getCheckValues,
    watch,
    formState: { errors },
    reset,
  } = useForm<ProjectSchema>({
    resolver: zodResolver(Project),
    defaultValues: {
      project_name: '',
      product_name: '',
      is_released: 'true',
      members: [],
      slug: getRandomString(8),
      external_members: [],
      emails: [],
      roles: [],
    },
  });
  const [validatedUsers, setValidatedUsers] = useState<UserResponse[]>([]);
  const [isClearTags, setIsClearTags] = useState<boolean>(false);
  const [isClearRoles, setIsClearRoles] = useState<boolean>(false);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [isOpenModalRequestAccess, setIsOpenModalRequestAccess] =
    useState<boolean>(false);
  const [requestAccessRoles, setRequestAccessRoles] = useState<string[]>([]);
  const [validatedExternalUsers, setValidatedExternalUsers] = useState<
    ExternalUser[]
  >([]);
  const [excludedUsers, setExcludedUsers] = useState<UserResponse[]>([]);
  const [popUnSave, setPopupUnSave] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mutateAsync: validateTeam, isPending: isValidating } =
    useValidateTeam();
  const { mutate: postSubmit, isPending: isProjectSubmitting } =
    useSubmitProject();
  const [currentStep, setCurrentStep] = useState(0);
  const closeModalRequestAccess = () => setIsOpenModalRequestAccess(false);
  const handleClearTags = () => {
    setIsClearTags(true);
    setValue('emails', []);
  };

  const handleClearTagsComplete = () => {
    setIsClearTags(false);
  };

  const handleClearRoles = () => {
    setIsClearRoles(true);
  };

  const handleClearRolesComplete = () => {
    setIsClearRoles(false);
  };
  const handleCloseModal = useCallback(() => {
    onClose();
    closeModalRequestAccess();
    setPopupUnSave(false);
  }, [onClose, closeModalRequestAccess]);

  const handlePopup = (state: boolean) => {
    if (currentStep === 0) {
      handleCloseModal();
    } else {
      setPopupUnSave(state);
    }
  };

  const onSubmit: SubmitHandler<ProjectSchema> = async () => {
    const rawData = getCheckValues();
    if (isProjectSubmitting) return;

    handlePopup(false);
    const data = {
      ...rawData,
      is_released: rawData.is_released === 'true',
      members: validatedUsers.map((user) => ({
        id: user.id,
        email: user.email,
        roles: user.roles,
      })),
      external_members: validatedExternalUsers,
    };

    triggerGTMEvent({
      event: `Add Project`,
      eventCategory: `Button Add Project Click`,
      eventAction: 'Click',
      eventLabel: 'new project',
      userId: user?.email,
      data,
    });

    postSubmit(
      { payload: data, id },
      {
        onSuccess: () => {
          if (id === -1) navigate(`/${watch('slug')}/submission`);
          else onRefetchLists?.();

          handleCloseModal();
        },
      },
    );
  };

  const nextStep = () => {
    if (currentStep < 2 - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const removeUser = useCallback(
    (user: UserResponse) => {
      setValidatedUsers(validatedUsers.filter((u) => u.email !== user.email));
      setValue(
        'members',
        watch('members')?.filter((u) => u.email !== user.email),
      );
      setExcludedUsers(excludedUsers.filter((u) => u.email !== user.email));
    },
    [validatedUsers, excludedUsers, setValue, watch],
  );

  const handleAddUser = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      if (isValidating) return;

      const { invalidEmails: invalidEmailsData, validatedEmails } =
        await validateTeam({
          payload: getCheckValues('emails') ?? [],
        });

      if ((invalidEmailsData?.length ?? 0) > 0) {
        const newInvalidEmails = invalidEmailsData?.filter(
          (email: string) => !invalidEmails.includes(email), // Cek jika email belum ada
        );

        if (newInvalidEmails && newInvalidEmails.length > 0) {
          setInvalidEmails((prevEmails) => [
            ...prevEmails,
            ...newInvalidEmails,
          ]);
          setIsOpenModalRequestAccess(true);
        }
      }
      if (validatedEmails && validatedEmails.length > 0) {
        const users = validatedEmails.map((email: string) => {
          const user = initialUsers?.find((user) => user.email === email);
          return { ...user, roles: watch('roles') } as UserResponse;
        });
        setValidatedUsers([...validatedUsers, ...users]);
        setExcludedUsers([...excludedUsers, ...users]);
        setValue('members', [
          ...watch('members')!,
          ...users.map((user: UserResponse) => {
            const roles =
              user.roles && user.roles.length > 0
                ? ([user.roles[0], ...user.roles.slice(1)] as [
                    string,
                    ...string[],
                  ])
                : undefined;

            return {
              id: user.id,
              email: user.email,
              roles,
            };
          }),
        ]);
      }
      handleClearTags();
      handleClearRoles();
    } catch (error) {
      /* empty */
    }
  };

  const handleRequestAccess = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    closeModalRequestAccess();
    const externalUsers = invalidEmails.map((email) => ({
      email,
      roles: requestAccessRoles,
    }));
    setValidatedExternalUsers([...validatedExternalUsers, ...externalUsers]);

    setValue('external_members', [
      ...watch('external_members')!,
      ...externalUsers.map((user) => {
        if (!user.roles || user.roles.length === 0) {
          throw new Error(`Invalid roles for user ${user.email}`);
        }
        return {
          ...user,
          roles: [user.roles[0], ...user.roles.slice(1)] as [
            string,
            ...string[],
          ],
        };
      }),
    ]);

    setExcludedUsers([
      ...excludedUsers,
      ...externalUsers.map((user) => ({
        id: 0,
        email: user.email,
        name: '',
        roles: user.roles,
      })),
    ]);
  };

  const removeExternalUser = useCallback(
    (user: ExternalUser) => {
      setValidatedExternalUsers(
        validatedExternalUsers.filter((u) => u.email !== user.email),
      );
      setValue(
        'external_members',
        watch('external_members')?.filter((u) => u.email !== user.email),
      );
      setExcludedUsers(excludedUsers.filter((u) => u.email !== user.email));
    },
    [validatedExternalUsers, excludedUsers, setValue, watch],
  );

  const handleCloseModalRequestAccess = () => {
    setIsOpenModalRequestAccess(false);
    setInvalidEmails([]);
  };

  useEffect(() => {
    if (id !== -1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
  }, [id]);

  useEffect(() => {
    if (project) {
      setValidatedUsers(project.users);
      setValidatedExternalUsers(project.requested_users);
      setExcludedUsers([
        project.owner,
        ...validatedUsers,
        ...validatedExternalUsers.map((user: ExternalUser): UserResponse => {
          return {
            id: 0,
            email: user.email,
            name: '',
            roles: user.roles,
            whatsapp_number: '',
            status: 'pending',
          };
        }),
      ]);
      reset({
        slug: project.slug,
        is_released: project.is_released ? 'true' : 'false',
        project_name: project.name,
        product_name: project.submissions[0].product_name,
        members: project.users.map((user) => ({
          id: user.id,
          email: user.email,
          roles: user.roles,
        })),
        external_members: [],
      });
    }
  }, [project]);

  return (
    <ModalCenter isOpen={isOpen} onClose={() => handlePopup(true)}>
      <div
        className={`relative w-full ${
          currentStep === 0 ? 'max-h-400 max-w-md' : 'max-w-4xl'
        }`}
      >
        <div
          className="z-10 flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentStep * 100}%)` }}
        >
          <div className="w-full shrink-0">
            <div className="relative rounded-10 px-40 py-50 md:px-30 md:py-50">
              <div>
                <h1 className="mb-10 text-25 font-medium leading-30 text-black-redx">
                  Let’s start your project!
                </h1>
              </div>
              <div className="mb-20">
                <InputLabel value="Project Name" />
                <Controller
                  control={control}
                  name="project_name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextInput
                      autoComplete="off"
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input your project name here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              </div>
              <div className="mb-20">
                <InputLabel value="Product Name" />
                <Controller
                  control={control}
                  name="product_name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextInput
                      autoComplete="off"
                      className="overflow-custom mt-1 block w-full resize-none !text-14"
                      containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                      error={error?.message}
                      onChange={onChange}
                      placeholder="Input your product name here"
                      rows={2}
                      value={value}
                    />
                  )}
                />
              </div>
              <div>
                <InputLabel value="Has your product been released?" />
                <ul className="text-sm mt-1 flex w-full items-center rounded-md border-transparent bg-transparent py-8 font-medium">
                  <li className="border-b sm:border-r w-full border-gray-200 sm:border-b-0 dark:border-gray-600">
                    <div className="flex items-center ps-3">
                      <input
                        {...register('is_released')}
                        className="!focus:blue-purple-redx !focus:ring-2 size-18 border-1 border-gray-200 bg-gray-100 text-blue-purple-redx"
                        defaultChecked
                        id="yes"
                        type="radio"
                        value="true"
                      />
                      <label
                        className="ms-10 w-full py-3 text-14 font-medium text-black-redx"
                        htmlFor="yes"
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className="border-b sm:border-r w-full border-gray-200 sm:border-b-0 dark:border-gray-600">
                    <div className="flex items-center ps-3">
                      <input
                        {...register('is_released')}
                        className="!focus:blue-purple-redx !focus:ring-2 size-18 border-1 border-gray-200 bg-gray-100 text-blue-purple-redx"
                        id="no"
                        type="radio"
                        value="false"
                      />
                      <label
                        className="ms-10 w-full py-3 text-14 font-medium text-black-redx"
                        htmlFor="no"
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-30 flex items-center justify-end gap-x-10">
                <PrimaryButton
                  className="ms-4 border-1 border-blue-redx bg-transparent px-20 py-10 text-12 !text-blue-redx"
                  id="btn-close-new-project"
                  onClick={onClose}
                >
                  Close
                </PrimaryButton>
                <PrimaryButton
                  className="ms-4 border-1 bg-blue-redx px-20 py-10 text-12 hover:bg-blue-redx active:bg-blue-redx"
                  disabled={
                    !watch('project_name') ||
                    !watch('product_name') ||
                    !!errors.project_name ||
                    !!errors.product_name ||
                    !!errors.is_released
                  }
                  id="btn-next-add-member-project"
                  onClick={nextStep}
                >
                  Next
                </PrimaryButton>
              </div>
            </div>
          </div>
          <div className="relative max-h-[80vh] w-full shrink-0 overflow-y-auto rounded-10 p-[36px] md:px-30 md:py-50">
            {popUnSave && (
              <UnsavedChangesPopup
                onBack={() => handlePopup(false)}
                onClose={handleCloseModal}
              />
            )}
            <div className="flex items-center justify-items-center gap-20">
              {id === -1 && (
                <Icon
                  className="size-15 shrink-0 text-black-redx hover:cursor-pointer"
                  icon="ep:back"
                  onClick={previousStep}
                />
              )}
              <h1 className="text-25 font-medium leading-30 text-black">
                Add your teammates!
              </h1>
            </div>
            <div className="flex flex-col gap-[36px]">
              <div className="mt-24 flex items-start gap-24">
                <div className="flex-1">
                  <div className="flex items-start justify-between gap-24 md:flex-col">
                    <div className="flex-1 md:w-full">
                      <InputLabel className="mb-4" value="Email" />
                      <TextInputTags
                        className="!grow px-0"
                        containerClassName="text-black-redx border-b-2 border-soft-purplestroke-redx"
                        excludedUsers={excludedUsers}
                        id="emails"
                        initialTags={[]}
                        initialUsers={initialUsers ?? []}
                        isClearTags={isClearTags}
                        onChangeTags={(tags) => setValue('emails', tags)}
                        onClearTagsComplete={handleClearTagsComplete}
                        placeholder="Input your teammates email here"
                      />
                    </div>
                    <div className="w-7/24 md:w-full">
                      <InputLabel value="Roles" />
                      <PopoverRolesInput
                        containerClassName="w-full !ps-0 text-black-redx border-b-2 border-soft-purplestroke-redx"
                        initialRoles={['Strategist', 'Creative', 'Content']}
                        isClearRoles={isClearRoles}
                        onChangeRoles={(roles) => {
                          setValue('roles', roles);
                          if (roles.length > 0) {
                            setRequestAccessRoles(roles);
                          }
                        }}
                        onClearRolesComplete={handleClearRolesComplete}
                      />
                    </div>
                  </div>
                </div>
                <PrimaryButton
                  className="!px-22 w-100 shrink-0 border-1 border-blue-redx bg-transparent !py-14 !text-16 !font-semibold !leading-18 !text-blue-redx"
                  disabled={
                    watch('emails')?.length === 0 ||
                    watch('roles')?.length === 0
                  }
                  id="btn-add-member-project"
                  onClick={(e) => handleAddUser(e)}
                >
                  Add
                </PrimaryButton>
              </div>
              <div className="flex flex-col gap-12">
                <InputLabel
                  className="!text-black-redx"
                  htmlFor="members"
                  value="Members"
                />
                <div
                  className={
                    currentStep !== 0 ? 'max-h-5/6 overflow-y-auto' : ''
                  }
                >
                  <div className="flex items-center gap-24 py-5">
                    <div className="flex-1">
                      <div className="flex items-center justify-between gap-20">
                        <div className="flex-1">
                          <AvatarLabel
                            src={user?.avatar_url ?? ''}
                            subtitle={user?.email ?? ''}
                            title={user?.name ?? ''}
                          />
                        </div>
                        <div className="w-8/24">
                          <p className="ps-8 text-14 font-semibold leading-20 text-black-redx/50">
                            Owner
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="flex items-center justify-center">
                        <Icon
                          className="size-24 text-black-redx/50"
                          icon="uil:trash"
                        />
                      </div>
                    </div>
                  </div>
                  {validatedUsers.map((user, i) => {
                    return (
                      <div
                        key={user.email}
                        className="flex items-center gap-24 py-5"
                      >
                        <div className="flex-1">
                          <div className="flex items-center justify-between gap-20">
                            <div className="flex-1">
                              <AvatarLabel
                                src={user.avatar_url ?? ''}
                                subtitle={user.email}
                                title={user.name}
                              />
                            </div>
                            <div className="relative w-8/24">
                              <PopoverRolesInput
                                className="font-semibold"
                                containerClassName="w-full"
                                error={
                                  errors.members?.[i] &&
                                  (errors.members[i].roles as any)
                                }
                                initialRoles={[
                                  'Strategist',
                                  'Creative',
                                  'Content',
                                ]}
                                onChangeRoles={(roles) => {
                                  setValue(
                                    'members',
                                    watch('members')?.map((m) =>
                                      m.email === user.email
                                        ? {
                                            ...m,
                                            roles: roles as [
                                              string,
                                              ...string[],
                                            ],
                                          }
                                        : m,
                                    ),
                                  );
                                  setValidatedUsers(
                                    validatedUsers.map((u) =>
                                      u.email === user.email
                                        ? { ...u, roles }
                                        : u,
                                    ),
                                  );
                                }}
                                selectedRoles={user.roles}
                              />
                              {errors.members?.[i] && (
                                <InputError
                                  className=""
                                  light={false}
                                  message={
                                    (errors.members[i].roles as any)?.message
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="flex items-center justify-center">
                            <Icon
                              className="size-24 cursor-pointer text-black-redx"
                              icon="uil:trash"
                              id="btn-remove-member-project"
                              onClick={() => removeUser(user)}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {validatedExternalUsers &&
                    validatedExternalUsers.length > 0 && (
                      <div className="py-10">
                        <InputLabel
                          className="!text-black-redx"
                          htmlFor="nonmembers"
                          value="Requested Members"
                        />
                      </div>
                    )}
                  {validatedExternalUsers?.map((user) => (
                    <div
                      key={user.email}
                      className="flex items-center justify-between gap-20 py-8"
                    >
                      <div className="flex-1">
                        <div className="flex items-center gap-10">
                          <Icon className="size-32" icon="mdi:user-circle" />
                          <div className="font-medium text-black">
                            <div className="mb-5 text-14 font-semibold leading-16 text-black-redx">
                              {user.email}
                            </div>
                            <div className="text-12 leading-14 text-grey-redx">
                              {user.email}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-6/24">
                        <p className="text-14">{user.roles.join(', ')}</p>
                      </div>
                      <div className="w-100">
                        <div className="flex items-center justify-center">
                          <Icon
                            className="size-24 cursor-pointer text-black-redx"
                            icon="uil:trash"
                            onClick={() => removeExternalUser(user)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-end justify-between border-b-2 border-soft-purple-redx py-10">
                <div className="text-black-redx">
                  <p className="text-12 font-semibold leading-16">
                    Share Project Link
                  </p>
                  <p className="text-14 font-normal leading-18">
                    {appUrl}/{watch('slug')}
                  </p>
                </div>
                <ButtonCopyLink value={`${appUrl}/${watch('slug')}`} />
              </div>
              <div className="mt-30 flex items-center justify-end gap-x-10">
                <PrimaryButton
                  className={`ms-4 px-31 py-16 !text-16 !leading-18 ${
                    isProjectSubmitting ||
                    !watch('project_name') ||
                    !watch('product_name') ||
                    !!errors.project_name ||
                    !!errors.product_name
                      ? 'cursor-not-allowed border-placeholder-redx bg-placeholder-redx text-white '
                      : 'cursor-pointer bg-blue-redx hover:bg-blue-redx active:bg-blue-redx'
                  }`}
                  disabled={
                    isProjectSubmitting ||
                    !watch('project_name') ||
                    !watch('product_name') ||
                    !!errors.project_name ||
                    !!errors.product_name
                  }
                  id="btn-submit-project"
                  onClick={() => {
                    handleSubmit(onSubmit)();
                  }}
                >
                  {id === -1 ? 'Create Project' : 'Save'}
                </PrimaryButton>
              </div>
            </div>
            <RequestAccessModal
              emails={invalidEmails}
              isOpen={isOpenModalRequestAccess}
              onClickRequest={(e) => handleRequestAccess(e)}
              onClose={handleCloseModalRequestAccess}
            />
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default FormModal;
