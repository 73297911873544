const stepTitles = [
  'Select, edit, or add the goals, success measurement, and main features for your contents',
  'Select, edit, or add the trend to ride your contents',
  'Select, edit, or add the topics for your content',
  'Select, edit, or add manually to your final content ideas',
];

const StepHeader = ({ currentStep }: { currentStep: number }) => {
  return (
    <h1 className="w-fit my-37 text-20 font-bold leading-24 text-black-redx">
      {stepTitles[currentStep]}
    </h1>
  );
};

export default StepHeader;
