import { TabGroup } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import ActionSection from '@/Components/Projects/ActionSection';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import {
  useCtaAction,
  useKeyInsights,
  useSectionDataHandler,
  useUpdateKeyInsight,
} from '@/Hooks/KeyInsight';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type {
  ArchetypeKeyInsight,
  ArchetypesState,
  SectionKeyInsight,
} from '@/Types/KeyInsight';
import type { KeyInsightSchema } from '@/Types/KeyInsight/schema';
import type { SourcesType } from '@/Types/Projects';
import type { SectionList } from '@/Types/tabs';
import { getArchetypeDetails } from '@/Utils/archetype';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import Skeleton from './Components/Skeleton';
import Tabs from './Components/Tabs';

const Index = () => {
  const sectionList: SectionList = {
    title: 'Key Insight',
    value: '',
    section: 'key_insights',
  };
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const { roles } = useRolesBadge(project, user);
  const [message, setMessage] = useState('');
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [archetypes, setArchetypes] = useState<ArchetypesState>({});
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>([]);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, isLoading, refetch, isRefetching } = useKeyInsights(project);
  const [totals, setTotals] = useState<{
    allSelected: number;
    allData: number;
  }>({
    allSelected: 0,
    allData: 0,
  });
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const { mutate, isPending: isSubmitting } = useUpdateKeyInsight();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({}); // State per archetype
  const archetypesArray = useMemo(
    () => Object.values(archetypes),
    [archetypes],
  );
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [shouldTriggerModal, setShouldTriggerModal] = useState(false);
  const { mutate: generate, isPending: isRegenerating } = useGenerate();
  const { data: differentArchetypes, refetch: refetchDiffArchetype } =
    useDifferentArchetypes(project.slug);

  const { isChallengeAndTaskOfTheBrand, isKeyInsightsComplete } =
    useHistoryStatus(project, state.isEnabledHistoryStatus);

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Generate Challenge & Task',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  });

  const activeArchetype = useMemo(() => {
    return (
      archetypesArray.find(
        (archetype) => archetype.id === archetypesArray[activeTab]?.id,
      ) || null
    );
  }, [archetypesArray, activeTab]);

  useSectionDataHandler({
    diffArch: differentArchetypes,
    isChallengeAndTaskOfTheBrand,
    archetypes,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionKey: sectionList.section,
    setArchetypes,
    setGeneratingStates,
    setProgressStates,
    setSources,
    setTotalRegenerate,
    setTotals,
    setActiveTab,
    setMessage,
  });

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: sectionList.section,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
  });

  const onSelectSuccess = useCallback(
    async (isEdited: boolean) => {
      setShowAlertGenerateModal(
        isEdited && !activeArchetype?.has_regenerate_challenge,
      );
      await refetch();
      setShouldTriggerModal(true);
    },
    [activeArchetype?.has_regenerate_challenge],
  );

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const selectedIndexes = useMemo(() => {
    return activeArchetype?.key_insights
      .map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [activeArchetype?.key_insights]);

  // const limit = activeArchetype?.total_regenerate || 0; TODO: will use this if 'generate more' is generated by archetype

  const handleFetchSubmit = async (
    payload: KeyInsightSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, id, archetypeId: activeArchetype?.id!, projectSlug },
      {
        onSuccess: ({ data }) => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Key Insight`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Key Insight  Click`,
            eventAction: 'Click',
            eventLabel: 'Key Insight',
            userId: user.email,
            data: payload,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setShowAlertGenerateModal(data.is_edited && data.is_selected);
          refetch();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleCancelEdit = () => {
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleCancelAdd = () => {
    if (!activeArchetype) return;

    setArchetypes((prev) => {
      const currentKeyInsights = prev[activeArchetype.id]?.key_insights || [];
      const filteredKeyInsights = currentKeyInsights.filter(
        (keyInsight) => keyInsight.id !== -1,
      );

      return {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          key_insights: filteredKeyInsights,
        },
      };
    });

    toggleIsAdding();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleAddKeyInsight = () => {
    if (!activeArchetype) return;

    const newKeyInsight: SectionKeyInsight = {
      id: -1, // Gunakan ID sementara
      is_manual: true,
      archetype_id: activeArchetype.id,
      is_edited: false,
      submission_id: -1, // Sesuaikan dengan tipe Anda
      history_id: -1, // Sesuaikan dengan tipe Anda
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_selected: false,
      key_insight_content: {
        consumer: '',
        cultural: '',
        category: '',
      },
    };

    // Hitung panjang array baru
    const currentKeyInsights =
      archetypes[activeArchetype.id]?.key_insights || [];
    const newIndex = currentKeyInsights.length;

    // Update state `archetypes` dengan data baru
    setArchetypes((prev) => {
      const updatedArchetypes = {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          key_insights: [...currentKeyInsights, newKeyInsight],
        },
      };
      return updatedArchetypes;
    });

    // Setelah state diperbarui, pastikan slider berpindah ke item baru
    setGoToIndex(newIndex);
    toggleIsAdding(); // Aktifkan mode "Adding"
  };

  const handleRegenerate = useCallback(() => {
    if (isChallengeAndTaskOfTheBrand.status) {
      const eventLabel = 'Challenge And Task';
      const eventAction = 'Click';
      const eventCategory = 'Generate Challenge And Task Click';
      const event = 'Generate Challenge And Task';
      const payload = {
        generate_more: '',
        regenerate_prompt: '',
        ...((activeArchetype?.is_diff_key_insight ||
          activeArchetype?.has_regenerate_per_archetype_challenges) &&
          !isChallengeAndTaskOfTheBrand.isRegenerated && {
            status: 'generate per archetype from key insight',
            archetype_id: [activeArchetype.id],
          }),
      };

      triggerGTMEvent({
        event,
        eventCategory,
        eventAction,
        eventLabel,
        userId: user.email,
        data: project.slug,
      });

      generate(
        {
          payload,
          projectSlug: project.slug,
          section: 'challenge-and-task',
        },
        {
          onSuccess: () => {
            refetch();
            setShowRegenerateModal(false);
            if (differentArchetypes?.is_different_archetype.challenges) {
              navigate(`/${project.slug}/challenge-and-communication-task`);
            }
          },
        },
      );
    }
  }, [
    isChallengeAndTaskOfTheBrand.isRegenerated,
    isChallengeAndTaskOfTheBrand.status,
    activeArchetype?.is_diff_key_insight,
    activeArchetype?.has_regenerate_per_archetype_challenges,
    differentArchetypes?.is_different_archetype.challenges,
  ]);

  const handleRefetchSelectedData = useCallback(async () => {
    queryClient.resetQueries({
      queryKey: ['challenges', project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));

    const totalEditedAndSelected =
      activeArchetype?.key_insights.filter(
        (keyInsight) => keyInsight.is_edited && keyInsight.is_selected,
      ).length ?? 0;

    if (
      isChallengeAndTaskOfTheBrand.status &&
      !isChallengeAndTaskOfTheBrand.isRegenerated &&
      !activeArchetype?.has_regenerate_challenge &&
      !differentArchetypes?.is_different_archetype.challenges &&
      (totalEditedAndSelected > 0 ||
        activeArchetype?.has_regenerate_per_archetype_challenges ||
        activeArchetype?.is_diff_key_insight)
    ) {
      setShowRegenerateModal(true);
      return;
    }

    const payload = {
      generate_more: '',
      regenerate_prompt: '',
      ...(activeArchetype?.has_regenerate_challenge &&
        !isChallengeAndTaskOfTheBrand.isRegenerated &&
        !differentArchetypes?.is_different_archetype.challenges && {
          archetype_id: [activeArchetype.id],
          status: 'generate per archetype from key insight',
        }),
    };

    generate(
      {
        payload,
        projectSlug: project.slug,
        section: 'challenge-and-task',
      },
      {
        onSuccess: () => {
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
          navigate(`/${project.slug}/challenge-and-communication-task`);
        },
      },
    );
  }, [
    project.slug,
    routeOptionValue,
    isChallengeAndTaskOfTheBrand.status,
    isChallengeAndTaskOfTheBrand.isRegenerated,
    activeArchetype?.key_insights,
    activeArchetype?.has_regenerate_per_archetype_challenges,
    activeArchetype?.has_regenerate_challenge,
    differentArchetypes?.is_different_archetype.challenges,
    activeArchetype?.is_diff_key_insight,
  ]);

  const handleRegenerateButton = async (prompt: string) => {
    if (!activeArchetype) return;

    // TO DO: will use this code when generate more has per archetype

    // removeProgressFromLocalStorage(
    //   project.slug,
    //   'key_insights',
    //   activeArchetype.id,
    // );

    // setArchetypes((prev) => ({
    //   ...prev,
    //   [activeArchetypeId]: {
    //     ...prev[activeArchetype.id],
    //     is_querying: true,
    //   },
    // }));

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      sectionList.section,
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeKeyInsight>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Key Insight`,
      eventCategory: `Generate More Key Insight Click`,
      eventAction: 'Click',
      eventLabel: 'Key Insight',
      userId: user.email,
      data: prompt,
    });

    generate(
      {
        payload: {
          generate_more: prompt,
          regenerate_prompt: '',
        },
        projectSlug: project.slug,
        section: 'key-insight',
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(setEnableHistoryStatus(true));
        },
      },
    );
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totals.allSelected} from ${totals.allData} key insight selected`,
      ),
    );
  }, [totals]);

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    const isDiffKeyInsight = activeArchetype?.is_diff_key_insight || false;

    if (
      shouldTriggerModal &&
      isDiffKeyInsight &&
      isChallengeAndTaskOfTheBrand.status &&
      !differentArchetypes?.is_different_archetype.challenges &&
      !isChallengeAndTaskOfTheBrand.isRegenerated &&
      !activeArchetype?.has_regenerate_challenge
    ) {
      setShowAlertGenerateModal(true);
    }
    setShouldTriggerModal(false);
  }, [
    activeArchetype?.is_diff_key_insight,
    activeArchetype?.has_regenerate_challenge,
    shouldTriggerModal,
    isChallengeAndTaskOfTheBrand.status,
    isChallengeAndTaskOfTheBrand.isRegenerated,
  ]);

  useRoutePrev({
    id: 'btn-back-key-insight',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/audience-archetype`),
  });

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useCtaAction({
    totalSelected: totals.allSelected,
    activeArchetype,
    archetypesArray,
    isEditing,
    isEditor,
    project,
    routeOptionValue,
    message,
    setErrorTabIndex,
    handleRefetchSelectedData,
    setRouteOption: setRouteOptionValue,
    setShowViewInfoModal,
  });

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [
    isKeyInsightsComplete.isQuerying,
    isChallengeAndTaskOfTheBrand.isQuerying,
    isChallengeAndTaskOfTheBrand.isRegenerated,
  ]);
  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex max-w-4xl grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">Key Insight</h1>
            <span className="text-1620 font-normal leading-relaxed text-grey-redx">
              Please{' '}
              <span className="font-bold text-black-redx">
                select one or more
              </span>{' '}
              key insights{' '}
              <span className="font-bold text-black-redx">
                for each archetype
              </span>
              , or add a new by clicking &apos;Add New Key Insight&apos;.
            </span>
          </div>
          {activeArchetype &&
            isEditor &&
            !activeArchetype.is_querying &&
            message !== 'No data found' && (
              <RegenerateButton
                isGenerateMore
                isLoading={isLoading}
                limit={totalRegenerate} // TODO: change with 'limit', if 'generate more' is generated per archetype
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="key-insight"
              />
            )}
        </div>

        <TabGroup
          className="pb-80"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs
            activeTab={activeTab}
            archetypesArray={archetypesArray}
            errorTabIndex={errorTabIndex}
            isEditing={isEditing || isAdding}
            isLoading={isLoading}
            isQuerying={isKeyInsightsComplete.isQuerying}
            onActiveTab={setActiveTab}
            user={user}
          />
          {isLoading ? (
            <Skeleton />
          ) : (
            <div
              key={activeArchetype?.id || activeTab}
              className="h-full py-24"
            >
              {(() => {
                if (message === 'No data found') {
                  return (
                    <NotDataFound
                      buttonLabel="Go to Audience Archetype"
                      description="You don't have selected archetype, click the button below to generate a archetype in Audience Archetype page."
                      onClick={() =>
                        navigate(`/${project.slug}/audience-archetype`)
                      }
                      title="No Data Found."
                    />
                  );
                }

                if (!activeArchetype) {
                  return null; // Jangan tampilkan apa-apa
                }

                if (activeArchetype.is_querying) {
                  return (
                    <GenerateLoading
                      estimateTimeTo={4}
                      progress={progressPortion({
                        progress: Number(
                          (progressStates[activeArchetype.id] || 0).toFixed(0),
                        ),
                        isQuerying: activeArchetype.is_querying,
                        isContentAvailable:
                          activeArchetype.key_insights.length > 0,
                      })}
                      project={project}
                      section={sectionList}
                      showEmailNotification={
                        isKeyInsightsComplete.isSendingEmail
                      }
                    />
                  );
                }

                return (
                  <div className="flex flex-col gap-20 ">
                    <SelectedCard
                      data={getArchetypeDetails(
                        activeArchetype.archetype_content,
                      )}
                      title={`Archetype Detail - ${activeArchetype.archetype_content.archetype_name}`}
                    />
                    <SliderContainer
                      goToIndex={goToIndex}
                      isAdding={isAdding}
                      isEditing={isEditing}
                      length={activeArchetype.key_insights.length}
                      onActiveIndex={(index) => setActiveIndex(index)}
                      selectedIndexes={selectedIndexes}
                    >
                      {activeArchetype.key_insights.map((keyInsight, index) => (
                        <Card
                          key={index}
                          ref={(el) => (cardRefs.current[index] = el)}
                          id={keyInsight.id}
                          index={index}
                          isCanSelect={isEditor}
                          isDisabledSelectButton={
                            isChallengeAndTaskOfTheBrand.status &&
                            !differentArchetypes?.is_different_archetype
                              .challenges &&
                            activeArchetype.total_selected_key_insights === 1 &&
                            !isChallengeAndTaskOfTheBrand.isRegenerated &&
                            !activeArchetype.has_regenerate_challenge
                          }
                          isEdited={
                            keyInsight.is_edited || keyInsight.is_manual
                          }
                          isEditing={isEditing || isAdding}
                          isFetching={isRefetching}
                          isSelected={!!keyInsight.is_selected}
                          item={keyInsight.key_insight_content}
                          onSelectSuccess={onSelectSuccess}
                          onSubmitSuccess={handleFetchSubmit}
                          project={project}
                          refetchDiffArchetype={refetchDiffArchetype}
                          totalData={activeArchetype.key_insights.length}
                          user={user}
                        />
                      ))}
                    </SliderContainer>

                    <ActionSection
                      isEditing={isAdding || isEditing}
                      isEditor={isEditor}
                      isSubmitting={isSubmitting}
                      label="Add Key Insight Manually"
                      onAdd={handleAddKeyInsight}
                      onCancel={isAdding ? handleCancelAdd : handleCancelEdit}
                      onSave={handleSubmit}
                      toggleEditing={toggleIsEditing}
                    />

                    {sources && <SourcesList sources={sources} />}
                  </div>
                );
              })()}
            </div>
          )}
        </TabGroup>
      </div>
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="key-insight"
      />
      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="key-insight"
        visibleSections={[
          'Challenge & Communication Task',
          'One-Page Strategy',
          'Key Touch Point',
          'Idea Alignment',
          'Idea Personalization',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
