import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ProjectProps } from '@/Types/Projects';
import type { SearchabilityListResponse } from '@/Types/SearchabilityContent';

interface UseSearchabilityProps {
  project: ProjectProps;
}

const getLists = async ({ project }: UseSearchabilityProps) => {
  const projectSlug = project.slug;
  const url = `/projects/${projectSlug}/searchability/lists`;

  try {
    const response = await goFetcher.get(url);

    return response.data as SearchabilityListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useSearchability = ({
  project,
}: UseSearchabilityProps): UseQueryResult<SearchabilityListResponse, Error> => {
  return useQuery<SearchabilityListResponse, Error>({
    queryKey: [
      'searchability',
      project.slug,
      project.submissions[0].selected_framework,
    ], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists({ project }),
    refetchOnWindowFocus: false,
    enabled: project.submissions[0].selected_framework === 'sdrcp',
  });
};

export { useSearchability };
