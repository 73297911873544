import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { OpportunityListResponse } from '@/Types/OpportunityAmongCompetition';

const fetch = async (projectSlug: string): Promise<OpportunityListResponse> => {
  try {
    const { data } = await goFetcher.get(
      `/projects/${projectSlug}/opportunity/lists`,
    );
    return data as OpportunityListResponse;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || 'Failed to fetch opportunities data',
    );
  }
};

const useOpportunities = (projectSlug: string) => {
  return useQuery<OpportunityListResponse, Error>({
    queryKey: ['opportunities', projectSlug],
    queryFn: () => fetch(projectSlug),
    refetchOnWindowFocus: false,
  });
};

export { useOpportunities };
