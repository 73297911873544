/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Breadcrumb from '@/Components/TopMenu/Breadcrumb';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useMounted } from '@/Hooks/useMounted';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { CategoryProps } from '@/Types/Projects';
import type { SectionHandles, SubmissionResponse } from '@/Types/Submission';

import CompetitorInformation from './Components/CompetitorInformation';
import MarketInformation from './Components/MarketInformation';
import Objective from './Components/Objective';
import ProductInformation from './Components/ProductInformation';
import TargetAudience from './Components/TargetAudience';

const Index = ({
  data,
  refetchSubmission,
}: {
  data: SubmissionResponse;
  refetchSubmission: () => void;
}) => {
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);

  const [isEditingCount, setIsEditingCount] = useState(0);
  const navigate = useNavigate();

  const { roles } = useRolesBadge(data.project, user);
  const location = useLocation();
  const isEditor = roles.includes('Strategist') || roles.includes('Owner');
  const [activeSection, setActiveSection] = useState(0);
  const isMounted = useMounted();
  const formSections = [
    'Product Information',
    'Market Information',
    'Target Audience',
    'Competitor Information',
    'Campaign Objective',
  ];

  const sectionContainerRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useMemo(
    () => formSections.map(() => createRef<SectionHandles>()),
    [],
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sectionParam = query.get('section');

    if (sectionParam && !Number.isNaN(sectionParam) && isMounted) {
      const sectionIndex = +sectionParam;

      if (sectionRefs[sectionIndex]?.current && activeSection > 3) {
        if (sectionRefs[sectionIndex]?.current?.element) {
          sectionRefs[sectionIndex].current.element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    }
  }, [sectionRefs, isMounted, activeSection]);

  useEffect(() => {
    let activateSection = 0;
    if (data.submission_status.is_product_information_complete) {
      activateSection = 1;
    }
    if (data.submission_status.is_market_information_complete) {
      activateSection = 2;
    }
    if (data.submission_status.is_audience_information_complete) {
      activateSection = 3;
    }
    if (data.submission_status.is_competitor_information_complete) {
      activateSection = 4;
    }
    if (data.submission_status.is_objective_information_complete) {
      activateSection = 4;
    }

    setActiveSection(activateSection);
  }, [data.submission_status]);

  const categoriesList = useMemo(() => {
    return data.categories.map((item: CategoryProps) => ({
      title: item.name,
      value: item.id,
    }));
  }, [data.categories]);

  const handleRouteNext = () => {
    const isIncomplete = [
      !data.submission_status.is_product_information_complete,
      !data.submission_status.is_market_information_complete,
      !data.submission_status.is_audience_information_complete,
      !data.submission_status.is_competitor_information_complete,
      !data.submission_status.is_objective_information_complete,
    ][activeSection];

    const onClick = isIncomplete
      ? async () => handleNextSection()
      : () => {
          sectionRefs[activeSection + 1].current?.element?.scrollIntoView({
            behavior: 'smooth',
          });
          setActiveSection(activeSection + 1);
        };

    const label =
      activeSection === formSections.length - 1
        ? 'Next to Product Research'
        : 'Next';

    const onClickRoute =
      activeSection === formSections.length - 1 &&
      data.submission_status.is_objective_information_complete
        ? () => navigate(`/${data.project.slug}/product-research`)
        : onClick;

    let isDisabled = false;
    if (isEditingCount > 0) {
      isDisabled = true;
    }

    return dispatch(
      saveRouteNext({
        id:
          label === 'Next to Product Research'
            ? 'btn-next-to-product-research'
            : 'btn-submission',
        label,
        isActive: true,
        isDisabled,
        onClick: onClickRoute,
      }),
    );
  };

  const handleRoutePrev = () => {
    if (activeSection !== 0) {
      return dispatch(
        saveRoutePrev({
          id: 'btn-back',
          label: 'Back',
          isActive: true,
          onClick: () => {
            sectionRefs[activeSection - 1].current?.element?.scrollIntoView({
              behavior: 'smooth',
            });
            setActiveSection(activeSection - 1);
          },
        }),
      );
    }
    return dispatch(
      saveRoutePrev({
        id: 'btn=back',
        label: 'Back',
        isActive: false,
      }),
    );
  };

  const changeInformation = () => {
    if (activeSection < formSections.length - 1) {
      return dispatch(
        saveInformation(
          `Click next to continue to ${formSections[
            activeSection + 1
          ].toLowerCase()}`,
        ),
      );
    }
    return dispatch(saveInformation(''));
  };

  useEffect(() => {
    handleRouteNext();
    handleRoutePrev();
    changeInformation();
  }, [activeSection, isEditingCount, data.submission_status]);

  useEffect(() => {
    if (sectionContainerRef) sectionContainerRef.current?.scrollTo(0, 0);
  }, [activeSection]);

  const handleNextSection = async () => {
    const currentRef = sectionRefs[activeSection]?.current;

    if (
      activeSection === formSections.length - 1 &&
      data.submission_status.is_objective_information_complete
    ) {
      navigate(`/${data.project.slug}/product-research`);
      return;
    }

    if (currentRef?.save) {
      await currentRef.save();
    }
  };

  const handleBreadcrumbClick = (index: number) => {
    const sectionRef = sectionRefs[index]?.current;

    if (sectionRef?.element) {
      sectionRef.element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex size-full flex-col py-24">
      <Breadcrumb
        index={activeSection}
        list={formSections}
        onBreadCrumbClick={handleBreadcrumbClick}
        submissionStatus={data.submission_status}
      />

      <div
        ref={sectionContainerRef}
        className="overflow-custom relative flex max-h-538 min-h-538 flex-1 flex-col gap-24 overflow-y-auto pb-80 pr-20"
      >
        <ProductInformation
          ref={sectionRefs[0]}
          activeSection={activeSection}
          data={{
            brand_name: data.project.submissions[0].brand_name ?? '',
            product_links: data.project.submissions[0].product_website ?? '',
            product_name: data.project.submissions[0].product_name ?? '',
          }}
          isActive={!(activeSection < 0)}
          isComplete={data.submission_status.is_product_information_complete}
          isEditingCount={isEditingCount}
          isEditor={isEditor}
          onDispatch={dispatch}
          onRefetchSubmission={refetchSubmission}
          onSetActiveSection={setActiveSection}
          onSetIsEditingCount={setIsEditingCount}
          projectSlug={data.project.slug}
          user={user}
        />
        <MarketInformation
          ref={sectionRefs[1]}
          activeSection={activeSection}
          categories={categoriesList}
          data={{
            product_category:
              data.project.submissions[0].category?.parent?.id ?? 0,
            product_subcategory: data.project.submissions[0].category?.id ?? 0,
          }}
          isActive={!(activeSection < 1)}
          isComplete={data.submission_status.is_market_information_complete}
          isEditingCount={isEditingCount}
          isEditor={isEditor}
          onDispatch={dispatch}
          onRefetchSubmission={refetchSubmission}
          onSetActiveSection={setActiveSection}
          onSetIsEditingCount={setIsEditingCount}
          projectSlug={data.project.slug}
          subcategories={data.subcategories}
          user={user}
        />
        <TargetAudience
          ref={sectionRefs[2]}
          activeSection={activeSection}
          data={{
            gender: data.project.submissions[0].gender ?? '',
            age_market_start:
              data.project.submissions[0].age_market_start ?? undefined,
            age_market_end:
              data.project.submissions[0].age_market_end ?? undefined,
          }}
          isActive={!(activeSection < 2)}
          isComplete={data.submission_status.is_audience_information_complete}
          isEditingCount={isEditingCount}
          isEditor={isEditor}
          onDispatch={dispatch}
          onRefetchSubmission={refetchSubmission}
          onSetActiveSection={setActiveSection}
          onSetIsEditingCount={setIsEditingCount}
          projectSlug={data.project.slug}
          user={user}
        />
        <CompetitorInformation
          ref={sectionRefs[3]}
          activeSection={activeSection}
          data={{
            competitors: data.project.submissions[0].competitors ?? [],
          }}
          isActive={!(activeSection < 3)}
          isComplete={data.submission_status.is_competitor_information_complete}
          isEditingCount={isEditingCount}
          isEditor={isEditor}
          onDispatch={dispatch}
          onRefetchSubmission={refetchSubmission}
          onSetActiveSection={setActiveSection}
          onSetIsEditingCount={setIsEditingCount}
          projectSlug={data.project.slug}
          user={user}
        />
        <Objective
          ref={sectionRefs[4]}
          data={{
            objective: data.project.submissions[0].objective ?? '',
            second_objective:
              data.project.submissions[0].second_objective ?? '',
            kpi: data.project.submissions[0].kpi ?? '',
          }}
          isActive={!(activeSection < 4)}
          isComplete={data.submission_status.is_objective_information_complete}
          isEditingCount={isEditingCount}
          isEditor={isEditor}
          onDispatch={dispatch}
          onRefetchSubmission={refetchSubmission}
          onSetIsEditingCount={setIsEditingCount}
          projectSlug={data.project.slug}
          user={user}
        />
      </div>
    </div>
  );
};

export default Index;
