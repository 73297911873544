import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { CompetitorAnalysisListResponse } from '@/Types/CompetitorAnalysis';

const getLists = async (projectSlug: string) => {
  const url = `/projects/${projectSlug}/competitor-analysis/lists`;
  try {
    const { data } = await goFetcher.get(url);

    return data as CompetitorAnalysisListResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useCompetitors = (
  projectSlug: string,
): UseQueryResult<CompetitorAnalysisListResponse, Error> => {
  return useQuery<CompetitorAnalysisListResponse, Error>({
    queryKey: ['competitors', projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists(projectSlug),
    refetchOnWindowFocus: false, // Keep data fresh indefinitely (no refetching)
  });
};

export { useCompetitors };
