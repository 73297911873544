import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { CreativeIdeaResponse } from '@/Types/CreativeIdea/creative-idea';

const fetch = async (projectSlug: string): Promise<CreativeIdeaResponse> => {
  try {
    const { data } = await goFetcher.get(
      `/projects/${projectSlug}/idea-submission/data`,
    );
    return data as CreativeIdeaResponse;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || 'Failed to fetch submisison data',
    );
  }
};

const useIdeaCreative = (projectSlug: string) => {
  return useQuery<CreativeIdeaResponse, Error>({
    queryKey: ['ideaCreative', projectSlug],
    queryFn: () => fetch(projectSlug),
    refetchOnWindowFocus: false,
  });
};

export { useIdeaCreative };
