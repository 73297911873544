import { useCallback, useEffect } from 'react';

import type { GetDifferentResponse } from '@/Types/AudienceArchetype';
import type { HistoryStatus } from '@/Types/history';
import type {
  ArchetypeIdeaPersonalization,
  ArchetypesState,
  IdeaPersonalizationResponse,
} from '@/Types/IdeaPersonalization';
import type { SourcesType } from '@/Types/Projects';
import { calculateTotals } from '@/Utils/calculate-totals';
import {
  initializeGeneratingStates,
  initializeProgressStates,
  mapArchetypes,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataHandlerProps {
  diffArch: GetDifferentResponse | undefined;
  data: IdeaPersonalizationResponse | undefined;
  isIdeaExpansion: HistoryStatus;
  sectionKey: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  archetypes: ArchetypesState;
  setArchetypes: React.Dispatch<React.SetStateAction<ArchetypesState>>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setSources: React.Dispatch<React.SetStateAction<SourcesType[]>>;
  setTotalRegenerate: React.Dispatch<React.SetStateAction<number>>;
  setTotals: React.Dispatch<
    React.SetStateAction<{
      allSelected: number;
      allData: number;
    }>
  >;
}

export const useSectionDataHandler = ({
  diffArch,
  isIdeaExpansion,
  data,
  archetypes,
  sectionKey,
  projectSlug,
  progressStates,
  setTotalRegenerate,
  setGeneratingStates,
  setProgressStates,
  setSources,
  setArchetypes,
  setTotals,
  setMessage,
}: UseSectionDataHandlerProps) => {
  const checkChanges = useCallback(
    ({ data }: { data: ArchetypeIdeaPersonalization[] }) => {
      if (!Array.isArray(data)) {
        return [];
      }

      return data.map((current) => {
        const previous = current.previous_selected_archetype?.find(
          (item) => item.archetype_id === current.id,
        );

        if (
          !previous ||
          isIdeaExpansion.isRegenerated ||
          diffArch?.is_different_archetype.idea_expansions
        ) {
          return { archetype_id: current.id, isDifferent: false };
        }

        // Ambil semua key_insights yang dipilih di data saat ini
        const currentSelectedInsights = current.idea_personalizations
          .filter((data) => data.is_selected)
          .map((data) => data.content_number);

        const previousSelectedInsight =
          previous.selected_idea_personalization?.idea_personalization_number ||
          null;

        // Periksa apakah ada perbedaan dalam jumlah atau isi ID yang dipilih
        const isDifferent =
          currentSelectedInsights.length !== 1 ||
          !currentSelectedInsights.includes(previousSelectedInsight!);

        return {
          archetype_id: current.id,
          isDifferent,
        };
      });
    },
    [
      isIdeaExpansion.isRegenerated,
      diffArch?.is_different_archetype.idea_expansions,
    ],
  );

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      const mappedArchetypes = mapArchetypes(
        data.data, // Inferred type
        archetypes,
      );

      const newGeneratingStates = initializeGeneratingStates(data.data);
      setGeneratingStates(newGeneratingStates);

      const savedProgress = getSectionProgressFromLocalStorage(
        projectSlug,
        sectionKey,
      );

      const updatedProgressStates = initializeProgressStates(
        data.data,
        savedProgress,
        progressStates,
      );

      setProgressStates((prev) => ({
        ...prev,
        ...updatedProgressStates,
      }));
      setSources(data.sources);
      setTotalRegenerate(data.total_regenerate);
      const result = calculateTotals(
        mappedArchetypes,
        'total_idea_personalizations',
        'total_selected_idea_personalizations',
      );

      const changes = checkChanges({
        data: data.data,
      });

      const updatedArchetypes = { ...mappedArchetypes }; // Salin mappedArchetypes

      Object.keys(updatedArchetypes).forEach((archetypeId) => {
        const change = changes.find(
          (c) => c.archetype_id === Number(archetypeId),
        );

        updatedArchetypes[Number(archetypeId)].is_diff_idea_personalization =
          change?.isDifferent || false;
      });

      setMessage(data.message);
      setArchetypes(updatedArchetypes);
      setTotals(result);
    }
  }, [data]);
};
