import React from 'react';

import SliderContainer from '@/Components/Projects/SliderContainer';
import type { Framework } from '@/Types/DiscoverabilityContent';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

import Card from './Card';

const IdeasList = ({
  archetypeId,
  stepDatum,
  getDisplayTitle,
  isEditor,
  user,
  project,
  onItemChange,
  recommendationNumber,
}: {
  recommendationNumber: number;
  archetypeId: number;
  stepDatum: Framework;
  getDisplayTitle: (
    archetypeId: number,
    framework: string,
    data: any[],
  ) => string;
  isEditor: boolean;
  user: UserResponse;
  project: ProjectProps;
  onItemChange?: (
    archetypeId: number,
    title: string,
    framework: string,
    recommendationNumber: number,
    id: number,
    checked: boolean,
  ) => void;
}) => {
  const filteredEntries = stepDatum.data.filter(
    (dataEntry) =>
      dataEntry.title ===
      getDisplayTitle(archetypeId, stepDatum.framework, stepDatum.data),
  );

  if (!filteredEntries.length) {
    return <div>No ideas available for this category.</div>;
  }

  return (
    <SliderContainer
      key={`${archetypeId}_${stepDatum.framework}_${filteredEntries[0].title}`}
      isAdding={false}
      isEditing={false}
      length={filteredEntries[0].ideas.length}
      selectedIndexes={[]}
    >
      {filteredEntries[0].ideas.map((idea, idx) => (
        <div key={idx} className="my-24">
          <Card
            archetypeId={archetypeId}
            framework={stepDatum.framework}
            id={+idea.id}
            index={idx}
            isEditor={isEditor}
            isFetching={false}
            isSelected={idea.is_checked}
            item={{
              id: +idea.id,
              who_should_say_it: idea.who_should_say_it ?? '',
              category: idea.category ?? '',
              content_idea: idea.content_idea ?? '',
              content_format: idea.content_format ?? '',
              is_checked: idea.is_checked ?? false,
              is_edited: idea.is_edited ?? false,
            }}
            onItemChange={onItemChange}
            project={project}
            recommendationNumber={recommendationNumber}
            title={stepDatum.data[0].title}
            totalData={filteredEntries[0].ideas.length}
            user={user}
          />
        </div>
      ))}
    </SliderContainer>
  );
};

export default IdeasList;
