import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { UpdateFrameworkResponse } from '@/Types/Submission';
import type { ObjectiveSubmissionSchema } from '@/Types/Submission/schema';

const updateObjectiveSubmission = async ({
  projectSlug,
  payload,
}: {
  projectSlug: string;
  payload: ObjectiveSubmissionSchema;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/submission/project-objective`,
      {
        objective: payload.objective,
        second_objective: payload.second_objective,
        kpi: payload.kpi,
      },
    );

    return data as UpdateFrameworkResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useObjectiveSubmission = () => {
  return useMutation<
    UpdateFrameworkResponse,
    Error,
    { projectSlug: string; payload: ObjectiveSubmissionSchema }
  >({
    mutationFn: updateObjectiveSubmission,
  });
};

export { useObjectiveSubmission };
