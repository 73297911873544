import { Helmet } from 'react-helmet';

import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';

import Index from './Index';

const Wrapper = () => {
  return (
    <>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <AuthenticatedLayout>
        <Index />
      </AuthenticatedLayout>
    </>
  );
};

export default Wrapper;
