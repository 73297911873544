/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import moment from 'moment';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProjectMembers from '@/Components/ProjectMembers';
import { saveProjectData } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { sessionStorage } from '@/Services/session-storage';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

interface ProjectMembersProps {
  initialUsers: UserResponse[];
  project: ProjectProps;
  onRefetchLists: () => void;
}

const ProjectCard = ({
  initialUsers,
  project,
  onRefetchLists,
}: ProjectMembersProps) => {
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);

  const { Badge } = useRolesBadge(project, user);
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);

  // handle click to submission page
  const handleClick = () => {
    if (isOpenModal) return;
    sessionStorage.set('projects', {
      project_slug: project.slug,
    });
    dispatch(saveProjectData(project));

    navigate(`/${project.slug}/submission`);
  };

  return (
    <div
      className="container cursor-pointer rounded-8 border-1 border-blue-purple-redx bg-white p-24 hover:bg-[#F6F4FF]"
      onClick={handleClick}
    >
      <div className="flex items-center gap-20 border-b-1 border-b-stroke-redx pb-18">
        <div className="grow">
          {/* project title and date created */}
          <div className="mb-18 flex items-center gap-10">
            <h2 className="line-clamp-2 text-24 font-semibold leading-28 text-black-redx sm:text-20 sm:leading-24 md:w-1/2">
              {project.name}
            </h2>
            <div className="flex items-center justify-between gap-10">
              <div className="h-1 w-16 bg-stroke-redx">&nbsp;</div>
              <p className="flex-1 text-16 leading-20 text-grey-redx sm:text-14 sm:leading-18 md:w-1/2">
                {moment(project.created_at).format('DD MMMM YYYY')}
              </p>
            </div>
          </div>
          <div className="flex -space-x-2">
            <ProjectMembers
              initialUsers={initialUsers}
              onRefetchLists={onRefetchLists}
              project={project}
              setIsOpenModal={setIsOpenModal}
            />
          </div>
        </div>
        <div className="">
          <Badge />
        </div>
      </div>
      {/* project fields */}
      {project.submissions.map((submission, i) => {
        return (
          <div key={i} className="mt-20 grid grid-cols-3 gap-20">
            <div>
              <div className="text-12 font-semibold leading-15 text-grey-redx">
                Brand Name
              </div>
              <div className="text-16 font-normal leading-20 text-black-redx">
                {submission.brand_name}
              </div>
            </div>
            <div>
              <div className="text-12 font-semibold leading-15 text-grey-redx">
                Product Name
              </div>
              <div className="text-16 font-normal leading-20 text-black-redx">
                {submission.product_name}
              </div>
            </div>
            <div>
              <div className="text-12 font-semibold leading-15 text-grey-redx">
                Product Category
              </div>
              <div className="text-16 font-normal leading-20 text-black-redx">
                {submission.category?.name}
              </div>
            </div>
            <div>
              <div className="text-12 font-semibold leading-15 text-grey-redx">
                Main Objective
              </div>
              <div className="text-16 font-normal leading-20 text-black-redx">
                {submission.objective}
              </div>
            </div>
            <div>
              <div className="text-12 font-semibold leading-15 text-grey-redx">
                Audience Archetype
              </div>
              <div className="text-16 font-normal leading-20 text-black-redx">
                {project.archetype_name}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectCard;
