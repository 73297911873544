import { Radio as HRadio } from '@headlessui/react';
import React from 'react';

import MarkdownEditorReadOnly from './MarkdownEditorReadOnly';

interface RadioButtonProps {
  isEditor?: boolean;
  value: string;
  label?: string;
  className?: string;
  disabled?: boolean;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  isEditor = true,
  value,
  label,
  className = '',
  disabled = false,
}) => {
  return (
    <div
      className={`inline-flex items-center gap-8 ${className} ${
        disabled ? 'cursor-not-allowed' : ''
      }`}
    >
      <HRadio
        className="group flex size-20 items-center justify-center rounded-full border-2 bg-white data-[checked]:border-blue-purple-redx data-[disabled]:border-gray-200 data-[disabled]:bg-gray-200"
        disabled={disabled || !isEditor}
        value={value}
      >
        <span className="invisible size-10 rounded-full bg-blue-purple-redx group-data-[checked]:visible group-data-[disabled]:bg-blue-redx/65" />
      </HRadio>

      {label && (
        <span className="cursor-pointer text-14 font-normal leading-20 ">
          <MarkdownEditorReadOnly content={label} />
        </span>
      )}
    </div>
  );
};

export default RadioButton;
