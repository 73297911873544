import Wrapper from '@/Pages/TargetAudience/Wrapper';

const TargetAudienceRoutes = [
  {
    path: '/:project_slug/target-audience',
    element: <Wrapper />,
  },
];

export default TargetAudienceRoutes;
