import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { useSubmission } from '@/Hooks/Submission';
import ProjectLayout from '@/Layouts/ProjectLayout';

import Index from './Index';

const Wrapper = () => {
  const params = useParams<{ project_slug: string }>();
  const { data, isLoading, refetch } = useSubmission(
    params?.project_slug ?? '',
  );

  return (
    <ProjectLayout>
      <Helmet>
        <title>Submission</title>
      </Helmet>
      {!isLoading && data && <Index data={data} refetchSubmission={refetch} />}
    </ProjectLayout>
  );
};

export default Wrapper;
