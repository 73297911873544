import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import GuestLayout from '@/Layouts/GuestLayout';
import { fetcher } from '@/Services/axios';

interface FormData {
  password: string;
}

const ConfirmPassword = () => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    setFocus('password');
  }, [setFocus]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      // Use Inertia's post method for form submission
      await fetcher.post('/confirm-password', data);
    } catch (error) {
      toast.error('Error confirm password!');
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Confirm Password</title>
      </Helmet>

      <div className="text-sm mb-4 text-gray-600">
        This is a secure area of the application. Please confirm your password
        before continuing.
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <InputLabel htmlFor="password" value="Password" />

          <TextInput
            className="mt-1 block w-full"
            id="password"
            type="password"
            {...register('password', { required: 'Password is required' })}
          />

          <InputError className="mt-2" message={errors.password?.message} />
        </div>

        <div className="mt-4 flex items-center justify-end">
          <PrimaryButton className="ms-4" disabled={isSubmitting}>
            Confirm
          </PrimaryButton>
        </div>
      </form>
    </GuestLayout>
  );
};

export default ConfirmPassword;
