import { memo } from 'react';

interface LabelProps {
  value: string;
  optional?: boolean;
}

const Label = ({ value = '', optional = false }: LabelProps) => {
  return (
    <p className="text-12 font-semibold leading-14 text-black-redx">
      {value} {optional && <span>(Optional)</span>}{' '}
    </p>
  );
};

export default memo(Label);
