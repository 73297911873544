import {
  Checkbox,
  Field,
  Label,
  Popover,
  PopoverButton,
  PopoverPanel,
  Radio,
  RadioGroup,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import type { InputHTMLAttributes } from 'react';
import { Fragment, useEffect, useState } from 'react';

interface ITag {
  containerClassName?: string;
  error?: string;
  initialRoles?: string[];
  selectedRoles?: string[];
  onChangeRoles?: (roles: string[]) => void;
  isClearRoles?: boolean;
  onClearRolesComplete?: () => void;
  canDelegateOwnership?: boolean;
  onChooseOwnership?: (status: boolean) => void;
}

const PopoverRolesInput = ({
  className = '',
  containerClassName = '',
  error = '',
  initialRoles = [],
  selectedRoles = [],
  onChangeRoles,
  isClearRoles = false,
  onClearRolesComplete,
  canDelegateOwnership = false,
  onChooseOwnership,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & ITag) => {
  const permissions = ['Edit', 'View Only'];

  const [selectedPermission, setSelectedPermission] = useState('');
  const [checkedRoles, setCheckedRoles] = useState<string[]>(selectedRoles);
  const [roles, setRoles] = useState<string[]>(initialRoles);
  const [selectedPermissionOwner, setSelectedPermissionOwner] = useState('');

  useEffect(() => {
    if (!selectedRoles.includes('View Only')) {
      setSelectedPermission('Edit');
    } else {
      setSelectedPermission('View Only');
    }
  }, [selectedRoles.length > 0]);

  useEffect(() => {
    onChangeRoles?.(checkedRoles);
  }, [checkedRoles]);

  useEffect(() => {
    setRoles?.(initialRoles);
  }, [initialRoles]);

  useEffect(() => {
    if (isClearRoles) {
      setCheckedRoles([]);
      setSelectedPermission('Edit');
      onClearRolesComplete?.();
    }
  }, [isClearRoles]);

  const handleCheckboxChange = (role: string) => {
    setCheckedRoles((prevCheckedRoles) =>
      prevCheckedRoles.includes(role)
        ? prevCheckedRoles.filter((r) => r !== role)
        : [...prevCheckedRoles, role],
    );
  };

  return (
    <Popover className="relative bg-transparent">
      <PopoverButton
        className={`flex items-center justify-between gap-20 py-12 ps-8 font-normal text-black-redx focus:outline-none data-[active]:text-black-redx data-[hover]:text-black-redx data-[focus]:outline-1 data-[focus]:outline-black-redx ${
          containerClassName
        }${error ? ' border-red-500' : ''}`}
      >
        {checkedRoles.length > 0 ? (
          <div className={`text-14 font-normal leading-20 ${className}`}>
            {checkedRoles.join(', ')}
          </div>
        ) : (
          <div className="text-14 font-normal leading-20 text-lightplaceholder-redx">
            Choose your team role here
          </div>
        )}
        <Icon className="size-20" icon="mdi:chevron-down" />
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          anchor="bottom end"
          className="mt-5 flex w-[var(--button-width)] min-w-200 flex-col rounded-8 bg-white shadow-[0px_0px_12px_0px_rgba(0,0,0,0.15)] [--anchor-gap:var(--spacing-5)]"
        >
          {({ close }) => (
            <div className="relative mx-auto mt-5 size-[90%] rounded-10 p-10">
              <RadioGroup
                aria-label="Server size"
                className="flex flex-col gap-5"
                onChange={(value) => {
                  setSelectedPermission(value);
                  if (value === 'View Only') {
                    setCheckedRoles(['View Only']);
                  } else {
                    setCheckedRoles([]);
                  }
                }}
                value={selectedPermission}
              >
                {permissions.map((permission, index) => (
                  <Fragment key={index}>
                    <Field className="flex items-center gap-5">
                      <Radio
                        className="group flex size-20 items-center justify-center rounded-full border-2 bg-white data-[checked]:border-blue-purple-redx data-[disabled]:border-gray-200 data-[disabled]:bg-gray-200"
                        disabled={props.disabled}
                        value={permission}
                      >
                        <span className="invisible size-10 rounded-full bg-blue-purple-redx group-data-[checked]:visible group-data-[disabled]:bg-gray-400" />
                      </Radio>
                      <Label className="ms-5 text-14 font-normal leading-20">
                        {permission}
                      </Label>
                    </Field>
                    {permission === 'Edit' &&
                      roles.map((role, i) => {
                        return (
                          <Field
                            key={i}
                            className="ms-30 flex items-center gap-5"
                          >
                            <Checkbox
                              checked={checkedRoles.includes(role)}
                              className="group block size-20 rounded border-2 bg-white data-[checked]:bg-blue-purple-redx data-[disabled]:bg-gray-200"
                              disabled={
                                selectedPermission !== 'Edit' || props.disabled
                              }
                              onChange={(_checked) => {
                                handleCheckboxChange(role);
                              }}
                            >
                              <svg
                                className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                                fill="none"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  d="M3 8L6 11L11 3.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                />
                              </svg>
                            </Checkbox>
                            <Label className="ms-5 text-14 font-normal leading-20">
                              {role}
                            </Label>
                          </Field>
                        );
                      })}
                  </Fragment>
                ))}
              </RadioGroup>

              {canDelegateOwnership && (
                <RadioGroup
                  aria-label="Server size"
                  className="mt-7 flex flex-col gap-5"
                  onChange={() => {
                    setSelectedPermissionOwner('');
                    close();
                    onChooseOwnership?.(true);
                  }}
                  value={selectedPermissionOwner}
                >
                  <Field className="flex items-center gap-5">
                    <Radio
                      className="group flex size-20 items-center justify-center rounded-full border-2 bg-white data-[checked]:border-blue-purple-redx data-[disabled]:border-gray-200 data-[disabled]:bg-gray-200"
                      disabled={props.disabled}
                      value="Owner"
                    >
                      <span className="invisible size-10 rounded-full bg-blue-purple-redx group-data-[checked]:visible group-data-[disabled]:bg-gray-400" />
                    </Radio>
                    <Label className="ms-5 text-14 font-normal leading-20">
                      Owner
                    </Label>
                  </Field>
                </RadioGroup>
              )}
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default PopoverRolesInput;
