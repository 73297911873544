import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { IdeaExpansionListResponse } from '@/Types/IdeaExpansion';

const fetch = async (
  projectSlug: string,
): Promise<IdeaExpansionListResponse> => {
  try {
    const response = await goFetcher.get(
      `/projects/${projectSlug}/idea-expansion/lists`, // todo: change with correct url
    );

    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || 'Failed to fetch idea expansion data',
    );
  }
};

const useIdeaExpansions = (projectSlug: string) => {
  return useQuery<IdeaExpansionListResponse, Error>({
    queryKey: ['ideaExpansion', projectSlug],
    queryFn: () => fetch(projectSlug),
    refetchOnWindowFocus: false, // Prevent refetch on window focus (e.g., tab switch)
  });
};

export { useIdeaExpansions };
