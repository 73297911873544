import type { TextareaHTMLAttributes } from 'react';
import React, { forwardRef, useContext } from 'react';

import { setEnableHistoryStatus } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  isFocused?: boolean;
  rightElement?: React.ReactNode;
  error?: string;
  containerClassName?: string;
  light?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className = '',
      containerClassName = '',
      error = '',
      placeholder = '',
      light = false,
      rightElement = null,
      ...props
    },
    ref,
  ) => {
    const [, dispatch] = useContext(ProjectContext);

    const placeholderClass = light
      ? 'placeholder-lightplaceholder-redx'
      : 'placeholder-placeholder-redx';

    // Event handler untuk fokus
    const handleFocus = () => {
      dispatch(setEnableHistoryStatus(false));
    };

    // Event handler untuk kehilangan fokus

    return (
      <>
        <div
          className={`${
            error ? '!border-red-500 ' : ''
          }flex w-full items-center overflow-hidden bg-transparent ${
            containerClassName
          }`}
        >
          <textarea
            ref={ref} // Pass the ref to the textarea element
            className={`!focus:border-transparent !focus:ring-0 rounded-md !border-transparent bg-transparent px-0 py-2 text-12 shadow-sm ${placeholderClass} ${
              className
            }`}
            onFocus={handleFocus}
            placeholder={placeholder}
            {...props}
          />
          {rightElement}
        </div>
        {error && <p className="mt-2 text-12 text-red-500">{error}</p>}
      </>
    );
  },
);

// Add a display name for easier debugging
TextArea.displayName = 'TextArea';

export default TextArea;
