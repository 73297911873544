import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { IdeaAlignmentSchema } from '@/Types/IdeaAlignment/schema';

const submit = async ({
  payload,
  projectSlug,
  id,
}: {
  payload: IdeaAlignmentSchema;
  projectSlug: string;
  id: number;
}) => {
  try {
    if (id === -1) {
      const { data } = await goFetcher.post(
        `/projects/${projectSlug}/idea-alignment/submit`,
        {
          content: payload,
        },
      );

      return data;
    }

    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/idea-alignment/update/${id}`,
      {
        content: payload,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useSubmitIdeaAlignment = () => {
  return useMutation<
    any,
    Error,
    { payload: IdeaAlignmentSchema; projectSlug: string; id: number }
  >({
    mutationFn: submit,
  });
};

export { useSubmitIdeaAlignment };
