export const calculateTotals = <T extends Record<string, any>>(
  data: T,
  totalKey: string,
  selectedKey: string,
): { allData: number; allSelected: number } => {
  let totalAllData = 0;
  let totalAllSelected = 0;

  if (totalKey in data && selectedKey in data) {
    totalAllData += data[totalKey] || 0;
    totalAllSelected += data[selectedKey] || 0;
  } else {
    Object.values(data).forEach((item) => {
      if (item && typeof item === 'object') {
        totalAllData += item[totalKey] || 0;
        totalAllSelected += item[selectedKey] || 0;
      }
    });
  }

  return {
    allData: totalAllData,
    allSelected: totalAllSelected,
  };
};
