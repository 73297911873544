import { useMutation } from '@tanstack/react-query';

import { fetcher } from '@/Services/axios';
import type { GenerateProps } from '@/Types/props';

const generate = async ({ projectSlug, section, payload }: GenerateProps) => {
  try {
    await fetcher.post(`/${projectSlug}/${section}/generate`, {
      ...payload,
    });
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useGenerate = () => {
  return useMutation<any, Error, GenerateProps>({
    mutationFn: generate,
  });
};

export { useGenerate };
