import { z } from 'zod';

export const KeyTouchPoint = z.object({
  digital_recommendation: z
    .string()
    .min(4, 'Digital recommendation is required'),
  idea_example: z.string().min(4, 'Idea example is required'),
  success_measurement: z.string().min(4, 'Success measurement is required'),
  media_types: z.string().min(4, 'Media types is required'),
  reason: z.string().min(4, 'Reason is required'),
  keyword: z.string(),
  trending_topics: z.string(),
});

export type KeyTouchPointSchema = z.infer<typeof KeyTouchPoint>;
