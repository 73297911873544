import { Icon } from '@iconify/react';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';

import ProjectMembers from '@/Components/ProjectMembers';
import { ProjectContext } from '@/Context/ProjectContext';
import { useApprovedUsers } from '@/Hooks/Project';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { ProjectProps } from '@/Types/Projects';

interface ProjectHeadProps {
  project: ProjectProps;
  onRefetchProject: () => void;
}

const ProjectHead = ({ project, onRefetchProject }: ProjectHeadProps) => {
  const [state] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);

  const { Badge } = useRolesBadge(project, user);
  const { data, isSuccess } = useApprovedUsers();

  const formattedDate = useMemo(
    () => moment(project.updated_at).format('DD MMMM YYYY - HH:mm'),
    [project.updated_at],
  );

  const initialUsers = useMemo(() => data?.users || [], [data?.users]);

  return (
    <div className="flex w-full flex-col border-b-2 border-gray-200 pb-24">
      <div className="mb-10 flex w-full items-center text-32 font-bold">
        <h1>{project.name}</h1>
      </div>
      {isSuccess && (
        <div className="flex w-full items-center gap-x-30">
          <ProjectMembers
            initialUsers={initialUsers}
            onRefetchLists={onRefetchProject}
            project={project}
          />
          <div className="inline-flex gap-x-8 text-grey-redx">
            <Icon className="size-24" icon="uil:clock" />
            <p>{formattedDate}</p>
          </div>
          <Badge />
        </div>
      )}
    </div>
  );
};

export default ProjectHead;
