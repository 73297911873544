import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ProjectProps } from '@/Types/Projects';

import { useDifferentArchetypes } from '../AudienceArchetype';
import { useGenerate } from '../useGenerate';
import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  message: string;
  isDiffSelected: boolean;
  isEditor: boolean;
  isEditing: boolean;
  project: ProjectProps;
  routeOptionValue: RouteOption;
  activeSection: string;
  totalSelected: number;
  setShowRegenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  message,
  isDiffSelected,
  isEditor,
  isEditing,
  project,
  routeOptionValue,
  activeSection,
  totalSelected,
  setShowRegenerateModal,
  setShowViewInfoModal,
  setRouteOption,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;
  const { data: differentArchetypes } = useDifferentArchetypes(project.slug);
  const { mutate: generate } = useGenerate();

  const { isIdeaAlignment, isIdeaPersonalization } = useHistoryStatus(project);

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(async () => {
    if (isIdeaAlignment.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait idea alignment is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isIdeaPersonalization.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait idea personalization is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation('{text-error-redx} Please do save first.'));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (totalSelected === 0) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select idea alignment first`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isDiffSelected) {
      setShowRegenerateModal(true);
      return;
    }
    if (
      !isIdeaPersonalization.status ||
      isIdeaPersonalization.isRegenerated ||
      differentArchetypes?.is_different_archetype.idea_expansions
    ) {
      dispatch(
        saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: true }),
      );

      generate(
        {
          projectSlug: project.slug,
          section: 'idea-personalization',
          payload: {
            regenerate_prompt: '',
            generate_more: '',
          },
        },
        {
          onSuccess: () => {
            dispatch(
              saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: false }),
            );
            navigate(`/${project.slug}/idea-personalization`);
          },
        },
      );
      return;
    }

    navigate(`/${project.slug}/idea-personalization`);
  }, [
    memoizedRouteOptionValue,
    isEditing,
    totalSelected,
    isDiffSelected,
    differentArchetypes?.is_different_archetype.idea_personalizations,
    isIdeaAlignment.isQuerying,
    isIdeaPersonalization.status,
    isIdeaPersonalization.isQuerying,
    isIdeaPersonalization.isRegenerated,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (isIdeaAlignment.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait idea alignment is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      !isIdeaPersonalization.status ||
      differentArchetypes?.is_different_archetype.idea_personalizations ||
      isIdeaPersonalization.isRegenerated
    ) {
      setShowViewInfoModal(true);
      return;
    }

    navigate(`/${projectSlug}/idea-personalization`);
  }, [
    isEditing,
    isIdeaPersonalization.status,
    isIdeaAlignment.isQuerying,
    differentArchetypes?.is_different_archetype.idea_personalizations,
    isIdeaPersonalization.isRegenerated,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';

    if (isEditor) {
      memoizedRouteOptionValue.isInactive =
        totalSelected === 0 ||
        isIdeaPersonalization.isQuerying ||
        isIdeaAlignment.isQuerying;

      if (
        !isDiffSelected &&
        (!isIdeaPersonalization.status ||
          isIdeaPersonalization.isRegenerated ||
          differentArchetypes?.is_different_archetype.idea_personalizations)
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Generate Idea Personalization';
      } else if (
        isIdeaPersonalization.status &&
        !isIdeaPersonalization.isRegenerated &&
        !isDiffSelected
      ) {
        memoizedRouteOptionValue.isGenerate = false;
        memoizedRouteOptionValue.label = 'Next to Idea Personalization';
      } else {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Update other pages';
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.isInactive =
        !isIdeaPersonalization.status ||
        isIdeaPersonalization.isQuerying ||
        isIdeaPersonalization.isRegenerated ||
        (isIdeaPersonalization.status &&
          differentArchetypes?.is_different_archetype.idea_personalizations);
      totalSelected === 0;

      memoizedRouteOptionValue.label = 'Next to Idea Personalization';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    totalSelected,
    activeSection,
    isEditor,
    isEditing,
    differentArchetypes?.is_different_archetype.idea_personalizations,
    isIdeaAlignment.isQuerying,
    isIdeaPersonalization.isQuerying,
    isIdeaPersonalization.isRegenerated,
    isIdeaPersonalization.status,
    isDiffSelected,
    message,
  ]);
};

export { useCtaAction };
