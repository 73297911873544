import React from 'react';

import EditedLabel from './EditedLabel';

// Props interface for reusable component
interface CardTitleProps {
  funnel?: string;
  label: string; // Current index of the idea alignment
  index: number; // Current index of the idea alignment
  totalData: number; // Total number of idea alignments
  isEdited: boolean; // Indicates whether the alignment is edited
  id: number; // Unique identifier for the idea alignment
}

// Reusable Component
const CardTitle: React.FC<CardTitleProps> = ({
  index,
  label,
  funnel,
  totalData,
  isEdited,
  id,
}) => {
  return (
    <div className="inline-flex items-center justify-center gap-8 px-35 py-15 text-center">
      {funnel ? (
        <>
          <p className="font-bold">{funnel}</p>
          <div className="h-20 w-1 border-1 border-stroke-redx" />
        </>
      ) : null}

      <h1 className="text-1620 font-bold">
        {label} {index + 1} of {totalData}
      </h1>
      {(isEdited || id === -1) && <EditedLabel />}
    </div>
  );
};

export default CardTitle;
