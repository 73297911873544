import { Icon } from '@iconify/react/dist/iconify.js';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { saveSubInformation } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useSidebarContext } from '@/Context/SidebarContext';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import type { ProjectProps } from '@/Types/Projects';

import MenuLink from './MenuLink'; // Ensure this import is correct

interface RouteItem {
  href: string;
  label: string;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  className?: string;
  subMenu?: RouteItem[];
}

const Sidebar: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const showCreative =
    (import.meta.env.VITE_SHOW_CREATIVE ?? 'true') === 'true';
  const showContent = (import.meta.env.VITE_SHOW_CONTENT ?? 'true') === 'true';
  const showStrategy =
    (import.meta.env.VITE_SHOW_STRATEGY ?? 'true') === 'true';

  const { isSidebarOpen } = useSidebarContext();
  const [state, dispatch] = useContext(ProjectContext);
  const location = useLocation();
  const [openSections, setOpenSections] = useState<Set<string>>(new Set());

  const isEnabledHistoryStatus = useMemo(
    () => state.isEnabledHistoryStatus,
    [state.isEnabledHistoryStatus],
  );

  const { data: differentArchetypes, refetch } = useDifferentArchetypes(
    project.slug,
  );

  const {
    isMarketResearchDemand,
    isMarketResearchTrend,
    isProductResearchProductDescription,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
    isKeyTouchPoint,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isIdeaAlignment,
    isIdeaPersonalization,
    isIdeaExpansion,
    isIdeaSubmission,
    searchabilityIdea,
    searchabilityObjective,
    searchabilityKeyword,
    searchabilityTopic,
    discoverabilityIdea,
    discoverabilityObjective,
    discoverabilityTrend,
    discoverabilityTopic,
    credibilityIdea,
    credibilityObjective,
    credibilityPerception,
    credibilityTopic,
    totalCompetitors,
    refetch: refetchHistory,
    isPending: isPendingHistory,
  } = useHistoryStatus(project, isEnabledHistoryStatus);

  const toggleSection = (section: string) => {
    setOpenSections((prev) => {
      const updatedSections = new Set(prev);
      if (updatedSections.has(section)) {
        updatedSections.delete(section); // Jika section terbuka, tutup
      } else {
        updatedSections.add(section); // Jika section tertutup, buka
      }
      return updatedSections;
    });
  };

  // Memoize isActiveRoute function
  const isActiveRoute = useCallback(
    (path: string) => location.pathname.includes(path),
    [location.pathname],
  );

  useEffect(() => dispatch(saveSubInformation(``)), []);

  useEffect(() => {
    refetch();
  }, [
    isMarketResearchDemand,
    isMarketResearchTrend,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
    isKeyTouchPoint,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isIdeaAlignment,
    isIdeaPersonalization,
    isIdeaExpansion,
    isIdeaSubmission,
    searchabilityIdea,
    searchabilityObjective,
    searchabilityKeyword,
    searchabilityTopic,
    discoverabilityIdea,
    discoverabilityObjective,
    discoverabilityTrend,
    discoverabilityTopic,
    credibilityIdea,
    credibilityObjective,
    credibilityPerception,
    credibilityTopic,
  ]);

  useEffect(() => {
    refetchHistory();
  }, [differentArchetypes?.is_different_archetype]);

  const routeItems: RouteItem[] = useMemo(() => {
    const routes: RouteItem[] = [
      {
        href: `/${project.slug}/submission`,
        label: 'Submission',
        disabled: false,
        icon: 'lucide:clipboard-list',
        active: isActiveRoute('/submission'),
      },
    ];

    if (showStrategy) {
      routes.push({
        href: `#`,
        label: 'Strategy',
        active:
          openSections.has('Strategy') ||
          isActiveRoute(`/${project.slug}/product-research`) ||
          isActiveRoute(`/${project.slug}/market-research`) ||
          isActiveRoute(`/${project.slug}/target-audience`) ||
          isActiveRoute(`/${project.slug}/audience-archetype`) ||
          isActiveRoute(`/${project.slug}/key-insight`) ||
          isActiveRoute(`/${project.slug}/challenge-and-communication-task`) ||
          isActiveRoute(`/${project.slug}/competitor-analysis`) ||
          isActiveRoute(`/${project.slug}/opportunity-among-competition`) ||
          isActiveRoute(`/${project.slug}/one-page-strategy`) ||
          isActiveRoute(`/${project.slug}/key-touch-point`),
        disabled: false,
        subMenu: [
          {
            href: `/${project.slug}/product-research`,
            icon: 'lucide:package-search',
            label: 'Product Research',
            disabled:
              (!isPendingHistory &&
                !isProductResearchProductDescription.status &&
                !isProductResearchMainFeature.status &&
                !isProductResearchMarcommHighlight.status) ||
              isProductResearchProductDescription.isRegenerated ||
              isProductResearchMainFeature.isRegenerated ||
              isProductResearchMarcommHighlight.isRegenerated,
            loading:
              !isPendingHistory &&
              (isProductResearchProductDescription.isQuerying ||
                isProductResearchMainFeature.isQuerying ||
                isProductResearchMarcommHighlight.isQuerying),
          },
          {
            href: `/${project.slug}/market-research`,
            icon: 'lucide:file-line-chart',
            label: 'Market Research: Industry',
            disabled:
              (!isPendingHistory &&
                !isMarketResearchDemand.status &&
                !isMarketResearchTrend.status) ||
              isMarketResearchDemand.isRegenerated ||
              isMarketResearchTrend.isRegenerated,
            loading:
              !isPendingHistory &&
              (isMarketResearchDemand.isQuerying ||
                isMarketResearchTrend.isQuerying),
          },
          {
            href: `/${project.slug}/target-audience`,
            icon: 'lucide:users-round',
            label: 'Target Audience',
            disabled:
              (!isPendingHistory &&
                !isTargetAudienceAudienceInsight.status &&
                !isTargetAudienceBehavioralTrend.status &&
                !isTargetAudienceMediaConsumptionPattern.status &&
                !isTargetAudiencePerceptionAnalysis.status) ||
              isTargetAudienceAudienceInsight.isRegenerated ||
              isTargetAudienceMediaConsumptionPattern.isRegenerated ||
              isTargetAudiencePerceptionAnalysis.isRegenerated ||
              isTargetAudienceBehavioralTrend.isRegenerated,
            loading:
              !isPendingHistory &&
              (isTargetAudienceAudienceInsight.isQuerying ||
                isTargetAudienceBehavioralTrend.isQuerying ||
                isTargetAudienceMediaConsumptionPattern.isQuerying ||
                isTargetAudiencePerceptionAnalysis.isQuerying),
          },
          {
            href: `/${project.slug}/audience-archetype`,
            icon: 'lucide:book-user',
            label: 'Audience Archetype',
            disabled:
              !isPendingHistory &&
              (!isAudienceArchetypeComplete.status ||
                isAudienceArchetypeComplete.isRegenerated),
            loading:
              !isPendingHistory && isAudienceArchetypeComplete.isQuerying,
          },
          {
            href: `/${project.slug}/key-insight`,
            icon: 'lucide:lightbulb',
            label: 'Key Insight',
            disabled:
              !isPendingHistory &&
              (!isKeyInsightsComplete.status ||
                differentArchetypes?.is_different_archetype.key_insight ||
                isKeyInsightsComplete.isRegenerated),
            loading: !isPendingHistory && isKeyInsightsComplete.isQuerying,
          },
          {
            href: `/${project.slug}/challenge-and-communication-task`,
            icon: 'lucide:puzzle',
            label: 'Challenge & Communication Task',
            disabled:
              !isPendingHistory &&
              (!isChallengeAndTaskOfTheBrand.status ||
                differentArchetypes?.is_different_archetype.challenges ||
                isChallengeAndTaskOfTheBrand.isRegenerated),
            loading:
              !isPendingHistory && isChallengeAndTaskOfTheBrand.isQuerying,
          },
          {
            href: `/${project.slug}/competitor-analysis`,
            icon: 'lucide:sword',
            label: 'Competitor Analysis',
            disabled:
              !isPendingHistory &&
              (!isCompetitorAnalysisComplete.status ||
                totalCompetitors === 0 ||
                isCompetitorAnalysisComplete.isRegenerated),
            loading:
              !isPendingHistory && isCompetitorAnalysisComplete.isQuerying,
          },
          {
            href: `/${project.slug}/opportunity-among-competition`,
            icon: 'lucide:split',
            label: 'Opportunity Among Competition',
            disabled:
              !isPendingHistory &&
              (!isOpportunityAmongCompetitionComplete.status ||
                differentArchetypes?.is_different_archetype.opportunities ||
                isOpportunityAmongCompetitionComplete.isRegenerated),
            loading:
              !isPendingHistory &&
              isOpportunityAmongCompetitionComplete.isQuerying,
          },
          {
            href: `/${project.slug}/one-page-strategy`,
            icon: 'lucide:goal',
            label: 'One-Page Strategy',
            disabled:
              !isPendingHistory &&
              (!isOnePageStrategy.status ||
                differentArchetypes?.is_different_archetype.one_pages ||
                isOnePageStrategy.isRegenerated),
            loading: !isPendingHistory && isOnePageStrategy.isQuerying,
          },
          {
            href: `/${project.slug}/key-touch-point`,
            icon: 'lucide:key-square',
            label: 'Key Touch Point',
            disabled:
              !isPendingHistory &&
              (!isKeyTouchPoint.status ||
                isKeyTouchPoint.isRegenerated ||
                differentArchetypes?.is_different_archetype.key_touch_points),
            loading: !isPendingHistory && isKeyTouchPoint.isQuerying,
          },
        ],
      });
    }

    if (showCreative) {
      routes.push({
        href: `#`,
        label: 'Creative',
        active:
          openSections.has('Creative') ||
          isActiveRoute(`/${project.slug}/creative-idea`) ||
          isActiveRoute(`/${project.slug}/idea-alignment`) ||
          isActiveRoute(`/${project.slug}/idea-personalization`) ||
          isActiveRoute(`/${project.slug}/idea-expansion`),

        disabled: false,
        subMenu: [
          {
            href: `/${project.slug}/creative-idea`,
            icon: 'lucide:sprout',
            label: 'Creative Idea',
            disabled:
              !differentArchetypes?.is_selected_content.key_touch_points,
            loading: false,
          },
          {
            href: `/${project.slug}/idea-alignment`,
            icon: 'lucide:arrows-up-from-line',
            label: 'Idea Alignment',
            disabled:
              !isPendingHistory &&
              (!isIdeaAlignment.status ||
                isIdeaAlignment.isRegenerated ||
                differentArchetypes?.is_different_archetype.one_pages),
            loading: !isPendingHistory && isIdeaAlignment.isQuerying,
          },
          {
            href: `/${project.slug}/idea-personalization`,
            icon: 'lucide:person-standing',
            label: 'Idea Personalization',
            disabled:
              !isPendingHistory &&
              (!isIdeaPersonalization.status ||
                differentArchetypes?.is_different_archetype
                  .idea_personalizations ||
                isIdeaPersonalization.isRegenerated),
            loading: !isPendingHistory && isIdeaPersonalization.isQuerying,
          },
          {
            href: `/${project.slug}/idea-expansion`,
            icon: 'lucide:expand',
            label: 'Idea Expansion',
            disabled:
              !isPendingHistory &&
              (!isIdeaExpansion.status ||
                isIdeaExpansion.isRegenerated ||
                differentArchetypes?.is_different_archetype.idea_expansions),
            loading: !isPendingHistory && isIdeaExpansion.isQuerying,
          },
        ],
      });
    }

    if (showContent) {
      routes.push({
        href: '#',
        label: 'Content',
        active:
          openSections.has('Content') ||
          isActiveRoute(`/${project.slug}/searchability-content`) ||
          isActiveRoute(`/${project.slug}/discoverability-content`) ||
          isActiveRoute(`/${project.slug}/credibility-content`) ||
          isActiveRoute(`/${project.slug}/selected-content-ideas`),
        disabled: false,
        subMenu: [
          {
            href: `/${project.slug}/searchability-content`,
            icon: 'lucide:text-cursor-input',
            label: 'Searchability Content',
            disabled:
              !(
                isKeyTouchPoint.status &&
                isIdeaExpansion.status &&
                searchabilityObjective.status &&
                project.submissions[0].selected_framework === 'sdrcp'
              ) || searchabilityObjective.isRegenerated, //  todo cek db
            loading:
              searchabilityIdea.isQuerying ||
              searchabilityObjective.isQuerying ||
              searchabilityTopic.isQuerying ||
              searchabilityKeyword.isQuerying, // todo cek db
          },
          {
            href: `/${project.slug}/discoverability-content`,
            icon: 'lucide:trending-up',
            label: 'Discoverability Content',
            disabled:
              !(
                isKeyTouchPoint.status &&
                isIdeaExpansion.status &&
                discoverabilityObjective.status &&
                project.submissions[0].selected_framework === 'sdrcp'
              ) || discoverabilityObjective.isRegenerated, //  todo cek db
            loading:
              discoverabilityIdea.isQuerying ||
              discoverabilityObjective.isQuerying ||
              discoverabilityTopic.isQuerying ||
              discoverabilityTrend.isQuerying,
          },
          {
            href: `/${project.slug}/credibility-content`,
            icon: 'lucide:handshake',
            label: 'Credibility Content',
            disabled:
              !(
                isKeyTouchPoint.status &&
                isIdeaExpansion.status &&
                credibilityObjective.status &&
                project.submissions[0].selected_framework === 'sdrcp'
              ) || credibilityObjective.isRegenerated,
            loading:
              credibilityIdea.isQuerying ||
              credibilityPerception.isQuerying ||
              credibilityObjective.isQuerying ||
              credibilityTopic.isQuerying,
          },
          {
            href: `/${project.slug}/selected-content-ideas`,
            icon: 'lucide:book-heart',
            label: 'Selected Content Ideas',
            disabled:
              !(
                isKeyTouchPoint.status &&
                isIdeaExpansion.status &&
                credibilityObjective.status &&
                project.submissions[0].selected_framework === 'sdrcp'
              ) ||
              searchabilityObjective.isRegenerated ||
              !(
                isKeyTouchPoint.status &&
                isIdeaExpansion.status &&
                discoverabilityObjective.status &&
                project.submissions[0].selected_framework === 'sdrcp'
              ) ||
              searchabilityObjective.isRegenerated ||
              !(
                isKeyTouchPoint.status &&
                isIdeaExpansion.status &&
                discoverabilityObjective.status &&
                project.submissions[0].selected_framework === 'sdrcp'
              ) ||
              searchabilityObjective.isRegenerated,
            loading: false,
            className: 'selected-content-ideas',
          },
        ],
      });
    }

    return routes;
  }, [
    project.submissions[0].selected_framework,
    project.slug,
    openSections,
    isPendingHistory,
    isProductResearchProductDescription,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    differentArchetypes,
    isChallengeAndTaskOfTheBrand,
    isCompetitorAnalysisComplete,
    totalCompetitors,
    isOpportunityAmongCompetitionComplete,
    isOnePageStrategy,
    isKeyTouchPoint,
    isIdeaSubmission,
    isIdeaAlignment,
    isIdeaPersonalization,
    isIdeaExpansion,
    searchabilityIdea,
    searchabilityObjective,
    searchabilityKeyword,
    searchabilityTopic,
    discoverabilityIdea,
    discoverabilityObjective,
    discoverabilityTrend,
    discoverabilityTopic,
    credibilityIdea,
    credibilityObjective,
    credibilityPerception,
    credibilityTopic,
    openSections,
    showCreative,
    showContent,
    showStrategy,
  ]);

  const renderMenu = useCallback(
    (items: RouteItem[]) => {
      return items.map((item, index) => (
        <React.Fragment key={index}>
          {item.href !== '#' && (
            <li>
              <MenuLink
                disabled={item.disabled}
                icon={item.icon}
                isLoading={item.loading}
                to={item.href === '#' ? '#' : item.href}
              >
                {item.label}
              </MenuLink>
            </li>
          )}
          {item.href === '#' && (
            <button
              className="inline-flex w-full cursor-pointer justify-between border-b-2 border-stroke-redx py-5 text-14 text-gray-200"
              onClick={() => toggleSection(item.label)}
            >
              {item.label}
              <Icon
                className={`size-24 transition-transform duration-300 ${
                  item.active ? 'rotate-180' : 'rotate-0'
                }`}
                icon="lucide:chevron-down"
              />
            </button>
          )}
          {/* Submenu items */}
          <ul
            className={`flex flex-col gap-8 overflow-hidden transition-all duration-300 ease-in-out ${
              item.active ? 'max-h-full opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            {item.subMenu?.map((subItem, subIndex) => (
              <li
                key={subIndex}
                className={subItem.className ? subItem.className : ''}
              >
                <MenuLink
                  disabled={subItem.disabled}
                  icon={subItem.icon}
                  isLoading={subItem.loading}
                  to={subItem.href}
                >
                  {subItem.label}
                </MenuLink>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ));
    },
    [toggleSection],
  );

  return (
    <aside
      aria-label="Sidebar"
      className={`fixed inset-y-0 left-0 z-30 h-full min-w-3/12 shadow-xl transition-transform ${
        isSidebarOpen ? 'block' : 'hidden'
      }`}
    >
      <div
        className="flex size-full flex-col justify-between bg-darkblue-redx"
        style={{ minHeight: '100vh' }} // Gunakan tinggi 100vh agar sidebar penuh
      >
        <div className="flex-1 overflow-y-auto px-30 pt-80 scrollbar-hide">
          <ul className="flex flex-col gap-y-8">{renderMenu(routeItems)}</ul>
        </div>

        <div className="bg-darkblue-redx px-30 py-40 shadow-inner">
          <div className="hidden flex-col items-start gap-y-8 font-medium">
            <div className="inline-flex w-full cursor-not-allowed items-center justify-center rounded-8 border-2 border-soft-purple-redx py-8 text-14 text-gray-200">
              Duplicate Project
              <span className="ml-8 inline-flex items-center rounded-4 bg-soft-purple-redx px-4 py-2 text-center text-8 font-bold uppercase text-blue-redx">
                Coming Soon
              </span>
            </div>
            <div className="inline-flex w-full justify-center rounded-8 border-2 border-soft-purple-redx py-8 text-14 text-gray-200">
              View History
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default memo(Sidebar);
