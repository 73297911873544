import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { ChallengeUpdateSelectedResponse } from '@/Types/ChallengeAndTask';
import type { ChallengeSchema } from '@/Types/ChallengeAndTask/schema';

const updateChallengeAndTask = async ({
  payload,
  id,
  archetypeId,
  projectSlug,
}: {
  payload: ChallengeSchema;
  id: number;
  archetypeId: number;
  projectSlug: string;
}) => {
  try {
    if (id === -1) {
      const url = `/projects/${projectSlug}/challenge-and-task/submit`;

      const { data } = await goFetcher.post(url, {
        content: payload,
        archetypeId,
      });

      return data as ChallengeUpdateSelectedResponse;
    }

    const url = `/projects/${projectSlug}/challenge-and-task/update/${id}`;

    const { data } = await goFetcher.put(url, {
      content: payload,
      archetypeId,
    });

    return data as ChallengeUpdateSelectedResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateChallengeAndTask = () => {
  return useMutation<
    ChallengeUpdateSelectedResponse,
    Error,
    {
      payload: ChallengeSchema;
      id: number;
      archetypeId: number;
      projectSlug: string;
    }
  >({
    mutationFn: updateChallengeAndTask,
  });
};

export { useUpdateChallengeAndTask };
