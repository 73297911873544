// TargetAudienceList.tsx
import React from 'react';

import MarkdownEditorReadOnly from '@/Components/MarkdownEditorReadOnly';
import type { TargetAudienceItem } from '@/Types/TargetAudience';

interface TargetAudienceListProps {
  items: TargetAudienceItem[];
}

const TargetAudienceList: React.FC<TargetAudienceListProps> = ({ items }) => {
  return (
    <ul className="ms-20 list-disc">
      {items.length > 0 &&
        items.map((item, index) => (
          <li key={index}>
            <MarkdownEditorReadOnly
              key={index}
              content={`**${item.title}**\n ${item.content}`}
            />
          </li>
        ))}
    </ul>
  );
};

export default TargetAudienceList;
