import Wrapper from '@/Pages/ProductResearch/Wrapper';

const ProductResearchRoutes = [
  {
    path: '/:project_slug/product-research',
    element: <Wrapper />,
  },
];

export default ProductResearchRoutes;
