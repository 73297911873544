/* eslint-disable react/no-danger */
import DOMPurify from 'dompurify';
import React from 'react';

import ModalCenter from '@/Components/Modals/Center';
import PrimaryButton from '@/Components/PrimaryButton';

const NoAccessModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  message: string | null;
}> = ({ isOpen, onClose, message }) => {
  const sanitizedMessage = () => ({
    __html: DOMPurify.sanitize(message!),
  });

  return (
    <ModalCenter isOpen={isOpen} onClose={onClose}>
      <div className="relative w-full min-w-486 max-w-md md:min-w-[100vw]">
        <div className="relative w-full rounded-10 px-35 pb-35 pt-50">
          <h1 className="mb-15 text-25 font-medium leading-30 text-black-redx">
            You need an invitation
          </h1>
          <p
            className="prose mb-25 text-15 leading-20 text-grey-redx"
            dangerouslySetInnerHTML={sanitizedMessage()}
          />
          <div className="flex w-full justify-end">
            <PrimaryButton
              className="ms-4 bg-blue-redx px-20 py-10 text-12 hover:bg-indigo-700 active:bg-indigo-700"
              onClick={onClose}
            >
              Understand
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default NoAccessModal;
