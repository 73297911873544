import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import InputError from '@/Components/InputError';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import GuestLayout from '@/Layouts/GuestLayout';
import { fetcher } from '@/Services/axios';

interface FormData {
  email: string;
}

const ForgotPassword = ({ status }: { status?: string }) => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      // Use Inertia's post method for form submission
      await fetcher.post('/forgot-password', data);
    } catch (error) {
      toast.error('Error forgot password!');
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>

      <div className="text-sm mb-4 text-gray-600">
        Forgot your password? No problem. Just let us know your email address
        and we will email you a password reset link that will allow you to
        choose a new one.
      </div>

      {status && (
        <div className="text-sm mb-4 font-medium text-green-600">{status}</div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          className="mt-1 block w-full"
          id="email"
          type="email"
          {...register('email', { required: 'Email is required' })}
        />

        <InputError className="mt-2" message={errors.email?.message} />

        <div className="mt-4 flex items-center justify-end">
          <PrimaryButton className="ms-4" disabled={isSubmitting}>
            Email Password Reset Link
          </PrimaryButton>
        </div>
      </form>
    </GuestLayout>
  );
};

export default ForgotPassword;
