import axios from 'axios';
import React, { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import ModalCenter from '@/Components/Modals/Center';
import PrimaryButton from '@/Components/PrimaryButton';
import TextAreaInput from '@/Components/TextAreaInput';
import TextInput from '@/Components/TextInput';
import RequestAccessSuccessToast from '@/Components/Toast/RequestAccessSuccess';
import UserHasApprovedToast from '@/Components/Toast/UserHasApproved';
import { fetcher } from '@/Services/axios';

interface IFormRequest {
  email: string;
  password: string;
}

interface FormData {
  email: string;
  password: string;
  reason: string;
}

const RequestAccess: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  formRequest: IFormRequest;
}> = ({ isOpen, onClose, formRequest }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      email: formRequest.email,
      password: formRequest.password,
      reason: '',
    },
  });

  const [disableButton, setDisableButton] = useState(false);

  const handleClose = () => {
    setDisableButton(false);
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setDisableButton(true);
      const response = await fetcher.post('/request-access', data);
      reset();
      handleClose();
      if (response.data.status === 'approved') {
        toast.custom((t) => <UserHasApprovedToast t={t} />);
      } else {
        toast.custom((t) => <RequestAccessSuccessToast t={t} />);
      }
    } catch (err) {
      setDisableButton(false);
      if (axios.isAxiosError(err) && err.response?.data?.errors?.email) {
        setValue('email', err.response.data.errors.email[0]);
      }
    }
  };

  useEffect(() => {
    setValue('email', formRequest.email);
    setValue('password', formRequest.password);
  }, [formRequest, setValue]);

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className="relative w-full min-w-486 max-w-md md:min-w-[100vw]">
        <form
          className="relative w-full rounded-10 px-40 py-50 md:px-30 md:py-50"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <h1 className="mb-10 text-25 font-medium leading-30 text-black">
              Request Access
            </h1>
          </div>
          <div className="mb-20">
            <InputLabel value="Email" />
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextInput
                  disabled
                  id="email_request"
                  type="email"
                  {...field}
                  className="mt-1 block w-full"
                  containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                  light
                  placeholder="Input your RedComm email here"
                />
              )}
            />
            {errors.email && (
              <InputError className="mt-2" message={errors.email.message} />
            )}
          </div>
          <div className="">
            <InputLabel
              className="!text-black-redx"
              htmlFor="reason"
              value="Why do you need access to Ainstein?"
            />
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <TextAreaInput
                  id="reason_request"
                  {...field}
                  className="mt-1 block w-full"
                  containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                  light
                  placeholder="Input your reason here"
                  rows={3}
                />
              )}
              rules={{ required: 'Reason is required' }}
            />
            {errors.reason && (
              <InputError className="mt-2" message={errors.reason.message} />
            )}
          </div>
          <div className="mt-30 flex items-center justify-end gap-x-10">
            <PrimaryButton
              className="ms-4 bg-indigo-800 px-20 py-10 text-12 hover:bg-indigo-700 active:bg-indigo-700"
              disabled={isSubmitting || !watch('reason') || disableButton}
            >
              Send Request
            </PrimaryButton>
          </div>
        </form>
      </div>
    </ModalCenter>
  );
};

export default RequestAccess;
