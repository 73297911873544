import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { SubmitIdeaExpansionParams } from '@/Types/IdeaExpansion';

const submit = async ({
  payload,
  projectSlug,
  archetypeId,
  funnel,
  recommendationNumber,
  id,
}: SubmitIdeaExpansionParams) => {
  try {
    if (id === -1) {
      const { data } = await goFetcher.post(
        `/projects/${projectSlug}/idea-expansion/submit`,
        {
          archetype_id: archetypeId,
          recommendation_number: recommendationNumber,
          funnel,
          content: payload,
        },
      );

      return data;
    }

    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/idea-expansion/update/${id}`,
      {
        archetype_id: archetypeId,
        recommendation_number: recommendationNumber,
        funnel,
        content: payload,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useSubmitIdeaExpansion = () => {
  return useMutation<any, Error, SubmitIdeaExpansionParams>({
    mutationFn: submit,
  });
};

export { useSubmitIdeaExpansion };
