import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { IdeaPersonalizationUpdateSelectedResponse } from '@/Types/IdeaPersonalization';

const patch = async ({
  id,
  projectSlug,
}: {
  id: number;
  projectSlug: string;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/idea-personalization/update-selected/${id}`,
      {},
    );

    return data as IdeaPersonalizationUpdateSelectedResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateSelected = () => {
  return useMutation<
    IdeaPersonalizationUpdateSelectedResponse,
    Error,
    { id: number; projectSlug: string }
  >({
    mutationFn: patch,
  });
};

export { useUpdateSelected };
