import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';

const deleteCompetitor = async ({
  id,
  brandName,
  projectSlug,
}: {
  id: number;
  brandName: string;
  projectSlug: string;
}) => {
  try {
    await goFetcher.delete(
      `/projects/${projectSlug}/competitor-analysis/delete/${id}`,
      {
        brand_name: brandName,
      },
    );
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useDeleteCompetitor = () => {
  return useMutation<
    any,
    Error,
    {
      id: number;
      brandName: string;
      projectSlug: string;
    }
  >({
    mutationFn: deleteCompetitor,
  });
};

export { useDeleteCompetitor };
