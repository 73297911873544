import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react/dist/iconify.js';
import { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CardTitle from '@/Components/CardTitle';
import MarkdownView from '@/Components/MardownView';
import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import { useUpdateSelectedIdeaAlignment } from '@/Hooks/IdeaAlignment';
import type { IdeaAlignmentContentData } from '@/Types/IdeaAlignment';
import {
  IdeaAlignment,
  type IdeaAlignmentSchema,
} from '@/Types/IdeaAlignment/schema';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

export interface CardHandles {
  submitForm: () => void;
}

const fieldsToDisplay = [
  { name: 'idea_title', label: 'Idea Title' },
  {
    name: 'idea_description',
    label: 'Idea Description',
  },
];

const Card = forwardRef<
  CardHandles,
  {
    user?: UserResponse;
    isDisabledSelectButton?: boolean;
    isEdited?: boolean;
    id?: number;
    project?: ProjectProps;
    totalData?: number;
    isFetching?: boolean;
    item?: IdeaAlignmentContentData;
    index?: number;
    isEditing?: boolean;
    isSelected?: boolean;
    isCanSelect?: boolean;
    onSubmitSuccess?: (
      data: IdeaAlignmentSchema,
      id: number,
      index: number,
    ) => void;
    onSelectSuccess?: (isShowPopUp: boolean) => void;
    refetchDiffArchetype?: () => void;
  }
>(
  (
    {
      user,
      id,
      project,
      totalData,
      item,
      isDisabledSelectButton = false,
      isEdited = false,
      isFetching = true,
      index = 0,
      isSelected = false,
      isEditing = false,
      isCanSelect = false,
      onSubmitSuccess,
      onSelectSuccess,
      refetchDiffArchetype,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedIdeaAlignment();

    const { handleSubmit, reset, control } = useForm<IdeaAlignmentSchema>({
      resolver: zodResolver(IdeaAlignment),
      defaultValues: {
        idea_description: item?.idea_description ?? '',
        idea_title: item?.idea_title ?? '',
      },
      mode: 'all',
    });

    const onSubmit = (data: IdeaAlignmentSchema) => {
      if (
        id === undefined ||
        index === undefined ||
        onSubmitSuccess === undefined
      )
        return;

      onSubmitSuccess(data, id, index);
    };

    useEffect(() => {
      if (isEditing) {
        reset({
          idea_description: item?.idea_description ?? '',
          idea_title: item?.idea_title ?? '',
        });
      }
    }, [isEditing, reset, item]);

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => {
          handleSubmit(onSubmit)();
        },
      }),
      [handleSubmit, onSubmit],
    );

    const handleSelected = (id: number) => {
      if (
        project === undefined ||
        user === undefined ||
        onSelectSuccess === undefined
      )
        return;

      const projectSlug = project.slug;

      triggerGTMEvent({
        event: `Select Idea Alignment`,
        eventCategory: `Button Select Idea Alignment Click`,
        eventAction: 'Click',
        eventLabel: 'Idea Alignment',
        userId: user.email,
        data: { type: isSelected ? 'Unselect' : 'Select', ...item },
      });

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: (data) => {
            onSelectSuccess(data.is_show_popup);
            refetchDiffArchetype?.();
          },
        },
      );
    };

    return (
      <div className="flex w-full flex-col pb-35 transition duration-500">
        <div className="px-35 py-15 text-center">
          {isFetching ? (
            <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
          ) : (
            <CardTitle
              id={id ?? 1}
              index={index}
              isEdited={isEdited}
              label="Idea Alignment"
              totalData={totalData ?? 1}
            />
          )}
        </div>
        <div
          className={`mb-16 grid grid-cols-1 gap-24 p-24 text-black-redx transition duration-500 ${
            isEditing && isSelected ? '!bg-transparent' : ''
          } ${
            isSelected
              ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
              : 'border-t-1 border-stroke-redx'
          }`}
        >
          {fieldsToDisplay.map(({ name, label }) => (
            <Controller
              key={name}
              control={control}
              name={name as keyof IdeaAlignmentSchema}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <CardInput
                    defaultValue={
                      item?.[name as keyof IdeaAlignmentSchema] || ''
                    }
                    error={error?.message}
                    isEditing={isEditing ?? false}
                    isFetching={isFetching}
                    onChange={onChange}
                    placeholder={`Input the ${label} here`}
                    rows={name === 'idea_description' ? 5 : 2}
                    title={label}
                    value={value}
                  />
                );
              }}
            />
          ))}
        </div>
        {item?.reason && !isEdited ? (
          <div className="mb-20 inline-flex w-full items-center gap-7 border-y-1 border-soft-purple-redx p-10 px-30">
            <div className="mx-20 w-20">
              <Icon
                className="size-20 text-black-redx"
                icon="lucide:message-circle-question"
              />
            </div>
            <div className="w-auto">
              <h1 className="text-12 font-semibold leading-24">
                How Ainstein aligned this Idea?
              </h1>

              <div className="text-12 font-normal leading-18 text-grey-redx">
                <MarkdownView content={item.reason} />
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex w-full justify-end px-1 pt-15">
          {id && id !== -1 && isCanSelect && (
            <SelectButton
              hasOne={isDisabledSelectButton && isSelected}
              index={index ?? 0}
              isEditing={isEditing ?? false}
              isLoading={isUpdatingSelected}
              isSelected={isSelected ?? false}
              onSelect={() => handleSelected(id)}
              section="idea-alignment"
            />
          )}
        </div>
      </div>
    );
  },
);

export default memo(Card);
