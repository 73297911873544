import { Icon } from '@iconify/react/dist/iconify.js';
import React from 'react';

import { useCopyLink } from '@/Hooks/useCopyLink';

const ButtonCopyLink: React.FC<{ value: string }> = ({ value }) => {
  const { isCopied, copyLink } = useCopyLink();

  const handleClick = () => {
    copyLink(value);
  };

  if (isCopied) {
    return (
      <div className="flex items-center justify-center gap-5 text-[#066C0A]">
        <Icon
          className="size-24 text-[#066C0A]"
          icon="mdi:check-circle-outline"
        />
        <span className="text-14 font-semibold leading-18">Link Copied</span>
      </div>
    );
  }

  return (
    <button
      className="flex items-center justify-center gap-5 text-blue-redx"
      id="btn-copy-link"
      onClick={handleClick}
    >
      <Icon className="size-24 text-blue-redx" icon="ph:link" />
      <span className="text-14 font-semibold leading-18">Copy Link</span>
    </button>
  );
};

export default ButtonCopyLink;
