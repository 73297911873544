/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Icon } from '@iconify/react/dist/iconify.js';
import type { InputHTMLAttributes } from 'react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useClickOutside } from '@/Hooks/useClickOutside';
import { useTag } from '@/Hooks/useTag';
import type { UserResponse } from '@/Types/user';
import { isValidEmail } from '@/Utils/helper';

interface ITag {
  isFocused?: boolean;
  containerClassName?: string;
  error?: string;
  initialTags?: string[];
  onChangeTags?: (tags: string[]) => void;
  initialUsers: UserResponse[];
  excludedUsers?: UserResponse[];
  isClearTags?: boolean;
  onClearTagsComplete?: () => void;
}

export default forwardRef(function TextInputTags(
  {
    type = 'text',
    className = '',
    containerClassName = '',
    isFocused = false,
    placeholder = '',
    error = '',
    initialTags = [],
    initialUsers = [],
    excludedUsers = [],
    isClearTags = false,
    onChangeTags = () => {},
    onClearTagsComplete = () => {},
    ...props
  }: InputHTMLAttributes<HTMLInputElement> & ITag,
  ref,
) {
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [users, setUsers] = useState<UserResponse[]>(initialUsers);
  const [approvedUsers, setApprovedUsers] = useState<UserResponse[]>([]);
  const [whitelistEmails, setWhitelistEmails] = useState<string[]>([]);
  const elementRef = useRef<HTMLDivElement>(null);
  const tagUlRef = useRef<HTMLUListElement>(null);

  const { tags, tagInput, removeTag, inputKeyDown, addTag, clearTags } =
    useTag(initialTags);

  useEffect(() => {
    // set the approvedUsers state where email is not in the exludedUsers
    setUsers(
      initialUsers.filter(
        (user) => !excludedUsers.map((user) => user.email).includes(user.email),
      ),
    );
  }, [excludedUsers]);

  useImperativeHandle(ref, () => ({
    focus: () => tagInput.current?.focus(),
  }));

  const handleClickOutside = () => {
    setOpenAutoComplete(false);
  };

  useClickOutside(elementRef, handleClickOutside);

  useEffect(() => {
    if (isFocused) {
      tagInput.current?.focus();
    }
    onChangeTags(tags);
  }, [tags]);

  useEffect(() => {
    if (isClearTags) {
      clearTags();
      onClearTagsComplete();
    }
  }, [isClearTags, onClearTagsComplete]);

  // filter users by name or email except the selected tags
  const filterUsers = (users: UserResponse[], query: string) => {
    return users.filter((user) => {
      return (
        !tags.includes(user.email) &&
        (user.name?.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase()))
      );
    });
  };

  const handleAddTag = (user: UserResponse) => {
    addTag(user.email);
    setOpenAutoComplete(false);
    if (tagInput.current) {
      tagInput.current.value = '';
      tagInput.current.focus();
    }
    if (tagUlRef.current) {
      tagUlRef.current.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleAddTagFromWhitelist = (email: string) => {
    // add the first email in the whitelistEmails
    addTag(email);
    // clear whitelistEmails
    setWhitelistEmails([]);
    // clear the input value
    if (tagInput.current) {
      tagInput.current.value = '';
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    inputKeyDown(e);
    onChangeTags(tags);

    const val = (e.target as HTMLInputElement).value;
    // check if there is whitelistEmails and key is Tab or Space with some value
    if (
      !tags.includes(val) &&
      !excludedUsers.map((user) => user.email).includes(val)
    ) {
      if (whitelistEmails.length > 0) {
        if (e.key === 'Tab') {
          handleAddTagFromWhitelist(whitelistEmails[0]);
          // prevent default
          e.preventDefault();
        }
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpenAutoComplete(true);
    setApprovedUsers(filterUsers(users, e.target.value));

    if (e.target.value.length <= 0) {
      setOpenAutoComplete(false);
    }

    // check if value length > 0 and is valid email and value is not in the tags and not in exludedUsers and not in approvedUsers
    if (
      e.target.value.length > 0 &&
      isValidEmail(e.target.value) &&
      !tags.includes(e.target.value) &&
      !excludedUsers.map((user) => user.email).includes(e.target.value) &&
      !approvedUsers.map((user) => user.email).includes(e.target.value) &&
      !users.map((user) => user.email).includes(e.target.value)
    ) {
      // set timeout then add the email to the whitelistEmails
      setTimeout(() => {
        // filter duplicate email
        if (!whitelistEmails.includes(e.target.value)) {
          setWhitelistEmails([e.target.value]);
        }
      }, 500);
    } else {
      // clear the whitelistEmails
      setWhitelistEmails([]);
    }
  };

  return (
    <div
      ref={elementRef}
      className={`relative flex flex-wrap bg-transparent ${
        containerClassName
      }${error ? ' border-red-500' : ''}`}
    >
      <ul
        ref={tagUlRef}
        className="m-0 inline-flex max-h-100 w-full flex-wrap items-center overflow-y-auto p-0"
      >
        {tags.map((tag, i) => (
          <li
            key={tag}
            className="me-5 mt-5 flex h-20 list-none items-center gap-4 rounded-4 bg-soft-purple-redx px-8 py-2 text-12 text-blue-purple-redx last:items-start"
          >
            {tag}
            <Icon
              className="size-15"
              icon="mdi:remove"
              onClick={() => {
                removeTag(i);
              }}
            />
          </li>
        ))}
      </ul>
      <div className="grow bg-none" />
      <input
        ref={tagInput}
        {...props}
        className={`!focus:border-transparent !focus:ring-0 w-full !border-transparent bg-transparent placeholder:text-14 placeholder:text-lightplaceholder-redx ${
          className
        }`}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        type={type}
      />
      {openAutoComplete && (
        <div
          className=" absolute top-full z-50 mt-5 w-full rounded-10 bg-white text-black-redx"
          style={{
            boxShadow: '0px 0px 12px 0px #00000026',
          }}
        >
          <div className="flex max-h-250 flex-col overflow-y-auto overscroll-auto">
            {approvedUsers.map((user, _) => (
              <div
                key={user.email}
                className="flex cursor-pointer items-center gap-10 rounded-10 p-10 hover:bg-soft-purple-redx"
                onClick={() => handleAddTag(user)}
              >
                <img
                  alt="image"
                  className="size-32 rounded-full"
                  src={user.avatar_url}
                />
                <div className="font-medium">
                  <div className="mb-5 text-14 font-semibold leading-16 text-black-redx">
                    {user.name}
                  </div>
                  <div className="text-12 leading-14 text-grey-redx">
                    {user.email}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* show whitelist email suggestion when openAutoComplete and approvedUsers is < 0 */}
      {approvedUsers.length <= 0 && whitelistEmails.length > 0 && (
        <div
          className="absolute top-full z-50 mt-5 w-full rounded-10 bg-white text-black-redx"
          style={{
            boxShadow: '0px 0px 12px 0px #00000026',
          }}
        >
          <div className="flex max-h-250 flex-col overflow-y-auto overscroll-auto">
            {whitelistEmails.map((email, _) => (
              <div
                key={email}
                className="flex cursor-pointer items-center gap-10 rounded-10 p-10 hover:bg-soft-purple-redx"
                onClick={() => handleAddTagFromWhitelist(email)}
              >
                <Icon className="size-40" icon="mdi:user-circle" />
                <div className="font-medium">
                  <div className="mb-5 text-14 font-semibold leading-16 text-black-redx">
                    {email}
                  </div>
                  <div className="text-12 leading-14 text-grey-redx">
                    {email}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});
