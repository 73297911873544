import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { SectionResponseMap } from '@/Types/TargetAudience';

const getLists = async <T extends keyof SectionResponseMap>(
  projectSlug: string,
  activeSection: T,
): Promise<SectionResponseMap[T]> => {
  // Mapping endpoint berdasarkan section
  const endpoints: Record<keyof SectionResponseMap, string> = {
    audience_insight: `/projects/${projectSlug}/target-audience/audience-insight`,
    perception_analysis: `/projects/${projectSlug}/target-audience/perception-analysis`,
    behavioral_trends: `/projects/${projectSlug}/target-audience/behavioral-trend`,
    media_consumption_patterns: `/projects/${projectSlug}/target-audience/media-consumption-pattern`,
  };

  const url = endpoints[activeSection];

  if (!url) {
    throw new Error('Invalid section provided');
  }

  try {
    const response = await goFetcher.get(url);
    return response.data as SectionResponseMap[T]; // Return tipe yang sesuai
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useTargetAudience = <T extends keyof SectionResponseMap>(
  projectSlug: string,
  activeSection: T,
): UseQueryResult<SectionResponseMap[T], Error> => {
  return useQuery<SectionResponseMap[T], Error>({
    queryKey: ['targetAudience', activeSection, projectSlug],
    queryFn: () => getLists(projectSlug, activeSection),
    refetchOnWindowFocus: false,
  });
};

export { useTargetAudience };
