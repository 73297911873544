import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { RecommendationContent } from '@/Types/KeyTouchPoint';

const updateKeyTouchPoint = async ({
  payload,
  id,
  archetypeId,
  funnel,
  projectSlug,
}: {
  payload: Omit<RecommendationContent, 'recommendation_number'>;
  id: number;
  archetypeId: number;
  funnel: string;
  projectSlug: string;
}) => {
  try {
    if (id === -1) {
      const url = `/projects/${projectSlug}/key-touch-point/submit`;
      const { data } = await goFetcher.post(url, {
        content: payload,
        archetype_id: archetypeId,
        funnel,
      });
      return data;
    }

    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/key-touch-point/update/${id}`,
      {
        content: payload,
        archetype_id: archetypeId,
        funnel,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateKeyTouchPoint = () => {
  return useMutation<
    any,
    Error,
    {
      payload: Omit<RecommendationContent, 'recommendation_number'>;
      id: number;
      archetypeId: number;
      funnel: string;
      projectSlug: string;
    }
  >({
    mutationFn: updateKeyTouchPoint,
  });
};

export { useUpdateKeyTouchPoint };
