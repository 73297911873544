import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import CardTitle from '@/Components/CardTitle';
import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import type { ProjectProps } from '@/Types/Projects';
import type { Idea } from '@/Types/SearchabilityContent';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

export interface CardHandles {
  submitForm: () => void;
}

const schema = z.object({
  who_should_say_it: z.string().min(4, 'Who Should Say it is required'),
  category: z.string().min(4, 'Category is required'),
  content_idea: z.string().min(4, 'Content Idea is required'),
  content_format: z.string().min(4, 'Format is required'),
});

const Card = forwardRef<
  CardHandles,
  {
    user: UserResponse;
    recommendationNumber: number;
    id: number | string;
    archetypeId: number;
    framework: string;
    title: string;
    index: number;
    project: ProjectProps;
    item?: Omit<Idea, 'value'>;
    isSelected?: boolean;
    isEditing?: boolean;
    isFetching?: boolean;
    isEditor: boolean;
    totalData?: number;
    onItemChange?: (
      archetypeId: number,
      recommendationNumber: number,
      title: string,
      id: string,
      checked: boolean,
    ) => void;
    onHandleAddOrUpdateIdea: (
      archetypeId: number,
      framework: string,
      title: string,
      newItem: Omit<Idea, 'value'>,
      index?: number,
    ) => void;
  }
>(
  (
    {
      recommendationNumber,
      title,
      archetypeId,
      framework,
      onItemChange,
      user,
      id,
      index,
      project,
      item,
      onHandleAddOrUpdateIdea,
      isEditing = false,
      isEditor,
      isFetching = false,
      isSelected = false,
      totalData = 0,
    },
    ref,
  ) => {
    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
      clearErrors,
    } = useForm<Omit<Idea, 'value'>>({
      resolver: zodResolver(schema),
      defaultValues: {
        who_should_say_it: item?.who_should_say_it ?? '',
        category: item?.category ?? '',
        content_idea: item?.content_idea ?? '',
        content_format: item?.content_format ?? '',
      },
    });

    useEffect(() => {
      setValue('who_should_say_it', item?.who_should_say_it ?? '');
      setValue('category', item?.category ?? '');
      setValue('content_idea', item?.content_idea ?? '');
      setValue('content_format', item?.content_format ?? '');
      clearErrors();
    }, [item, setValue, isEditing]);

    const onSubmit = (data: Omit<Idea, 'value'>) => {
      if (!item) return;
      onHandleAddOrUpdateIdea(
        archetypeId,
        framework,
        title,
        {
          id: item.id,
          who_should_say_it: data.who_should_say_it,
          category: data.category,
          content_idea: data.content_idea,
          content_format: data.content_format,
          is_checked: item.is_checked,
        },
        index,
      );
    };

    const handleSelected = (id: number) => {
      if (!item) return;

      onItemChange?.(
        archetypeId,
        recommendationNumber,
        title,
        id.toString(),
        !item.is_checked,
      );

      triggerGTMEvent({
        event: `Select searchability content`,
        eventCategory: `Button Select searchability content Click`,
        eventAction: 'Click',
        eventLabel: 'Searchability content',
        userId: user.email,
        data: {
          slug: project.slug,
          archetypeId,
          framework,
          title,
          isSelected: !item.is_checked ? 'Unselect' : 'Select',
          ...item,
        },
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    return (
      <>
        {!isFetching ? (
          <div className="w-full text-center">
            <CardTitle
              id={+id}
              index={index}
              isEdited={item?.is_edited ?? false}
              label="Content Idea"
              totalData={totalData ?? 1}
            />
          </div>
        ) : (
          <div className="inline-flex w-full items-center justify-center gap-8">
            <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
            <div className="h-20 w-1 border-1 border-stroke-redx" />
            <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
          </div>
        )}

        <div
          className={`mt-10 flex w-full flex-col gap-y-8 px-18 py-20 text-black-redx ${
            isEditing && isSelected ? '!bg-transparent' : ''
          }
        ${
          isSelected && !isFetching
            ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
            : 'border-t-1 border-stroke-redx'
        }`}
        >
          <div className="grid grid-cols-2 gap-x-24 gap-y-15 pb-8">
            <Controller
              control={control}
              name="who_should_say_it"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.who_should_say_it?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input who should say it here"
                  showTitle
                  title="Who Should Say It"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.category?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input category here"
                  showTitle
                  title="Category"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="content_idea"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.content_idea?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input content Idea here"
                  showTitle
                  title="Content Idea"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="content_format"
              render={({ field }) => (
                <CardInput
                  defaultValue={field.value}
                  error={errors.content_format?.message}
                  isEditing={isEditing}
                  isFetching={isFetching}
                  placeholder="Input format here"
                  showTitle
                  title="Format"
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <div className="flex w-full justify-end px-1 py-15">
          {id !== -1 && isEditor && !isEditing && (
            <SelectButton
              hasOne={false}
              index={index}
              isEditing={isEditing}
              isLoading={false} // to do change with state
              isSelected={isSelected}
              onSelect={() => handleSelected(+id)}
              section="searchability-content"
            />
          )}
        </div>
      </>
    );
  },
);

export default Card;
