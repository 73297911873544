import SliderContainer from '@/Components/Projects/SliderContainer';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

import Card from './Card';

interface SkeletonProps {
  project: ProjectProps;
  user: UserResponse;
}

const Skeleton = ({ project, user }: SkeletonProps) => {
  return (
    <div className="block">
      <div className="h-55 w-full animate-pulse rounded-8 bg-soft-purple-redx" />
      <div className="mt-20" />
      <SliderContainer>
        <Card
          id={1}
          index={0}
          isEditing={false}
          isFetching
          isSelected={false}
          item={{
            challenge: '',
            communication_task: '',
          }}
          onSelectSuccess={() => {}}
          onSubmitSuccess={() => {}}
          project={project}
          totalData={1}
          user={user}
        />
      </SliderContainer>
      <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
      </div>
      <div className="flex w-full pb-60">
        <div className="inline-flex gap-10">
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
