import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';
import React from 'react';

import type { ProjectProps } from '@/Types/Projects';
import type { Framework } from '@/Types/SelectedContentIdeas';
import type { UserResponse } from '@/Types/user';

import IdeasList from './IdeaList';

const CategoryTabs = ({
  archetypeId,
  stepDatum,
  recommendationNumber,
  getActiveCategoryTab,
  updateActiveCategoryTab,
  updateSelectedTitle,
  getDisplayTitle,
  isEditor,
  user,
  project,
  addTabCategoryRef,
  onItemChange,
}: {
  recommendationNumber: number;
  archetypeId: number;
  stepDatum: Framework;
  getDisplayTitle: (
    archetypeId: number,
    framework: string,
    data: any[],
  ) => string;
  getActiveCategoryTab: (archetypeId: number, framework: string) => number;
  updateActiveCategoryTab: (
    archetypeId: number,
    framework: string,
    value: number,
  ) => void;
  updateSelectedTitle: (
    archetypeId: number,
    framework: string,
    title: string,
  ) => void;
  isEditor: boolean;
  user: UserResponse;
  project: ProjectProps;
  addTabCategoryRef: (
    archetypeId: number,
    framework: string,
    index: number,
    element: HTMLElement | null,
  ) => void;
  onItemChange: (
    archetypeId: number,
    title: string,
    framework: string,
    recommendationNumber: number,
    id: number,
    checked: boolean,
  ) => void;
}) => {
  return (
    <TabGroup
      className="flex w-full flex-row gap-24"
      defaultIndex={getActiveCategoryTab(archetypeId, stepDatum.framework)}
      onChange={(newIndex) =>
        updateActiveCategoryTab(archetypeId, stepDatum.framework, newIndex)
      }
      selectedIndex={getActiveCategoryTab(archetypeId, stepDatum.framework)}
    >
      <TabList className="flex w-1/3 max-w-250 flex-col items-start justify-start gap-12 border-r-1 border-r-stroke-redx">
        {stepDatum.data.map((dataEntry, index) => (
          <Tab
            key={`${archetypeId}_${stepDatum.framework}_${index}`}
            ref={(el) =>
              addTabCategoryRef(archetypeId, stepDatum.framework, index, el)
            }
            className="inline-flex gap-1 py-12 pr-12 text-start text-black-redx data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx"
            onClick={() =>
              updateSelectedTitle(
                archetypeId,
                stepDatum.framework,
                dataEntry.title,
              )
            }
          >
            <p>{index + 1}. </p>
            <p>{dataEntry.title}</p>
          </Tab>
        ))}
      </TabList>

      <TabPanels className="flex w-4/5 grow flex-col">
        <IdeasList
          archetypeId={archetypeId}
          getDisplayTitle={getDisplayTitle}
          isEditor={isEditor}
          onItemChange={onItemChange}
          project={project}
          recommendationNumber={recommendationNumber}
          stepDatum={stepDatum}
          user={user}
        />
      </TabPanels>
    </TabGroup>
  );
};

export default CategoryTabs;
