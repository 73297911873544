import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { IdeaPersonalizationSchema } from '@/Types/IdeaPersonalization/schema';

const update = async ({
  payload,
  projectSlug,
  id,
  archetypeId,
}: {
  payload: IdeaPersonalizationSchema;
  projectSlug: string;
  id: number;
  archetypeId: number;
}) => {
  try {
    if (id === -1) {
      const { data } = await goFetcher.post(
        `/projects/${projectSlug}/idea-personalization/submit`,
        {
          content: payload,
          archetype_id: archetypeId,
        },
      );

      return data;
    }

    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/idea-personalization/update/${id}`,
      {
        content: payload,
        archetype_id: archetypeId,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateIdeaPersonalization = () => {
  return useMutation<
    any,
    Error,
    {
      payload: IdeaPersonalizationSchema;
      projectSlug: string;
      id: number;
      archetypeId: number;
    }
  >({
    mutationFn: update,
  });
};

export { useUpdateIdeaPersonalization };
