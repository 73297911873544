import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';

import type {
  AudienceInsights,
  DesiredResponse,
  KeyHighlight,
  SelectedAudienceArchetypes,
} from '@/Types/OnePage/one-page-strategy';

import CardInput from './CardInput';

// Map propertyKey to its corresponding type
export interface PropertyKeyMap {
  audience_insights: AudienceInsights;
  desired_response: DesiredResponse;
  key_highlight: KeyHighlight;
  product_feature_benefit: string;
  strategic_statement: string;
}

interface SectionRendererProps<K extends keyof PropertyKeyMap> {
  cols: 1 | 2;
  propertyKey: K;
  items: SelectedAudienceArchetypes[];
  control: any;
  isEditing: boolean;
  isFetching: boolean;
  fields: {
    name: keyof PropertyKeyMap[K];
    label: string;
    placeholder: string;
  }[];
}

const SectionRenderer = <K extends keyof PropertyKeyMap>({
  cols,
  items,
  propertyKey,
  control,
  isEditing,
  isFetching,
  fields,
}: SectionRendererProps<K>) => {
  return (
    <>
      {items.map((item, index) => {
        // Dynamically determine the default value for the current propertyKey
        const property = item.onepage_content[propertyKey] as PropertyKeyMap[K];

        return (
          <Fragment key={index}>
            <h3 className="pb-4 text-15 font-bold text-blue-redx">
              {`Archetype ${index + 1} - ${item.archetype_name || ''}`}
            </h3>
            <div
              className={`grid ${
                cols === 2 ? 'grid-cols-2' : 'grid-cols-1'
              } gap-x-24 gap-y-15 pb-8`}
            >
              {typeof property === 'string' ? (
                <Controller
                  control={control}
                  name={`selected_audience_archetypes.${index}.onepage_content.${propertyKey}`}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CardInput
                      defaultValue={property || ''}
                      error={error?.message}
                      isEditing={isEditing}
                      isFetching={isFetching}
                      onChange={onChange}
                      placeholder={`Input ${propertyKey} here`}
                      showTitle
                      title={propertyKey
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                      value={typeof value === 'string' ? value : ''}
                    />
                  )}
                />
              ) : (
                // Handle object-based properties with fields
                fields?.map(({ name, label, placeholder }) => (
                  <Controller
                    key={`${propertyKey}-${String(name)}`}
                    control={control}
                    name={`selected_audience_archetypes.${index}.onepage_content.${propertyKey}.${String(
                      name,
                    )}`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CardInput
                        defaultValue={
                          typeof property[name as keyof PropertyKeyMap[K]] ===
                          'string'
                            ? (property[
                                name as keyof PropertyKeyMap[K]
                              ] as string)
                            : ''
                        }
                        error={error?.message}
                        isEditing={isEditing}
                        isFetching={isFetching}
                        onChange={onChange}
                        placeholder={placeholder}
                        showTitle
                        title={label}
                        value={typeof value === 'string' ? value : ''}
                      />
                    )}
                  />
                ))
              )}
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default SectionRenderer;
