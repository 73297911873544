import { Tab, TabList } from '@headlessui/react';
import React, { useRef } from 'react';

import type { SectionList } from '@/Types/tabs';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

interface TabsProps {
  user: UserResponse;
  sections: SectionList[];
}

const Tabs: React.FC<TabsProps> = ({ sections, user }) => {
  const tabsRef = useRef<(HTMLElement | null)[]>([]);

  const handleTabClicked = (title: string, value: string) => {
    triggerGTMEvent({
      event: `Tab ${value} Insight`,
      eventCategory: `Tab ${value} Click`,
      eventAction: 'Click',
      eventLabel: title,
      userId: user.email,
      data: { title, value },
    });
  };

  return (
    <div className="relative flex items-center">
      <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
        {sections.map((section, index) => (
          <Tab
            key={index}
            ref={(el) => {
              tabsRef.current[index] = el;
            }}
            className="w-full min-w-5/24 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
            onClick={() => handleTabClicked(section.title, section.value)}
          >
            <div className="text-sm h-fit inline-flex w-full items-center justify-center gap-8 py-2 pb-12 font-medium focus:outline-none">
              <div className="flex flex-col gap-8">{section.title}</div>
            </div>
          </Tab>
        ))}
      </TabList>
    </div>
  );
};

export default Tabs;
