import { zodResolver } from '@hookform/resolvers/zod';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Content from '@/Components/Content';
import InputLabel from '@/Components/InputLabel';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import TextAreaInput from '@/Components/TextAreaInput';
import type { ProjectAction } from '@/Context/Actions/project-actions';
import {
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useObjectiveSubmission } from '@/Hooks/Submission';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import type { SectionHandles } from '@/Types/Submission';
import {
  ObjectiveSubmission,
  type ObjectiveSubmissionSchema,
} from '@/Types/Submission/schema';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import ActionButtons from './Action';

const fieldsToDisplay = [
  { name: 'objective', label: 'Main Objective', required: true },
  {
    name: 'second_objective',
    label: 'Second Objective',
    required: true,
  },
  {
    name: 'kpi',
    label: 'Campaign KPI Metrics',
    required: false,
  },
];

interface ObjectiveSectionProps {
  projectSlug: string;
  user: UserResponse;
  isComplete: boolean;
  data: {
    objective: string;
    second_objective: string;
    kpi: string;
  };
  isActive: boolean;
  isEditor: boolean;
  isEditingCount: number;
  onRefetchSubmission: () => void;
  onDispatch: React.Dispatch<ProjectAction>;
  onSetIsEditingCount: React.Dispatch<React.SetStateAction<number>>;
}

const ObjectiveSection = forwardRef<SectionHandles, ObjectiveSectionProps>(
  (
    {
      projectSlug,
      user,
      data,
      isActive,
      isEditor,
      isEditingCount,
      isComplete,
      onDispatch,
      onRefetchSubmission,
      onSetIsEditingCount,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const navigate = useNavigate();
    const project = useMemo(() => state.project, [state.project]);
    const isEnabledHistoryStatus = useMemo(
      () => state.isEnabledHistoryStatus,
      [state.isEnabledHistoryStatus],
    );
    const { mutate } = useObjectiveSubmission();
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const { mutate: generate } = useGenerate();
    const { isKeyInsightsComplete } = useHistoryStatus(
      project,
      isEnabledHistoryStatus,
    );

    const defaultValues = useMemo(
      () => ({
        objective: data?.objective ?? '',
        second_objective: data?.second_objective ?? '',
        kpi: data?.kpi ?? '',
      }),
      [data],
    );

    const {
      handleSubmit,
      reset,
      control,
      formState: { isLoading, isValid },
    } = useForm<ObjectiveSubmissionSchema>({
      resolver: zodResolver(ObjectiveSubmission),
      defaultValues,
      mode: 'all',
    });

    const toggleIsEditing = useCallback(
      (isEdit: boolean) => {
        setIsEditing(isEdit);
        if (onSetIsEditingCount) {
          const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
          onSetIsEditingCount(newCount);
        }
      },
      [isEditingCount, onSetIsEditingCount],
    );

    const onSubmit = useCallback(
      (data: ObjectiveSubmissionSchema) => {
        triggerGTMEvent({
          event: 'Edit Submission Objective Information',
          eventCategory: 'Button Edit Submission Objective Information Click',
          eventAction: 'Click',
          eventLabel: 'Submission Objective Information',
          userId: user.email,
          data,
        });

        toggleIsEditing(true);
        mutate(
          {
            payload: data,
            projectSlug,
          },
          {
            onSuccess: () => {
              onRefetchSubmission();
              toggleIsEditing(false);
              if (isKeyInsightsComplete.status) {
                triggerGTMEvent({
                  event: 'Generate Key Insight',
                  eventCategory: 'Button Generate Key Insight Click',
                  eventAction: 'Click',
                  eventLabel: 'Generate Key Insight',
                  userId: user.email,
                  data: projectSlug,
                });

                generate(
                  {
                    payload: {
                      generate_more: '',
                      regenerate_prompt: '',
                      status: 'regenerate from submission',
                    },
                    projectSlug,
                    section: 'key-insight',
                  },
                  {
                    onSuccess: () => {
                      onDispatch(setEnableHistoryStatus(true));
                    },
                  },
                );

                return;
              }
              navigate(`/${projectSlug}/product-research`);
            },
          },
        );
      },
      [isKeyInsightsComplete.status],
    );

    useImperativeHandle(ref, () => ({
      save: async () => {
        await handleSubmit(onSubmit)();
      },
      element: divRef.current, // Ekspos elemen HTMLDivElement
    }));

    useEffect(() => {
      if (isEditing) {
        onDispatch(setEnableHistoryStatus(false));
        reset({
          kpi: data?.kpi ?? '',
          objective: data?.objective ?? '',
          second_objective: data?.second_objective ?? '',
        });
      } else {
        onDispatch(setEnableHistoryStatus(true));
      }
    }, [isEditing]);

    const handleCancel = () => {
      setIsEditing(false);
    };

    const isSaveDisabled = useMemo(() => !isValid, [isValid]);

    const handleSave = useCallback(async () => {
      if (isKeyInsightsComplete.isQuerying) {
        onDispatch(
          saveSubInformation(
            `{text-error-redx} Please wait key insight is still generating`,
          ),
        );
        return;
      }

      if (isKeyInsightsComplete.status) {
        setIsModalOpen(true);
        return;
      }
      await handleSubmit(onSubmit)();
    }, [isKeyInsightsComplete.status, isKeyInsightsComplete.isQuerying]);

    return (
      <div
        ref={divRef}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Campaign Objective
          </h1>
          <ActionButtons
            isActive={isActive && isComplete}
            isEditing={isEditing}
            isEditor={isEditor}
            isFetching={false}
            isValid={isSaveDisabled}
            onCancel={handleCancel}
            onEdit={() => setIsEditing(true)}
            onSave={handleSave}
          />
        </div>
        <div className="grid w-full grid-cols-2 gap-24">
          {fieldsToDisplay.map(({ name, label, required }) => {
            return (
              <div key={name} className="flex flex-col gap-10">
                <InputLabel optional={!required} value={label} />
                {isEditing || !isComplete ? (
                  <Controller
                    control={control}
                    name={name as keyof ObjectiveSubmissionSchema}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextAreaInput
                        autoComplete="off"
                        className="overflow-custom mt-1 block w-full resize-none !text-14"
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        error={error?.message}
                        onChange={onChange}
                        placeholder={`Input the ${label} here`}
                        rows={2}
                        value={value}
                      />
                    )}
                  />
                ) : (
                  <Content
                    isFetching={false}
                    isMarkDown={false}
                    value={
                      data?.[name as keyof ObjectiveSubmissionSchema] || ''
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
        <ChangingImpactModal
          handleSubmit={async () => {
            await handleSubmit(onSubmit)();
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          isRegenerating={isLoading}
          onClose={() => {
            handleCancel();
            toggleIsEditing(true);
            setIsModalOpen(false);
          }}
          section="product-information"
          visibleSections={[
            'Challenge & Communication Task',
            'One-Page Strategy',
            'Key Touch Point',
            'Idea Alignment',
            'Idea Personalization',
            'Idea Expansion',
            'Searchability Content',
            'Discoverability Content',
            'Credibility Content',
            'Selected Content Ideas',
          ]}
        />
      </div>
    );
  },
);

export default memo(ObjectiveSection);
