import Wrapper from '@/Pages/IdeaSubmission/Wrapper';

const IdeaSubmissionRoutes = [
  {
    path: '/:project_slug/creative-idea',
    element: <Wrapper />,
  },
];

export default IdeaSubmissionRoutes;
