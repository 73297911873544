import { z } from 'zod';

// ExternalUser schema
const ExternalUser = z.object({
  email: z.string().email('Invalid email format').optional(), // Optional email
  roles: z.array(z.string()).nonempty('Roles cannot be empty').optional(), // Optional roles
});

// UserPayload schema
const UserPayload = z.object({
  id: z.number().positive('ID must be a positive number').optional(), // Optional ID
  email: z.string().email('Invalid email format').optional(), // Optional email
  roles: z.array(z.string()).nonempty('Roles cannot be empty').optional(), // Optional roles
  error: z.string().optional(), // Optional error
});

// Project schema
export const Project = z.object({
  project_name: z.string().min(4, 'Project name cannot be empty'), // Non-empty string
  product_name: z.string().min(4, 'Product name cannot be empty'), // Non-empty string
  is_released: z.enum(['true', 'false'], {
    errorMap: (_issue, _ctx) => ({
      message: 'Value must be either "true" or "false"',
    }),
  }), // Boolean
  slug: z.string().min(1, 'Slug cannot be empty'), // Non-empty string
  members: z.array(UserPayload).optional(), // Optional members
  external_members: z.array(ExternalUser).optional(), // Optional external members
  emails: z.array(z.string().email('Invalid email format')).optional(), // Optional emails
  roles: z.array(z.string().min(1, 'Roles cannot be empty')).optional(), // Optional roles
});

// Infer the TypeScript types
export type ProjectSchema = z.infer<typeof Project>;
