import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MarkdownEditor from '@/Components/MarkdownEditor';
import MarkdownEditorReadOnly from '@/Components/MarkdownEditorReadOnly';
import NotDataFound from '@/Components/NotDataFound';
import EditButton from '@/Components/Projects/EditButton';
import SourcesList from '@/Components/Projects/SourcesList';
import { useUpdateMarketResearch } from '@/Hooks/MarketResearch';
import type { SourcesType } from '@/Types/Projects';

interface ContentSectionProps {
  projectSlug?: string;
  id?: number;
  activeSection: string;
  isEditor?: boolean;
  isLoading?: boolean;
  content?: string;
  message?: string;
  sources?: SourcesType[];
  refetch?: () => void;
  onEditingChange?: (isEditing: boolean) => void;
  setShowAlertGenerateModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContentSection: React.FC<ContentSectionProps> = ({
  id,
  projectSlug,
  activeSection,
  isEditor = false,
  isLoading = false,
  content = '',
  message = '',
  sources = [],
  refetch,
  onEditingChange,
  setShowAlertGenerateModal,
}) => {
  const navigate = useNavigate();
  const { mutate: updateMarketResearch, isPending: isUpdating } =
    useUpdateMarketResearch();

  const [isEditing, setIsEditing] = useState(false);
  const [localContent, setLocalContent] = useState(content);

  useEffect(() => {
    setLocalContent(content);
  }, [content]);

  const toggleIsEditing = () => {
    setIsEditing((prev) => {
      const newEditingState = !prev;
      if (onEditingChange) {
        onEditingChange(newEditingState);
      }
      return newEditingState;
    });
  };

  const handleCancelEdit = () => {
    setLocalContent(content);
    toggleIsEditing();
  };

  const handleSave = () => {
    updateMarketResearch(
      {
        content: localContent,
        id: id!,
        projectSlug: projectSlug!,
        activeSection,
      },
      {
        onSuccess(data) {
          data.is_regenerate_key_insight && setShowAlertGenerateModal?.(true);
          refetch!();
          handleCancelEdit();
        },
      },
    );
  };

  if (message === 'No data found') {
    return (
      <NotDataFound
        buttonLabel="Go to Submission"
        description="Click the button below to generate a product research in Submission page."
        onClick={() => navigate(`/${projectSlug}/submission`)}
        title="No Data Found."
      />
    );
  }

  return (
    <>
      {isEditing ? (
        <div className="mb-12">
          <MarkdownEditor
            content={localContent}
            onChange={(html) => setLocalContent(html)}
          />
        </div>
      ) : (
        <>
          {!isLoading && content && (
            <MarkdownEditorReadOnly content={content} />
          )}
          {isLoading && (
            <div className="flex flex-col gap-20">
              {Array.from({ length: 8 }).map((_, index) => (
                <div
                  key={index}
                  className={`full w- h-1${index === 7 ? '2/3' : 'full'} animate-pulse rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx py-4`}
                />
              ))}
            </div>
          )}
        </>
      )}

      <div className="my-24 flex w-full items-center justify-end gap-x-15">
        {isEditor &&
          (isEditing ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={handleCancelEdit}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className={`inline-flex items-center gap-x-4 ${
                  isUpdating ? 'text-gray-600' : 'text-blue-redx'
                } cursor-pointer`}
                disabled={isUpdating}
                id={`btn-edit-${activeSection.replace('_', '-')}`}
                onClick={handleSave}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>

      <div className="mr-1 flex w-full pb-60">
        {!isLoading && sources && <SourcesList sources={sources} />}
        {isLoading && (
          <div className="inline-flex gap-10">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ContentSection;
