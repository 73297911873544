import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import {
  saveInformation,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import { useCtaAction, useIdeaCreative } from '@/Hooks/IdeaSubmission';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type { SectionHandles } from '@/Types/Submission';

import CreativeIdea from './Components/CreativeIdea';

const Index = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);

  const navigate = useNavigate();
  const [isEditingCount, setIsEditingCount] = useState(0);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);

  const { data, refetch, isPending, isFetching } = useIdeaCreative(
    project.slug,
  );

  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Creative');
  const cardRefs = useRef<SectionHandles | null>(null);

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Generate Idea Alignment',
    isActive: true,
    isGenerate: true,
    isInactive: false,
    isDisabled: false,
  });

  const { data: differentArchetypes } = useDifferentArchetypes(project.slug);

  useRoutePrev({
    id: 'btn-creative-idea',
    label: 'Back',
    isActive: !!differentArchetypes?.is_selected_content.key_touch_points,
    onClick: () => navigate(`/${project.slug}/key-touch-point`),
  });

  const isComplete = Object.keys(data?.data ?? {}).length > 0;

  useCtaAction({
    isEditing: isEditingCount > 0,
    isActiveCta: !!differentArchetypes?.is_selected_content.key_touch_points,
    isEditor,
    project,
    routeOptionValue,
    setShowViewInfoModal,
    setRouteOption: setRouteOptionValue,
    currentRef: cardRefs,
    isIdeaAlignmentComplete: isComplete,
  });

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  if (!differentArchetypes?.is_selected_content.key_touch_points) {
    return (
      <NotDataFound
        buttonLabel="Go to Key Touch Point"
        description="You don't have selected key touch point, click the button below to select a key touch point in Key Touch Point page."
        onClick={() => navigate(`/${project.slug}/key-touch-point`)}
        title="No Data selected."
      />
    );
  }

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 w-full bg-white py-16">
          <h1 className="mb-8 text-25 font-bold leading-none">Creative Idea</h1>
          <span className="text-15 font-normal text-grey-redx">
            Please provide your initial ideas, which will be aligned with the
            strategy and expanded based on your selected touch points.
          </span>
        </div>

        <CreativeIdea
          ref={cardRefs}
          data={{
            title: data?.data?.title ?? '',
            description: data?.data?.description ?? '',
          }}
          isComplete={isComplete}
          isEditingCount={isEditingCount}
          isEditor={isEditor}
          isFetching={isFetching || isPending}
          isGenerate={routeOptionValue.isGenerate ?? true}
          onDispatch={dispatch}
          onRefetch={refetch}
          onSetIsEditingCount={setIsEditingCount}
          projectSlug={project.slug}
          user={user}
        />
      </div>

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
