import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';

interface UpdateCompetitorSubmissionProps {
  projectSlug: string;
  section: string;
  status: string;
  archetypeId: number;
}

const updateLastStatusSubmission = async ({
  projectSlug,
  section,
  status,
  archetypeId,
}: UpdateCompetitorSubmissionProps) => {
  try {
    await goFetcher.put(
      `/projects/${projectSlug}/submission/last-status-stepper`,
      {
        section,
        status,
        archetype_id: archetypeId,
      },
    );
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateLastStatusSubmission = () => {
  return useMutation<any, Error, UpdateCompetitorSubmissionProps>({
    mutationFn: updateLastStatusSubmission,
  });
};

export { useUpdateLastStatusSubmission };
