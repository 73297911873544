import MarkdownEditor from '@/Components/MarkdownEditor';
import type { TargetAudienceItem } from '@/Types/TargetAudience';

import { jsonToMarkdown } from '../Utils/utils';
import TargetAudienceList from './TargetAudienceList';

interface SectionDataProps {
  title: string;
  isEditing: boolean;
  isLoading: boolean;
  content: TargetAudienceItem[];
  onChange: (newContent: string) => void;
}

const SectionData = ({
  title,
  isEditing,
  isLoading,
  content,
  onChange,
}: SectionDataProps) => {
  let contentDisplay;

  if (isEditing) {
    contentDisplay = (
      <MarkdownEditor
        content={jsonToMarkdown(content)}
        onChange={(html) => {
          onChange(html);
        }}
      />
    );
  } else if (isLoading) {
    contentDisplay = (
      <div className="mt-20 flex flex-col gap-20">
        {Array.from({ length: 8 }).map((_, index) => (
          <div
            key={index}
            className={`full w- h-1${index === 7 ? '2/3' : 'full'} animate-pulse rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx py-4`}
          />
        ))}
      </div>
    );
  } else {
    contentDisplay = <TargetAudienceList items={content} />;
  }

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-16 font-bold leading-19">{title}</h3>
      {contentDisplay}
    </div>
  );
};

export default SectionData;
