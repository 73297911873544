const cleanContent = (content: string): string[] => {
  // Bersihkan simbol, angka, dan format yang tidak relevan
  return content
    .replace(/[#*~[\]()\-+=`><]/g, '') // Hapus simbol Markdown
    .replace(/[^a-zA-Z\s.]/g, '') // Hapus karakter non-alfabet kecuali spasi dan titik
    .replace(/\s+/g, ' ') // Ganti banyak spasi dengan satu spasi
    .toLowerCase() // Konversi ke huruf kecil
    .split(/[.!?]\s+/) // Pecah berdasarkan tanda titik, tanda seru, atau tanya
    .map((line) => line.trim()) // Hilangkan spasi tambahan
    .filter((line) => line.length > 0); // Hilangkan string kosong
};

export { cleanContent };
