import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { IdeaPersonalizationResponse } from '@/Types/IdeaPersonalization';

const fetch = async (
  projectSlug: string,
): Promise<IdeaPersonalizationResponse> => {
  try {
    const response = await goFetcher.get(
      `/projects/${projectSlug}/idea-personalization/lists`, // todo: change with correct url
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message ||
        'Failed to fetch idea personalization data',
    );
  }
};

const useIdeaPersonalization = (projectSlug: string) => {
  return useQuery<IdeaPersonalizationResponse, Error>({
    queryKey: ['ideaPersonalization', projectSlug],
    queryFn: () => fetch(projectSlug),
    refetchOnWindowFocus: false, // Prevent refetch on window focus (e.g., tab switch)
  });
};

export { useIdeaPersonalization };
