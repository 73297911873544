import { zodResolver } from '@hookform/resolvers/zod';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import Content from '@/Components/Content';
import InputCheckboxDropdown from '@/Components/InputCheckboxDropdown';
import InputLabel from '@/Components/InputLabel';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import TextInput from '@/Components/TextInput';
import type { ProjectAction } from '@/Context/Actions/project-actions';
import {
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useUpdateTargetAudienceSubmission } from '@/Hooks/Submission';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import {
  TargetAudience,
  type TargetAudienceSchema,
} from '@/Types/Submission/schema';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import ActionButtons from './Action';

interface TargetAudienceSectionProps {
  activeSection: number;
  projectSlug: string;
  user: UserResponse;
  isComplete: boolean;
  data: {
    age_market_start: number;
    age_market_end: number;
    gender: string;
  };
  isActive: boolean;
  isEditor: boolean;
  isEditingCount: number;
  onRefetchSubmission: () => void;
  onDispatch: React.Dispatch<ProjectAction>;
  onSetIsEditingCount: React.Dispatch<React.SetStateAction<number>>;
  onSetActiveSection: React.Dispatch<React.SetStateAction<number>>;
}

const TargetAudienceSection = forwardRef<
  { save: () => Promise<void>; element: HTMLDivElement | null },
  TargetAudienceSectionProps
>(
  (
    {
      activeSection,
      projectSlug,
      user,
      data,
      isActive,
      isEditor,
      isEditingCount,
      isComplete,
      onDispatch,
      onRefetchSubmission,
      onSetIsEditingCount,
      onSetActiveSection,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const project = useMemo(() => state.project, [state.project]);
    const isEnabledHistoryStatus = useMemo(
      () => state.isEnabledHistoryStatus,
      [state.isEnabledHistoryStatus],
    );
    const { mutate } = useUpdateTargetAudienceSubmission();
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const { mutate: generate } = useGenerate();
    const {
      isTargetAudienceAudienceInsight,
      isTargetAudienceBehavioralTrend,
      isTargetAudienceMediaConsumptionPattern,
      isTargetAudiencePerceptionAnalysis,
    } = useHistoryStatus(project, isEnabledHistoryStatus);

    const defaultValues = useMemo(
      () => ({
        age_market_start: data?.age_market_start ?? undefined,
        age_market_end: data?.age_market_end ?? undefined,
        gender: data?.gender ?? '',
      }),
      [data],
    );

    const {
      handleSubmit,
      reset,
      control,
      setValue,
      trigger,
      formState: { isValid, isSubmitting },
    } = useForm<TargetAudienceSchema>({
      resolver: zodResolver(TargetAudience),
      defaultValues,
      mode: 'onBlur',
    });

    const toggleIsEditing = useCallback(
      (isEdit: boolean) => {
        setIsEditing(isEdit);
        const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
        onSetIsEditingCount(newCount);
      },
      [isEditingCount, onSetIsEditingCount],
    );

    const onSubmit = async (formData: TargetAudienceSchema) => {
      triggerGTMEvent({
        event: 'Edit Submission Target Audience Information',
        eventCategory: 'Button Edit Submission Target Audience Click',
        eventAction: 'Click',
        eventLabel: 'Submission Target Audience',
        userId: user.email,
        data: formData,
      });

      toggleIsEditing(false);
      mutate(
        { payload: formData, projectSlug },
        {
          onSuccess: () => {
            triggerGTMEvent({
              event: 'Generate Audience Insight',
              eventCategory: 'Button Generate Audience Insight Click',
              eventAction: 'Click',
              eventLabel: 'Generate Audience Insight',
              userId: user.email,
              data: projectSlug,
            });

            triggerGTMEvent({
              event: 'Generate Behavioral Trend',
              eventCategory: 'Button Generate Behavioral Trend Click',
              eventAction: 'Click',
              eventLabel: 'Generate Behavioral Trend',
              userId: user.email,
              data: projectSlug,
            });

            triggerGTMEvent({
              event: 'Generate Media Consumption Pattern',
              eventCategory: 'Button Generate Media Consumption Pattern Click',
              eventAction: 'Click',
              eventLabel: 'Generate Media Consumption Pattern',
              userId: user.email,
              data: projectSlug,
            });

            triggerGTMEvent({
              event: 'Generate Perception Analysis',
              eventCategory: 'Button Generate Perception Analysis Click',
              eventAction: 'Click',
              eventLabel: 'Generate Perception Analysis',
              userId: user.email,
              data: projectSlug,
            });

            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                status: 'regenerate from submission',
              },
              projectSlug,
              section: 'target-audience/audience-insight',
            });

            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                status: 'regenerate from submission',
              },
              projectSlug,
              section: 'target-audience/behavioral-trends',
            });

            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                status: 'regenerate from submission',
              },
              projectSlug,
              section: 'target-audience/media-consumption-patterns',
            });

            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                status: 'regenerate from submission',
              },
              projectSlug,
              section: 'target-audience/perception-analysis',
            });

            onRefetchSubmission();
            onDispatch(setEnableHistoryStatus(true));
          },
        },
      );
    };

    useImperativeHandle(ref, () => ({
      save: async () => {
        await handleSubmit(onSubmit)();
        onSetActiveSection(activeSection + 1);
      },
      element: divRef.current,
    }));

    useEffect(() => {
      if (isEditing) {
        onDispatch(setEnableHistoryStatus(false));
        reset(defaultValues);
      } else {
        onDispatch(setEnableHistoryStatus(true));
      }
    }, [isEditing]);

    const handleCancel = () => {
      toggleIsEditing(false);
      reset(defaultValues);
    };

    const genders = useMemo(
      () => [
        { title: 'Male', value: 'Male' },
        { title: 'Female', value: 'Female' },
      ],
      [],
    );

    const handleSave = useCallback(async () => {
      if (
        isTargetAudienceAudienceInsight.isQuerying ||
        isTargetAudienceBehavioralTrend.isQuerying ||
        isTargetAudiencePerceptionAnalysis.isQuerying ||
        isTargetAudienceMediaConsumptionPattern.isQuerying
      ) {
        onDispatch(
          saveSubInformation(
            `{text-error-redx} Please wait market research is still generating`,
          ),
        );
        return;
      }

      if (
        isTargetAudienceAudienceInsight.status ||
        isTargetAudienceBehavioralTrend.status ||
        isTargetAudiencePerceptionAnalysis.status ||
        isTargetAudienceMediaConsumptionPattern.status
      ) {
        setIsModalOpen(true);
        return;
      }
      await handleSubmit(onSubmit)();
    }, [
      isTargetAudienceAudienceInsight.status,
      isTargetAudienceBehavioralTrend.status,
      isTargetAudienceMediaConsumptionPattern.status,
      isTargetAudiencePerceptionAnalysis.status,
      isTargetAudienceAudienceInsight.isQuerying,
      isTargetAudienceBehavioralTrend.isQuerying,
      isTargetAudienceMediaConsumptionPattern.isQuerying,
      isTargetAudiencePerceptionAnalysis.isQuerying,
    ]);

    return (
      <div
        ref={divRef}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Target Audience
          </h1>
          <ActionButtons
            isActive={isActive && isComplete}
            isEditing={isEditing}
            isEditor={isEditor}
            isFetching={isSubmitting}
            isValid={!isValid}
            onCancel={handleCancel}
            onEdit={() => setIsEditing(true)}
            onSave={handleSave}
          />
        </div>
        <div className="grid w-full grid-cols-2 gap-24">
          <div>
            <InputLabel value="Age Range" />
            <div className="inline-flex w-full items-center  gap-10">
              {isEditing || !isComplete ? (
                <Controller
                  control={control}
                  name="age_market_start"
                  render={({ field: { value }, fieldState: { error } }) => {
                    return (
                      <TextInput
                        autoComplete="off"
                        className="overflow-custom mt-1 block w-full resize-none !text-14"
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        error={error?.message}
                        max={100}
                        maxLength={3}
                        min={0}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          if (value) setValue('age_market_start', +value);
                          else setValue('age_market_start', value);
                          trigger('age_market_start');
                        }}
                        placeholder="Input lower bound  here"
                        type="number"
                        value={value ?? ''}
                      />
                    );
                  }}
                />
              ) : (
                <div className="grow">
                  <Content
                    isFetching={false}
                    isMarkDown={false}
                    value={data.age_market_start || ''}
                  />
                </div>
              )}

              <span className="text-black">-</span>

              {isEditing || !isComplete ? (
                <Controller
                  control={control}
                  name="age_market_end"
                  render={({ field: { value }, fieldState: { error } }) => {
                    return (
                      <TextInput
                        autoComplete="off"
                        className="overflow-custom mt-1 block w-full resize-none !text-14"
                        containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                        error={error?.message}
                        max={100}
                        maxLength={3}
                        min={0}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          if (value) setValue('age_market_end', +value);
                          else setValue('age_market_end', value);
                          trigger('age_market_end');
                        }}
                        placeholder="Input upper bound  here"
                        type="number"
                        value={value ?? ''}
                      />
                    );
                  }}
                />
              ) : (
                <div className="grow">
                  <Content
                    isFetching={false}
                    isMarkDown={false}
                    value={data.age_market_end || ''}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <InputLabel value="Gender" />
            {isEditing || !isComplete ? (
              <Controller
                control={control}
                name="gender"
                render={({ field: { value }, fieldState: { error } }) => (
                  <InputCheckboxDropdown
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    disabled={!isActive}
                    error={error?.message}
                    list={genders}
                    modelValue={value}
                    onChange={(value: any) => {
                      setValue('gender', value);
                      trigger('gender');
                    }}
                    placeholder="Choose gender here"
                  />
                )}
              />
            ) : (
              <Content
                isFetching={false}
                isMarkDown={false}
                value={data.gender || ''}
              />
            )}
          </div>
        </div>
        <ChangingImpactModal
          handleSubmit={async () => {
            await handleSubmit(onSubmit)();
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          isRegenerating={isSubmitting}
          onClose={() => {
            handleCancel();
            toggleIsEditing(true);
            setIsModalOpen(false);
          }}
          section="target-audience"
          visibleSections={[
            'Target Audience',
            'Audience Archetype',
            'Key Insight',
            'Challenge & Communication Task',
            'One-Page Strategy',
            'Key Touch Point',
            'Idea Alignment',
            'Idea Personalization',
            'Idea Expansion',
            'Searchability Content',
            'Discoverability Content',
            'Credibility Content',
            'Selected Content Ideas',
          ]}
        />
      </div>
    );
  },
);

export default memo(TargetAudienceSection);
