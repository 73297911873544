import { Tab, TabList } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import type {
  ArchetypeIdeaExpansion,
  FunnelContent,
} from '@/Types/IdeaExpansion';
import type { UserResponse } from '@/Types/user';
import { cn } from '@/Utils/cn';
import { triggerGTMEvent } from '@/Utils/gtm';

interface TabsProps {
  user: UserResponse;
  archetypesArray: ArchetypeIdeaExpansion[];
  activeTab: number;
  isLoading: boolean;
  isQuerying: boolean;
  isEditing: boolean;
  errorTabIndex?: number[];
  onActiveTab: (index: number) => void;
}

const Tabs: React.FC<TabsProps> = ({
  archetypesArray,
  user,
  isQuerying,
  activeTab,
  isLoading = true,
  isEditing = false,
  errorTabIndex = [],
  onActiveTab,
}) => {
  const [hoveredData, setHoveredData] = useState<{
    index: number | null;
    isRegenerate: boolean;
  }>({ index: null, isRegenerate: false });

  const [popupPosition, setPopupPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const tabsRef = useRef<HTMLElement[]>([]);

  const handleMouseEnter = (index: number) => {
    const tabElement = tabsRef.current[index];
    if (tabElement) {
      const rect = tabElement.getBoundingClientRect();
      setPopupPosition({
        top: rect.bottom + window.scrollY + 8, // Adjust popup position below the tab
        left: rect.left + rect.width / 2 + window.scrollX, // Center horizontally
      });
      setHoveredData({
        index,
        isRegenerate:
          archetypesArray[index]?.is_regenerate ||
          (archetypesArray[index]?.idea_expansions.length === 0 &&
            !isQuerying) ||
          false,
      });
    }
  };

  const handleMouseLeave = () => {
    setHoveredData({ index: null, isRegenerate: false });
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      onActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleNext = () => {
    if (activeTab < archetypesArray.length - 1) {
      const nextIndex = activeTab + 1;
      onActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleTabClicked = (title: string, keyTouchPoints: FunnelContent[]) => {
    triggerGTMEvent({
      event: `Tab Idea Expansion`,
      eventCategory: `Tab Idea Expansion Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Expansion',
      userId: user.email,
      data: { title, keyTouchPoints },
    });
  };

  return (
    <div className="relative flex items-center">
      {archetypesArray.length > 3 && (
        <button
          className="p-2 text-gray-500 hover:text-black"
          disabled={activeTab === 0}
          onClick={handlePrev}
        >
          <Icon className="text-25" icon="mingcute:left-line" />
        </button>
      )}
      <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
        {!isLoading
          ? archetypesArray.map((section, index) => (
              <div
                key={index}
                ref={(el) => {
                  if (el) tabsRef.current[index] = el;
                }}
                className="relative w-full min-w-5/24 px-25 pb-10 text-center text-15"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Tab
                  key={index}
                  className={cn(
                    'data-[selected]:border-b-3 w-full min-w-5/24 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0',
                    isEditing ||
                      section.is_regenerate ||
                      (archetypesArray[index]?.idea_expansions.length === 0 &&
                        !isQuerying)
                      ? 'cursor-not-allowed text-placeholder-redx'
                      : 'cursor-pointer text-black-redx',
                  )}
                  disabled={
                    isEditing ||
                    section.is_regenerate ||
                    (archetypesArray[index]?.idea_expansions.length === 0 &&
                      !isQuerying)
                  }
                  onClick={() =>
                    handleTabClicked(
                      section.archetype_content.archetype_name,
                      section.idea_expansions,
                    )
                  }
                >
                  <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                    <div className="flex flex-col gap-8">
                      {`Archetype ${index + 1}`}
                      <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                        {section.archetype_content.archetype_name}
                      </div>
                    </div>
                    {errorTabIndex?.includes(index) && (
                      <Icon
                        className="text-25 text-error-redx"
                        icon="mingcute:information-line"
                      />
                    )}
                  </div>
                </Tab>
              </div>
            ))
          : Array.from({ length: 3 }).map((_, index) => (
              <button
                key={`dummy-${index}`}
                className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
              >
                <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
              </button>
            ))}
      </TabList>
      {hoveredData.index !== null &&
        hoveredData.isRegenerate &&
        createPortal(
          <div
            className="absolute z-50 rounded-8 bg-white px-15 py-8 text-15"
            style={{
              top: `${popupPosition.top - 20}px`,
              left: `${popupPosition.left}px`,
              transform: 'translateX(-50%)',
              boxShadow: '0px 0px 12px 0px #00000026',
            }}
          >
            Please choose the idea personalization for this archetype first
          </div>,
          document.body,
        )}
      {archetypesArray.length > 3 && (
        <button
          className="p-2 text-gray-500 hover:text-black"
          disabled={activeTab === archetypesArray.length - 1}
          onClick={handleNext}
        >
          <Icon className="text-25" icon="mingcute:right-line" />
        </button>
      )}
    </div>
  );
};

export default Tabs;
