import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import ActionSection from '@/Components/Projects/ActionSection';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import {
  useCtaAction,
  useIdeaAlignments,
  useSectionData,
  useSubmitIdeaAlignment,
} from '@/Hooks/IdeaAlignment';
import { useUpdateLastStatusSubmission } from '@/Hooks/Submission';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type { IdeaAlignmentData, SectionData } from '@/Types/IdeaAlignment';
import type { IdeaAlignmentSchema } from '@/Types/IdeaAlignment/schema';
import type { SectionList } from '@/Types/tabs';
import { cleanContent } from '@/Utils/clean-content';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import Skeleton from './Components/Skeleton';

const Index = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const { mutate: updateLastStatus } = useUpdateLastStatusSubmission();
  const navigate = useNavigate();
  const sectionList: SectionList = {
    title: 'idea alignment',
    value: 'alignment',
    section: 'idea',
  };
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({});
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const { mutate, isPending: isSubmitting } = useSubmitIdeaAlignment();
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { roles } = useRolesBadge(project, user);
  const { data, refetch, isPending, isRefetching } = useIdeaAlignments(
    project.slug,
  );
  const { mutate: generate, isPending: isRegenerating } = useGenerate();
  const { isIdeaPersonalization, isIdeaAlignment } = useHistoryStatus(
    project,
    state.isEnabledHistoryStatus,
  );

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Generate Idea Personalization',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  });
  const isEditor = roles.includes('Owner') || roles.includes('Creative');
  const { data: differentArchetypes, refetch: refetchDiffArchetypes } =
    useDifferentArchetypes(project.slug);

  const [sectionData, setSectionData] = useState<Record<string, SectionData>>(
    {},
  );
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );

  const [archetypes, setArchetypes] = useState<
    Record<
      number,
      {
        id: number;
        is_querying: boolean;
      }
    >
  >({});

  const [formState, setFormState] = useState({
    isEditing: false,
    isAdding: false,
  });

  const [totals, setTotals] = useState<{
    allSelected: number;
    allData: number;
  }>({
    allSelected: 0,
    allData: 0,
  });

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totals.allSelected} from ${totals.allData} idea alignment selected`,
      ),
    );
  }, [totals]);

  useSectionData({
    activeSection: sectionList.value,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionData,
    setGeneratingStates,
    setProgressStates,
    setSectionData,
    setTotals,
  });

  const handleRegenerateButton = async (prompt: string) => {
    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      sectionList.value,
    );

    setSectionData((prev) => ({
      ...prev,
      [sectionList.value]: {
        ...prev[sectionList.value],
        is_querying: true,
      },
    }));

    setProgressStates({});

    triggerGTMEvent({
      event: `Generate More Idea Alignment`,
      eventCategory: `Generate More Idea Alignment Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Alignment',
      userId: user.email,
      data: prompt,
    });
    dispatch(saveSubInformation(''));

    generate(
      {
        payload: {
          regenerate_prompt: '',
          generate_more: prompt,
        },
        projectSlug: project.slug,
        section: 'idea-alignment',
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const toggleEditing = () => {
    setFormState((prev) => ({ ...prev, isEditing: !prev.isEditing }));
  };

  const toggleAdding = () => {
    setFormState((prev) => ({ ...prev, isAdding: !prev.isAdding }));
  };

  const handleCancelEdit = () => {
    toggleEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const totalRegenerate = sectionData[sectionList.value]?.total_regenerate || 0;
  const previousSelected =
    sectionData[sectionList.value]?.previous_selected || -1;

  const message = sectionData[sectionList.value]?.message ?? '';
  const content = sectionData[sectionList.value]?.content ?? [];
  const sources = sectionData[sectionList.value]?.sources ?? [];
  const selectedFunnels =
    sectionData[sectionList.value]?.selected_funnels ?? [];
  const historyId = sectionData[sectionList.value]?.history_id ?? -1;
  const isQuerying = sectionData[sectionList.value]?.is_querying ?? false;

  const isDiffSelected = useMemo(() => {
    const currSelected = content.find((item) => item.is_selected);
    if (
      !currSelected ||
      isIdeaPersonalization.isRegenerated ||
      differentArchetypes?.is_different_archetype.idea_expansions
    ) {
      return false;
    }

    const diffLineTitle = cleanContent(
      currSelected.idea_alignment_content.idea_title,
    ).filter((line) => !cleanContent(previousSelected.title).includes(line));

    const diffPrevTitle = cleanContent(previousSelected.title).filter(
      (line) =>
        !cleanContent(currSelected.idea_alignment_content.idea_title).includes(
          line,
        ),
    );

    const diffLineDesc = cleanContent(
      currSelected.idea_alignment_content.idea_description,
    ).filter((line) => !cleanContent(previousSelected.desc).includes(line));

    const diffPrevPrevDesc = cleanContent(previousSelected.desc).filter(
      (line) =>
        !cleanContent(
          currSelected.idea_alignment_content.idea_description,
        ).includes(line),
    );

    return (
      currSelected.id !== previousSelected.alignment_id ||
      diffLineTitle.length > 0 ||
      diffPrevTitle.length > 0 ||
      diffLineDesc.length > 0 ||
      diffPrevPrevDesc.length > 0
    );
  }, [
    previousSelected,
    content,
    isIdeaPersonalization.isRegenerated,
    differentArchetypes?.is_different_archetype.idea_expansions,
  ]);

  const hasRegenerateIdeaPerson = useMemo(() => {
    const currSelected = content.find(
      (item) => item.has_regenerate_idea_personalization,
    )?.id;
    if (!currSelected) return false;
    return true;
  }, [previousSelected, content]);

  useEffect(() => {
    if (historyId) {
      setArchetypes((prev) => ({
        ...prev,
        [historyId]: {
          id: historyId,
          is_querying: isQuerying ?? false,
        },
      }));
    }
  }, [historyId, isQuerying]);

  const activeArchetype = useMemo(() => {
    if (!historyId) return null;
    return archetypes[historyId] || null;
  }, [archetypes, historyId]);

  const handleAddNew = () => {
    const newData: IdeaAlignmentData = {
      id: -1,
      has_regenerate_idea_personalization: false,
      is_edited: false,
      submission_id: -1,
      history_id: -1,
      idea_alignment_number: 1,
      is_selected: false,
      is_manual: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      idea_alignment_content: {
        idea_description: '',
        idea_title: '',
        reason: '',
        idea_alignment_number: 1,
      },
    };

    const newIndex = content.length;

    // Update state `archetypes` dengan data baru
    setSectionData((prev) => {
      const updatedContent = [
        ...(prev[sectionList.value]?.content || []),
        newData,
      ];

      return {
        ...prev,
        [sectionList.value]: {
          ...prev[sectionList.value],
          content: updatedContent,
        },
      };
    });

    setGoToIndex(newIndex);
    toggleAdding(); // Aktifkan mode "Adding"
  };

  const handleCancelAdd = () => {
    toggleAdding();

    setSectionData((prev) => {
      const curr = prev[sectionList.value]?.content || [];
      const filter = curr.filter((datum) => datum.id !== -1);

      return {
        ...prev,
        [sectionList.value]: {
          ...prev[sectionList.value],
          content: filter,
        },
      };
    });

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleFetchSubmit = async (
    payload: IdeaAlignmentSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, id, projectSlug },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Idea Alignment`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Idea Alignment  Click`,
            eventAction: 'Click',
            eventLabel: 'Idea Alignment',
            userId: user.email,
            data: payload,
          });

          formState.isAdding && toggleAdding();
          formState.isEditing && toggleEditing();

          refetch();

          formState.isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleRegenerate = useCallback(() => {
    if (!activeArchetype) return;

    triggerGTMEvent({
      event: `Generate Idea Personalization`,
      eventCategory: `Generate Idea Alignment Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Alignment',
      userId: user.email,
      data: project.slug,
    });

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      'personalization',
    );

    if (isDiffSelected || hasRegenerateIdeaPerson) {
      selectedFunnels.forEach((item) => {
        if (item.funnel === 'credible') {
          removeAllArchetypesBasedOnSectionFromLocalStorage(
            project.slug,
            'credibility_goals_success_metrics_main_features',
          );

          generate(
            {
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                archetype_id: item.archetype_id,
                status: 'diff selected idea alignment',
              },
              projectSlug: project.slug,
              section: 'content/credible/objective',
            },
            {
              onSuccess: () => {
                item.archetype_id.forEach((id) => {
                  updateLastStatus({
                    archetypeId: id,
                    projectSlug: project.slug,
                    section: 'credibility',
                    status: 'goals_success_metrics_main_features',
                  });
                });
              },
            },
          );
        }

        if (item.funnel === 'discoverable') {
          removeAllArchetypesBasedOnSectionFromLocalStorage(
            project.slug,
            'discoverability_goals_success_metrics_main_features',
          );

          generate(
            {
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                archetype_id: item.archetype_id,
                status: 'diff selected idea alignment',
              },
              projectSlug: project.slug,
              section: 'content/discoverable/objective',
            },
            {
              onSuccess: () => {
                item.archetype_id.forEach((id) => {
                  updateLastStatus({
                    archetypeId: id,
                    projectSlug: project.slug,
                    section: 'discoverability',
                    status: 'goals_success_metrics_main_features',
                  });
                });
              },
            },
          );
        }

        if (item.funnel === 'searchable') {
          removeAllArchetypesBasedOnSectionFromLocalStorage(
            project.slug,
            'searchability_goals_success_metrics_main_features',
          );

          generate(
            {
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                archetype_id: item.archetype_id,
                status: 'diff selected idea alignment',
              },
              projectSlug: project.slug,
              section: 'content/searchable/objective',
            },
            {
              onSuccess: () => {
                item.archetype_id.forEach((id) => {
                  updateLastStatus({
                    archetypeId: id,
                    projectSlug: project.slug,
                    section: 'searchability',
                    status: 'goals_success_metrics_main_features',
                  });
                });
              },
            },
          );
        }
      });

      generate(
        {
          payload: {
            regenerate_prompt: '',
            generate_more: '',
            status: 'edit selected idea alignment',
          },
          projectSlug: project.slug,
          section: 'idea-personalization',
        },
        {
          onSuccess: () => {
            setShowRegenerateModal(false);
            dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
          },
        },
      );
    }
  }, [
    isDiffSelected,
    hasRegenerateIdeaPerson,
    routeOptionValue,
    selectedFunnels,
  ]);

  const selectedIndexes = useMemo(() => {
    return content
      .map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [content]);

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    refetch,
    activeTab: 0,
    projectSlug: project.slug,
    setArchetypes,
    setGeneratingStates,
    setProgressStates,
    section: sectionList.value,
  });

  useEffect(() => {
    dispatch(
      setEnableHistoryStatus(!(formState.isAdding || formState.isAdding)),
    );
  }, [formState]);

  useCtaAction({
    message,
    activeSection: sectionList.value,
    isEditing: formState.isAdding || formState.isEditing,
    isEditor,
    project,
    isDiffSelected: isDiffSelected || hasRegenerateIdeaPerson,
    routeOptionValue,
    totalSelected: totals.allSelected,
    setShowViewInfoModal,
    setRouteOption: setRouteOptionValue,
    setShowRegenerateModal,
  });

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [
    isIdeaPersonalization.isQuerying,
    isIdeaPersonalization.isRegenerated,
    isIdeaAlignment.isQuerying,
  ]);

  useRoutePrev({
    id: 'btn-back-idea-alignment',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/creative-idea`),
  });

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 mb-24 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">
              Idea Alignment
            </h1>
            <span className="text-1620 font-normal text-grey-redx">
              Please{' '}
              <span className="font-bold text-black-redx">
                select one idea{' '}
              </span>{' '}
              , edit, or add by clicking &apos;Add Idea Manually&apos;.
            </span>
          </div>
          {isEditor && !isQuerying && message !== 'No data found' && (
            <div className="flex items-center">
              <RegenerateButton
                isGenerateMore
                limit={totalRegenerate}
                maxLimit={project.max_generated_data} // to do ganti ke max limit
                onSubmit={({ prompt }) => handleRegenerateButton(prompt)}
                section="idea-alignment"
              />
            </div>
          )}
        </div>

        {isPending ? (
          <Skeleton />
        ) : (
          <div className="h-full py-24">
            {(() => {
              if (isQuerying) {
                return (
                  <GenerateLoading
                    estimateTimeTo={3}
                    progress={progressPortion({
                      progress: Number(
                        (progressStates[historyId] || 0).toFixed(0),
                      ),
                      isQuerying,
                      isContentAvailable: content.length > 0,
                    })}
                    project={project}
                    section={sectionList}
                    showEmailNotification={isIdeaAlignment.isSendingEmail}
                  />
                );
              }

              if (message === 'No data found') {
                return (
                  <NotDataFound
                    buttonLabel="Go to Creative Idea"
                    description="You don't have data, click the button below to generate a idea alignment in Creative Idea page."
                    onClick={() => navigate(`/${project.slug}/creative-idea`)}
                    title="No Data Found."
                  />
                );
              }

              return (
                <div className="flex flex-col gap-20 pb-40">
                  <SliderContainer
                    goToIndex={goToIndex}
                    isAdding={formState.isAdding}
                    isEditing={formState.isEditing}
                    length={content.length}
                    onActiveIndex={(index) => setActiveIndex(index)}
                    selectedIndexes={selectedIndexes}
                  >
                    {content.map((archetype, index) => (
                      <Card
                        key={archetype.id}
                        ref={(el) => (cardRefs.current[index] = el)}
                        id={archetype.id}
                        index={index}
                        isCanSelect={isEditor}
                        isDisabledSelectButton={
                          isIdeaPersonalization.status &&
                          !differentArchetypes?.is_different_archetype
                            .idea_personalizations &&
                          totals.allSelected === 1 &&
                          !isIdeaPersonalization.isRegenerated
                        }
                        isEdited={archetype.is_edited || archetype.is_manual}
                        isEditing={formState.isAdding || formState.isEditing}
                        isFetching={isRefetching}
                        isSelected={archetype.is_selected}
                        item={archetype.idea_alignment_content}
                        onSelectSuccess={onSelectSuccess}
                        onSubmitSuccess={handleFetchSubmit}
                        project={project}
                        refetchDiffArchetype={refetchDiffArchetypes}
                        totalData={content.length}
                        user={user}
                      />
                    ))}
                  </SliderContainer>

                  <ActionSection
                    isEditing={formState.isAdding || formState.isEditing}
                    isEditor={isEditor}
                    isSubmitting={isSubmitting}
                    label="Add Idea Alignment Manually"
                    onAdd={handleAddNew}
                    onCancel={
                      formState.isAdding ? handleCancelAdd : handleCancelEdit
                    }
                    onSave={handleSubmit}
                    toggleEditing={toggleEditing}
                  />

                  {sources && <SourcesList sources={sources} />}
                </div>
              );
            })()}
          </div>
        )}
      </div>

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="idea-alignment"
      />

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="idea-alignment"
        visibleSections={[
          'Idea Personalization',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
