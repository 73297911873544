/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Icon } from '@iconify/react/dist/iconify.js';
import React from 'react';

import type { Step } from '@/Types/step';

interface ProgressLineProps {
  isFetching?: boolean;
  currentStep?: number;
  steps: Step[];
  onStepClick?: (stepIndex: number) => void; // Callback when a step is clicked
}

const ProgressLine: React.FC<ProgressLineProps> = ({
  steps,
  currentStep = 0,
  isFetching = false,
  onStepClick,
}) => {
  const getStepClass = (step: Step, index: number, currentStep: number) => {
    if (step.status) {
      return index === currentStep
        ? 'font-semibold text-blue-purple-redx'
        : 'font-normal text-black';
    }
    return 'font-normal text-stroke-redx';
  };

  return (
    <ol className="relative flex w-full items-start justify-between">
      {steps.map((step, index) => {
        const renderIcon = () => {
          if (step.status && step.statusNextStep) {
            return <Icon icon="lucide:check" />;
          }
          if (step.isGenerating) {
            return <Icon className="animate-spin" icon="lucide:loader-2" />; // Loader animasi
          }
          return step.icon;
        };

        return (
          <React.Fragment key={index}>
            <li
              className={`relative z-10 flex flex-col items-center ${
                !step.status ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (step.status) {
                  onStepClick?.(index);
                }
              }} // Trigger callback on click
            >
              {/* Step Icon */}
              <div className="flex min-w-233 max-w-233 flex-col items-center justify-center gap-8">
                {isFetching ? (
                  <div className="block size-24  rounded-full border-1 border-soft-purple-redx bg-soft-purple-redx" />
                ) : (
                  <span
                    className={`flex size-24 items-center justify-center rounded-full ${
                      step.status
                        ? 'bg-soft-purple-redx text-blue-purple-redx'
                        : 'bg-soft-grey-redx text-placeholder-redx'
                    }`}
                  >
                    {renderIcon()}
                  </span>
                )}
                {step.label && (
                  <h3
                    className={`text-center text-14 leading-16 ${getStepClass(step, index, currentStep)}`}
                  >
                    {isFetching ? (
                      <div className="full flex h-3 w-25 animate-pulse rounded-full border-1 border-soft-purple-redx bg-soft-purple-redx px-24 py-2" />
                    ) : (
                      <p>{step.label}</p>
                    )}
                  </h3>
                )}
              </div>
            </li>
            {index < steps.length - 1 && (
              <div className="absolute inset-x-108 top-10 h-1 bg-stroke-redx" />
            )}
          </React.Fragment>
        );
      })}
    </ol>
  );
};

export default ProgressLine;
