import { Tab } from '@headlessui/react';
import React from 'react';

import type { FunnelContent } from '@/Types/KeyTouchPoint';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

interface TabListFunnelProps {
  archetypeName: string;
  funnels: FunnelContent[];
  isEditing: boolean;
  user: UserResponse;
  onHandleTitleFunnel: (funnel: string) => string;
  onSetSelectedFunnel: React.Dispatch<React.SetStateAction<string>>;
}

const TabListFunnel = ({
  archetypeName,
  funnels,
  isEditing,
  onHandleTitleFunnel,
  onSetSelectedFunnel,
  user,
}: TabListFunnelProps) => {
  const handleTabClick = (funnel: FunnelContent) => {
    triggerGTMEvent({
      event: `Tab Funnel Key Touch Point`,
      eventCategory: `Tab Funnel Key Touch Point Click`,
      eventAction: 'Click',
      eventLabel: 'Key Touch Point',
      userId: user.email,
      data: {
        archetype: archetypeName,
        key_touch_points: funnel.content,
        funnel: funnel!.funnel ?? '',
      },
    });
    onSetSelectedFunnel(funnel!.funnel ?? '');
  };

  return (
    <div className="flex w-full flex-col items-start justify-start border-r-1  border-r-stroke-redx">
      {funnels.map((funnel, index) => {
        return (
          <Tab
            key={`${index}-${funnel.funnel}`}
            className="w-full py-10 text-start text-16 font-normal leading-20 text-black-redx data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx"
            disabled={isEditing}
            onClick={() => handleTabClick(funnel)}
          >
            <p className="font-normal">
              {onHandleTitleFunnel(funnel?.funnel ?? '')}
            </p>
            <p className="text-12 leading-14 text-grey-redx">
              {funnel.total_selected_touchpoint} selected
            </p>
          </Tab>
        );
      })}
    </div>
  );
};

export default TabListFunnel;
