import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { MarketResearchResponse } from '@/Types/MarketResearch';

const getLists = async (projectSlug: string, activeSection: string) => {
  // Mapping section to API endpoints
  const endpoints: Record<string, string> = {
    trends: `/projects/${projectSlug}/market-research/trend`,
    demand: `/projects/${projectSlug}/market-research/demand`,
  };

  try {
    const url = endpoints[activeSection];

    if (!url) {
      throw new Error('Invalid section provided');
    }

    const response = await goFetcher.get(url);
    return response.data as MarketResearchResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};

const useMarketResearch = (
  projectSlug: string,
  activeSection: string,
): UseQueryResult<MarketResearchResponse, Error> => {
  return useQuery<MarketResearchResponse, Error>({
    queryKey: ['marketResearch', activeSection, projectSlug],
    queryFn: () => getLists(projectSlug, activeSection),
    refetchOnWindowFocus: false,
  });
};

export { useMarketResearch };
