import { useRef } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';

import type { SourcesType } from '@/Types/Projects';

interface SourcesListProps {
  sources: SourcesType[] | undefined;
}
const SourcesList = ({ sources }: SourcesListProps) => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>; // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);

  return (
    <div className="flex w-full flex-col pb-56">
      {sources && sources.length > 0 && (
        <h1 className="mb-4 text-12 font-semibold">Sources</h1>
      )}
      <div
        className="relative flex w-full max-w-full cursor-grab flex-nowrap gap-x-15 overflow-x-scroll pr-10 scrollbar-hide"
        {...events}
        ref={ref}
      >
        {sources?.map((source, index) => (
          <div
            key={index}
            className="flex min-w-363 items-center gap-x-12 rounded-10 border-1 border-stroke-redx bg-white px-15 py-12"
          >
            <div className="flex">
              <div className="flex size-15 items-center justify-center self-center rounded-full bg-soft-purple-redx p-5 text-12 font-semibold leading-0 text-blue-redx">
                {index + 1}
              </div>
            </div>
            <div className="max-w-300 flex-1 flex-col text-15 font-normal leading-15 text-black-redx">
              <div className="mb-8 inline-flex w-full items-center gap-x-8">
                {source.url && (
                  <img
                    alt=""
                    className="size-15"
                    src={`https://s2.googleusercontent.com/s2/favicons?domain=${source.url}`}
                  />
                )}
                <a
                  className="truncate"
                  href={source.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <p className="truncate">{source.url}</p>
                </a>
              </div>
              <a
                className="truncate"
                href={source.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                <p className="truncate">{source.title}</p>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SourcesList;
