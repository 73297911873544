import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ArchetypeChallenge } from '@/Types/ChallengeAndTask';
import type { ProjectProps } from '@/Types/Projects';

import { useDifferentArchetypes } from '../AudienceArchetype';
import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  routeOptionValue: RouteOption;
  activeArchetype: ArchetypeChallenge | null;
  totalSelected: number;
  isEditor: boolean;
  isEditing: boolean;
  project: ProjectProps;
  message: string;
  archetypesArray: ArchetypeChallenge[];
  handleRefetchSelectedData: () => void;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorTabIndex: React.Dispatch<React.SetStateAction<number[]>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  isEditor,
  routeOptionValue,
  project,
  totalSelected,
  isEditing,
  archetypesArray,
  message,
  setErrorTabIndex,
  setShowViewInfoModal,
  activeArchetype,
  handleRefetchSelectedData,
  setRouteOption,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;

  const { data: differentArchetypes } = useDifferentArchetypes(projectSlug);

  const {
    isCompetitorAnalysisComplete,
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
  } = useHistoryStatus(project);

  // Memoize routeOptionValue to avoid unnecessary re-render
  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const hasSelected = (archetypesArray: ArchetypeChallenge[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelected = archetypesArray
      .map((section, index) => {
        const hasSelectedKey = section.challenges.some(
          (data) => data.is_selected,
        );
        return hasSelectedKey ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelected.length) {
      setErrorTabIndex(indicesWithoutSelected);
      const namesWithoutSelected = indicesWithoutSelected
        .map((index) => `Archetype ${index + 1}`)
        .join(', ');
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select Challenge and Task for ${namesWithoutSelected}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleEditorClick = useCallback(() => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Challenge & Communication Task is still generating.`,
        ),
      );
      return;
    }
    if (activeArchetype?.is_regenerate) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select and generate key insight first.`,
        ),
      );
      return;
    }

    if (isCompetitorAnalysisComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Competitor is still generating`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
    }

    if (isEditing) {
      dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isCompetitorAnalysisComplete.status &&
      !activeArchetype?.is_diff_challenges &&
      !activeArchetype?.regenerate_per_archetype_onepage
    ) {
      if (totalSelected === 0) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please select Challenge & Communication first`,
          ),
        );

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      navigate(`/${project.slug}/competitor-analysis`);
      return;
    }
    const hasError = hasSelected(archetypesArray);

    if (!hasError) handleRefetchSelectedData();
  }, [
    memoizedRouteOptionValue,
    archetypesArray,
    isEditing,
    activeArchetype?.is_regenerate,
    totalSelected,
    activeArchetype?.is_querying,
    activeArchetype?.regenerate_per_archetype_onepage,
    activeArchetype?.is_diff_challenges,
    isCompetitorAnalysisComplete.status,
    isCompetitorAnalysisComplete.isQuerying,
    isOnePageStrategy.isRegenerated,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Challenge & Communication is still generating.`,
        ),
      );
      return;
    }

    if (
      !isCompetitorAnalysisComplete.status ||
      activeArchetype?.regenerate_per_archetype_onepage
    ) {
      setShowViewInfoModal(true);
      return;
    }

    navigate(`/${projectSlug}/competitor-analysis`);
  }, [
    activeArchetype?.is_querying,
    isCompetitorAnalysisComplete.status,
    activeArchetype?.regenerate_per_archetype_onepage,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';

    if (isEditor) {
      memoizedRouteOptionValue.isInactive =
        activeArchetype?.is_querying ||
        activeArchetype?.challenges.length === 0 ||
        totalSelected === 0 ||
        activeArchetype?.is_regenerate;

      if (
        isChallengeAndTaskOfTheBrand.status &&
        isOnePageStrategy.status &&
        !differentArchetypes?.is_different_archetype.one_pages &&
        !isOnePageStrategy.isRegenerated &&
        (activeArchetype?.is_diff_challenges ||
          activeArchetype?.regenerate_per_archetype_onepage)
      ) {
        memoizedRouteOptionValue.label = 'Update Other Pages';
        memoizedRouteOptionValue.isGenerate = true;
      } else if (isCompetitorAnalysisComplete.status) {
        memoizedRouteOptionValue.label = 'Next to Competitor Analysis';
        memoizedRouteOptionValue.isGenerate = false;
      } else if (!isCompetitorAnalysisComplete.status) {
        memoizedRouteOptionValue.label = 'Generate Competitor Analysis';
        memoizedRouteOptionValue.isGenerate = true;
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.label = 'Next to Competitor Analysis';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.isInactive =
        !isCompetitorAnalysisComplete.status ||
        isCompetitorAnalysisComplete.isQuerying;

      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    differentArchetypes?.is_different_archetype.one_pages,
    activeArchetype?.is_regenerate,
    activeArchetype?.is_querying,
    activeArchetype?.total_challenges,
    activeArchetype?.challenges,
    activeArchetype?.is_diff_challenges,
    activeArchetype?.regenerate_per_archetype_onepage,
    isEditor,
    totalSelected,
    isOnePageStrategy.status,
    isCompetitorAnalysisComplete.status,
    isCompetitorAnalysisComplete.isQuerying,
    message,
  ]);
};

export { useCtaAction };
