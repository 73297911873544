import { useContext, useMemo } from 'react';

import { ProjectContext } from '@/Context/ProjectContext';
import { formatInformation } from '@/Utils/helper';

import PencilGenerate from '../Icon/PencilGenerate';

const ProjectFooter = () => {
  const [state] = useContext(ProjectContext);

  const information = useMemo(() => state.information, [state.information]);
  const subInformation = useMemo(
    () => state.subInformation,
    [state.subInformation],
  );
  const routeNext = useMemo(() => state.routeNext, [state.routeNext]);
  const routePrev = useMemo(() => state.routePrev, [state.routePrev]);

  const formattedInformation = formatInformation(information);
  const formattedSubInformation = formatInformation(subInformation ?? '');

  const buttonNextGradient = routeNext.isGenerate
    ? 'bg-button-gradient-90 bg-200% animate-gradient-animation'
    : 'bg-blue-redx border-blue-redx';

  const buttonNextDisabledClass =
    routeNext.isDisabled || routeNext.isInactive
      ? `bg-placeholder-redx border-placeholder-redx ${
          routeNext.isDisabled && 'cursor-not-allowed'
        } text-white`
      : `${buttonNextGradient} cursor-pointer`;

  return (
    (routeNext.isActive || routePrev.isActive) && (
      <div
        className="fixed inset-x-0 bottom-0 z-20 bg-white py-10 pl-20 pr-30 shadow-lg lg:pl-[26%]"
        style={{
          boxShadow: '0px -4px 20px 0px #0000001F',
        }}
      >
        <div className="flex w-full flex-col items-center justify-between gap-8 lg:flex-row">
          <div className="w-full flex-1">
            <p className={formattedInformation.className}>
              {formattedInformation.content}
            </p>
            <p className={formattedSubInformation.className}>
              {formattedSubInformation.content}
            </p>
          </div>
          <div className="flex w-full flex-1 flex-row justify-end gap-10 gap-x-24 md:flex-col">
            {routePrev.isActive && (
              <button
                className="w-fit cursor-pointer whitespace-nowrap rounded-lg border-2 border-blue-redx bg-transparent px-24 py-12 text-20 font-semibold leading-18 text-blue-redx"
                id={
                  routePrev?.id ??
                  `btn-${routePrev.label.toLowerCase().replace(/\s+/g, '-')}`
                }
                onClick={routePrev.onClick}
              >
                {routePrev.label}
              </button>
            )}
            {routeNext.isActive && (
              <button
                className={`flex flex-row items-center justify-center gap-8 whitespace-nowrap rounded-lg border-2 px-24 py-12 text-20 font-semibold text-white md:whitespace-break-spaces ${buttonNextDisabledClass}`}
                data-button="button-cta"
                disabled={routeNext.isDisabled ?? false}
                id={
                  routeNext?.id ??
                  `btn-${routeNext.label.toLowerCase().replace(/\s+/g, '-')}`
                }
                onClick={routeNext.onClick}
              >
                {routeNext.label}
                {routeNext.isGenerate && <PencilGenerate />}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ProjectFooter;
