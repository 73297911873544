import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type {
  DiscoverabilityArchetype,
  Framework,
  Section,
} from '@/Types/DiscoverabilityContent';
import type { StepId } from '@/Types/DiscoverabilityContent/schema';
import { stepPropertyMap } from '@/Types/DiscoverabilityContent/schema';
import type { HistoryStatus } from '@/Types/history';
import type { SelectedFramework } from '@/Types/Projects';
import type { Step } from '@/Types/step';
import { removeProgressFromLocalStorage } from '@/Utils/persist-progress';

import { useUpdateLastStatusSubmission } from '../Submission';
import { useGenerate } from '../useGenerate';

interface UseCtaParams {
  discoverabilityIdea: HistoryStatus;
  discoverabilityObjective: HistoryStatus;
  discoverabilityTrend: HistoryStatus;
  discoverabilityTopic: HistoryStatus;
  message: string;
  activeArchetype: DiscoverabilityArchetype;
  steps: Step[];
  activeTab: number;
  projectSlug: string;
  totalArchetypes: number;
  currStep: number;
  isEditor: boolean;
  selectedFramework: SelectedFramework;
  refetch: () => void;
  onSetCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  onSetActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCtaAction = ({
  discoverabilityIdea,
  discoverabilityObjective,
  discoverabilityTrend,
  discoverabilityTopic,
  activeArchetype,
  message,
  steps,
  currStep,
  isEditor,
  activeTab,
  totalArchetypes,
  projectSlug,
  selectedFramework,
  refetch,
  onSetCurrentStep,
  onSetActiveTab,
  setShowViewInfoModal,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const { mutate: generate, isPending: isRegenerating } = useGenerate();
  const { mutate: updateLastStatus } = useUpdateLastStatusSubmission();

  const memoizedRouteOptionValue: RouteOption = useMemo(
    () => ({
      label: 'Generate Discoverability Trends',
      isActive: true,
      isGenerate: true,
      isInactive: false,
      isDisabled: false,
    }),
    [],
  );

  const memoizedRouteBackOptionValue: RouteOption = useMemo(
    () => ({
      label: 'Back',
      isActive: true,
      isGenerate: true,
      isInactive: false,
      isDisabled: false,
    }),
    [],
  );

  const isAnyChecked = (frameworks: Framework[]): boolean => {
    return frameworks.every((framework) =>
      framework.data.some((entry) =>
        entry.ideas.some((idea) => idea.is_checked),
      ),
    );
  };

  useEffect(() => {
    const lastStepper = activeArchetype?.contents.last_stepper_status;
    const indexSelected =
      steps.findIndex((item) => item.id === lastStepper) ?? 0;

    currStep === -1 && onSetCurrentStep(indexSelected);
  }, [activeArchetype, currStep]);

  useEffect(() => {
    const currentStepId = steps[currStep]?.id as StepId;
    const propertyName = stepPropertyMap[currentStepId];
    const selectedStepData = activeArchetype?.contents[propertyName] as
      | Section
      | undefined;

    if (!selectedStepData) return;

    if (selectedFramework !== 'sdrcp' || message === 'No data found') {
      memoizedRouteOptionValue.isActive = false;
      memoizedRouteBackOptionValue.isActive = false;
    } else {
      switch (currStep) {
        case 0:
        case -1: {
          const stepName = stepPropertyMap[steps[1]?.id as StepId];
          memoizedRouteOptionValue.isActive =
            (activeArchetype.contents.goals_success_metrics_main_features?.data
              ?.length ?? 0) > 0;
          memoizedRouteBackOptionValue.isActive = false;
          memoizedRouteOptionValue.isInactive =
            isRegenerating ||
            !isAnyChecked(selectedStepData.data) ||
            activeArchetype.contents.goals_success_metrics_main_features
              ?.is_querying;
          memoizedRouteOptionValue.onClick = () => {
            if (!isEditor) {
              if (!activeArchetype.contents.trend_to_ride?.status) {
                setShowViewInfoModal(true);
                return;
              }

              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'discoverability',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            const isValid = isAnyChecked(selectedStepData.data);
            if (!isValid) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} each framework must selected at least one.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (
              activeArchetype.contents.goals_success_metrics_main_features
                ?.is_querying
            ) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} Please wait Goals is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (
              isRegenerating ||
              activeArchetype.contents.trend_to_ride?.is_querying ||
              discoverabilityTrend.isQuerying
            ) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} Please wait trend to ride is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (activeArchetype.contents.trend_to_ride?.status) {
              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'discoverability',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            if (isEditor) {
              removeProgressFromLocalStorage(
                projectSlug,
                stepName,
                activeArchetype.id,
              );

              generate(
                {
                  projectSlug,
                  section: 'content/discoverable/trends', // TODO: need to be check with api
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                    archetype_id: [activeArchetype.id],
                  },
                },
                {
                  onSuccess: () => {
                    dispatch(setEnableHistoryStatus(true));
                    updateLastStatus(
                      {
                        archetypeId: activeArchetype.id,
                        projectSlug,
                        section: 'discoverability',
                        status: stepName,
                      },
                      {
                        onSuccess: () => refetch(),
                      },
                    );
                  },
                },
              );
            }
          };

          if (!isEditor) {
            memoizedRouteOptionValue.isGenerate = false;
            memoizedRouteOptionValue.label = `Next to Trends (Archetype ${activeTab + 1})`;
            memoizedRouteOptionValue.isInactive =
              !selectedStepData.status_next_step;
          } else {
            memoizedRouteOptionValue.isGenerate =
              !selectedStepData.status_next_step;
            memoizedRouteOptionValue.label = selectedStepData.status_next_step
              ? `Next to Trends (Archetype ${activeTab + 1})`
              : `Generate Trends (Archetype ${activeTab + 1})`;
          }

          break;
        }

        case 1: {
          const stepBackName = stepPropertyMap[steps[0]?.id as StepId];
          const stepName = stepPropertyMap[steps[2]?.id as StepId];
          memoizedRouteOptionValue.isInactive =
            isRegenerating ||
            !isAnyChecked(selectedStepData.data) ||
            activeArchetype.contents.trend_to_ride?.is_querying ||
            discoverabilityTopic.isQuerying;
          memoizedRouteBackOptionValue.label = 'Back to Goals';
          memoizedRouteBackOptionValue.isActive = true;
          memoizedRouteBackOptionValue.onClick = () => {
            updateLastStatus(
              {
                archetypeId: activeArchetype.id,
                projectSlug,
                section: 'discoverability',
                status: stepBackName,
              },
              {
                onSuccess: () => refetch(),
              },
            );
          };
          memoizedRouteOptionValue.onClick = () => {
            if (!isEditor) {
              if (!activeArchetype.contents.content_topics?.status) {
                setShowViewInfoModal(true);
                return;
              }

              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'discoverability',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            const isValid = isAnyChecked(selectedStepData.data);
            if (!isValid) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} each framework must selected at least one.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (activeArchetype.contents.trend_to_ride?.is_querying) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} Please wait Trends is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (
              isRegenerating ||
              activeArchetype.contents.content_topics?.is_querying ||
              discoverabilityTopic.isQuerying
            ) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} Please wait topic is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (activeArchetype.contents.content_topics?.status) {
              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'discoverability',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            if (isEditor) {
              removeProgressFromLocalStorage(
                projectSlug,
                stepName,
                activeArchetype.id,
              );

              generate(
                {
                  projectSlug,
                  section: 'content/discoverable/topics',
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                    archetype_id: [activeArchetype.id],
                  },
                },
                {
                  onSuccess: () => {
                    dispatch(setEnableHistoryStatus(true));
                    updateLastStatus(
                      {
                        archetypeId: activeArchetype.id,
                        projectSlug,
                        section: 'discoverability',
                        status: stepName,
                      },
                      {
                        onSuccess: () => refetch(),
                      },
                    );
                  },
                },
              );
            }
          };

          if (!isEditor) {
            memoizedRouteOptionValue.isGenerate = false;
            memoizedRouteOptionValue.label = `Next to Topics (Archetype ${activeTab + 1})`;
            memoizedRouteOptionValue.isInactive =
              !selectedStepData.status_next_step;
          } else {
            memoizedRouteOptionValue.isGenerate =
              !selectedStepData.status_next_step;
            memoizedRouteOptionValue.label = selectedStepData.status_next_step
              ? `Next to Topics (Archetype ${activeTab + 1})`
              : `Generate Topics (Archetype ${activeTab + 1})`;
          }
          break;
        }

        case 2: {
          const stepBackName = stepPropertyMap[steps[1]?.id as StepId];
          const stepName = stepPropertyMap[steps[3]?.id as StepId];

          memoizedRouteBackOptionValue.label = 'Back to Trends';
          memoizedRouteBackOptionValue.isActive = true;
          memoizedRouteBackOptionValue.onClick = () => {
            updateLastStatus(
              {
                archetypeId: activeArchetype.id,
                projectSlug,
                section: 'discoverability',
                status: stepBackName,
              },
              {
                onSuccess: () => refetch(),
              },
            );
          };

          memoizedRouteOptionValue.isInactive =
            isRegenerating ||
            !isAnyChecked(selectedStepData.data) ||
            activeArchetype.contents.content_topics?.is_querying;
          memoizedRouteOptionValue.onClick = () => {
            if (!isEditor) {
              if (!activeArchetype.contents.content_ideas?.status) {
                setShowViewInfoModal(true);
                return;
              }

              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'discoverability',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            const isValid = isAnyChecked(selectedStepData.data);
            if (!isValid) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} each framework must selected at least one.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (
              isRegenerating ||
              discoverabilityIdea.isQuerying ||
              activeArchetype.contents.content_ideas?.is_querying
            ) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} Please wait ideas is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (activeArchetype.contents.content_topics?.is_querying) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} Please wait Topic is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (activeArchetype.contents.content_ideas?.status) {
              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'discoverability',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            if (isEditor) {
              removeProgressFromLocalStorage(
                projectSlug,
                stepName,
                activeArchetype.id,
              );

              generate(
                {
                  projectSlug,
                  section: 'content/discoverable/ideas',
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                    archetype_id: [activeArchetype.id],
                  },
                },
                {
                  onSuccess: () => {
                    dispatch(setEnableHistoryStatus(true));
                    updateLastStatus(
                      {
                        archetypeId: activeArchetype.id,
                        projectSlug,
                        section: 'discoverability',
                        status: stepName,
                      },
                      {
                        onSuccess: () => refetch(),
                      },
                    );
                  },
                },
              );
            }
          };

          if (!isEditor) {
            memoizedRouteOptionValue.isGenerate = false;
            memoizedRouteOptionValue.label = `Next to Ideas (Archetype ${activeTab + 1})`;
            memoizedRouteOptionValue.isDisabled =
              !selectedStepData.status_next_step;
          } else {
            memoizedRouteOptionValue.isGenerate =
              !selectedStepData.status_next_step;
            memoizedRouteOptionValue.label = selectedStepData.status_next_step
              ? `Next to Ideas (Archetype ${activeTab + 1})`
              : `Generate Ideas (Archetype ${activeTab + 1})`;
          }
          break;
        }

        default: {
          const stepBackName = stepPropertyMap[steps[2]?.id as StepId];
          memoizedRouteBackOptionValue.label = 'Back to Topics';
          memoizedRouteBackOptionValue.isActive = true;
          memoizedRouteBackOptionValue.onClick = () => {
            updateLastStatus(
              {
                archetypeId: activeArchetype.id,
                projectSlug,
                section: 'discoverability',
                status: stepBackName,
              },
              {
                onSuccess: () => refetch(),
              },
            );
          };

          memoizedRouteOptionValue.isGenerate = false;
          memoizedRouteOptionValue.isInactive =
            activeArchetype.contents.content_ideas?.is_querying;

          if (activeTab < totalArchetypes - 1) {
            memoizedRouteOptionValue.label = `Next to Discoverability Content (Archetype ${activeTab + 2})`;
            memoizedRouteOptionValue.onClick = () => {
              if (activeArchetype.contents.content_ideas?.is_querying) {
                dispatch(
                  saveSubInformation(
                    '{text-error-redx} Please wait Ideas is generating.',
                  ),
                );

                setTimeout(() => {
                  dispatch(saveSubInformation(''));
                }, 3000);
                return;
              }
              onSetActiveTab((oldVal) => oldVal + 1);
            };
          } else {
            memoizedRouteOptionValue.label = `Next to Credibility Content`;
            memoizedRouteOptionValue.onClick = () => {
              if (activeArchetype.contents.content_ideas?.is_querying) {
                dispatch(
                  saveSubInformation(
                    '{text-error-redx} Please wait Ideas is generating.',
                  ),
                );

                setTimeout(() => {
                  dispatch(saveSubInformation(''));
                }, 3000);
                return;
              }

              navigate(`/${projectSlug}/credibility-content`);
            };
          }
        }
      }
    }

    dispatch(saveRouteNext(memoizedRouteOptionValue));
    dispatch(saveRoutePrev(memoizedRouteBackOptionValue));
  }, [
    activeArchetype,
    steps,
    currStep,
    isEditor,
    selectedFramework,
    message,
    discoverabilityIdea,
    discoverabilityObjective,
    discoverabilityTrend,
    discoverabilityTopic,
    isRegenerating,
  ]);
};

export { useCtaAction };
