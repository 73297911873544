import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { CreativeIdeaSchema } from '@/Types/CreativeIdea/schema';

const post = async ({
  projectSlug,
  payload,
}: {
  projectSlug: string;
  payload: CreativeIdeaSchema;
}) => {
  try {
    const { data } = await goFetcher.post(
      `/projects/${projectSlug}/idea-submission/submit`,
      {
        content: {
          title: payload.title,
          description: payload.description,
        },
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateCreative = () => {
  return useMutation<
    any,
    Error,
    { projectSlug: string; payload: CreativeIdeaSchema }
  >({
    mutationFn: post,
  });
};

export { useUpdateCreative };
