import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react/dist/iconify.js';
import {
  forwardRef,
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import Content from '@/Components/Content';
import InputLabel from '@/Components/InputLabel';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import TextInput from '@/Components/TextInput';
import {
  type ProjectAction,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useUpdateCompetitorSubmission } from '@/Hooks/Submission';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import type { CompetitorProps } from '@/Types/Projects';
import type { SectionHandles } from '@/Types/Submission';
import { Competitor, type CompetitorSchema } from '@/Types/Submission/schema';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import ActionButtons from './Action';

interface CompetitorSectionProps {
  activeSection: number;
  projectSlug: string;
  user: UserResponse;
  isComplete: boolean;
  data: {
    competitors: CompetitorProps[];
  };
  isActive: boolean;
  isEditor: boolean;
  isEditingCount: number;
  onRefetchSubmission: () => void;
  onDispatch: React.Dispatch<ProjectAction>;
  onSetIsEditingCount: React.Dispatch<React.SetStateAction<number>>;
  onSetActiveSection: React.Dispatch<React.SetStateAction<number>>;
}

const CompetitorSection = forwardRef<SectionHandles, CompetitorSectionProps>(
  (
    {
      activeSection,
      projectSlug,
      user,
      data,
      isActive,
      isEditor,
      isEditingCount,
      isComplete,
      onDispatch,
      onRefetchSubmission,
      onSetIsEditingCount,
      onSetActiveSection,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const project = useMemo(() => state.project, [state.project]);
    const isEnabledHistoryStatus = useMemo(
      () => state.isEnabledHistoryStatus,
      [state.isEnabledHistoryStatus],
    );

    const maxCompetitorCount = 4;
    const { mutate } = useUpdateCompetitorSubmission();
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);

    const { mutate: generate } = useGenerate();

    const { isCompetitorAnalysisComplete } = useHistoryStatus(
      project,
      isEnabledHistoryStatus,
    );

    const [competitorCount, setCompetitorCount] = useState(
      Math.max(data.competitors.length, 1),
    );

    const defaultValues = useMemo(
      () => ({
        competitors: data.competitors.map((item) => ({
          id: item.id,
          name: item.name || '',
          website: item.website || '',
        })),
      }),
      [data],
    );

    const {
      handleSubmit,
      reset,
      control,
      setValue,
      formState: { isLoading, isValid },
    } = useForm<CompetitorSchema>({
      resolver: zodResolver(Competitor),
      defaultValues,
      mode: 'all',
    });

    const toggleIsEditing = useCallback(
      (isEdit: boolean) => {
        setIsEditing(isEdit);
        const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
        onSetIsEditingCount(newCount);
      },
      [isEditingCount, onSetIsEditingCount],
    );

    const onSubmit = async (data: CompetitorSchema) => {
      triggerGTMEvent({
        event: 'Edit Submission Competitor Information',
        eventCategory: 'Button Edit Submission Competitor Information Click',
        eventAction: 'Click',
        eventLabel: 'Submission Competitor Information',
        userId: user.email,
        data,
      });

      // Filter kompetitor yang memiliki isi
      let filteredCompetitors = data.competitors.filter(
        (competitor) =>
          competitor.name?.trim() !== '' || competitor.website?.trim() !== '',
      );

      // Jika semua kompetitor kosong, tambahkan satu entri default
      if (filteredCompetitors.length === 0) {
        filteredCompetitors = [{ name: '', website: '' }];
      }

      toggleIsEditing(true);
      mutate(
        {
          payload: { competitors: filteredCompetitors },
          projectSlug,
        },
        {
          onSuccess: () => {
            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                status: 'regenerate from submission',
              },
              projectSlug,
              section: 'competitor-analysis',
            });

            onDispatch(setEnableHistoryStatus(true));
            onRefetchSubmission();
            toggleIsEditing(false);
          },
        },
      );
    };

    useImperativeHandle(ref, () => ({
      save: async () => {
        await handleSubmit(onSubmit)();
        onSetActiveSection(activeSection + 1);
      },
      element: divRef.current,
    }));

    useEffect(() => {
      if (isEditing) {
        onDispatch(setEnableHistoryStatus(false));
        reset(defaultValues);
      } else {
        onDispatch(setEnableHistoryStatus(true));
      }
    }, [isEditing]);

    useEffect(() => {
      setCompetitorCount(Math.max(data.competitors.length, 1));
    }, [data.competitors]);

    const handleCancel = () => {
      setIsEditing(false);

      reset({
        competitors: defaultValues.competitors,
      });
      setCompetitorCount(defaultValues.competitors.length);
    };

    const isSaveDisabled = useMemo(() => !isValid, [isValid]);

    const handleAddCompetitor = () => {
      if (competitorCount < maxCompetitorCount) {
        setCompetitorCount((prev) => prev + 1);
        setValue(`competitors.${competitorCount}`, {
          id: -1,
          name: '',
          website: '',
        });
      }
    };

    const handleSave = useCallback(async () => {
      if (isCompetitorAnalysisComplete.isQuerying) {
        onDispatch(
          saveSubInformation(
            `{text-error-redx} Please wait product research is still generating`,
          ),
        );
        return;
      }
      if (isCompetitorAnalysisComplete.status) {
        setIsModalOpen(true);
        return;
      }
      await handleSubmit(onSubmit)();
    }, [
      isCompetitorAnalysisComplete.status,
      isCompetitorAnalysisComplete.isQuerying,
    ]);

    return (
      <div
        ref={divRef}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Competitor Information
          </h1>
          <ActionButtons
            isActive={isActive && isComplete}
            isEditing={isEditing}
            isEditor={isEditor}
            isFetching={false}
            isValid={isSaveDisabled}
            onCancel={handleCancel}
            onEdit={() => setIsEditing(true)}
            onSave={handleSave}
          />
        </div>
        <div className="grid w-full grid-cols-2 gap-24">
          {[...Array(competitorCount)].map((_, index) => (
            <Fragment key={index}>
              <div>
                <InputLabel value={`Competitor Name ${index + 1} (Optional)`} />
                {isEditing || !isComplete ? (
                  <Controller
                    control={control}
                    name={`competitors.${index}.name`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextInput
                          autoComplete="off"
                          className="mt-1 block w-full text-14"
                          containerClassName="border-b-2 border-soft-purplestroke-redx"
                          error={error?.message}
                          onChange={onChange}
                          placeholder="Input the name here"
                          value={value}
                        />
                      );
                    }}
                  />
                ) : (
                  <Content
                    isFetching={false}
                    isMarkDown={false}
                    value={data.competitors[index]?.name || '-'}
                  />
                )}
              </div>
              <div>
                <InputLabel
                  value={`Competitor Website ${index + 1} (Optional)`}
                />
                {isEditing || !isComplete ? (
                  <Controller
                    control={control}
                    name={`competitors.${index}.website`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextInput
                          autoComplete="off"
                          className="mt-1 block w-full text-14"
                          containerClassName="border-b-2 border-soft-purplestroke-redx"
                          error={error?.message}
                          onChange={onChange}
                          placeholder="Input the website here"
                          value={value}
                        />
                      );
                    }}
                  />
                ) : (
                  <Content
                    isFetching={false}
                    isMarkDown={false}
                    value={data.competitors[index]?.website || '-'}
                  />
                )}
              </div>
            </Fragment>
          ))}
        </div>

        {(isEditing || !isComplete) && (
          <div className="mb-24 flex justify-end">
            <button
              className={`mt-10 inline-flex items-center gap-x-4 ${
                competitorCount < maxCompetitorCount
                  ? 'cursor-pointer text-blue-redx'
                  : 'cursor-not-allowed text-grey-redx'
              }`}
              disabled={competitorCount >= maxCompetitorCount}
              onClick={handleAddCompetitor}
            >
              <p className="text-14 font-semibold">Add Competitor</p>
              <Icon
                className={`size-16 ${
                  competitorCount < maxCompetitorCount
                    ? 'text-blue-redx'
                    : 'text-grey-redx'
                }`}
                icon="material-symbols:add"
              />
            </button>
          </div>
        )}

        <ChangingImpactModal
          handleSubmit={async () => {
            await handleSubmit(onSubmit)();
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          isRegenerating={isLoading}
          onClose={() => {
            handleCancel();
            toggleIsEditing(true);
            setIsModalOpen(false);
          }}
          section="competitor-information"
          visibleSections={[
            'Competitor Analysis',
            'Opportunity Among Competition',
            'One-Page Strategy',
            'Key Touch Point',
            'Idea Alignment',
            'Idea Personalization',
            'Idea Expansion',
            'Searchability Content',
            'Discoverability Content',
            'Credibility Content',
            'Selected Content Ideas',
          ]}
        />
      </div>
    );
  },
);

export default memo(CompetitorSection);
