/* eslint-disable react/prop-types */
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Icon } from '@iconify/react';
import React from 'react';

import type { SelectedCardProps } from '@/Types/props';

import MarkdownView from './MardownView';

// Pindahkan ke luar SelectedCard
const CardContent: React.FC<{ title: string; content: string }> = React.memo(
  ({ title, content }) => {
    return (
      <div className="flex flex-col gap-5">
        <p className="text-13 font-semibold leading-14 text-black-redx">
          {title}
        </p>
        <div className="text-12 leading-relaxed">
          <MarkdownView content={content} />
        </div>
      </div>
    );
  },
);

const CardHeader: React.FC<{
  label: string;
  open: boolean;
  onClick: () => void;
}> = React.memo(({ label, open, onClick }) => {
  return (
    <div className="flex grow items-center justify-between">
      <h3 className="text-14 font-bold leading-16 text-black-redx">{label}</h3>
      <Icon
        className="size-24 cursor-pointer text-black-redx"
        icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'}
        onClick={onClick}
      />
    </div>
  );
});

const SelectedCard: React.FC<{
  data: SelectedCardProps[];
  title: string;
}> = React.memo(({ data, title }) => {
  return (
    <Popover className="relative border-y-1 border-y-blue-purple-redx bg-soft-purple-redx data-[open]:opacity-0">
      <PopoverButton className="z-30 w-full p-16">
        <div className="flex flex-col gap-12">
          <CardHeader label={title} onClick={() => {}} open={false} />
        </div>
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-0"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-0"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          anchor={{ to: 'bottom end', gap: -53 }}
          className="z-30 w-[var(--button-width)] border-y-1 border-y-blue-purple-redx bg-soft-purple-redx p-16 shadow-[0px_8px_12px_0px_rgba(0,0,0,0.12)]"
        >
          {({ close }) => (
            <div className="flex flex-col gap-12">
              <CardHeader label={title} onClick={() => close()} open />

              <div className="grid grid-cols-3 gap-24 text-left">
                {data.map((item, index) => (
                  <CardContent
                    key={index}
                    content={item.content}
                    title={item.title}
                  />
                ))}
              </div>
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
});

export default SelectedCard;
