import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { GetDifferentResponse } from '@/Types/AudienceArchetype';
import type { HistoryStatus } from '@/Types/history';
import type { ArchetypeIdeaExpansion } from '@/Types/IdeaExpansion';
import type { ProjectProps } from '@/Types/Projects';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import { useUpdateLastStatusSubmission } from '../Submission';
import { useGenerate } from '../useGenerate';

interface UseCtaParams {
  searchabilityObjective: HistoryStatus;
  discoverabilityObjective: HistoryStatus;
  credibilityObjective: HistoryStatus;
  diffArchetype: GetDifferentResponse | undefined;
  message: string;
  routeOptionValue: RouteOption;
  activeArchetype: ArchetypeIdeaExpansion | null;
  totalSelected: number;
  isEditor: boolean;
  isEditing: boolean;
  project: ProjectProps;
  archetypesArray: ArchetypeIdeaExpansion[];
  setErrorTabIndex: React.Dispatch<React.SetStateAction<number[]>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCtaAction = ({
  diffArchetype,
  message,
  isEditor,
  routeOptionValue,
  project,
  totalSelected,
  isEditing,
  archetypesArray,
  activeArchetype,
  setErrorTabIndex,
  setRouteOption,
  setShowViewInfoModal,
}: UseCtaParams) => {
  const showContent = (import.meta.env.VITE_SHOW_CONTENT ?? 'true') === 'true';

  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;
  const { mutateAsync: generate, isPending: isRegenerating } = useGenerate();
  const funnelMapping = {
    searchable: 'content/searchable/objective',
    discoverable: 'content/discoverable/objective',
    credible: 'content/credible/objective',
  };

  const stepperMapping = {
    searchable: 'searchability',
    discoverable: 'discoverability',
    credible: 'credibility',
  };

  const { mutate: updateLastStatus } = useUpdateLastStatusSubmission();
  const existingFunnels = useMemo(
    () =>
      new Set(
        activeArchetype?.idea_expansions.flatMap((item) =>
          item.funnel ? [item.funnel] : [],
        ),
      ),
    [activeArchetype],
  );

  // Memoize routeOptionValue to avoid unnecessary re-render
  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const hasSelected = (archetypesArray: ArchetypeIdeaExpansion[]) => {
    setErrorTabIndex([]);

    const indicesWithoutSelectedKeyIdeaExpansion = archetypesArray
      .map((archetype, index) => {
        const hasSelectedIdeaExpansion = archetype.idea_expansions.some(
          (ideaExpansion) => ideaExpansion.total_selected_idea_expansions > 0,
        );
        return hasSelectedIdeaExpansion ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyIdeaExpansion.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyIdeaExpansion);

      const namesWithoutSelectedKeyIdeaExpansion =
        indicesWithoutSelectedKeyIdeaExpansion
          .map((index) => `Archetype ${index + 1}`) // Sesuaikan nama archetype
          .join(', ');

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select at least one idea expansion for ${namesWithoutSelectedKeyIdeaExpansion}`,
        ),
      );
      return true;
    }

    // Jika semua archetypes valid
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleEditorClick = useCallback(async () => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea expansion is still generating.`,
        ),
      );
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    const hasError = hasSelected(archetypesArray);

    if (!hasError) {
      if (
        activeArchetype?.framework === 'sdrcp' &&
        ((!activeArchetype?.credibility_content_sections.status &&
          existingFunnels.size === 1 &&
          existingFunnels.has('credible')) ||
          (!activeArchetype?.discoverability_content_sections.status &&
            existingFunnels.size === 1 &&
            existingFunnels.has('discoverable')) ||
          (!activeArchetype?.searchability_content_sections.status &&
            existingFunnels.size === 1 &&
            existingFunnels.has('searchable')) ||
          (activeArchetype?.credibility_content_sections.is_regenerate &&
            existingFunnels.size === 1 &&
            existingFunnels.has('credible')) ||
          (activeArchetype?.discoverability_content_sections.is_regenerate &&
            existingFunnels.size === 1 &&
            existingFunnels.has('discoverable')) ||
          (activeArchetype?.searchability_content_sections.is_regenerate &&
            existingFunnels.size === 1 &&
            existingFunnels.has('searchable')))
      ) {
        removeAllArchetypesBasedOnSectionFromLocalStorage(
          project.slug,
          'searchability_goals_success_metrics_main_features',
        );
        removeAllArchetypesBasedOnSectionFromLocalStorage(
          project.slug,
          'credibility_goals_success_metrics_main_features',
        );
        removeAllArchetypesBasedOnSectionFromLocalStorage(
          project.slug,
          'discoverability_goals_success_metrics_main_features',
        );

        const promises = Object.keys(funnelMapping)
          .filter((funnel) => existingFunnels.has(funnel)) // Filter hanya funnel yang ada
          .map((funnel) =>
            generate({
              payload: {
                generate_more: '',
                regenerate_prompt: '',
                archetype_id: [activeArchetype.id],
              },
              projectSlug: project.slug,
              section: funnelMapping[funnel as keyof typeof funnelMapping],
            }).then(() =>
              updateLastStatus({
                archetypeId: activeArchetype.id,
                projectSlug: project.slug,
                section: stepperMapping[funnel as keyof typeof funnelMapping],
                status: 'goals_success_metrics_main_features',
              }),
            ),
          );

        // Jalankan semua promise sekaligus tanpa menunggu satu sama lain
        Promise.allSettled(promises).then(() => {
          navigate(`/${project.slug}/searchability-content`);
        });

        return;
      }

      navigate(`/${project.slug}/searchability-content`);
    }
  }, [existingFunnels, isEditing, archetypesArray]);

  const handleNonEditorClick = useCallback(() => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea Expansion is still generating.`,
        ),
      );
      return;
    }

    if (
      (!activeArchetype?.credibility_content_sections.status &&
        existingFunnels.size === 1 &&
        existingFunnels.has('credible')) ||
      (!activeArchetype?.discoverability_content_sections.status &&
        existingFunnels.size === 1 &&
        existingFunnels.has('discoverable')) ||
      (!activeArchetype?.searchability_content_sections.status &&
        existingFunnels.size === 1 &&
        existingFunnels.has('searchable')) ||
      (activeArchetype?.credibility_content_sections.is_regenerate &&
        existingFunnels.size === 1 &&
        existingFunnels.has('credible')) ||
      (activeArchetype?.discoverability_content_sections.is_regenerate &&
        existingFunnels.size === 1 &&
        existingFunnels.has('discoverable')) ||
      (activeArchetype?.searchability_content_sections.is_regenerate &&
        existingFunnels.size === 1 &&
        existingFunnels.has('searchable'))
    ) {
      setShowViewInfoModal(true);
      return;
    }

    if (
      activeArchetype?.searchability_content_sections.status &&
      existingFunnels.size === 1 &&
      existingFunnels.has('searchable')
    ) {
      navigate(`/${projectSlug}/searchability-content`);
    } else if (
      activeArchetype?.discoverability_content_sections.status &&
      existingFunnels.size === 1 &&
      existingFunnels.has('discoverable')
    ) {
      navigate(`/${projectSlug}/discoverability-content`);
    } else {
      navigate(`/${projectSlug}/credibility-content`);
    }
  }, [activeArchetype]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive =
      showContent &&
      (message !== 'No data found' ||
        activeArchetype?.framework === 'marketing_funnel');
    memoizedRouteOptionValue.isInactive =
      activeArchetype?.is_querying || totalSelected === 0;

    if (isEditor) {
      if (
        (!activeArchetype?.credibility_content_sections.status &&
          existingFunnels.size === 1 &&
          existingFunnels.has('credible')) ||
        (!activeArchetype?.discoverability_content_sections.status &&
          existingFunnels.size === 1 &&
          existingFunnels.has('discoverable')) ||
        (!activeArchetype?.searchability_content_sections.status &&
          existingFunnels.size === 1 &&
          existingFunnels.has('searchable'))
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        if (existingFunnels.size === 1 && existingFunnels.has('searchable')) {
          memoizedRouteOptionValue.label = `Generate Searchability Content (Archetype ${activeArchetype?.archetype_number})`;
        } else if (
          existingFunnels.size === 1 &&
          existingFunnels.has('credible')
        ) {
          memoizedRouteOptionValue.label = `Generate Credibility Content (Archetype ${activeArchetype?.archetype_number})`;
        } else if (
          existingFunnels.size === 1 &&
          existingFunnels.has('discoverable')
        ) {
          memoizedRouteOptionValue.label = `Generate Discoverability Content (Archetype ${activeArchetype?.archetype_number})`;
        } else {
          memoizedRouteOptionValue.label = `Generate Content (Archetype ${activeArchetype?.archetype_number})`;
        }
      } else if (
        (activeArchetype?.credibility_content_sections.is_regenerate &&
          existingFunnels.size === 1 &&
          existingFunnels.has('credible')) ||
        (activeArchetype?.discoverability_content_sections.is_regenerate &&
          existingFunnels.size === 1 &&
          existingFunnels.has('discoverable')) ||
        (activeArchetype?.searchability_content_sections.is_regenerate &&
          existingFunnels.size === 1 &&
          existingFunnels.has('searchable'))
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        if (existingFunnels.size === 1 && existingFunnels.has('searchable')) {
          memoizedRouteOptionValue.label = `Generate Searchability Content (Archetype ${activeArchetype?.archetype_number})`;
        } else if (
          existingFunnels.size === 1 &&
          existingFunnels.has('credible')
        ) {
          memoizedRouteOptionValue.label = `Generate Credibility Content (Archetype ${activeArchetype?.archetype_number})`;
        } else if (
          existingFunnels.size === 1 &&
          existingFunnels.has('discoverable')
        ) {
          memoizedRouteOptionValue.label = `Generate Discoverability Content (Archetype ${activeArchetype?.archetype_number})`;
        } else {
          memoizedRouteOptionValue.label = `Generate Content (Archetype ${activeArchetype?.archetype_number})`;
        }
      } else {
        memoizedRouteOptionValue.isGenerate = false;
        memoizedRouteOptionValue.label = 'Next to Searchability Content';
      }
      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    memoizedRouteOptionValue.isDisabled = isRegenerating;
    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    showContent,
    existingFunnels,
    diffArchetype,
    activeArchetype,
    isEditor,
    totalSelected,
    message,
    isRegenerating,
  ]);
};

export { useCtaAction };
