import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

import type { RouteOption } from '../Reducer/project-reducer';
import { ProjectActionsTypes } from './action-type';

export interface SaveProjectDataAction {
  type: ProjectActionsTypes.SET_PROJECT_DATA;
  payload: ProjectProps;
}

export interface SaveRouteNextAction {
  type: ProjectActionsTypes.SET_ROUTE_NEXT;
  payload: RouteOption;
}

export interface SaveUserAction {
  type: ProjectActionsTypes.SET_USER;
  payload: UserResponse;
}

export interface SetEnableHistoryStatus {
  type: ProjectActionsTypes.SET_ENABLED_HISTORY_STATUS;
  payload: boolean;
}

export interface SaveRoutePrevAction {
  type: ProjectActionsTypes.SET_ROUTE_PREV;
  payload: RouteOption;
}

export interface SaveInformationAction {
  type: ProjectActionsTypes.SET_INFORMATION;
  payload: string;
}
export interface SaveSubInformationAction {
  type: ProjectActionsTypes.SET_SUB_INFORMATION;
  payload: string;
}

export const saveProjectData = (
  projectData: ProjectProps,
): SaveProjectDataAction => {
  return {
    type: ProjectActionsTypes.SET_PROJECT_DATA,
    payload: projectData,
  };
};

export const setEnableHistoryStatus = (
  isEnabled: boolean,
): SetEnableHistoryStatus => {
  return {
    type: ProjectActionsTypes.SET_ENABLED_HISTORY_STATUS,
    payload: isEnabled,
  };
};

export const saveUser = (userResponse: UserResponse): SaveUserAction => {
  return {
    type: ProjectActionsTypes.SET_USER,
    payload: userResponse,
  };
};

export const saveRouteNext = (routeNext: RouteOption): SaveRouteNextAction => {
  return {
    type: ProjectActionsTypes.SET_ROUTE_NEXT,
    payload: routeNext,
  };
};

export const saveRoutePrev = (routePrev: RouteOption): SaveRoutePrevAction => {
  return {
    type: ProjectActionsTypes.SET_ROUTE_PREV,
    payload: routePrev,
  };
};

export const saveInformation = (information: string): SaveInformationAction => {
  return {
    type: ProjectActionsTypes.SET_INFORMATION,
    payload: information,
  };
};
export const saveSubInformation = (
  subInformation: string,
): SaveSubInformationAction => {
  return {
    type: ProjectActionsTypes.SET_SUB_INFORMATION,
    payload: subInformation,
  };
};

export type ProjectAction =
  | SaveProjectDataAction
  | SaveUserAction
  | SaveRouteNextAction
  | SaveRoutePrevAction
  | SaveInformationAction
  | SaveSubInformationAction
  | SetEnableHistoryStatus;

export { ProjectActionsTypes };
