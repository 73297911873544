import CardTitle from '@/Components/CardTitle';
import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import type { ProjectProps } from '@/Types/Projects';
import type { Idea } from '@/Types/SelectedContentIdeas';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

const Card = ({
  title,
  archetypeId,
  framework,
  user,
  id,
  index,
  recommendationNumber,
  item,
  isEditor,
  isFetching = false,
  isSelected = false,
  totalData = 0,
  project,
  onItemChange,
}: {
  user: UserResponse;
  id: number | string;
  recommendationNumber: number;
  archetypeId: number;
  framework: string;
  title: string;
  index: number;
  project: ProjectProps;
  item?: Idea;
  isSelected?: boolean;
  isFetching?: boolean;
  isEditor: boolean;
  totalData?: number;
  onItemChange?: (
    archetypeId: number,
    title: string,
    framework: string,
    recommendationNumber: number,
    id: number,
    checked: boolean,
  ) => void;
}) => {
  const handleSelected = (id: number, isChecked: boolean) => {
    onItemChange?.(
      archetypeId,
      title,
      framework,
      recommendationNumber,
      +id,
      false,
    );

    // TODO: Integrating with be
    triggerGTMEvent({
      event: `Select Selected Content Ideas`,
      eventCategory: `Select Selected Content Ideas Click`,
      eventAction: 'Click',
      eventLabel: 'Selected Content Ideas',
      userId: user.email,
      data: {
        id,
        title,
        archetypeId,
        framework,
        user,
        project: project.slug,
        type: isChecked ? 'Unselect' : 'Select',
        index,
      },
    });
  };

  return (
    <>
      {/* Header */}
      {!isFetching ? (
        <div className="w-full text-center">
          <CardTitle
            id={+id}
            index={index}
            isEdited={item?.is_edited ?? false}
            label="Content Idea"
            totalData={totalData ?? 1}
          />
        </div>
      ) : (
        <div className="inline-flex w-full items-center justify-center gap-8">
          <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
          <div className="h-20 w-1 border-1 border-stroke-redx" />
          <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
        </div>
      )}

      {/* Main Content */}
      <div
        className={`mt-10 flex w-full flex-col gap-y-8 px-18 py-20 text-black-redx ${
          isSelected ? '!bg-transparent' : ''
        } border-t-1 border-stroke-redx`}
      >
        <div className="grid grid-cols-2 gap-x-24 gap-y-15 pb-8">
          <CardInput
            defaultValue={item?.who_should_say_it || ''}
            isEditing={false}
            isFetching={isFetching}
            placeholder="Input who should say it here"
            showTitle
            title="Who Should Say It"
          />
          <CardInput
            defaultValue={item?.category || ''}
            isEditing={false}
            isFetching={isFetching}
            placeholder="Input category here"
            showTitle
            title="Category"
          />
          <CardInput
            defaultValue={item?.content_idea || ''}
            isEditing={false}
            isFetching={isFetching}
            placeholder="Input content idea here"
            showTitle
            title="Content Idea"
          />
          <CardInput
            defaultValue={item?.content_format || ''}
            isEditing={false}
            isFetching={isFetching}
            placeholder="Input format here"
            showTitle
            title="Format"
          />
        </div>
      </div>

      {/* Footer */}
      <div className="flex w-full justify-center px-1 py-15">
        {id !== -1 && isEditor && (
          <SelectButton
            descPopUp="This will also unselect the content idea on its original page. If you want to select it again, please choose it on the content page."
            hasOne={false}
            hasPopUp
            index={index}
            isEditing={false}
            isLoading={false} // Placeholder for future state
            isSelected={isSelected}
            onSelect={() => handleSelected(+id, item?.is_checked ?? false)}
            section="searchability-content"
            titlePopUp="Unselect this Content Idea?"
          />
        )}
      </div>
    </>
  );
};

export default Card;
