interface RoleBadgeProps {
  role: string;
}

const RoleBadge = ({ role }: RoleBadgeProps) => {
  return (
    <div className="inline-flex items-center rounded-full bg-soft-purple-redx px-12 py-6 text-blue-redx">
      <p className="text-14 font-semibold">{role}</p>
    </div>
  );
};

export default RoleBadge;
