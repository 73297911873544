import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';

import type {
  CompetitorAnalysisContentData,
  CompetitorAnalysisListResponse,
  SectionData,
} from '@/Types/CompetitorAnalysis';
import type { HistoryStatus } from '@/Types/history';

interface UseSectionDataHandlerProps {
  isDiffArchetype: boolean;
  isOpportunityAmongCompetitionComplete: HistoryStatus;
  data: CompetitorAnalysisListResponse | undefined;
  sectionData: Record<string, SectionData>;
  sectionKey: string;
  projectSlug: string;
  initializeGeneratingStates: (dataArray: any[]) => Record<number, boolean>;
  getSectionProgressFromLocalStorage: (slug: string, section: string) => any;
  initializeProgressStates: (
    dataArray: any[],
    savedProgress: any,
    progressStates: Record<number, number>,
  ) => Record<number, number>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSectionData: React.Dispatch<
    React.SetStateAction<Record<string, SectionData>>
  >;
  progressStates: Record<number, number>;
}

export const useSectionDataHandler = ({
  isDiffArchetype,
  isOpportunityAmongCompetitionComplete,
  data,
  sectionData,
  sectionKey,
  projectSlug,
  initializeGeneratingStates,
  getSectionProgressFromLocalStorage,
  initializeProgressStates,
  setGeneratingStates,
  setProgressStates,
  setSectionData,
  progressStates,
}: UseSectionDataHandlerProps) => {
  const hasDifferences = useCallback(
    (
      current: CompetitorAnalysisContentData[],
      previous: CompetitorAnalysisContentData[],
    ): boolean => {
      if (
        isDiffArchetype ||
        isOpportunityAmongCompetitionComplete.isRegenerated ||
        !isOpportunityAmongCompetitionComplete.status
      ) {
        return false;
      }

      if (current.length !== previous.length) {
        return true; // Jika panjang array berbeda, langsung return true
      }

      // Bandingkan setiap item berdasarkan `brand_name`
      for (const currentItem of current) {
        const matchingPreviousItem = previous.find(
          (prevItem) => prevItem.brand_name === currentItem.brand_name,
        );

        if (!matchingPreviousItem) {
          return true; // Jika tidak ditemukan item dengan brand_name yang sama, data dianggap berbeda
        }

        // Bandingkan properti-properti penting
        const keysToCompare: (keyof CompetitorAnalysisContentData)[] = [
          'product_name',
          'communication_angle',
          'communication_idea',
          'communication_tone',
          'features_benefits',
          'key_touch_points',
          'opportunities',
        ];

        for (const key of keysToCompare) {
          if (!isEqual(currentItem[key], matchingPreviousItem[key])) {
            return true; // Jika ada properti yang berbeda, data dianggap berbeda
          }
        }
      }

      return false; // Jika semua item sama, return false
    },
    [
      isOpportunityAmongCompetitionComplete.isRegenerated,
      isDiffArchetype,
      isOpportunityAmongCompetitionComplete.status,
    ],
  );

  useEffect(() => {
    if (data) {
      const existingData = sectionData[sectionKey];

      if (data.is_querying) {
        const dataArray = [
          {
            id: data.history_id,
            ...data,
          },
        ];

        // Initialize generating states
        const initialStates = initializeGeneratingStates(dataArray);
        setGeneratingStates(initialStates);

        // Retrieve and initialize progress states
        const savedProgress = getSectionProgressFromLocalStorage(
          projectSlug,
          sectionKey,
        );
        const updatedProgressStates = initializeProgressStates(
          dataArray,
          savedProgress,
          progressStates,
        );

        setProgressStates((prev) => ({
          ...prev,
          ...updatedProgressStates,
        }));
      }

      const isDiff = hasDifferences(
        data.content,
        data.previous_selected_competitors,
      );

      // Update section data if there's a difference
      if (!isEqual(existingData, data)) {
        setSectionData((prev) => ({
          ...prev,
          [sectionKey]: {
            is_edited: data.is_edited ?? false,
            history_id: data.history_id ?? 0,
            is_querying: data.is_querying ?? false,
            is_email_notification: data.is_email_notification ?? false,
            sources: data.sources ?? [],
            total_regenerate: data.total_regenerate ?? 0,
            content: data.content ?? [],
            is_diff_competitor: isDiff,
            message: data.message,
          },
        }));
      }
    }
  }, [data]);
};
