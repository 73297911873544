import PrimaryButton from '@/Components/PrimaryButton';

interface ProjectTitleProps {
  onSetIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectTitle = ({ onSetIsOpen }: ProjectTitleProps) => {
  return (
    <div className="mt-20 flex flex-row items-center justify-center">
      <div className="basis-1/2">
        <div className="flex flex-col gap-10">
          <h1 className="text-32 font-semibold leading-[36px] text-black-redx sm:text-[24px] sm:leading-[28px]">
            Your AInstein Projects
          </h1>
          <p className="text-16 font-normal leading-20 text-grey-redx">
            Create, search, and choose your marketing project here.
          </p>
        </div>
      </div>
      <div className="basis-1/2 text-right">
        <PrimaryButton
          className="ms-4 w-[187px] bg-blue-purple-redx"
          disabled={false}
          id="btn-show-form-create-project"
          onClick={() => onSetIsOpen(true)}
        >
          Create Project
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ProjectTitle;
