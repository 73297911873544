import { memo } from 'react';

interface LabelProps {
  value: string;
}

const Label = ({ value = '' }: LabelProps) => {
  return (
    <p className="text-12 font-semibold leading-14 text-black-redx">{value}</p>
  );
};

export default memo(Label);
