import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from 'react';

import { useApprovedUsers, useProjects } from '@/Hooks/Project';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

import FilterAndSort from './Components/FilterAndSort';
import FormModal from './Components/FormModal';
import NoAccessModal from './Components/NoAccessModal';
import ProjectLists from './Components/ProjectLists';
import ProjectTitle from './Components/ProjectTitle';

const APP_URL = new URL(window.location.href);
const BASE_URL = APP_URL.origin;

const Index = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNoAccess, setIsOpenNoAccess] = useState(false);
  const { data: approvedUsers } = useApprovedUsers();
  const [initialUsers, setInitialUsers] = useState<UserResponse[]>([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [allProjects, setAllProjects] = useState<ProjectProps[]>([]);
  const [projectFound, setProjectFound] = useState(true);
  const [projectMessage, setProjectMessage] = useState<string>();
  const observerRef = useRef<HTMLDivElement | null>(null); // Ref untuk Intersection Observer
  const [sortedProjects, setSortedProjects] = useState<ProjectProps[]>([]);
  const [keyLine, setKeyFilterLine] = useState(+new Date());

  const {
    data: projects,
    isPending,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useProjects({ search, sort, date: keyLine });

  const toggleModal = () => setIsOpen((prev) => !prev);
  const closeModalNoAccess = () => setIsOpenNoAccess(false);

  useEffect(() => {
    setInitialUsers(approvedUsers?.users || []);
    setProjectFound(Cookies.get('project_found') !== 'false');
  }, [approvedUsers]);

  useEffect(() => {
    if (projectFound === false) {
      const name = Cookies.get('project_owner_name');
      const email = Cookies.get('project_owner_email');

      setProjectMessage(`You need an invitation to access this Ainstein project. 
Please contact the project owner, <b>${name} (${email})</b>, to be added as a teammate.`);
      setIsOpenNoAccess(true);
    }
  }, [projectFound]);

  useEffect(() => {
    if (projects?.pages) {
      const newProjects = projects.pages.flatMap((page) => page.projects);

      setAllProjects((prev) => {
        if (search) {
          // Replace semua data saat searching
          return newProjects;
        }
        // Jika tidak ada search, tambahkan data baru (untuk infinite scroll)
        const uniqueProjects = newProjects.filter(
          (newProject) =>
            !prev.some((prevProject) => prevProject.id === newProject.id),
        );
        return [...prev, ...uniqueProjects];
      });
    }
  }, [projects?.pages, search]);

  // Sorting saat allProjects atau sort berubah
  useEffect(() => {
    const sorted = [...allProjects];

    if (sort === 'project-asc') {
      sorted.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sort === 'project-desc') {
      sorted.sort((a, b) => b.name.localeCompare(a.name));
    } else if (sort === 'created-asc') {
      sorted.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      );
    } else if (sort === 'created-desc') {
      sorted.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
    }

    setSortedProjects(sorted); // Update state untuk ditampilkan
  }, [sort, allProjects]);

  // Intersection Observer untuk memuat lebih banyak data
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
          const lastPage = projects?.pages[projects.pages.length - 1];
          if (lastPage?.next === 999999999) {
            return; // Hentikan fetching jika sudah mencapai akhir
          }
          fetchNextPage();
        }
      },
      {
        root: null, // Gunakan viewport sebagai root
        rootMargin: '0px',
        threshold: 0.1, // Callback dipicu saat 10% elemen loader terlihat
      },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, projects?.pages]);

  useEffect(() => {
    setAllProjects([]);
    setKeyFilterLine(+new Date());
  }, []);

  return (
    <>
      <div className="pt-73">
        <div className="mx-auto flex max-w-7xl flex-col sm:px-6 lg:px-8">
          <ProjectTitle onSetIsOpen={toggleModal} />
          <FilterAndSort
            isDisabled={!projects?.pages.length || isPending || isLoading}
            onSetSearch={setSearch}
            onSetSort={setSort}
          />
          <div className="flex-1 overflow-scroll pb-24 scrollbar-hide">
            <ProjectLists
              initialUsers={initialUsers}
              isLoading={isPending || isLoading}
              onRefetchLists={refetch}
              projects={sortedProjects}
            />
            {hasNextPage && (
              <div ref={observerRef} className="p-24 text-center">
                {isFetchingNextPage ? (
                  <p className="font-medium">Loading more projects...</p>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <FormModal
        appUrl={BASE_URL}
        id={-1}
        initialUsers={initialUsers}
        isOpen={isOpen}
        onClose={toggleModal}
      />
      <NoAccessModal
        isOpen={isOpenNoAccess}
        message={projectMessage!}
        onClose={closeModalNoAccess}
      />
    </>
  );
};

export default Index;
