import type { TargetAudienceItem } from '@/Types/TargetAudience';

export const jsonToMarkdown = (data: TargetAudienceItem[]) => {
  let markdown = '';
  data.forEach((item) => {
    if (item.title && item.content) {
      markdown += `**${item.title}**: ${item.content}\n`;
    } else {
      markdown += `${item.content}\n`;
    }
  });

  return markdown;
};

export const markdownToJson = (markdown: string) => {
  const lines = markdown.split('\n');
  const parsedContent: TargetAudienceItem[] = [];

  lines.forEach((line) => {
    // Pastikan baris memiliki karakter ":"
    if (line.includes(':')) {
      const regex = /(\*\*.+?\*\*):/; // Regex untuk menangkap title
      const match = regex.exec(line);

      if (match) {
        let title = match[1].trim(); // Ambil title
        const content = line.replace(regex, '').trim(); // Sisanya jadi content

        // Bersihkan title dari kelebihan asterisks
        title = title.replace(/\*+/g, '').trim(); // Hilangkan semua '*'
        title = `**${title}**`; // Tambahkan hanya 2 asterisks di awal dan akhir

        if (title && content) {
          parsedContent.push({
            title,
            content,
          });
        }
      }
    } else if (line.trim().length > 0) {
      // Jika baris tidak memiliki ":" tetapi ada teks
      parsedContent.push({
        title: '',
        content: line.trim(),
      });
    }
  });

  return parsedContent;
};
