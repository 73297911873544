/* eslint-disable import/no-extraneous-dependencies */
import { Icon } from '@iconify/react';
import Link from '@tiptap/extension-link';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import showdown from 'showdown';
import { Markdown } from 'tiptap-markdown';
import TurndownService from 'turndown';

import { adjustOrderedList } from '@/Utils/markdown-adjust-order-list';

interface MarkdownEditorProps {
  content: string;
  onChange: (html: any) => void;
}
const MarkdownEditor = ({ content, onChange }: MarkdownEditorProps) => {
  const showdownService = new showdown.Converter({
    omitExtraWLInCodeBlocks: true,
    noHeaderId: true,
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true,
    strikethrough: true,
    tables: true,
    openLinksInNewWindow: true,
    emoji: true,
    simpleLineBreaks: true,
    encodeEmails: false,
    requireSpaceBeforeHeadingText: false,
  });

  let html = showdownService.makeHtml(content);
  html = adjustOrderedList(html);

  TurndownService.prototype.escape = function escape(string) {
    return string;
  };
  const turndownService = new TurndownService({ headingStyle: 'atx' });

  turndownService.addRule('strikethrough', {
    filter: ['s', 'del'],
    replacement(content) {
      return `~~${content}~~`;
    },
  });

  const htmlToMarkdown = (html: any) => {
    let markdown = turndownService.turndown(html);
    markdown = markdown.replace(/[ \t\r]+\n/g, '\n');
    markdown = markdown.replace(/\s+\n\n/g, '\n\n');
    return markdown;
  };

  const editor = useEditor({
    content: html,
    extensions: [
      StarterKit,
      Markdown,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
    ],
    onUpdate: ({ editor }) => {
      onChange(htmlToMarkdown(editor.getHTML()));
    },
    editorProps: {
      attributes: {
        class:
          'border border-gray-400 py-4 px-0 min-h-250 overflow-y-auto outline-none prose overflow-custom max-w-none',
      },
    },
  });

  return (
    <>
      {editor && (
        <section className="buttons border-t border-x flex w-full flex-wrap items-center gap-x-12 border-soft-purple-redx bg-soft-purple-redx px-15 py-4 text-black-redx">
          <button
            className={`inline-flex h-24 w-auto items-center p-4 ${
              editor.isActive('bold') ? 'rounded bg-blue-redx text-white' : ''
            }`}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            <Icon className="size-16" icon="lucide:bold" />
          </button>
          <button
            className={`inline-flex h-24 w-auto items-center p-4 ${
              editor.isActive('italic') ? 'rounded bg-blue-redx text-white' : ''
            }`}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            <Icon className="size-16" icon="lucide:italic" />
          </button>
          <button
            className={`inline-flex h-24 w-auto items-center p-4 ${
              editor.isActive('strike') ? 'rounded bg-blue-redx text-white' : ''
            }`}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            onClick={() => editor.chain().focus().toggleStrike().run()}
          >
            <Icon className="size-16" icon="lucide:strikethrough" />
          </button>
          <button
            className={`inline-flex h-24 w-auto items-center p-4 ${
              editor.isActive('bulletList')
                ? 'rounded bg-blue-redx text-white'
                : ''
            }`}
            disabled={!editor.can().chain().focus().toggleBulletList().run()}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          >
            <Icon className="size-16" icon="lucide:list" />
          </button>
          <button
            className={`inline-flex h-24 w-auto items-center p-4 ${
              editor.isActive('orderedList')
                ? 'rounded bg-blue-redx text-white'
                : ''
            }`}
            disabled={!editor.can().chain().focus().toggleOrderedList().run()}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
          >
            <Icon className="size-16" icon="lucide:list-ordered" />
          </button>
          <button
            className="inline-flex h-24 w-auto items-center p-4"
            disabled={!editor.can().chain().focus().undo().run()}
            onClick={() => editor.commands.undo()}
          >
            <Icon className="size-16" icon="lucide:undo-2" />
          </button>
          <button
            className="inline-flex h-24 w-auto items-center p-4"
            disabled={!editor.can().chain().focus().redo().run()}
            onClick={() => editor.commands.redo()}
          >
            <Icon className="size-16" icon="lucide:redo-2" />
          </button>
        </section>
      )}
      <EditorContent editor={editor} />
    </>
  );
};

export default MarkdownEditor;
