import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import TypeIt from 'typeit-react';

import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import RequestAccessModal from '@/Components/Modals/RequestAccess';
import PasswordInput from '@/Components/PasswordInput';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import RequestAccessToast from '@/Components/Toast/RequestAccess';
import validation from '@/Helpers/validation';
import { useLogin } from '@/Hooks/useLogin';
import GuestLayout from '@/Layouts/GuestLayout';
import type { LoginFormValues } from '@/Types/Login/login';

const Login = ({ status }: { status?: string; canResetPassword: boolean }) => {
  const { mutate, isPending } = useLogin();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const forgotPasswordLink = import.meta.env.VITE_FORGOT_URL;

  const {
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
    getValues,
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: '',
      password: '',
      remember: false,
      no_access: null,
    },
  });
  const [formRequest, setFormRequest] = useState({ email: '', password: '' });

  useEffect(() => {
    reset({ password: '' });
  }, [reset]);

  const updateFormRequest = (callback: () => void) => {
    setFormRequest({
      email: getValues('email') || '',
      password: getValues('password') || '',
    });

    callback();
  };

  const openRequestAccess = () => {
    toast.remove();
    updateFormRequest(() => openModal());
  };

  useEffect(() => {
    if (errors.no_access) {
      toast.custom((t) => (
        <RequestAccessToast handleClick={openRequestAccess} t={t} />
      ));
    }
  }, [errors]);

  const updateFormField = async (
    field: 'email' | 'password',
    value: string,
  ) => {
    const validationRules = {
      email: () =>
        // eslint-disable-next-line no-nested-ternary
        !_.isEmpty(value)
          ? validation.isEmail(value)
            ? ''
            : 'Invalid email address!'
          : 'The email field is required',
      password: () =>
        // eslint-disable-next-line no-nested-ternary
        !_.isEmpty(value)
          ? validation.isPassword(value)
            ? ''
            : 'Invalid password!'
          : 'The password field is required',
    };

    const error = validationRules[field];
    if (error) {
      setError(field, { type: 'manual', message: error() });
    }
  };

  const onSubmit = async (data: LoginFormValues) => {
    toast.remove();
    updateFormField('email', data.email);
    updateFormField('password', data.password);

    const validationRules = {
      email: () => validation.isEmail(data.email),
      password: () => validation.isPassword(data.password),
    };
    const emailValid = validationRules.email;
    const passwordValid = validationRules.password;

    if (emailValid() && passwordValid()) {
      await mutate(
        { email: data.email, password: data.password },
        {
          onSuccess: () => {
            localStorage.removeItem('progressStates');
            navigate('/projects');
          },
          onError: (error) => {
            if (error.message.includes('email')) {
              setError('email', {
                type: 'manual',
                message: 'Invalid email address!',
              });
            }

            if (error.message.includes('password')) {
              setError('password', {
                type: 'manual',
                message: 'Invalid password!',
              });
            }

            if (error.message.includes('approved')) {
              setFormRequest({
                email: data.email,
                password: data.password,
              });
              toast.custom((t) => (
                <RequestAccessToast handleClick={openRequestAccess} t={t} />
              ));
            }
          },
        },
      );
    }
  };

  useEffect(() => {
    const token = Cookies.get('access_token');
    if (token) {
      navigate('/projects');
    } else {
      queryClient.removeQueries();
    }
  }, []);

  return (
    <GuestLayout>
      <Helmet>
        <title>Login - Ainstein</title>
      </Helmet>
      {status && (
        <div className="text-sm mb-4 font-medium text-green-600">{status}</div>
      )}
      <TypeIt
        className="relative h-auto w-1/2 text-50 text-white md:hidden"
        getBeforeInit={(instance) => {
          instance
            .type('Meet your<br/><b>AI marketing brain</b>')
            .pause(750)
            .delete(40)
            .pause(500)
            .type('24/7<br/><b>Agency Assistant</b>')
            .pause(750)
            .delete()
            .pause(500)
            .type('The more you use it,<br/><b>the smarter it gets</b>')
            .pause(750)
            .delete()
            .pause(500)
            .type('Let’s help you<br/><b>build that proposal</b>')
            .pause(750)
            .delete()
            .pause(500)
            .type('Let’s make your client<br/><b>love you more</b>')
            .pause(750)
            .delete()
            .pause(500);
          return instance;
        }}
        options={{
          loop: true,
        }}
      />
      <form
        className="relative rounded-20 bg-white/30 bg-clip-padding px-73 py-60 backdrop-blur-none md:max-w-xs md:px-30 md:py-50 lg:min-w-538"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <h1 className="mb-10 text-20 font-medium leading-30 text-white">
            Get ready to explore AInstein!
          </h1>
        </div>
        <div className="mb-20">
          <InputLabel className="!text-white" htmlFor="email" value="Email" />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextInput
                autoComplete="username"
                className="mt-1 block w-full"
                containerClassName="border-b-2 border-white text-white focus-within:!border-white"
                id="email"
                isFocused
                light
                placeholder="Input your RedComm email here"
                type="text"
                {...field}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                ) => {
                  toast.remove();
                  field.onChange(e);
                }}
              />
            )}
          />
          <InputError className="mt-2" light message={errors.email?.message} />
        </div>
        <div className="">
          <InputLabel
            className="!text-white"
            htmlFor="password"
            value="Password"
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <PasswordInput
                autoComplete="current-password"
                className="mt-1 block w-full"
                containerClassName="border-b-2 border-white text-white focus-within:!border-white"
                id="password"
                light
                placeholder="Input your password here"
                {...field}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  toast.remove();
                  field.onChange(e);
                }}
              />
            )}
          />
          <InputError
            className="mt-2"
            light
            message={errors.password?.message}
          />
        </div>
        <div className="mb-30 mt-5 text-right text-10 font-semibold text-white md:py-10 md:text-12">
          <a href={forgotPasswordLink}> Forgot password? </a>
        </div>
        <div className="mt-4 flex items-center">
          <PrimaryButton
            className="ms-4 w-full bg-blue-redx py-10 text-12 hover:bg-indigo-700 active:bg-indigo-700"
            disabled={isPending}
          >
            Login
          </PrimaryButton>
        </div>
      </form>
      <RequestAccessModal
        formRequest={formRequest}
        isOpen={isOpen}
        onClose={closeModal}
      />
    </GuestLayout>
  );
};

export default Login;
