import { useMutation } from '@tanstack/react-query';

import { goFetcher } from '@/Services/axios-go';
import type { UpdateFrameworkResponse } from '@/Types/Submission';
import type { CompetitorSchema } from '@/Types/Submission/schema';

const updateCompetitorSubmission = async ({
  projectSlug,
  payload,
}: {
  projectSlug: string;
  payload: CompetitorSchema;
}) => {
  try {
    const { data } = await goFetcher.put(
      `/projects/${projectSlug}/submission/competitors`,
      {
        competitors: payload.competitors,
      },
    );

    return data as UpdateFrameworkResponse;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateCompetitorSubmission = () => {
  return useMutation<
    UpdateFrameworkResponse,
    Error,
    { projectSlug: string; payload: CompetitorSchema }
  >({
    mutationFn: updateCompetitorSubmission,
  });
};

export { useUpdateCompetitorSubmission };
