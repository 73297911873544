/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Icon } from '@iconify/react';
import React, { useEffect, useRef, useState } from 'react';

import { useClickOutside } from '@/Hooks/useClickOutside';

interface IInputSelectItem {
  title: string;
  value: string | number;
  disabled?: boolean;
}

interface InputSelectProps {
  modelValue: string | number | (string | number)[];
  list: IInputSelectItem[];
  disabled: boolean;
  multiple: boolean;
  placeholder: string;
  withFilter: boolean;
  containerClassName?: string;
  error?: string;
  onChange: (value: string | number | (string | number)[]) => void;
}

const InputDropdown: React.FC<InputSelectProps> = ({
  modelValue,
  list,
  disabled,
  multiple,
  placeholder,
  withFilter = true,
  onChange,
  containerClassName = '',
  error = '',
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [filterText, setFilterText] = useState('');
  const elementRef = useRef<HTMLDivElement>(null);
  const elementFilterTextRef = useRef<HTMLInputElement>(null);

  const isItemEmpty = !+modelValue;
  const item = list.find((listItem) => listItem.value === modelValue);
  const items = list.filter((listItem) => {
    if (Array.isArray(modelValue)) {
      return modelValue.find(
        (modeValueItem) => modeValueItem === listItem.value,
      );
    }
    return false;
  });
  const newList = list.filter((listItem) =>
    listItem.title.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleClickOutside = () => {
    setDropdownActive(false);
  };

  useClickOutside(elementRef, handleClickOutside);

  useEffect(() => {
    if (dropdownActive) {
      setFilterText('');
      setTimeout(() => {
        elementFilterTextRef.current?.focus();
      }, 0);
    }
  }, [dropdownActive]);

  useEffect(() => {
    if (!multiple) {
      const modelValueItem = list.find(
        (listItem) => listItem.value === modelValue,
      );
      if (modelValueItem) {
        onChange(modelValueItem.value);
      }
    }
  }, []);

  const handleChoose = (listItem: IInputSelectItem) => {
    if (multiple) {
      const newModelValue = Array.isArray(modelValue) ? [...modelValue] : [];
      const isItemSelected = newModelValue.find(
        (newModelValueItem) => listItem.value === newModelValueItem,
      );
      if (isItemSelected) {
        const newModelValueIndex = newModelValue.findIndex(
          (newModelValueItem) => listItem.value === newModelValueItem,
        );
        newModelValue.splice(newModelValueIndex, 1);
      } else {
        newModelValue.push(listItem.value);
      }
      onChange(newModelValue);
    } else {
      onChange(listItem.value);
    }
    setDropdownActive(false);
  };

  const handleFilterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newList.length > 0) {
      handleChoose(newList[0]);
    }
  };

  return (
    <>
      <div ref={elementRef} className="relative">
        <div
          className={`flex w-full items-center justify-between overflow-hidden bg-transparent ${
            disabled ? '' : 'cursor-pointer'
          } ${containerClassName} ${error ? '!border-red-500' : ''}`}
          onClick={() => !disabled && setDropdownActive(!dropdownActive)}
        >
          {!multiple && (
            <div
              className={`mr-20 px-0 py-4 !text-14 ${
                isItemEmpty ? 'text-placeholder-redx' : ''
              }`}
            >
              {!isItemEmpty ? item?.title : placeholder}
            </div>
          )}
          {multiple && (
            <>
              {items.length === 0 && (
                <div className="text-placholder-redx mr-20 px-2 py-4 !text-14">
                  {placeholder}
                </div>
              )}
              {items.length !== 0 && (
                <div className="-mx-2 flex flex-wrap items-center">
                  {items.map((itemItem, index) => (
                    <div key={index} className="mb-4 px-2">
                      <div className="flex overflow-hidden rounded-4 bg-blue01/10">
                        <div className="px-8 !text-14 font-medium text-blue01 text-line-1">
                          {itemItem.title}
                        </div>
                        {!disabled && (
                          <div
                            className="items-stretch bg-blue01 px-5"
                            onClick={() => handleChoose(itemItem)}
                          >
                            <div className="flex h-full items-center">
                              <Icon
                                className="size-5 text-white"
                                icon="mdi:close"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {!disabled && (
            <Icon
              className={`h-w-24 w-24 text-black transition-all duration-300 ease-out ${
                dropdownActive ? 'rotate-180' : ''
              }`}
              icon="uil:angle-down"
            />
          )}
        </div>
        {dropdownActive && (
          <div
            className="absolute left-8 top-full z-50 mt-10 w-[97.5%] rounded-10 bg-white p-10"
            style={{
              boxShadow: '0px 0px 12px 0px #00000026',
            }}
          >
            {withFilter && (
              <div className="mb-10">
                <input
                  ref={elementFilterTextRef}
                  className="w-full rounded-5 px-10 py-8 !text-14 focus:outline-none"
                  onChange={(e) => setFilterText(e.target.value)}
                  onKeyUp={handleFilterKeyPress}
                  placeholder="Search..."
                  type="text"
                  value={filterText}
                />
              </div>
            )}
            <div
              ref={elementRef}
              className="overflow-custom max-h-120 w-full overflow-y-auto py-0"
            >
              {newList.map((listItem, index) => (
                <div key={index} className="flex items-center">
                  <div className="flex-1">
                    <div
                      className={`rounded-5 px-10 py-5 text-12 ${
                        !listItem.disabled
                          ? 'cursor-pointer hover:bg-blue01/10'
                          : ''
                      }`}
                      onClick={() =>
                        !listItem.disabled && handleChoose(listItem)
                      }
                    >
                      <div
                        className={
                          multiple &&
                          items.find((itemItem) => itemItem === listItem)
                            ? 'font-medium text-blue01'
                            : !multiple && item === listItem
                              ? 'font-medium text-blue01'
                              : !listItem.disabled
                                ? ''
                                : 'text-black text-opacity-50'
                        }
                      >
                        {listItem.title}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {error && <p className="mt-2 text-12 text-red-500">{error}</p>}
    </>
  );
};

export default InputDropdown;
