import React from 'react';

import PrimaryButton from '@/Components/PrimaryButton';
import { joinEmails } from '@/Utils/helper';

const RequestAccessModal: React.FC<{
  emails: string[];
  isOpen: boolean;
  onClose: () => void;
  onClickRequest: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ emails, isOpen, onClose, onClickRequest }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="absolute inset-0 z-20 flex size-full items-center justify-center overflow-y-auto bg-black/40">
      <div className="relative max-w-md overflow-hidden rounded-2xl bg-white p-20 transition-all">
        {joinEmails(emails)} did not have access for Ainstein.
        <div className="mt-30 flex items-center justify-end gap-x-10">
          <PrimaryButton
            className="ms-4 bg-transparent px-20 py-10 text-12 font-semibold !text-black-redx"
            onClick={onClose}
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            className="ms-4 border-1 bg-blue-redx px-20 py-10 text-12 hover:bg-blue-redx active:bg-blue-redx"
            onClick={(e) => onClickRequest(e)}
          >
            Request Access
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default RequestAccessModal;
